import React, { useGlobal, useMemo, useState, useEffect, useRef } from "reactn"
import { get } from "lodash"
import { useTranslation } from "react-i18next"
import { convertCVData, convertDetailCV } from "src/services/config"
import {
  CardDetailsOfferWrapper,
  DetailsOfferWrapper,
  DetailsOfferLeftWrapper,
  DetailsOfferRightWrapper,
  LeftLogoWrapper,
  FooterCardWrapper,
  Footer,
  Contact,
  Informations,
  Left,
  PointsInterest,
  Profile,
  Right,
  StyledDetails,
  Prefix,
  Wrapper,
  Avatar,
} from "src/markup/Components/Cards/CardCV/styles"
import CVSummary from "src/markup/Components/Cards/CVSummary"
import { MecButton } from "src/markup/Components"
import { ASSET_QUALITYMAIN_URL, ASSET_QUALITY2_URL, ASSET_QUALITY3_URL, CV_STATUS, LEVELS } from "src/constants"
import { useHistory } from "react-router-dom"
import { MecTabSwitch } from "src/markup/Components/Forms"

import IconDepanneur from "src/images/icons/jobs/depanneur.png"
import { ReactComponent as IconCar } from "src/images/icons/icon-car.svg"
import { ReactComponent as IconEdit } from "src/images/icons/icon-edit.svg"
import { ReactComponent as IconView } from "src/images/icons/eye.svg"
import { ReactComponent as IconRemove } from "src/images/icons/icon-remove.svg"
import { ReactComponent as IconUser } from "src/images/icons/icon-user.svg"
import { ReactComponent as IconTelephone } from "src/images/icons/icon-telephone.svg"
import { ReactComponent as IconAddress } from "src/images/icons/icon-address.svg"
import { ReactComponent as IconEmail } from "src/images/icons/icon-email.svg"
import { ReactComponent as IconPlus } from "src/images/icons/icon-plus.svg"
import { ReactComponent as IconChecked } from "src/images/icons/icon-checked.svg"
import { ReactComponent as IconPDF } from "src/images/icons/pdf-icon.svg"
import { ReactComponent as IconShare } from "src/images/icons/share-icon.svg"
import { apiCVDetail, updateCv } from "src/services/api"
import { useFormik } from "formik"
import { CVFormvalidationSchema } from "./FormValidation"
import { toast } from "react-toastify"
import { isEmpty } from "lodash"
import html2pdf from "html2pdf.js"

const CardCV = ({ data, isDetails, handleViewDetail, view, onDelete, shareCV }) => {
  const myCvRef = useRef(null)

  const { t } = useTranslation()
  const history = useHistory()
  const [inexperienced, setInexperienced] = useState([])

  const [config] = useGlobal("config")
  const [user] = useGlobal("user")

  const cvDetail = useMemo(() => {
    if (config && data) {
      return convertCVData(config, data)
    }

    return null
  }, [config, data])

  const [initialFormValue, setInitialFromValue] = useState()

  useEffect(() => {
    ;(async () => {
      if (data?.id) {
        try {
          if (config) {
            const _cv = await apiCVDetail(data?.id)
            _cv && setInitialFromValue(convertDetailCV(config, _cv))
          }
        } finally {
        }
      }
    })()
  }, [data?.id, config])

  useEffect(() => {
    data?.experiences.length === 0 && setInexperienced((prevState) => [...prevState, "experiences"])
    data?.formations.length === 0 && setInexperienced((prevState) => [...prevState, "formations"])
  }, [data])

  const formik = useFormik({
    initialValues: initialFormValue,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: () => CVFormvalidationSchema({ t, isDraft: false, inexperienced }),
    onSubmit: async (values) => {
      const valuesSubmit = { ...values }
      inexperienced.map((value) => (valuesSubmit[value] = []))

      valuesSubmit.experiences = valuesSubmit.experiences.map((experience) => ({
        ...experience,
        id: null,
      }))

      valuesSubmit.formations = valuesSubmit.formations.map((formation) => ({
        ...formation,
        id: null,
        domain_activity: formation.domain_activity?.id,
        formDiploma: { id: formation.formDiploma?.id, name: formation.formDiploma?.name },
        formDiplomaOption: { id: formation.formDiplomaOption?.id, name: formation.formDiplomaOption?.name },
      }))

      valuesSubmit.mission = valuesSubmit.mission.map((mission) => ({
        ...mission,
        id: null,
        search_mission: { id: mission.search_mission?.id, name: mission.search_mission?.name },
        search_specMission: mission.search_specMission?.map((specMission) => ({
          id: specMission.id,
          name: specMission.name,
        })),
      }))

      valuesSubmit.skill = valuesSubmit.skill.map((skill) => ({
        ...skill,
        id: null,
        search_skill: { id: skill.search_skill?.id, name: skill.search_skill?.name },
        search_skillComp: skill.search_skillComp?.map((skillComp) => ({
          id: skillComp.id,
          name: skillComp.name,
        })),
      }))

      valuesSubmit.tools = valuesSubmit.tools.map((tool) => ({
        ...tool,
        keyId: null,
      }))

      valuesSubmit.search_qualityMain = valuesSubmit.search_qualityMain.map((quality) => ({
        qualityMainId: quality.qualityMainId,
        name: quality.name,
      }))

      valuesSubmit.languages = valuesSubmit.languages.map((language) => ({
        id: language.id,
        name: language.name,
      }))
      valuesSubmit.search_quality2 = valuesSubmit.search_quality2?.id
      valuesSubmit.search_quality3 = valuesSubmit.search_quality3?.id
      valuesSubmit.joblistId = valuesSubmit.joblistId?.id
      valuesSubmit.search_exp = valuesSubmit.search_exp?.id
      valuesSubmit.pres_jobOtherName = valuesSubmit.pres_jobOtherName?.id

      try {
        await updateCv({ ...valuesSubmit, id: data.id })
        toast.success("Le CV a été mis à jour avec succès")
        history.push("/candidate/mycv")
      } catch (error) {
        return error
      }
    },
  })

  const { setFieldValue, isSubmitting, handleSubmit, errors } = formik

  const handleExportPDF = async () => {
    const element = myCvRef.current

    const clonedElement = element.cloneNode(true)

    const tempContainer = document.createElement("div")
    tempContainer.style.position = "absolute"
    tempContainer.style.left = "-9999px"
    tempContainer.style.backgroundColor = "#ffffff"
    tempContainer.appendChild(clonedElement)
    document.body.appendChild(tempContainer)

    const overrideColors = (node) => {
      if (node instanceof Element) {
        node.style.setProperty("color", "#023f73", "important")
        node.style.setProperty("background-color", "#ffffff", "important")
        node.style.setProperty("border-color", "#e8eef2", "important")

        if (node.classList.contains("title")) {
          node.style.setProperty("color", "#ff7200", "important")
        }

        Array.from(node.children).forEach(overrideColors)
      }
    }

    try {
      const footerButtons = clonedElement.querySelector(".cv-footer")
      if (footerButtons) {
        footerButtons.style.display = "none"
      }

      overrideColors(clonedElement)

      const styleSheet = document.createElement("style")
      styleSheet.textContent = `
        * {
          color: #023f73 !important;
          background-color: #ffffff !important;
          border-color: #e8eef2 !important;
        }
        body {
          transform: scale(1.2);
          transform-origin: top left
        }
        h3 {
          font-size: 20px !important;
        }
        .title {
          color: #ff7200 !important;
          font-size: 28px !important;
        }
        .job {
          color: #ff7200 !important;
        }
        .content span:first-child {
          color: #ff7200 !important;
        }
      `
      clonedElement.appendChild(styleSheet)

      const opt = {
        margin: [10, 0, 10, 0],
        filename: `CV-${get(cvDetail, "nameCV", "")}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          scale: 1.5,
          useCORS: true,
          logging: true,
          backgroundColor: "#ffffff",
          letterRendering: true,
          removeContainer: true,
          allowTaint: true,
        },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
          compress: true,
          putTotalPage: true,
          margin: [15, 15, 20, 15],
        },
      }

      await html2pdf().set(opt).from(clonedElement).save()
    } catch (error) {
      console.error("Error generating PDF:", error)
      toast.error("Une erreur est survenue lors de la génération du PDF")
    } finally {
      if (document.body.contains(tempContainer)) {
        document.body.removeChild(tempContainer)
      }
    }
  }

  return (
    <Wrapper>
      <Prefix> {get(cvDetail, "nameCV", "")} </Prefix>
      <CardDetailsOfferWrapper ref={myCvRef} name_cv={get(cvDetail, "nameCV", "")}>
        <DetailsOfferWrapper className="row justify-content-between">
          <DetailsOfferLeftWrapper border>
            <LeftLogoWrapper>
              <div className="d-flex justify-content-start w-100 title-offers mb-4">
                {get(data, "user.profilepicture", "") ? (
                  <Avatar className="mr-3">
                    <img src={get(data, "user.profilepicture", "")} alt="avatar" />
                  </Avatar>
                ) : (
                  <IconUser className="cv-avatar mr-3" width={100} height={100} />
                )}
                <div className="d-flex flex-column justify-content-around">
                  <span>{`${get(data, "user.surname", "")} ${get(data, "user.name", "")}`}</span>
                  <div className="d-flex align-items-center">
                    <IconCar className="mr-2 icon-reponsive" />
                    <span className="job">{get(cvDetail, "jobName[0].name", "")}</span>
                  </div>
                </div>
              </div>
            </LeftLogoWrapper>
            <p className="job">{get(cvDetail, "jobName[0].name", "")}</p>
            <CVSummary cvDetail={cvDetail} />
          </DetailsOfferLeftWrapper>

          <DetailsOfferRightWrapper className="d-flex flex-column justify-content-between" border>
            <div className="d-flex mb-4 list-skills">
              {get(cvDetail, "qualityMainCard", []).length > 0 ? (
                get(cvDetail, "qualityMainCard", []).map((quality) => (
                  <div className="d-flex flex-column align-items-center" key={quality.qualityMainId}>
                    <img
                      className="icon-skills mb-2 icon-reponsive"
                      src={`${ASSET_QUALITYMAIN_URL}${get(quality, "qualityMainCardValue[0].filename", null)}`}
                      alt={get(quality, "qualityMainCardValue[0].name", "")}
                    />
                    <span className="card-text-home text-first-capitalize">
                      {get(quality, "qualityMainCardValueG[0].name", "")}
                    </span>
                  </div>
                ))
              ) : (
                <IconPlus className="dont-have-skill" />
              )}

              {get(cvDetail, "quality2Card", []).length > 0 && get(cvDetail, "quality2Card", [])?.[0] !== null ? (
                get(cvDetail, "quality2Card", []).map((quality2) => (
                  <div className="d-flex flex-column align-items-center" key={quality2?.[0]?.id}>
                    <img
                      className="icon-skills mb-2 icon-reponsive"
                      src={`${ASSET_QUALITY2_URL}${get(quality2?.[0], "filename", "")}`}
                      alt={get(quality2?.[0], "title", "")}
                    />
                    <span className="card-text-home text-first-capitalize">{get(quality2?.[0], "title", "")}</span>
                  </div>
                ))
              ) : (
                <IconPlus className="dont-have-skill" />
              )}

              {get(cvDetail, "quality3Card", []).length > 0 && get(cvDetail, "quality3Card", [])?.[0] !== null ? (
                get(cvDetail, "quality3Card", []).map((quality3) => (
                  <div className="d-flex flex-column align-items-center" key={quality3?.[0]?.id}>
                    <img
                      className="icon-skills mb-2 icon-reponsive"
                      src={`${ASSET_QUALITY3_URL}${get(quality3?.[0], "filename", "")}`}
                      alt={get(quality3?.[0], "title", "")}
                    />
                    <span className="card-text-home text-first-capitalize">{get(quality3?.[0], "title", "")}</span>
                  </div>
                ))
              ) : (
                <IconPlus className="dont-have-skill" />
              )}
            </div>
            <ul className="job-description infos text-blue ml-4 w-100">
              <li>
                <b>Localisation :</b> {get(cvDetail, "city", "")}
              </li>
              <br></br>
              <li>
                <b>Niveau d'expérience :</b> {get(cvDetail, "levelExp[0].name", "")}
              </li>
              <br></br>
              <li>
                <b>Dernière expérience :</b>{" "}
                {get(data, "experiences", [])
                  .map((exp) => `${exp.expJobName} [${exp.expCompany}]`)
                  .join(" - ") || "Sans expérience"}
              </li>
              <br></br>
              <li>
                <b>Diplôme :</b>{" "}
                {get(cvDetail, "formations", [])
                  .map((form) => `${form?.diplomaValue?.[0]?.name || ""} ${form?.diplomaOptionValue?.[0]?.name || ""}`)
                  .join(" - ") || "Sans formation"}
              </li>
            </ul>
          </DetailsOfferRightWrapper>

          <StyledDetails active={view === data.id}>
            <div className="title mt-4">CV détaillé</div>

            <div className="row mt-0 mt-xl-5 mb-5">
              <Left className="col-12 col-xl-4">
                <Profile>
                  <h3>PROFIL</h3>
                  <div className="pl-3 pr-3">
                    Dans le cadre de mes expériences <br />
                    passées, j'ai pu me renforcer dans <br />
                    {get(cvDetail, "skills", [])
                      .map((item) => item?.[0]?.name)
                      .join(", ")}
                    . <br />
                    <br /> Maîtrisant{" "}
                    {get(cvDetail, "skillComps", [])
                      .map((item) => item?.[0]?.name)
                      .join(", ")}
                    , <br />
                    je recherche un poste dans lequel je pourrais contribuer à{" "}
                    {get(cvDetail, "mission", [])
                      .map((item) => item?.[0]?.name)
                      .join(", ")}
                    . <br /> <br />
                    Étant{" "}
                    {get(cvDetail, "qualityMain", [])
                      .map((item) => item?.[0]?.name)
                      .join(", ")}
                    , j'ai{" "}
                    {get(cvDetail, "quality2", [])
                      .map((item) => item?.[0]?.title)
                      .join(", ")}{" "}
                    et je suis{" "}
                    {get(cvDetail, "quality3", [])
                      .map((item) => item?.[0]?.name)
                      .join(", ")}
                    . Je saurais répondre à vos attentes et surtout{" "}
                    {get(cvDetail, "missionComp", [])
                      .map((item) => item?.[0]?.name)
                      .join(", ")}
                    . Je parle{" "}
                    {get(cvDetail, "languages", [])
                      .map((item) => item?.[0]?.name)
                      .join(", ")}{" "}
                    est ma langue maternelle.
                  </div>
                </Profile>

                <Contact>
                  <h3>CONTACT</h3>
                  <div className="pl-3 pr-3 content">
                    <div className="d-flex mb-3">
                      <IconTelephone className="mr-2" width="40" height="40" />
                      <div className="d-flex flex-column">
                        <span>TÉLÉPHONE</span>
                        <span>{user?.phonenumber}</span>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <IconAddress className="mr-2" width="40" height="40" />
                      <div className="d-flex flex-column">
                        <span>ADRESSE</span>
                        <span>{user?.address}</span>
                        <span>
                          {user?.postalcode}, {user?.city}
                        </span>
                        <span>{user?.country}</span>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <IconEmail className="mr-2" width="40" height="40" />
                      <div className="d-flex flex-column">
                        <span>E-MAIL</span>
                        <span>{user?.email}</span>
                      </div>
                    </div>
                  </div>
                </Contact>

                <PointsInterest>
                  <h3>CENTRES D'INTÉRÊT</h3>
                  <div className="pl-3 pr-3">{cvDetail?.extra_otherInfos}</div>
                </PointsInterest>

                <Informations>
                  <h3>INFORMATIONS COMPLÉMENTAIRES</h3>
                  <div className="pl-3 pr-3 d-flex">
                    <div className="mr-5 d-flex flex-column">
                      {cvDetail?.extra_driversLicenceB && (
                        <>
                          <img src={IconDepanneur} alt="icon-france" className="icon-reponsive" />
                          <span>Permis B</span>
                        </>
                      )}
                    </div>
                  </div>
                </Informations>
              </Left>
              <Right className="col-12 col-xl-7 ml-0 ml-xl-5">
                <Profile>
                  <h3>FORMATION</h3>
                  <div className="pl-3 pr-3">
                    {Array.isArray(cvDetail?.formations) ? (
                      cvDetail.formations.map((form) => (
                        <div className="d-flex flex-column mb-3" key={form?.id || Math.random()}>
                          {(form?.diplomaValue?.[0]?.name || form?.diplomaOptionValue?.[0]?.name) && (
                            <span>
                              {form?.diplomaValue?.[0]?.name} {form?.diplomaOptionValue?.[0]?.name}
                            </span>
                          )}

                          {(form?.formSchool || form?.formEntryDate || form?.formExitDate) && (
                            <span>
                              {form?.formSchool} {form?.formEntryDate && "- " + form.formEntryDate}{" "}
                              {form?.formExitDate && "- " + form.formExitDate}
                            </span>
                          )}
                          {form?.formMissions && <span className="mt-2">{form.formMissions}</span>}
                        </div>
                      ))
                    ) : (
                      <div>Aucune formation disponible</div>
                    )}
                  </div>
                </Profile>

                <Contact>
                  <h3>PARCOURS PROFESSIONNEL</h3>
                  <div className="pl-3 pr-3">
                    {get(cvDetail, "exp", []).map((ex) => (
                      <div className="d-flex flex-column mb-3" key={ex?.id}>
                        <span>
                          {get(ex, "expJobName", "")} - {get(ex, "expCompany", "")}
                        </span>
                        <span>
                          {get(ex, "expEntryDate", "")} - {!ex.expExitDate ? "Présent" : ex.expExitDate}
                        </span>
                        <span className="mt-2">{get(ex, "expMissions", "")}</span>
                      </div>
                    ))}
                  </div>
                </Contact>

                <PointsInterest>
                  <h3>COMPÉTENCES</h3>
                  <div className="pl-3 pr-3 process-wrapper">
                    {get(cvDetail, "tools", []).map((tool) => (
                      <div key={tool?.[0]?.id} className="mb-4">
                        <span className="mr-5 col-12 mb-2">{get(tool, "[0].name", "")}</span>
                        <MecTabSwitch
                          tabs={LEVELS}
                          activeTab={tool.level - 1}
                          className="col-12 mb-2"
                          name="tools"
                          style={{ pointerEvents: "none" }}
                        />
                      </div>
                    ))}
                  </div>
                </PointsInterest>
              </Right>
            </div>
          </StyledDetails>

          {!isDetails && (
            <Footer className="cv-footer w-100 mt-5 justify-content-end">
              <FooterCardWrapper onSubmit={handleSubmit}>
                {data.status === CV_STATUS.DRAFT && isEmpty(errors) && (
                  <MecButton
                    icon={IconChecked}
                    type="primary isSubmit"
                    widthIcon={24}
                    height={44}
                    className="text-capitalize"
                    onClick={(e) => {
                      setFieldValue("status_cv", CV_STATUS.PUBLISHED)
                    }}
                  >
                    {isSubmitting ? "..." : "Publier"}
                  </MecButton>
                )}

                <MecButton
                  icon={IconView}
                  widthIcon={24}
                  height={44}
                  type="primary"
                  className="site-button site-button-gap radius-xl text-capitalize"
                  onClick={(e) => {
                    e.preventDefault()
                    handleViewDetail(data.id)
                  }}
                >
                  {view === data.id ? "Réduire" : "Voir"}
                </MecButton>

                <MecButton
                  icon={IconPDF}
                  widthIcon={24}
                  height={44}
                  type="primary"
                  className="site-button site-button-gap radius-xl text-capitalize"
                  onClick={async (e) => {
                    e.preventDefault()
                    try {
                      if (view === data.id) {
                        await handleExportPDF()
                      } else {
                        await handleViewDetail(data.id)
                        await handleExportPDF()
                      }
                    } catch (error) {
                      console.error("Error in handling view detail:", error)
                    }
                  }}
                >
                  {t("PDF")}
                </MecButton>

                <MecButton
                  icon={IconShare}
                  widthIcon={24}
                  height={44}
                  type="primary"
                  className="site-button site-button-gap radius-xl text-capitalize"
                  onClick={(e) => {
                    e.preventDefault()
                    shareCV()
                  }}
                >
                  {t("Partager")}
                </MecButton>

                <MecButton
                  icon={IconEdit}
                  widthIcon={24}
                  height={44}
                  type="primary"
                  className="site-button site-button-gap radius-xl text-capitalize"
                  onClick={(e) => {
                    e.preventDefault()
                    history.push(`/candidate/mycv/update/${data.id}`)
                  }}
                >
                  {t("Edit")}
                </MecButton>
                <MecButton
                  icon={IconRemove}
                  widthIcon={24}
                  height={44}
                  type="primary"
                  className="site-button site-button-gap radius-xl text-capitalize"
                  onClick={(e) => {
                    e.preventDefault()
                    onDelete(data.id)
                  }}
                >
                  {t("Delete")}
                </MecButton>
              </FooterCardWrapper>
            </Footer>
          )}
        </DetailsOfferWrapper>
      </CardDetailsOfferWrapper>
    </Wrapper>
  )
}

export default CardCV
