import React, { useGlobal } from "reactn"
import { FormGroup } from "reactstrap"
import moment from "moment"
import { find } from "lodash"

import * as S from "./styles"
import { MecInput, MecSelector } from "src/markup/Components/Forms"
import { MecButton } from "src/markup/Components"
import { ReactComponent as IconSearch } from "src/images/icons/search.svg"
import { FORMAT_DATE } from "src/constants"

const Lists = ({ handleViewMessage, messageView, messages, formik, contacts, mode }) => {
  const [user] = useGlobal("user")
  const { errors, touched, values, setFieldValue, setFieldTouched, handleSubmit, isSubmitting } = formik

  return (
    <S.MessageReceivedAndSent onSubmit={handleSubmit}>
      <S.Header>
        <div className="row">
          <FormGroup className="col d-flex">
            <div className="label text-align-last-left label-required mr-2 pt-3">
              {mode === "RECEIVED" ? "De" : "À"} :
            </div>
            <div style={{ flex: 1 }}>
              <MecSelector
                options={contacts}
                getOptionLabel={({ email }) => email}
                getOptionValue={({ email }) => email}
                className="text-align-last-left"
                colorTxtError="red"
                name="email"
                onChange={(option) => {
                  setFieldValue("email", option.email)
                }}
                onBlur={() => setFieldTouched("email", true)}
                value={values.email}
                errors={errors.email}
                touched={touched.email}
              />
            </div>
          </FormGroup>

          <FormGroup className="col d-flex">
            <div className="label text-align-last-left label-required mr-2 pt-3">Objet :</div>
            <div style={{ flex: 1 }}>
              <MecInput
                className="text-align-last-left secondary"
                name="object"
                formik={formik}
                colorTxtError="red"
                style={{ minWidth: "auto" }}
              />
            </div>
          </FormGroup>

          <FormGroup className="col d-flex">
            <MecButton
              icon={IconSearch}
              height={44}
              width={200}
              type="primary"
              className="site-button site-button-gap radius-xl change-fill"
            >
              {isSubmitting ? "..." : "Rechercher"}
            </MecButton>
          </FormGroup>
        </div>
        <hr />
      </S.Header>
      <S.ListWrapper>
        {messages?.map((message) => (
          <S.List
            className="m-0 p-0"
            key={message.id}
            onClick={() => handleViewMessage(message)}
            active={messageView?.id === message.id}
            style={find(message.user_read, (id) => id === user.id) && { fontWeight: "200" }}
          >
            <div className="row">
              <div className="col d-flex pt-3">
                <div className="label text-align-last-left label-required mr-2">
                  {mode === "RECEIVED" ? message.group_messages_users[0].email : message.groups_users_receive[0].email}
                </div>
              </div>

              <div className="col d-flex pt-3">
                <div className="label text-align-last-left label-required mr-2">{message.object_title}</div>
              </div>

              <div className="col d-flex pt-3">
                <div className="label text-align-last-left label-required mr-2">
                  {moment(message.createdAt).format(FORMAT_DATE)}
                </div>
              </div>
            </div>
          </S.List>
        ))}
      </S.ListWrapper>
    </S.MessageReceivedAndSent>
  )
}

export default Lists
