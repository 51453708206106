import React from "react"
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import fr from "date-fns/locale/fr"
import getMonth from "date-fns/getMonth"
import getYear from "date-fns/getYear"
import MaskedTextInput from "react-text-mask"
import { range } from "lodash"

import * as S from "./styles"
import { FORMAT_DATE_FR } from "src/constants"

registerLocale("fr", fr)

export const MecDatePicker = ({
  onChange,
  date,
  colorTxtError,
  touched,
  errors,
  handleBlur,
  name,
  disabled,
  placeholder,
  isTime,
  ...rest
}) => {
  const years = range(1990, getYear(new Date()) + 1, 1)
  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ]

  return (
    <S.Wrapper isTime={isTime}>
      <div className="position-relative">
        <S.WrapperMecDatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <S.CustomHeader
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <S.ButtonDatePicker
                onClick={(e) => {
                  e.preventDefault()
                  decreaseMonth()
                }}
                disabled={prevMonthButtonDisabled}
              >
                {"<"}
              </S.ButtonDatePicker>

              <S.SelectMonth
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </S.SelectMonth>

              <S.SelectYear value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </S.SelectYear>

              <S.ButtonDatePicker
                onClick={(e) => {
                  e.preventDefault()
                  increaseMonth()
                }}
                disabled={nextMonthButtonDisabled}
              >
                {">"}
              </S.ButtonDatePicker>
            </S.CustomHeader>
          )}
          name={name}
          selected={date}
          onChange={onChange}
          onBlur={handleBlur}
          isDisabled={disabled}
          locale="fr"
          disabled={disabled}
          error={touched && !!errors}
          placeholderText={placeholder || "jj/mm/aaaa"}
          dateFormat={FORMAT_DATE_FR}
          customInput={
            <MaskedTextInput type="text" mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]} />
          }
          {...rest}
        />
        <S.Icon />
      </div>
      <S.Feedback colorTxtError={colorTxtError}>{touched && errors}</S.Feedback>
    </S.Wrapper>
  )
}
