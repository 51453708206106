import { memo } from 'react'

const ProcessStep = memo(({ step }) => (
  <div className="step-item">
    <img src={step.icon} alt={step.title} className="step-icon" />
    <div className="step-info">
      <h5>{step.title}</h5>
      {step.description && <p>{step.description}</p>}
    </div>
  </div>
))

export default ProcessStep