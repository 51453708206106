import React, { useGlobal } from 'reactn'
import Markup from './markup/Markup'
import SEO from './components/SEO'
import usePageTracking from 'src/hooks/usePageTracking';
import { GlobalModal } from 'src/markup/Pages/Modal'
import { loadUserFromStorage } from 'src/services/auth'
import { loadDataModalFromStorage } from 'src/services'
import { fetchConfigData, fetchFormulas } from 'src/services/config'
import { useComponentWillMount, not } from 'src/utils'
import './css/plugins.css'
import './css/style.css'
import './css/template.css'
import './css/skin/skin-1.css'
import './plugins/slick/slick.min.css'
import './plugins/slick/slick-theme.min.css'
import "react-loading-skeleton/dist/skeleton.css"

const App = () => {
  const [user, setUser] = useGlobal('user')
  const [modal, setModal] = useGlobal('modal')
  const [config] = useGlobal('config')
  const [formulas] = useGlobal('formulas')

  useComponentWillMount(() => {
    if (not(user)) {
      setUser(loadUserFromStorage())
    }
    if (not(modal)) {
      setModal(loadDataModalFromStorage())
    }
    if (not(config)) {
      fetchConfigData();
    }
    if (not(formulas)) {
      fetchFormulas()
    }
  });

  usePageTracking();

  return (
    <div className="App">
      <SEO />
      <Markup />
      <GlobalModal />
    </div>
  )
}

export default App
