import React, { useCallback, useEffect, useState, useGlobal, useMemo } from "reactn"
import Header from "src/markup/Layout/Header"
import SidebarRecruiter from "src/markup/Layout/SidebarRecruiter"
import OfferForms from "src/markup/Element/OfferForms"
import Footer from "src/markup/Layout/Footer"
import { recruiterGetListOffersDetail } from "src/services/api"
import { populateOffer } from "src/services/config"
import { genderize } from "src/utils"
import { Loading } from "src/markup/Element/Loading"
import * as S from "./styles"
import { isEmpty } from 'lodash'

const getInitalFormValues = (config, offer, gender) => {
  const data = populateOffer(config, offer)
  const xp = (() => genderize({ male: "xp_m", female: "xp_f", both: "xp", gender }))()
  const quanlityMain = (() =>
    genderize({ male: "qualityMain_m", female: "qualityMain_f", both: "qualityMains", gender }))()

    return  {
      name_company: offer?.name_company,
      pres_city: offer?.pres_city,
      pres_zip: offer?.pres_zip,
      recruit_recruitObj:
        offer?.recruitmentJobs?.length > 0
          ? {
              id: offer?.recruitmentJobs?.[0]?.objectiveId,
              name: data?.recruiterObjectives?.[0],
            }
          : null,
      recruit_responsability:
        offer?.recruitmentJobs?.length > 0
          ? {
              id: offer?.recruitmentJobs?.[0]?.responsabilityId,
              name: data?.responsabilities?.[0],
            }
          : null,
      joblistId: offer?.joblistJobs?.[0]?.mainJobId
        ? {
            id: offer?.joblistJobs?.[0]?.mainJobId,
            name: data?.jobNames?.[0],
          }
        : null,
      pres_jobOtherName: offer?.joblistJobs?.[0]?.mainJobId
        ? {
            joblistId: offer?.joblistJobs?.[0]?.mainJobId,
            id: offer?.joblistJobs?.[0]?.otherJobId,
            name: data?.otherJobNames?.[0],
          }
        : null,
      search_exp: data?.[xp]?.[0],

      search_minDiploma: offer?.joblistJobs?.[0]?.mainJobId
        ? {
            joblistId: offer?.joblistJobs?.[0]?.mainJobId,
            id: offer?.formationJobs?.[0]?.xpId,
            name: data?.diplomas?.[0],
          }
        : null,
      mission:
        offer?.missionJobs?.length > 0
          ? data?.customMission
          : [
              {
                id: 1,
                search_mission: null,
                search_specMission: [],
              },
            ],
      search_qualityMain: data?.[quanlityMain],
      search_quality2: data?.quality2?.[0],
      search_quality3: data?.quality3?.[0],
      skill:
        offer?.skillJobs?.length > 0
          ? data?.customSkill
          : [
              {
                id: 1,

                search_skill: null,
                search_skillComp: [],
              },
            ],
      tools: offer?.toolsJobs?.map((tool, index) => ({
        joblistId: offer?.joblistJobs?.[0]?.mainJobId,
        id: tool.toolId,
        name: data?.tools?.[index],
      })),
      languages: data?.languages,
      extra_driversLicenceOther: offer?.driverLicenseOtherJobs?.map((driverLicanLicense, index) => ({
        id: driverLicanLicense.licenseId,
        name: data?.driverLicenseOthers?.[index],
      })),
      extra_criminalRecord: offer?.extra_criminalRecord,
      recruit_contractType: offer?.recruitmentJobs?.[0]?.contractId
        ? {
            id: offer?.recruitmentJobs?.[0]?.contractId,
            name: data?.diplomas?.[0],
          }
        : null,
      salary: offer?.salary,
      adv_advantages: offer?.advantages?.map((advantage, index) => ({
        id: advantage.advantageId,
        name: data?.advantages?.[index],
      })),
      otherAdvantage: offer?.otherAdvantage,
      featured_offer: offer?.featured_offer,
      offer_logo: offer?.offer_logo,
      status: offer?.status,
    }
}

export default function RecruiterCreateOffer({ match }) {
  const [offer, setOffer] = useState({})
  const [initial, setInitial] = useState({})
  const [loading, setLoading] = useState(false)

  const [config] = useGlobal("config")
  const [user] = useGlobal("user")
  const { id } = match.params
  const { gender } = user

  const handleGetOffer = useCallback(async () => {
    setLoading(true)
    const res = await recruiterGetListOffersDetail(id)
    setOffer(res)
    setLoading(false)
  }, [id])

  useEffect(() => {
    id && handleGetOffer()
  }, [handleGetOffer, id])

  useEffect(() => {
    setInitial(getInitalFormValues(config, offer, gender))
  }, [offer, config, gender])

  const isModifiedInput = useMemo(() => {
    if (isEmpty(offer)) {
      return false;
    }

    return offer?.CVJobliaisons?.length
  }, [offer])

  return (
    <div className="page-wraper">
      <Header recruiter />

      <div className="container-content">
        <div className="d-flex">
          <SidebarRecruiter />

          <div style={{ textAlignLast: "center", marginTop: "50px", width: 'calc(100% - 60px)' }}>
            <div className="d-flex justify-content-center">
              <S.Heading>{id ? `Mise à jour de l'offre #${id}` : "Créez votre offre d'emploi"}</S.Heading>
            </div>
            <hr className="v3-separator pb-xl-3" />

            <div className="container position-relative">
              {loading && <Loading />}
              <OfferForms initial={initial} offerId={parseInt(id)} isModifiedInput={isModifiedInput} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
