import React, { useState, useEffect, useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";
import _find from "lodash/find";

import {
  SidebarWrapper,
  SlideMultipleSearchIcon,
  AdvertisementIcon,
  GroupWorkIcon,
} from "src/css/Sidebar";
import sidebar from "src/images/gallery/sidebar-recruiter.jpg";
import SidebarProfile from "src/markup/Layout/SidebarProfile";
import { apiGetNotifications } from 'src/services/api'
import { get } from "lodash";

const renderSideBar = (document) => [
  {
    title: "Mes offres d'emploi",
    icon: AdvertisementIcon,
    subs: [
      {
        title: "Nouvelle annonce",
        to: "/recruiter/myoffer/create",
      },
      {
        title: "Annonces publiées",
        to: "/recruiter/myoffer",
      },
      {
        title: "Brouillons",
        to: "/recruiter/myoffer/draft",
      },
    ],
  },
  {
    title: "Recherche de profils",
    icon: SlideMultipleSearchIcon,
    subs: [
      {
        title: "Rechercher",
        to: "/recruiter/search-cv",
      },
      {
        title: "Recherches sauvegardées",
        to: "/recruiter/saved-search",
      },
    ],
  },
  {
    title: "Recrutement",
    icon: SlideMultipleSearchIcon,
    subs: [
      {
        title: "CV reçus",
        to: "/recruiter/application/received",
        badgeNumber: get(document, "jobs.cvReceived", 0)
      },
      {
        title: "CV sélectionnés",
        to: "/recruiter/application/selected",
      },
      {
        title: "CV retenus",
        to: "/recruiter/application/accepted",
      },
      {
        title: "CV refusés",
        to: "/recruiter/application/declined",
      },
    ],
  },
  {
    title: "Agenda",
    icon: GroupWorkIcon,
    subs: [
      {
        title: "Mes entretiens",
        to: "/recruiter/agenda/list",
      },
      {
        title: "Planifier un entretien",
        to: "/recruiter/agenda/schedule-meeting",
      },
    ],
  },
  {
    title: "Messagerie",
    icon: GroupWorkIcon,
    subs: [
      {
        title: "Nouveau message",
        to: "/recruiter/message/new",
      },
      {
        title: "Messages reçus",
        to: "/recruiter/message/received",
        badgeNumber: get(document, 'messages.received', 0 ),
      },
      {
        title: "Messages envoyés",
        to: "/recruiter/message/sent",
      },
      {
        title: "Messages archivés",
        to: "/recruiter/message/archived",
      },
    ],
  },
];

const SidebarRecruiter = () => {
  const [activeSub, setActiveSub] = useState("");
  const location = useLocation();
  const { pathname } = location;
  const [sidebarConfig, setSidebarConfig] = useState(() => renderSideBar());

  const getNotificationCountAsync = useCallback(async () => {
    try {
      const notifs = await apiGetNotifications();
      setSidebarConfig(renderSideBar(notifs))
    } catch (error) {
      console.log(error);
    }
  }, [])

  useEffect(() => {
    getNotificationCountAsync()
  }, [getNotificationCountAsync])

  return (
    <SidebarWrapper className="div-sidebar">
      <nav className="nav-sidebar-wrapper">
        {sidebarConfig.map((sidebar, idx) => (
          <div
            className="menu-myoffers menu-sidebar"
            onClick={() =>
              setActiveSub((prevState) =>
                prevState === sidebar.title ? "" : sidebar.title
              )
            }
            key={idx}
          >
            <div
              className={`sidebar-item ${
                _find(sidebar.subs, (sub) => sub.to === pathname) && "is-active"
              }`}
            >
              <sidebar.icon />
              <div className="menu-title">{sidebar.title}</div>
            </div>
            <ul
              className={`sub-menu-wrapper ${
                activeSub === sidebar.title && "active-sub"
              }`}
            >
              {sidebar.subs.map((sub, idx) => (
                <NavLink
                  exact
                  to={sub.to}
                  className="sub-menu-item"
                  activeClassName={"is-active"}
                  key={idx}
                >
                  {!!sub.badgeNumber && (
                    <div className="sub-menu-notification">{sub.badgeNumber > 10 ? '10+' : sub.badgeNumber }</div>
                  )}
                  <div className="sub-item-title">{sub.title}</div>
                </NavLink>
              ))}
            </ul>
          </div>
        ))}

        <SidebarProfile
          baseRoute="recruiter"
          activeSub={activeSub}
          setActiveSub={setActiveSub}
        />
      </nav>

      <div className="sidebar-pic">
        <img src={sidebar} alt="Sidebar" />
      </div>
    </SidebarWrapper>
  );
};

export default SidebarRecruiter;
