import { memo } from "react";
import "./index.scoped.scss";
import { FEATURE } from "../constants";
import FeatureCard from "./FeatureCard";

function WhyChooseUs() {
  return (
    <section className="why-choose-us">
      <div className="title">
        <h3>POURQUOI CHOISIR MECANOJOB ?</h3>
      </div>
      <div className="feature-content">
        {FEATURE.map((feature) => (
          <FeatureCard key={feature.id} feature={feature} />
        ))}
      </div>
    </section>
  );
}

export default memo(WhyChooseUs);
