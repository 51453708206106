import React, { Component } from "react";
// import {Link} from 'react-router-dom';
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";

class StaticHelpCandidate extends Component {
  render() {
    return (
      <div className="page-wraper">
        <Header showTitle={false} />
        <div className="page-content bg-light">
            <div className="col" style={{ textAlignLast: "center", marginBottom: "50px" }}>
                <div className="d-flex justify-content-center">
                    <h1 className="m-b5 text-uppercase text-orange pt-5">Aides Candidats</h1>
                </div>
                <hr className="v3-separator" />
            </div>

            <div className="justify-content-left m-5">

            </div>

        </div>
        <Footer />
      </div>
    );
  }
}

export default StaticHelpCandidate;
