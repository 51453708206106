import React, { useEffect, useState, useGlobal, useCallback } from "reactn";
import * as S from "./styles";
import { Card } from "src/markup/Pages/Agenda/Recruiter/components";
import { recruiterListAgenda, recruiterDeleteAgenda } from "src/services/api";
import { Loading } from "src/markup/Element/Loading";
import { ConfirmModal } from "src/markup/Components/Modals";
import { toast } from "react-toastify";

export const ScheduleInterview = () => {
  const [listAgenda, setListAgenda] = useState([]);
  const [config] = useGlobal("config");
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({ showConfirmModal: false });
  
  const fetchAPI = useCallback(() => {
    setLoading(true);
    recruiterListAgenda()
      .then((result) => setListAgenda(result))
      .catch(console.log)
      .finally(() => setLoading(false));
  }, []);

  const handleDeleteAgenda = useCallback(async (id) => {
    recruiterDeleteAgenda(id)
      .then(() => {
        toast.success("Entretien supprimé");
        setModal({ showConfirmModal: false });
        fetchAPI();
      })
      .catch((error) => error);
  }, [fetchAPI]);

  const showDeleteAgendaModal = useCallback(
    async (id) => {
      setModal({
        showConfirmModal: true,
        onYes: () => handleDeleteAgenda(id),
        onNo: () => setModal({ showConfirmModal: false }),
        Description: "Voulez-vous supprimer l'entretien ?",
      });
    },
    [handleDeleteAgenda]
  );

  useEffect(() => {
    fetchAPI();
  }, [fetchAPI]);

  return (
    <S.Wrapper>
      <div className="d-flex justify-content-center">
        <S.Heading>Agenda ({listAgenda.length})</S.Heading>
      </div>
      <hr className="v3-separator pb-xl-3" />

      <S.WrapperContent>
        {loading && <Loading />}
        <div className="row align-items-center justify-content-between">
          {listAgenda.map((agenda) => (
            <div key={agenda.id} className="col-12 pb-4" >
              <Card
                agenda={agenda}
                config={config}
                onDelete={showDeleteAgendaModal}
              />
            </div>
          ))}
        </div>
      </S.WrapperContent>
      <ConfirmModal {...modal} />
    </S.Wrapper>
  );
};
