import axios from "axios"
import { createBrowserHistory } from "history"
import { toast } from "react-toastify"
import { API_URL } from "src/constants"
import { getUserFromGlobal, logout } from "./auth"

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;

const LOGOUT_STATUS = [UNAUTHORIZED, FORBIDDEN];

const axiosClient = axios.create({
  baseURL: API_URL,
})

axiosClient.interceptors.request.use(async (config) => {
  const user = getUserFromGlobal();

  if (user?.accessToken) {
      config.headers["x-access-token"] = user.accessToken
  }

  return config
})

axiosClient.interceptors.response.use(
  (response) => {
    if (response?.data) return response.data

    return response
  },
  async (error) => {
    const { status = undefined, data = undefined } = error?.response || {};

    const originalRequest = error.config

    if (LOGOUT_STATUS.includes(status) && !originalRequest._retry) {
      logout();
      createBrowserHistory().push("/")
    }

    if (data?.message) {
      toast.error(data.message, {
        autoClose: 2000,
        theme: "colored",
      })
    }

    return Promise.reject(error?.response?.data)
  }
)

export default axiosClient;
