import styled, { css } from "styled-components"
import { Form } from "reactstrap"

export const Wrapper = styled.div`
  position: relative;
  margin-top: 120px;
`

export const Prefix = styled.div`
  position: absolute;
  width: auto;
  height: 163px;
  left: 0px;
  top: -80px;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
  border-radius: 40px;
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #023f73;
  padding: 20px;
`
export const CardDetailsOfferWrapper = styled.div`
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  background-color: #fff;
  padding: 30px;
  position: relative;

  .d-inline-grid {
    display: inline-grid;
  }

  @media (min-width: 1024px) {
    padding: 60px;

    &::before {
      top: -95px;
      font-size: 35px;
      line-height: 41px;
      padding: 30px;
    }
  }
`

export const DetailsOfferWrapper = styled.div``

export const DetailsOfferLeftWrapper = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: #023f73;

  @media (min-width: 1024px) {
    border-right: ${(props) => props.border && "5px solid #e8eef2"};
    font-size: 1.25rem;
    line-height: 1.75rem;

    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
`

export const LeftLogoWrapper = styled.div`
  display: flex;
  justify-content: center;

  .title-offers {
    span:first-child {
      font-weight: 600;
      font-size: 17px;
      line-height: 25px;
      color: #ff7200;
    }
    span:last-child {
      font-weight: 800;
      font-size: 13px;
      line-height: 20px;
      color: #023f73;
    }

    .cv-avatar {
      /* transform: scale(0.6); */
    }
  }

  @media (min-width: 1024px) {
    .title-offers {
      span:first-child {
        font-size: 22px;
        line-height: 34px;
      }
      span:last-child {
        font-size: 19px;
        line-height: 27px;
      }
    }
  }

  @media (min-width: 1440px) {
    .title-offers {
      span:first-child {
        font-size: 29px;
        line-height: 39px;
      }
      span:last-child {
        font-size: 22px;
        line-height: 27px;
      }
    }
  }
`

export const LeftContentWrapper = styled.div`
  padding: 0;

  div {
    font-weight: 800;
    font-size: 18.4202px;
    line-height: 22px;
    color: #023f73;
    white-space: pre-line;
  }

  div:nth-child(2) {
    font-weight: 400;
    font-size: 14.7361px;
    line-height: 17px;
    margin-top: 15px;
    max-width: 75%;
    margin-left: 10%;
  }

  h3 {
    font-size: 24px;
  }

  @media (min-width: 1024px) {
    padding: 20px 0;

    h3 {
      font-size: 28px;
    }
  }
`

export const DetailsOfferRightWrapper = styled.div`
  margin-top: 20px;
  border-bottom: ${(props) => props.border && "2px solid #e8eef2"};
  flex: 0 0 100%;
  max-width: 100%;

  .list-skills {
    flex-wrap: no-wrap;
    justify-content: space-evenly;
    div {
      width: 100px;
      text-align: center;
    }
    svg,
    img {
      width: 40px;
      height: 40px;
    }
  }

  .job-description {
    list-style-type: none;
    max-width: 100%;
  }

  @media (min-width: 1024px) {
    border: none;

    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  @media (min-width: 1440px) {
    .list-skills {
      svg,
      img {
        width: 70px;
        height: 70px;
      }
    }
  }
`

export const FooterCardWrapper = styled(Form)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  width: 100%;
  max-width: 800px;

  .mec-button:first-child {
    display: block !important;
  }

  .icon-center {
    svg {
      transform: rotate(90deg);
      path {
        fill: #ff7200;
      }
    }
  }
`

export const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .text-right {
    color: #023f73;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 1440px) {
    justify-content: space-between;

    .text-right {
      margin-bottom: 0px;
    }
  }
`

const customSpan = css`
  span:first-child {
    font-weight: bold;
    color: #ff7200;
  }

  span:nth-child(2) {
    font-weight: bold;
  }
`

export const StyledCardDetails = styled.div`
  .styled-action {
    .note {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.75rem;
      color: #ff7200;
      margin-top: 15px;
    }
  }
`

export const Card = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
  border-radius: 30px;

  & > div {
    border: none;
    box-shadow: none;
  }

  .icon-skills {
    circle {
      fill: #002d56;
    }
  }

  .footer {
    padding: 0px 60px 60px 60px;
  }
`
export const StyledDetails = styled.div`
  padding: 0px 30px;
  overflow: hidden;
  transition: max-height 0.5s;
  height: 100%;
  max-height: ${(props) => (props.active ? "3000px" : 0)};

  .title {
    font-weight: 600;
    font-size: 35px;
    line-height: 42px;
    color: #ff7200;
  }
`
export const Left = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: #023f73;

  h3 {
    font-weight: 500;
    font-size: 25px;
    line-height: 37px;
    color: #023f73;
    margin-top: 20px;
  }

  @media (min-width: 1200px) {
    border-right: 5px solid #e8eef2;
  }
`

export const Profile = styled.div`
  ${customSpan}
`
export const Contact = styled.div`
  .content {
    span:first-child {
      font-weight: bold;
      color: #ff7200;
    }
  }
  ${customSpan}
`
export const PointsInterest = styled.div`
  .process-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  @media (min-width: 1600px) {
    .process-wrapper {
      flex-direction: column;
      align-items: flex-end;
      & > div {
        justify-content: space-between;
      }
    }
  }
`

export const Process = styled.div`
  background: #e8eef2;
  width: 500px;
  height: 30px;
  border-radius: 30px;

  &::before {
    content: ${({ percent }) => `"${percent}%"`};
    display: block;
    background: #bcced8;
    width: ${({ percent }) => `${percent}%`};
    height: 30px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #023f73;
  }
`

export const Informations = styled.div``

export const Right = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: #023f73;

  h3 {
    font-weight: 500;
    font-size: 25px;
    line-height: 37px;
    color: #023f73;
    margin-top: 20px;
  }
`

export const Avatar = styled.div`
  width: 100px;
  height: 100px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
`
