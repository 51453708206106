import React, { useRef } from "reactn"
import { FormGroup } from "reactstrap"
import { find, includes } from "lodash"

import { MecCollapse, MecInputMultiple, MecInput } from "src/markup/Components/Forms"
import { MecButton } from "src/markup/Components/MecButton"
import { StyledForm, StyledLabel, Minus } from "src/markup/Element/CVForm/styles"
import { MecDatePicker } from "src/markup/Components/MecDatePicker"
import { ReactComponent as IconPlush } from "src/images/icons/icon-plus.svg"
import { ReactComponent as IconMinus } from "src/images/icons/icon-minus.svg"
import { addDays, subDays } from "src/services/config"

const Experience = ({
  formik,
  handleAddExperience,
  handleCompleted,
  inexperienced,
  handleInexperienced,
  isOpen,
  handleOpenCollapse,
  handleDeleteItem,
  handleStillWorking,
  stillWorking,
}) => {
  let isCompleted = formik.values?.experiences?.map((experience) => {
    return find(experience, (value) => value === "")
  })
  const experienceRef = useRef(null)

  function scrollExperience() {
    experienceRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  return (
    <MecCollapse
      formik={formik}
      title="Quelle est votre expérience ?"
      completed={!includes(isCompleted, "") || includes(inexperienced, "experiences")}
      isOpen={isOpen === "experiences"}
      setIsOpen={() => handleOpenCollapse("experiences")}
      name="isCompleted"
      className="mb-5"
    >
      <StyledForm>
        <div className="row d-flex justify-content-end align-items-center" ref={experienceRef}>
          <FormGroup
            className="d-flex justify-content-end align-items-center"
            onClick={() => handleInexperienced("experiences")}
          >
            <div className="py-2 label text-align-last-left">Je suis sans expérience</div>
            <div>
              <MecInput
                type="radio"
                formik={formik}
                className="text-align-last-left w-auto custom-radio"
                // name="experience"
                checked={includes(inexperienced, "experiences")}
              />
            </div>
          </FormGroup>
        </div>

        {formik.values?.experiences?.map((experience, index) => (
          <div key={experience.id}>
            <div className="row">
              <FormGroup className="col-12">
                <StyledLabel
                  className="py-2 text-align-last-left label-required"
                  inexperienced={includes(inexperienced, "experiences")}
                >
                  Intitulé du poste
                </StyledLabel>
                <MecInputMultiple
                  formik={formik}
                  onChange={(e) => {
                    formik.setFieldValue(`experiences.${index}.expJobName`, e.target.value)
                  }}
                  disabled={includes(inexperienced, "experiences")}
                  value={formik.values.experiences?.[index]?.expJobName}
                  errors={formik.errors.experiences?.[index]?.expJobName}
                  touched={formik.touched.experiences?.[index]?.expJobName || formik.touched.expJobName}
                  className="secondary text-align-last-left"
                  placeholder="Quel poste occupiez-vous ?"
                  name="expJobName"
                />
              </FormGroup>
            </div>
            <div className="row">
              <FormGroup className="col-12">
                <StyledLabel
                  className="py-2 text-align-last-left label-required"
                  inexperienced={includes(inexperienced, "experiences")}
                >
                  Nom de l'employeur
                </StyledLabel>
                <MecInputMultiple
                  formik={formik}
                  onChange={(e) => {
                    formik.setFieldValue(`experiences.${index}.expCompany`, e.target.value)
                  }}
                  disabled={includes(inexperienced, "experiences")}
                  value={formik.values.experiences?.[index]?.expCompany}
                  errors={formik.errors.experiences?.[index]?.expCompany}
                  touched={formik.touched.experiences?.[index]?.expCompany || formik.touched.expCompany}
                  className="secondary text-align-last-left"
                  placeholder="Quel est le Nom entreprise/collectivité/association où vous avez travaillé ?"
                  name="expCompany"
                />
              </FormGroup>
            </div>
            <div className="row">
              <FormGroup className="col-12">
                <StyledLabel
                  className="py-2 text-align-last-left label-required"
                  inexperienced={includes(inexperienced, "experiences")}
                >
                  Début de contrat
                </StyledLabel>
                <MecDatePicker
                  name="expEntryDate"
                  onChange={(value) => {
                    formik.setFieldValue(`experiences.${index}.expEntryDate`, value)
                  }}
                  disabled={includes(inexperienced, "experiences")}
                  handleBlur={formik.handleBlur}
                  errors={formik.errors.experiences?.[index]?.expEntryDate}
                  touched={formik.touched.experiences?.[index]?.expEntryDate || formik.touched.expEntryDate}
                  selected={
                    formik.values.experiences?.[index]?.expEntryDate &&
                    new Date(formik.values.experiences?.[index]?.expEntryDate)
                  }
                  value={
                    formik.values.experiences?.[index]?.expEntryDate &&
                    new Date(formik.values.experiences?.[index]?.expEntryDate)
                  }
                  autoComplete="off"
                  // maxDate={(subDays(formik.values.experiences?.[index]?.expExitDate &&
                  //   new Date(formik.values.experiences?.[index]?.expExitDate)) || new Date())}
                  // dateFormat={formatDatepicker}
                />
              </FormGroup>
              <FormGroup className="col-12 col-xl-8">
                <StyledLabel
                  className="py-2 text-align-last-left label-required"
                  inexperienced={includes(inexperienced, "experiences")}
                >
                  Fin de contrat
                </StyledLabel>
                <MecDatePicker
                  name="expExitDate"
                  onChange={(value) => {
                    formik.setFieldValue(`experiences.${index}.expExitDate`, value)
                  }}
                  disabled={includes(inexperienced, "experiences") || includes(stillWorking, index)}
                  handleBlur={formik.handleBlur}
                  errors={formik.errors.experiences?.[index]?.expExitDate}
                  touched={formik.touched.experiences?.[index]?.expExitDate || formik.touched.expExitDate}
                  selected={
                    formik.values.experiences?.[index]?.expExitDate &&
                    new Date(formik.values.experiences?.[index]?.expExitDate)
                  }
                  value={
                    formik.values.experiences?.[index]?.expExitDate &&
                    new Date(formik.values.experiences?.[index]?.expExitDate)
                  }
                  autoComplete="off"
                  minDate={addDays(formik.values.experiences?.[index]?.expEntryDate &&
                    new Date(formik.values.experiences?.[index]?.expEntryDate))}
                />
              </FormGroup>
              <FormGroup
                className="d-flex justify-content-center align-items-end col-12 col-xl-4 pb-4"
                onClick={() => handleStillWorking(index)}
                style={{
                  opacity: includes(inexperienced, "experiences") &&  '0.5',
                  pointerEvents: includes(inexperienced, "experiences") &&  'none'
                }}
              >
                <div className="py-2 label text-align-last-left">Je suis toujours en poste</div>
                <div>
                  <MecInput
                    type="radio"
                    formik={formik}
                    className="text-align-last-left w-auto custom-radio"
                    checked={includes(stillWorking, index)}
                  />
                </div>
              </FormGroup>
            </div>
            <div className="row">
              <FormGroup className="col-12">
                <StyledLabel
                  className="py-2 text-align-last-left label-required"
                  inexperienced={includes(inexperienced, "experiences")}
                >
                  Lieu
                </StyledLabel>
                <MecInputMultiple
                  formik={formik}
                  onChange={(e) => {
                    formik.setFieldValue(`experiences.${index}.expCity`, e.target.value)
                  }}
                  disabled={includes(inexperienced, "experiences")}
                  value={formik.values.experiences?.[index]?.expCity}
                  errors={formik.errors.experiences?.[index]?.expCity}
                  touched={formik.touched.experiences?.[index]?.expCity || formik.touched.expCity}
                  className="secondary text-align-last-left"
                  placeholder="Dans quel pays/ville avez-vous travaillé ?"
                  name="expCity"
                />
              </FormGroup>
            </div>
            <div className="row">
              <FormGroup className="col-12">
                <StyledLabel
                  className="py-2 text-align-last-left label-required"
                  inexperienced={includes(inexperienced, "experiences")}
                >
                  Missions
                </StyledLabel>
                <MecInputMultiple
                  formik={formik}
                  onChange={(e) => {
                    formik.setFieldValue(`experiences.${index}.expMissions`, e.target.value)
                  }}
                  disabled={includes(inexperienced, "experiences")}
                  value={formik.values.experiences?.[index]?.expMissions}
                  errors={formik.errors.experiences?.[index]?.expMissions}
                  touched={formik.touched.experiences?.[index]?.expMissions || formik.touched.expMissions}
                  className="secondary text-align-last-left"
                  placeholder="Décrivez vos missions (détaillez au maximum pour aider votre recruteur)"
                  name="expMissions"
                />
              </FormGroup>
            </div>
            {index ? (
              <Minus onClick={() => handleDeleteItem("experiences", experience.id)}>
                <IconMinus style={{ cursor: "pointer", marginRight: "15px" }} width={30} height={30} />
                Supprimer une expérience
              </Minus>
            ) : null}
            {formik.values?.experiences?.length > 1 && (
              <hr className="v3-separator pb-xl-3" style={{ maxWidth: "100%" }}></hr>
            )}
          </div>
        ))}

        <div className="row">
          <FormGroup
            className="col-12 row d-flex justify-content-end add-experience"
            onClick={!includes(inexperienced, "experiences") ? handleAddExperience : null}
          >
            <IconPlush className={`${includes(inexperienced, "experiences") && "label-disabled"}`} />
            <StyledLabel className="py-2" inexperienced={includes(inexperienced, "experiences")}>
              Ajouter une expérience
            </StyledLabel>
          </FormGroup>
        </div>

        <div className="w-100 d-flex justify-content-center">
          <MecButton
            icon=""
            type="primary"
            width={120}
            widthIcon={20}
            className="text-capitalize"
            onClick={(e) => {
              handleCompleted(e, "experiences")
              handleOpenCollapse("training")
              scrollExperience()
            }}
            disabled={!includes(inexperienced, "experiences") && includes(isCompleted, "")}
          >
            Valider
          </MecButton>
        </div>
      </StyledForm>
    </MecCollapse>
  )
}

export default Experience
