import styled from "styled-components"
import { Form } from "reactstrap"

export const Card = styled.div`
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  background-color: #fff;
  padding: 30px;
  position: relative;
  text-align-last: left;
  margin-bottom: 30px;

  .d-inline-grid {
    display: inline-grid;
  }

  @media (min-width: 1024px) {
    padding: 60px;

    &::before {
      width: 380px;
      top: -95px;
      font-size: 35px;
      line-height: 41px;
      padding: 30px;
    }
  }
`

export const Content = styled.div``

export const ContentLeft = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: #023f73;

  @media (min-width: 1024px) {
    border-right: ${(props) => props.border && "5px solid #e8eef2"};
    font-size: 1.25rem;
    line-height: 1.75rem;

    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
`

export const LeftLogoWrapper = styled.div`
  display: flex;
  justify-content: center;

  .title-offers {
    span:first-child {
      font-weight: 600;
      font-size: 17px;
      line-height: 25px;
      color: #023f73;
    }
    span:last-child {
      font-weight: 800;
      font-size: 13px;
      line-height: 20px;
      color: #ff7200;
    }
  }

  @media (min-width: 1024px) {
    .title-offers {
      span:first-child {
        font-size: 22px;
        line-height: 34px;
      }
      span:last-child {
        font-size: 19px;
        line-height: 27px;
      }
    }
  }

  @media (min-width: 1440px) {
    .title-offers {
      span:first-child {
        font-size: 30px;
        line-height: 39px;
      }
      span:last-child {
        font-size: 22px;
        line-height: 27px;
      }
    }
  }
`

export const LeftContentWrapper = styled.div`
  padding: 0;

  div {
    font-weight: 800;
    font-size: 18.4202px;
    line-height: 22px;
    color: #023f73;
  }

  div:nth-child(2) {
    font-weight: 400;
    font-size: 14.7361px;
    line-height: 17px;
    margin-top: 15px;
    max-width: 75%;
    margin-left: 10%;
  }

  h3 {
    font-size: 24px;
  }

  @media (min-width: 1024px) {
    padding: 20px 0;

    h3 {
      font-size: 28px;
    }
  }
`

export const ContentRight = styled.div`
  margin-top: 20px;
  border-bottom: ${(props) => props.border && "2px solid #e8eef2"};
  flex: 0 0 100%;
  max-width: 100%;

  .list-skills {
    flex-wrap: wrap;
    justify-content: space-evenly;
    div {
      width: 100px;
      text-align: center;
    }
    svg,
    img {
      width: 50px;
      height: 50px;

      circle {
        fill: #002d56;
      }
    }
    .dont-have-skill {
      circle {
        fill: #ff7200;
      }
    }
  }

  .job-description {
    list-style-type: none;
    max-width: 100%;
  }

  @media (min-width: 1024px) {
    border: none;

    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  @media (min-width: 1440px) {
    .list-skills {
      div {
        text-align: center;
      }
      svg,
      img {
        width: 70px;
        height: 70px;
      }
    }
  }
`

export const FooterAction = styled(Form)`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  max-width: 800px;

  .mec-button:first-child {
    display: block !important;
  }

  .icon-center {
    svg {
      transform: rotate(90deg);
      path {
        fill: #ff7200;
      }
    }
  }
`

export const StyledFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: 1675px) {
    justify-content: space-between;
  }
`

export const FooterDate = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14.7361px;
  line-height: 17px;
  color: #023f73;
  margin-bottom: 15px;

  @media (min-width: 1440px) {
    margin-bottom: 0px;
  }
`

export const CardDetail = styled.div`
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  background-color: #fff;
  padding: 30px;
  position: relative;
  text-align-last: left;

  .d-inline-grid {
    display: inline-grid;
  }

  @media (min-width: 1024px) {
    padding: 60px;

    &::before {
      width: 380px;
      top: -95px;
      font-size: 35px;
      line-height: 41px;
      padding: 30px;
    }
  }
`

export const WrapperDetails = styled.div`
  overflow: hidden;
  transition: max-height 0.5s;
  height: 100%;
  max-height: ${(props) => (props.active ? "3000px" : 0)};
`

export const StyledDetails = styled.div`
  padding: 0px 30px;

  .title {
    font-weight: 600;
    font-size: 35px;
    line-height: 42px;
    color: #ff7200;
  }

  @media (min-width: 1200px) {
    /* padding: 0px 60px; */
  }
`
export const Left = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: #023f73;

  h3 {
    font-weight: 500;
    font-size: 25px;
    line-height: 37px;
    color: #023f73;
    margin-top: 20px;
  }

  @media (min-width: 1200px) {
    border-right: 5px solid #e8eef2;
  }
`

export const Profile = styled.div`
  font-weight: normal;
  font-size: 14.7361px;
  line-height: 17px;
  color: #023f73;

  svg {
    circle {
      fill: #023f73;
    }
  }
`

export const Right = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: #023f73;

  h3 {
    font-weight: 500;
    font-size: 25px;
    line-height: 37px;
    color: #023f73;
    margin-top: 20px;
  }
`

export const MasterWrapper = styled.div`
  & > div:first-child {
    font-weight: 800;
    font-size: 22.5336px;
    line-height: 26px;
    color: #023f73;
  }
  & > ul {
    list-style-type: none;
    line-height: 30px;
    li {
      display: flex;
    }
    li:before {
      content: "\f111";
      font-family: FontAwesome;
      display: inline-block;
      margin-left: 0px;
      margin-right: 10px;
      font-size: 10px;
      color: #ff7200;
    }
  }
`

export const ContentLeftWrapper = styled.div`
  ul {
    color: #ff7200;
    font-weight: bold;
  }
  .sub-list {
    font-weight: 500;
    font-size: 18.0269px;
    line-height: 21px;
    color: #023f73;
    list-style-type: disc;
    list-style-position: inside;
  }
`

export const InformationDetail = styled.div``
export const Detail = styled.div`
  display: flex;
  gap: 40px;

  border: 4px solid #e8eef2;
  border-radius: 15px;
  padding: 10px 40px;
  margin: 20px 0 20px 0;
  color: #023f73;

  .orange {
    path {
      fill: #ff7200;
    }
  }
`

export const Warning = styled.div`
  width: 100%;
  justify-content: center;
  margin-top: 30px;

  div {
    border: 4px solid #ff7200;
    border-radius: 15px;
    padding: 10px 40px;
    font-weight: 800;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #023f73;

    display: flex;
    align-items: center;
  }
`

export const Heading = styled.h1`
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #ff7200;

  @media (max-width: 992px) {
    font-size: 30px;
  }
`

export const IconCreate = styled.div`
  svg {
    min-width: 25%;
    max-height: 35px;
  }
`

export const WIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Package = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: #ff7200;
`
