import React from "react"
import * as S from "./styles"

export const MecSelector = ({
  className,
  name,
  options,
  colorTxtError,
  onChange,
  onBlur,
  disabled,
  value,
  errors: customErrors,
  touched: customTouched,
  placeholder: customPlaceholder,
  ...rest
}) => {
  return (
    <>
      <S.SelectComponent
        className={`"basic-single" ${className}`}
        classNamePrefix="select"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        options={options}
        disabled={disabled}
        isDisabled={disabled}
        error={customTouched && !!customErrors}
        invalid={customErrors && !!customErrors}
        placeholder={customPlaceholder ?? 'Sélectionner'}
        noOptionsMessage={() => 'Aucun choix disponible'}
        {...rest}
      />
      {customTouched && customErrors && (
        <S.Feedback colorTxtError={colorTxtError}>{customTouched && customErrors}</S.Feedback>
      )}
    </>
  )
}
