import { useState, useGlobal, useEffect } from 'reactn'
import { getProfileById, updateProfile } from 'src/services/api'
import { toast } from 'react-toastify'

export const useGetProfile = () => {
  const [{ id }] = useGlobal('user')
  const [profile, setProfile] = useState({})

  useEffect(() => {
    let subscribe = true;
    (async () => {
      const data = await getProfileById(id)
      if (subscribe) {
        setProfile(data)
      }
    })();

    return () => (
      subscribe = false
    )
  }, [id])

  return profile
}

export const onUpdateProfile = async (payload) => {
  const data = await updateProfile(payload);

  // TODO : this condition is not strong enough (check status code of HTTP response)
  if (data?.id) {
    toast.success('Mise à jour effectuée', {
      autoClose: 2000,
      theme: 'colored',
    })
  } else {
    toast.error('Erreur inconnue', {
      autoClose: 2000,
      theme: 'colored',
    })
  }
}
