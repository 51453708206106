import React, { useCallback } from 'reactn'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify"
import { MecInput } from 'src/markup/Components/Forms'
import * as S from 'src/markup/Pages/AuthPage/ConfirmPassword/Candidate/styles'
import tickIcon from 'src/images/gallery/tick-icon.png'
import { FormGroup } from 'reactstrap';
import { forgotNewPassword } from 'src/services/api';

export const CandidateConfirmPassword = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleForgotNewPassword = useCallback(async (values) => {
    try {
      const { code, password } = values;
      await forgotNewPassword({
        code,
        newPassword: password,
      });
      toast.success('Mot de passe mis à jour')
      history.push('/candidate/login');
    } catch (error) {
      console.log(error);
    }
  }, [history]);

  const formik = useFormik({
    initialValues: {
      code: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(t('required_field'))
        .min(6, 'Le mot de passe doit comporter de 6 à 20 caractères')
        .max(20, 'Le mot de passe doit comporter de 6 à 20 caractères'),
      confirmPassword: Yup.string()
        .when('password', {
          is: (val) => !!(val && val.length > 0),
          then: Yup.string().oneOf(
            [Yup.ref('password')],
            'Les mots de passe ne correspondent pas'
          ),
        })
        .required(t('required_field')),
      code: Yup.string().required(t('required_field')),
    }),
    onSubmit: (values) => handleForgotNewPassword(values),
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <S.Heading>
        RÉINITIALISER
        <div className='py-3'>
          LE MOT DE PASSE
        </div>
      </S.Heading>

      <S.NormalText className='py-4 text-center'>
        Un e-mail vous sera envoyé pour réinitialiser votre mot de passe
      </S.NormalText>
      <FormGroup>
        <MecInput
          name='code'
          type='input'
          placeholder='Entrer le code'
          formik={formik}
          colorTxtError='var(--orange300)' />
      </FormGroup>
      <FormGroup>
        <MecInput
          name='password'
          type='password'
          placeholder='Nouveau mot de passe'
          formik={formik}
          colorTxtError='var(--orange300)' />
      </FormGroup>
      <FormGroup>
        <MecInput
          name='confirmPassword'
          type='password'
          placeholder='Confirmer le mot de passe'
          formik={formik}
          colorTxtError='var(--orange300)' />
      </FormGroup>
      <div className='mx-auto'>
        <S.Button>
          <div className='d-flex justify-content-center'>
            <div className='pr-2'>
              <img src={tickIcon} alt='tick-icon' />
            </div>
            <div>Valider</div>
          </div>
        </S.Button>
      </div>
    </form>
  )
}
