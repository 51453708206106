import React from "react"
import Lottie from "react-lottie-player"

import * as S from "./styles"
import lottieJson from "./data.json"

export const Loading = () => {
  return (
    <S.Wrapper>
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width: 100, height: 100 }}
      />
    </S.Wrapper>
  )
}
