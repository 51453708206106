import { genderize } from "src/utils"

const booleanOptions = [
  { id: true, name: "Oui" },
  { id: false, name: "Non" },
]

export const YOUR_COMPANY = (user) => [
  {
    label: "Nom entreprise / enseigne",
    placeholder: "Renseignez le nom de votre entreprise / enseigne",
    name: "name_company",
    type: "string",
    display: true,
    isRequired: true,
  },
  {
    label: "Localisation",
    placeholder: "Renseignez une ville",
    name: "pres_city",
    type: "autocomplete_location",
    display: true,
    isRequired: true,
  },
  {
    label: "Souhaitez-vous mettre l'offre à la une ?",
    name: "featured_offer",
    col: 12,
    type: "boolean",
    options: booleanOptions,
    display: user?.internal,
    isRequired: false,
  },
]

export const POSITION = (gender) => [
  {
    title: "DANS QUEL CADRE RECRUTEZ-VOUS ?",
    label: "Quel est l'objectif du recrutement ?",
    placeholder: "Sélectionnez...",
    name: "recruit_recruitObj",
    optionKey: "recruiterObjectives",
    isMulti: false,
    col: 12,
    type: "object",
    isRequired: true,
  },
  {
    label: "Il sera placé sous la responsabilité...",
    placeholder: "Sélectionnez...",
    name: "recruit_responsability",
    optionKey: "responsabilities",
    isMulti: false,
    col: 12,
    type: "object",
    isRequired: true,
  },
  {
    title: "DÉCRIVEZ LE POSTE",
    label: "Pour quel poste recrutez-vous ?",
    placeholder: "Sélectionnez...",
    name: "joblistId",
    optionKey: "jobs",
    isMulti: false,
    col: '12 col-xl-6',
    type: "object",
    isRequired: true,
  },
  {
    label: "Mais encore ?",
    placeholder: "Précisez...",
    name: "pres_jobOtherName",
    optionKey: "otherJobs",
    isMulti: false,
    col: '12 col-xl-6',
    type: "object",
    dependency: "joblistId",
    isRequired: true,
  },
  {
    label: "Quel est son niveau d'expérience ?",
    placeholder: "Sélectionnez son niveau minimal",
    name: "search_exp",
    optionKey: (() => genderize({ male: "xp_m", female: "xp_f", both: "xp", gender }))(),
    isMulti: false,
    col: 12,
    type: "object",
    isRequired: true,
  },
  {
    label: "Quel est son niveau d'étude minimal ?",
    placeholder: "Sélectionnez...",
    name: "search_minDiploma",
    optionKey: "diplomas",
    isMulti: false,
    col: 12,
    type: "object",
    isRequired: true,
  },

  {
    type: "array_mission",
    name: "mission",
    col: 12,
    isRequired: true,
    fieldFirstRequired: "search_mission",
    fieldSecondRequired: "search_specMission",
    search_mission: {
      label: "Quelle sera sa mission ?",
      placeholder: "Sélectionnez la mission qu'il devra effectuer",
      name: "search_mission",
      optionKey: "missions",
      isMulti: false,
      col: 12,
      dependency: "joblistId",
      noOptionsMessage: "Veuillez sélectionner un métier...",
    },
    search_specMission: {
      label: "Plus précisément...",
      placeholder: "Sélectionnez...",
      name: "search_specMission",
      optionKey: "missionSpecs",
      isMulti: true,
      col: 12,
      dependency: "joblistId",
      noOptionsMessage: "Veuillez sélectionner une mission...",
    },
  },
]

export const PROFILE_DEFINITION = (gender) => [
  {
    label: "Quelles doivent être ses principales qualités ?",
    placeholder: "Sélectionnez 3 qualités",
    name: "search_qualityMain",
    fieldRequired: "qualityMainId",
    optionKey: (() => genderize({ male: "qualityMain_m", female: "qualityMain_f", both: "qualityMain", gender }))(),
    isMulti: true,
    col: 12,
    type: "array_object",
    isRequired: true,
  },
  {
    label: "Il devrait aussi avoir...",
    placeholder: "Sélectionnez...",
    name: "search_quality2",
    optionKey: "quality2",
    isMulti: false,
    col: '12 col-xl-6',
    type: "object",
    isRequired: true,
  },
  {
    label: "Quelles doivent être ses qualités complémentaires ?",
    placeholder: "Sélectionnez...",
    name: "search_quality3",
    optionKey: (() => genderize({ male: "quality3_m", female: "quality3_f", both: "quality3", gender }))(),
    isMulti: false,
    col: '12 col-xl-6',
    type: "object",
    isRequired: true,
  },
  {
    type: "array_mission",
    isRequired: true,
    name: "skill",
    col: 12,
    fieldFirstRequired: "search_skill",
    fieldSecondRequired: "search_skillComp",
    search_skill: {
      label: "Quelles doivent être ses compétences ?",
      placeholder: "Sélectionnez une compétence",
      name: "search_skill",
      optionKey: "skills",
      isMulti: false,
      col: 6,
      dependency: "joblistId",
      noOptionsMessage: "Veuillez sélectionner un métier...",
    },
    search_skillComp: {
      label: "Plus précisément...",
      placeholder: "Sélectionnez...",
      name: "search_skillComp",
      optionKey: "skillsComp",
      isMulti: true,
      col: 6,
      dependency: "joblistId",
      noOptionsMessage: "Veuillez sélectionner une compétence...",
    },
  },
  {
    label: "Quels sont les outils qu'il doit connaître ?",
    placeholder: "Sélectionnez un ou plusieurs outils",
    name: "tools",
    optionKey: "tools",
    isMulti: true,
    col: 12,
    type: "array_object",
    dependency: "joblistId",
    fieldRequired: "id",
    isRequired: false,
    noOptionsMessage: "Veuillez sélectionner un métier...",
  },
  {
    label: "Quelles langues doit-il parler ?",
    placeholder: "Sélectionnez une ou plusieurs langues",
    name: "languages",
    optionKey: "languages",
    isMulti: true,
    col: 12,
    type: "array_object",
    fieldRequired: "id",
    isRequired: true,
  },
  {
    label: "A-t-il un permis ?",
    placeholder: "Si oui, sélectionnez un ou plusieurs permis",
    name: "extra_driversLicenceOther",
    optionKey: "otherDriverLicenses",
    isMulti: true,
    col: 12,
    type: "array_object",
    fieldRequired: "id",
    isRequired: false,
  },
  {
    label: "Doit-il avoir un casier judiciaire vierge ?",
    name: "extra_criminalRecord",
    col: 12,
    type: "boolean",
    options: booleanOptions,
    isRequired: true,
  },
]

export const CONDITIONS = [
  {
    label: "Type de contrat",
    placeholder: "Sélectionnez un type de contrat",
    name: "recruit_contractType",
    optionKey: "contractTypes",
    isMulti: false,
    col: 12,
    type: "object",
    fieldRequired: "id",
    isRequired: true,
  },
  {
    label: "Salaire (annuel brut)",
    placeholder: "Renseignez un salaire",
    name: "salary",
    col: 12,
    type: "input",
    typeInput: "number",
    isRequired: true,
  },
  {
    label: "Avantages",
    placeholder: "Sélectionnez un ou plusieurs avantages",
    name: "adv_advantages",
    optionKey: "advantages",
    isMulti: true,
    col: 12,
    type: "array_object",
    fieldRequired: "id",
    isRequired: true,
  },
  {
    label: "Autres avantages",
    placeholder: "",
    name: "otherAdvantage",
    col: 12,
    type: "input",
    isRequired: false,
  },
]
