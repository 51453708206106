import styled from 'styled-components'
import { Container } from 'reactstrap'

export const Wrapper = styled(Container)`
  padding: 30px;
`

export const PackageFocus = styled.div`
  border: 4px solid var(--orange300);
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  min-height: 700px;
  .heading {
    height: 45px;
    padding-top: 20px;
    color: #023f73;
    font-weight: bold;
    text-align: center;
    font-size: 24px;
  }
`

export const Heading = styled.h1`
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #ff7200;

  @media(max-width: 992px) {
    font-size: 30px;
  }
`

export const ResponsivePackages = styled.div`
  display: flex;
  flex-direction: row;
  justify-contents: between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`
