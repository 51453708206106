import * as Yup from "yup"
import { FRENCH_PHONE_REG_EXP, POSTAL_CODE_REG_EXP, URL_WEB_REG_EXP } from "src/constants"
import { isSIRET, isSIREN } from "src/utils"

export const validationProfileRecruiter = ({ t }) => Yup.object({
  surname: Yup.string().required(t("required_field")).nullable(true),
  name: Yup.string().required(t("required_field")).nullable(true),
  field_function: Yup.string().required(t("required_field")).nullable(true),
  email: Yup.string().email().required(t("required_field")).nullable(true),
  phonenumber: Yup.string()
    .matches(FRENCH_PHONE_REG_EXP, t("invalid_field"))
    .required(t("required_field"))
    .nullable(true),
  telephone_portable: Yup.string()
    .matches(FRENCH_PHONE_REG_EXP, t("invalid_field"))
    .nullable(true),
  SIRET: Yup.string()
    .min(14, "Le SIRET doit comporter au moins 14 caractères").required(t("invalid_field"))
    .test('siret-format', "Veuillez entrer un numéro SIRET ou SIREN valide", (value) => isSIRET(value) || isSIREN(value))
    .nullable(true),
  Enterprisename: Yup.string().required(t("required_field")).nullable(true),
  enterprisephonenumber: Yup.string()
    .matches(FRENCH_PHONE_REG_EXP, t("invalid_field"))
    .nullable(true),
  enterpriseweb: Yup.string().matches(URL_WEB_REG_EXP, t("invalid_field")).nullable(true),
  address: Yup.string().required(t("required_field")).nullable(true),
  postalcode: Yup.string()
    .matches(POSTAL_CODE_REG_EXP, t("invalid_field"))
    .required(t("required_field"))
    .nullable(true),
  city: Yup.string().required(t("required_field")).nullable(true),
  country: Yup.string().required(t("required_field")).nullable(true),
})
