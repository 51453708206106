import React, { useGlobal, useMemo } from "reactn"
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { get } from "lodash"

import * as S from "./styles"
import { MecButton } from "src/markup/Components"
import { not, pluralize } from "src/utils"

import { ReactComponent as IconCar } from "src/images/icons/icon-car.svg"
import { ReactComponent as IconView } from "src/images/icons/eye.svg"
import { ReactComponent as IconPhone } from "src/images/icons/phone.svg"
import { ReactComponent as IconEmail } from "src/images/icons/emailoutline.svg"
import { ReactComponent as IconUser } from "src/images/icons/icon-user.svg"
import { ReactComponent as IconPlus } from "src/images/icons/icon-plus.svg"
import IconDepanneur from "src/images/icons/jobs/depanneur.png"
import { ReactComponent as IconTelephone } from "src/images/icons/icon-telephone.svg"
import { ReactComponent as IconAddress } from "src/images/icons/icon-address.svg"

import CVSummary from "src/markup/Components/Cards/CVSummary"
import { ASSET_QUALITY2_URL, ASSET_QUALITY3_URL, ASSET_QUALITYMAIN_URL, LEVELS } from "src/constants"
import { convertCVData } from "src/services/config"
import { MecTabSwitch } from "src/markup/Components/Forms"

const Card = ({ mode, cv: { cv }, handleViewDetail, view }) => {
  const [config] = useGlobal("config")
  const [user] = useGlobal("user")

  const gender = cv?.user?.gender?.toLowerCase()

  const { t } = useTranslation()

  // const WORDING_BY_MODE = {
  //   received: "reçu",
  //   declined: "refusé",
  //   accepted: "retenu",
  //   selected: "sélectionné",
  // }

  const jobName = cv?.joblistCVs?.map((joblistcvs, index) => {
    const jobNameValue = config?.jobs?.find((jobs) => joblistcvs.jobId === jobs?.id)
    if (not(jobNameValue)) return null
    return jobNameValue;
  })

  const config_qualityMain = gender ? config?.[`qualityMain_${gender}`] : config?.qualityMain

  const qualityMainCard = cv?.qualityMains?.map((qualityMain, index) => {
    const qualityMainCardValue = config?.qualityMain?.filter(
      (qualityMains) => qualityMain.qualityMainId === qualityMains.id
    )
    const qualityMainCardValueG = config_qualityMain?.filter(
      (qualityMains) => qualityMain.qualityMainId === qualityMains.id
    )
    if (not(qualityMainCardValue)) return null
    return (
      <div className="d-flex flex-column align-items-center" key={index}>
        <img className="mx-3" src={ASSET_QUALITYMAIN_URL + qualityMainCardValue?.[0]?.filename} alt={index + 1} />
        <div className="text-first-capitalize card-text mx-3">{qualityMainCardValueG?.[0]?.name}</div>
      </div>
    )
  })

  const quality2Card = cv?.quality2s?.map((Quality2, index) => {
    const quality2CardValue = config?.quality2?.filter((Quality2s) => Quality2.quality2Id === Quality2s.id)
    if (not(quality2CardValue)) return null
    return (
      <div className="d-flex flex-column align-items-center" key={index}>
        <img className="mx-3" src={ASSET_QUALITY2_URL + quality2CardValue?.[0]?.filename} alt={index + 1} />
        <div className="text-first-capitalize card-text">{quality2CardValue?.[0]?.title}</div>
      </div>
    )
  })

  const quality3Card = cv?.quality3s?.map((Quality3, index) => {
    const quality3CardValue = config?.quality3?.filter((Quality3s) => Quality3.quality3Id === Quality3s.id)
    if (not(quality3CardValue)) return null
    return (
      <div className="d-flex flex-column align-items-center" key={index}>
        <img className="mx-3" src={ASSET_QUALITY3_URL + quality3CardValue?.[0]?.filename} alt={index + 1} />
        <div className="text-first-capitalize card-text">{quality3CardValue?.[0]?.title}</div>
      </div>
    )
  })

  let expYears = 0
  cv?.experiences?.map((xps) => {
    const entryDate = new Date(xps.expEntryDate)
    const exitDate = new Date(xps.expExitDate)
    const date = exitDate.getTime() - entryDate.getTime()
    expYears += Math.round(date / (1000 * 60 * 60 * 24 * 30 * 12))

    return expYears
  })

  const cvDetail = useMemo(() => {
    if (config && cv) {
      return convertCVData(config, cv)
    }
    return null
  }, [config, cv])

  return (
    <S.CardDetailsOfferWrapper className="" isPrefix={mode !== "selected"} nameCV={get(jobName, '[0].name', '')}>
      <S.DetailsOfferWrapper className="row justify-content-between">
        <S.DetailsOfferLeftWrapper className="col" border>
          <S.LeftLogoWrapper>
            <div className="d-flex justify-content-start w-100 title-offers mb-4">
              <IconUser className="cv-avatar mr-5" />
              <div className="d-flex flex-column" style={{ maxWidth: 300 }}>
                <span>{cv ? `${cv?.user.name} ${cv?.user.surname}` : `${user.name} ${user.surname}`}</span>
                <div className="d-flex align-items-center">
                  <IconCar className="mr-2 icon-reponsive" />
                  <span>{get(jobName?.[0], 'name', '')}</span>
                </div>
              </div>
            </div>
          </S.LeftLogoWrapper>

          <CVSummary cvDetail={cvDetail} />
        </S.DetailsOfferLeftWrapper>

        <S.DetailsOfferRightWrapper className="col d-flex flex-wrap flex-column">
          <div className="d-flex justify-content-around mb-4 list-skills">
            {qualityMainCard?.length ? qualityMainCard : <IconPlus className="dont-have-skill" />}
            {quality2Card?.length ? quality2Card : <IconPlus className="dont-have-skill" />}
            {quality3Card?.length ? quality3Card : <IconPlus className="dont-have-skill" />}
          </div>
          <ul className="job-description infos text-blue ml-4 w-100">
            <li>
              <b>{t("Localisation")} :</b> {get(cvDetail, "city", "")}
            </li>
            <br></br>
            <li>
              <b>{t("Niveau d'expérience")} :</b>{" "}
              {pluralize({
                singular: "an",
                count: expYears,
                showCount: true,
                zero: "Aucune",
              })}
            </li>
            <br></br>
            <li>
              <b>{t("Dernière expérience")} :</b>{" "}
              {(get(cv, "experiences", [])
                ?.map((exp) => `${exp.expJobName} [${exp.expCompany}]`)
                ?.join(" - ") || "Sans expérience") || "Sans expérience"}
            </li>
            <br></br>
            <li>
              <b>{t("Diplôme")} : </b>
              {(get(cvDetail, "formations", [])?.map(
                (form) => `${form?.diplomaValue?.[0]?.name} ${form?.diplomaOptionValue?.[0]?.name}`
              )?.join(" - ") || "Sans formation") || "Sans formation"}
            </li>
          </ul>
        </S.DetailsOfferRightWrapper>
        <S.StyledDetails className={`${view === cv.id ? "active" : ""}`}>
          <div className="title">CV détaillé</div>

          <div className="row mt-0 mt-xl-5 mb-5">
            <S.Left className="col-12 col-xl-4">
              <S.Profile>
                <h3>PROFIL</h3>
                <div className="pl-3 pr-3">
                  Dans le cadre de mes expériences <br />
                  passées, j'ai pu me renforcer dans <br />
                  {get(cvDetail, "skills", [])
                    ?.map((item) => item?.[0]?.name)
                    ?.join(", ")}
                  . <br />
                  <br /> Maîtrisant{" "}
                  {get(cvDetail, "skillComps", [])
                    ?.map((item) => item?.[0]?.name)
                    ?.join(", ")}
                  , <br />
                  je recherche un poste dans lequel je pourrais contribuer à{" "}
                  {get(cvDetail, "mission", [])
                    ?.map((item) => item?.[0]?.name)
                    ?.join(", ")}
                  . <br /> <br />
                  Étant{" "}
                  {get(cvDetail, "qualityMain", [])
                    ?.map((item) => item?.[0]?.name)
                    ?.join(", ")}
                  , j'ai{" "}
                  {get(cvDetail, "quality2", [])
                    ?.map((item) => item?.[0]?.title)
                    ?.join(", ")}{" "}
                  et je suis{" "}
                  {get(cvDetail, "quality3", [])
                    ?.map((item) => item?.[0]?.name)
                    ?.join(", ")}
                  . Je saurais répondre à vos attentes et surtout{" "}
                  {get(cvDetail, "missionComp", [])
                    ?.map((item) => item?.[0]?.name)
                    ?.join(", ")}
                  . Je parle{" "}
                  {get(cvDetail, "languages", [])
                    ?.map((item) => item?.[0]?.name)
                    ?.join(", ")}{" "}
                  est ma langue maternelle.
                </div>
              </S.Profile>

              <S.Contact>
                <h3>CONTACT</h3>
                <div className="pl-3 pr-3 content">
                  <div className="d-flex mb-3">
                    <IconTelephone className="mr-2" width="40" height="40" />
                    <div className="d-flex flex-column">
                      <span>TÉLÉPHONE</span>
                      <span>{user?.phonenumber}</span>
                    </div>
                  </div>

                  <div className="d-flex mb-3">
                    <IconAddress className="mr-2" width="40" height="40" />
                    <div className="d-flex flex-column">
                      <span>ADRESSE</span>
                      <span>{user?.address}</span>
                      <span>{user?.postalcode}, {user?.city}</span>
                      <span>{user?.country}</span>
                    </div>
                  </div>

                  <div className="d-flex mb-3">
                    <IconEmail className="mr-2" width="40" height="40" style={{ fill: "#FF7200" }} />
                    <div className="d-flex flex-column">
                      <span>E-MAIL</span>
                      <span>{user?.email}</span>
                    </div>
                  </div>
                </div>
              </S.Contact>

              <S.PointsInterest>
                <h3>CENTRES D'INTÉRÊT</h3>
                <div className="pl-3 pr-3">{cv?.extra_otherInfos}</div>
              </S.PointsInterest>

              <S.Informations>
                <h3>INFORMATIONS COMPLÉMENTAIRES</h3>
                <div className="pl-3 pr-3 d-flex">
                  <div className="d-flex w-100 flex-wrap">
                    {
                      get(cvDetail, 'licenseList', [])?.map(elm => {
                        return (
                          <div className='col-6 pt-3'>
                            <img src={IconDepanneur} alt="icon-france" className="icon-reponsive" />
                            <div> {elm.name} </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </S.Informations>
            </S.Left>
            <S.Right className="col-12 col-xl-7 ml-0 ml-xl-5">
              <S.Profile>
                <h3>FORMATION</h3>
                <div className="pl-3 pr-3">
                    {Array.isArray(cvDetail?.formations) ? (
                      cvDetail.formations.map((form) => (
                        <div className="d-flex flex-column mb-3" key={form?.id || Math.random()}>
                          {(form?.diplomaValue?.[0]?.name || form?.diplomaOptionValue?.[0]?.name) && (
                            <span>
                              {form?.diplomaValue?.[0]?.name} {form?.diplomaOptionValue?.[0]?.name}
                            </span>
                          )}
                          
                          {(form?.formSchool || form?.formEntryDate || form?.formExitDate) && (
                            <span>
                              {form?.formSchool} {form?.formEntryDate && "- " + form.formEntryDate}{" "}
                              {form?.formExitDate && "- " + form.formExitDate}
                            </span>
                          )}
                          {form?.formMissions && <span className="mt-2">{form.formMissions}</span>}
                        </div>
                      ))
                    ) : (
                      <div>Aucune formation disponible</div>
                    )}
                  </div>
              </S.Profile>

              <S.Contact>
                <h3>PARCOURS PROFESSIONNEL</h3>
                <div className="pl-3 pr-3">
                  {get(cvDetail, "exp", [])?.map((ex) => (
                    <div className="d-flex flex-column mb-3" key={ex.id}>
                      <span>
                        {get(ex, "expJobName", "")} - {get(ex, "expCompany", "")}
                      </span>
                      <span>
                        {get(ex, "expEntryDate", "")}-{get(ex, "expExitDate", "")}
                      </span>
                      <span className="mt-2">{get(ex, "expMissions", "")}</span>
                    </div>
                  ))}
                </div>
              </S.Contact>

              <S.PointsInterest>
                <h3>COMPÉTENCES</h3>
                <div className="pl-3 pr-3 process-wrapper">
                  {get(cvDetail, "tools", [])?.map((tool) => (
                    <div key={tool?.[0]?.id} className="mb-4">
                      <span className="mr-5 col-12 mb-2">{get(tool, "[0].name", "")}</span>
                      <MecTabSwitch
                        tabs={LEVELS}
                        activeTab={tool.level - 1}
                        className="col-12 mb-2"
                        name="tools"
                        style={{ pointerEvents: 'none' }}
                      />
                    </div>
                  ))}
                </div>
              </S.PointsInterest>
            </S.Right>
          </div>
        </S.StyledDetails>

        <S.StyledFooter className="w-100 mt-5 justify-content-end">
          <S.FooterCardWrapper className="justify-content-center justify-content-xl-end">
            <MecButton
              icon={IconView}
              height={44}
              width={250}
              type="outlineNotHover"
              className="site-button site-button-gap radius-xl custom-btn-footer"
              onClick={(e) => {
                e.preventDefault();
                handleViewDetail(cv.id)
              }}
            >
              {view === cv.id ? 'Réduire' : 'Voir le CV'}
            </MecButton>
            <MecButton
              icon={IconPhone}
              height={44}
              width={250}
              type="outlineNotHover"
              className="site-button site-button-gap radius-xl custom-btn-footer"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              {get(cv, 'user.phonenumber', '')}
            </MecButton>
            <Link
              to={{
                pathname: "/recruiter/message/new",
                state: { email: cv?.user?.email }
              }}
            >
              <MecButton
                icon={IconEmail}
                height={44}
                width={250}
                style={{minWidth: 250}}
                type="outlineNotHover"
                className="site-button site-button-gap radius-xl custom-btn-footer"
              >
                Envoyer un message
              </MecButton>
            </Link>
          </S.FooterCardWrapper>
        </S.StyledFooter>
      </S.DetailsOfferWrapper>
    </S.CardDetailsOfferWrapper>
  )
}

export default Card
