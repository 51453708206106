import * as Yup from "yup"
import { PHONE_REG_EXP } from "src/constants"
import { isVAT } from "src/utils"

export const FacturationInitialValues = (initialValues) => {
  return {
    name_company: initialValues?.name_company || null,
    vat_number: initialValues?.vat_number || null,
    address: initialValues?.address || null,
    code_postal: initialValues?.code_postal || null,
    city: initialValues?.city || null,
    country: initialValues?.country || null,
    telephone: initialValues?.telephone || null,
  }
}

export const FacturationValidationSchema = ({ t }) => {
  return Yup.object().shape({
    name_company: Yup.string(t("invalid_field"))
      .required(t("required_field"))
      .nullable(true),
    vat_number: Yup.string(t("invalid_field"))
      .test('vat-number', t("invalid_field"), isVAT)
      .required(t("required_field"))
      .nullable(true),
    address: Yup.string(t("invalid_field"))
      .required(t("required_field"))
      .nullable(true),
    code_postal: Yup.number(t("invalid_field"))
      .required(t("required_field"))
      .nullable(true),
    city: Yup.string(t("invalid_field"))
      .required(t("required_field"))
      .nullable(true),
    country: Yup.string(t("invalid_field"))
      .required(t("required_field"))
      .nullable(true),
    telephone: Yup.string()
      .matches(PHONE_REG_EXP, t("invalid_field"))
      .nullable(true),
  })
}

export const PaymentInitialValues = {
  name: null,
  cardNumber: null,
  cvc: null,
  date: null,
}

export const PaymentValidationSchema = ({ t }) => {
  return Yup.object().shape({
    name: Yup.string(t("invalid_field"))
      .required(t("required_field"))
      .nullable(true),
    cardNumber: Yup.string(t("invalid_field"))
      .max(16)
      .required(t("required_field"))
      .nullable(true),
    cvc: Yup.string(t("invalid_field"))
      .min(3)
      .max(4)
      .required(t("required_field"))
      .nullable(true),
    date: Yup.string(t("invalid_field"))
      .required(t("required_field"))
      .nullable(true),
  })
}
