import { memo } from "react"
import PhoneImg from "../../../../images/phone-fomula.png"
import { MecButton } from "src/markup/Components"
import ProfileIcon from "../../../../images/icons/profile-circle.svg"
import "./index.scoped.scss"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const PROCESS_STEPS = [
  {
    id: "define",
    icon: ProfileIcon,
    title: "Définition du profil",
    description: "Nous vous aidons à définir vos besoins",
  },
  {
    id: "source",
    icon: ProfileIcon,
    title: "Diffusion & sourcing",
    description: "Nous diffusons et sélectionnons les meilleurs candidats",
  },
  {
    id: "analyze",
    icon: ProfileIcon,
    title: "Analyse et entretiens",
    description: "Vérification des CV, vérifications du parcours entretiens, suivi",
  },
  {
    id: "recruit",
    icon: ProfileIcon,
    title: "Recrutement garanti !",
    description: "",
  },
]

const ProcessStep = memo(({ step }) => (
  <div className="step-item">
    <img src={step.icon} alt={step.title} className="step-icon" />
    <div className="step-info">
      <h5>{step.title}</h5>
      {step.description && <p>{step.description}</p>}
    </div>
  </div>
))

const HowItWorks = () => {
  const history = useHistory()

  return (
    <section className="how-it-works">
      <div className="title">
        <h3>COMMENT ÇA MARCHE ?</h3>
      </div>
      <div className="content">
        <div className="process-row w-100">
          <div className="phone-container">
            <img src={PhoneImg} alt="Phone Application" className="phone-image" />
          </div>
          <div className="divider" />
          <div className="steps-container">
            {PROCESS_STEPS.map((step) => (
              <ProcessStep key={step.id} step={step} />
            ))}
          </div>
        </div>
        <MecButton
          onClick={(e) => {
            e.preventDefault()
            history.push("/recruiter/login")
          }}
          className="w-auto ml-auto px-3"
        >
          Publier une offre d’emploi
        </MecButton>
      </div>
    </section>
  )
}

export default memo(HowItWorks)
