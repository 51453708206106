import { useGlobal, addReducer } from "reactn"
import { MODAL, ROLE } from "src/constants"

const initialState = {
  isOpen: false,
  modalName: MODAL.CONFIRM_AUTH,
  confirmAuth: {
    role: ROLE.RECRUITER,
  },
  dataCvSelected: null,
  payload: {},
  showConfirmModal: false,
}

// payload: ROLE.RECRUITER | ROLE.CANDIDATE
export const closeModal = (global, dispatch, action) => ({
  ...global,
  modal: initialState,
})

addReducer("showModalAuth", (global, dispatch, action) => ({
  ...global,
  modal: {
    isOpen: true,
    modalName: MODAL.CONFIRM_AUTH,
    confirmAuth: {
      role: action.payload,
    },
  },
}))

addReducer("showModal", (global, dispatch, action) => ({
  ...global,
  modal: {
    isOpen: true,
    modalName: action.payload,
  },
}))

addReducer("confirmApplyCv", (global, dispatch, action) => {
  return {
    ...global,
    modal: {
      isOpen: true,
      modalName: MODAL.CONFIRM_APPLY_CV,
      dataCvSelected: action,
    },
  }
})

export const loadDataModalFromStorage = () => {
  try {
    const data = localStorage.getItem("modal")
    return data ? JSON.parse(data) : initialState
  } catch (error) {
    return null
  }
}

addReducer("showConfirmModal", (global, dispatch, action) => ({
  ...global,
  modal: {
    modalName: MODAL.CONFIRM_MODAL,
    showConfirmModal: action.showConfirmModal,
    payload: action
  },
}))

export const useGetDataModal = () => {
  const [data] = useGlobal("modal")
  return data
}
