import styled from "styled-components"
import DatePicker from "react-datepicker"

import { ReactComponent as IconCalendar } from "src/images/icons/canlendar-icon.svg"

export const Wrapper = styled.div`
  .react-datepicker {
    border: none;
    border-radius: 20px;
    background: #e8eef2;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #4b6c82;
    font-family: "Raleway", sans-serif;
  }

  .react-datepicker__input-container {
    input {
      text-align-last: left;
    }
  }

  .react-datepicker__triangle {
    &::before,
    &::after {
      display: none;
    }
  }

  .react-datepicker__header {
    border: none;
    padding: 0;
    text-transform: capitalize;
    background-color: initial;
    border-radius: ${({ isTime }) => (isTime ? "20px 0px 0px 0px" : "20px 20px 0px 0px")};
  }

  .react-datepicker__time-container {
    border: none;
  }

  .react-datepicker__header--has-time-select {
    border: none;
    border-radius: 20px 0px 0px 0px;
  }

  .react-datepicker__header--time {
    border-radius: 0px 20px 0px 0px;
    background-color: #b6cad1;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-datepicker-time__header {
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    color: #03223f;
  }

  .react-datepicker__current-month {
    background-color: #cad9e0;
    border-radius: ${({ isTime }) => (isTime ? "20px 0px 0px 0px" : "20px 20px 0px 0px")};
    padding: 10px 0px;
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    color: #03223f;
  }

  .react-datepicker__navigation {
    top: 15px;
  }

  .react-datepicker__day-names {
    background: #e8eef2;
  }

  .react-datepicker__day-name {
    font-size: 1rem;
    line-height: 1.5rem;
    color: #4b6c82;

    &:first-letter {
      display: none;
    }
  }

  .react-datepicker__month,
  .react-datepicker__time-box {
    background: #ffffff;
    border-radius: 10px;
  }

  .react-datepicker__time-box {
    width: 70px !important;
  }

  .react-datepicker__time-list {
    height: 210px !important;
  }

  .react-datepicker__time {
    border-radius: 0px 0px 20px 0px;
    background: #e8eef2;
    margin-top: 10px;
    text-transform: capitalize;
  }

  .react-datepicker__day {
    font-size: 1rem;
    line-height: 1.5rem;
    color: #4b6c82;
  }

  .react-datepicker__day--selected {
    color: #ffffff;
  }

  .react-datepicker__time-list-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-datepicker__day--disabled {
    opacity: 0.5;
  }

  .react-datepicker-popper {
    z-index: 2;
  }
`

export const WrapperMecDatePicker = styled(DatePicker)`
  background: #e8eef2;
  width: 100%;
  padding: 5px 7px 5px 20px;
  height: 48px;
  color: var(--blue600);
  border-radius: 20px;
  border: ${(props) => (props.error ? "2px solid red" : "none")};
  outline: none;
  overflow: hidden;

  &:focus,
  &:active,
  &:target,
  &:hover {
    border: ${(props) => (props.error ? "2px solid red" : "none")};
  }

  background: ${(props) => props.isDisabled && "#f0f5f7"} !important;
  opacity: ${(props) => props.isDisabled && "0.8"} !important;
`

export const CustomHeader = styled.div``

export const ButtonDatePicker = styled.button`
  border: none;
  cursor: pointer;
`

export const SelectMonth = styled.select`
  border: none;
  cursor: pointer;
  background-color: initial;
  color: #03223f;
  font-weight: 800;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #03223f;
`

export const SelectYear = styled(SelectMonth)``

export const Icon = styled(IconCalendar)`
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
`

export const Feedback = styled.div`
  color: ${({ colorTxtError = "red" }) => colorTxtError};
  font-size: 14px;
  padding: 10px 0 0 0;

  width: 100%;
  text-align-last: left;
`
