import React, { useCallback, useEffect, useGlobal, useState } from "reactn"
import { useParams } from "react-router-dom"
import Header from "src/markup/Layout/Header"
import SidebarCandidate from "src/markup/Layout/SidebarCandidate"
import Footer from "src/markup/Layout/Footer"
import Card from "src/markup/Pages/Candidate/CandidateApplicationContainer/Card/index"
import * as S from "./styles"
import { getOfferInfos, getOfferById, apiReadNotifications } from "src/services/api"
import { Loading } from 'src/markup/Element/Loading';
import Offer from 'src/markup/Pages/Offer'

const TITLES_BY_MODE = {
  sent: "Candidatures envoyées",
  declined: "Candidatures déclinées",
  accepted: "Candidatures retenues",
  favorite: "Offres d'emploi enregistrées",
}

const QUERIES_BY_MODE = {
  sent: {
    accepted: false,
    rejected: false,
    hidden: false,
  },
  accepted: {
    accepted: true,
    rejected: false,
    hidden: false,
  },
  declined: {
    rejected: true,
    hidden: false,
  },
}

export default function CandidateApplicationContainer({ mode, view }) {
  const [offers, setOffers] = useState([])
  const [, setOffer] = useState({})

  const [user] = useGlobal("user")
  const { id } = useParams()
  const [loading, setLoading] = useState(false);

  const handleGetOffers = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getOfferInfos({
        candidateId: user?.id,
        query: QUERIES_BY_MODE[mode],
      })
      setOffers(res)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }

  }, [mode, user?.id])

  const handleGetOffer = useCallback(async (id) => {
    try {
      const res = await getOfferById(id)
      setOffer(res)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (id) {
      handleGetOffer(id)
    } else {
      handleGetOffers()
    }
  }, [handleGetOffer, handleGetOffers, id])

  useEffect(() => {
    let updateData = {};
    if (mode === 'accepted') {
      updateData = {
        accepted: true,
        rejected: false,
        candidateId: user.id,
      }
    } else {
      updateData = {
        rejected: true,
        candidateId: user.id,
      }
    }
    apiReadNotifications(updateData).then(() => {}).catch(err => err);
  }, [mode, user.id])

  return (
    <div className="page-wraper">
      <Header candidate />

      <div className="container-content">
        <div className="d-flex" style={{ flexWrap: "nowrap" }}>
          <SidebarCandidate />
          <S.Content className="col-10 col-xl">
            {
              view !== 'details' && (
                <>
                  <div className="d-flex justify-content-center">
                    {loading && <Loading />}
                    <S.Heading>{`${TITLES_BY_MODE[mode]} (${offers?.length || 0})`}</S.Heading>
                  </div>
                  <hr className="v3-separator pb-xl-3" />
                </>
              )}

            {view === "details" ? (
              <Offer />
            ) : (
              offers && offers.map((offer) => (
                <Card mode={mode} state={view === "details" ? "details" : "view"} key={offer.id} offer={offer} />
              ))
            )}
          </S.Content>
        </div>
      </div>

      <Footer />
    </div>
  )
}
