import React, { useEffect, useRef, useGlobal, useState, useMemo, useCallback, useDispatch, Fragment } from "reactn"
import { isEmpty } from "lodash"
import { Link, useHistory } from "react-router-dom"
import { populateOffer, getJobById } from "src/services/config"
import { OfferDetailCard } from "src/markup/Components/Cards"
import { ContentLeft, ContentRight, JobsectionWrapper, ListOffersWrapper } from "src/markup/Pages/Homepage/styles"
import { MecButton } from "src/markup/Components"
import { ReactComponent as IconEye } from "src/images/icons/eye.svg"
import { ReactComponent as IconNote } from "src/images/icons/note.svg"
import { ReactComponent as Wrench } from "src/images/icons/wrench.svg"

import { requestSearchJob, getAllOffers, getMyListCVCandidate } from "src/services/api"
import { DEFAULT_LIMIT, CV_STATUS, OFFER_STATUS, SORT_BY } from "src/constants"
import { isCandidate } from "src/services/auth"
import { SelectCV } from "src/markup/Pages/SearchPage/Candidate/components"
import { useGetDataModal } from "src/services"
import CardLoadingSkeleton from "src/markup/Pages/Homepage/Jobsection/CardLoadingSkeleton"
import { useGetJobLiaisons } from "src/markup/Pages/SearchPage/Candidate/hooks/useGetJobLiaisons"
import { Loading } from "src/markup/Element/Loading"
import { CandidateRegister } from "../../AuthPage/Register"
import * as S from "src/markup/Layout/AuthLayout/styles"

const popularSearch = [
  {
    image: require("src/images/icons/jobs/mecanicien.png"),
    name: "Mécanicien H/F",
  },
  {
    image: require("src/images/icons/jobs/carrossier.png"),
    name: "Carrossier H/F",
  },
  {
    image: require("src/images/icons/jobs/depanneur.png"),
    name: "Dépanneur H/F",
  },
  {
    image: require("src/images/icons/jobs/demonteur.png"),
    name: "Démonteur H/F",
  },
]

const footerCard = ({
  isOfferJoined,
  setToggle,
  history,
  user,
  offer,
  config,
  totalNumCandidat,
  myCvs,
  onShowModal,
  dataCvSelected,
}) => {
  const CANDIDATE = isCandidate()

  const data = populateOffer(config, offer)
  if (isEmpty(data)) return null

  const handleSelectCV = () => {
    if (!CANDIDATE) {
      setToggle(true)
    }
  }

  const onChangeSelect = (option) => {
    const payload = {
      [offer.id]: {
        dataView: option,
        bodyRequest: {
          cvId: option.value,
          offerId: offer.id,
          recruiterId: offer.userId,
          candidateId: user.id,
        },
      },
    }
    onShowModal(payload)
  }

  const cvSelected = !isEmpty(dataCvSelected) && dataCvSelected[offer.id] ? dataCvSelected[offer.id]?.dataView : null

  return (
    <div className="d-flex justify-content-between align-items-center mt-4 w-100">
      <ul className="d-flex">
        <li className="list-unstyled">
          <MecButton
            icon={IconEye}
            widthIcon={40}
            height={44}
            type="primary"
            className="site-button site-button-gap radius-xl text-capitalize"
            onClick={() => history.push(`/offer/${offer.id}`)}
          >
            Voir
          </MecButton>
        </li>
        <li className="list-unstyled">
          <div className="pl-2">
            {CANDIDATE ? (
              <>
                {isOfferJoined ? (
                  <MecButton
                    icon={Wrench}
                    width={100}
                    widthIcon={20}
                    height={44}
                    type="primary"
                    onClick={() => history.push("/candidate/application/sent")}
                  >
                    Modifier
                  </MecButton>
                ) : (
                  <SelectCV value={cvSelected} options={myCvs} onChange={onChangeSelect} />
                )}
              </>
            ) : (
              <MecButton
                icon={IconNote}
                width={200}
                height={44}
                type="primary"
                className="site-button site-button-gap radius-xl"
                onClick={() => handleSelectCV()}
              >
                Vite, je postule !
              </MecButton>
            )}
          </div>
        </li>
      </ul>
    </div>
  )
}

const Jobsection = ({ search, setToggle }) => {
  const history = useHistory()
  const [user] = useGlobal("user")
  const [config] = useGlobal("config")
  const [lastOffers, setLastOffers] = useState([])
  const [myCvs, setMyCvs] = useState([])

  const resultsRef = useRef(null)
  const [searchOffers, setSearchOffers] = useState(null)

  const CANDIDATE = isCandidate()
  const onShowModal = useDispatch("confirmApplyCv")
  const { dataCvSelected } = useGetDataModal()

  const { loadingGetListOfferJoined, liaisonsOfferList } = useGetJobLiaisons()

  const getAllOffersRequest = async () => {
    try {
      const res = await getAllOffers({
        limit: DEFAULT_LIMIT.LATEST_OFFER_HOMEPAGE,
        status: OFFER_STATUS.PUBLISHED,
      })
      if (res?.length) {
        setLastOffers(res)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getMyCvs = useCallback(async () => {
    if (CANDIDATE) {
      const res = await getMyListCVCandidate({
        status: CV_STATUS.PUBLISHED,
        sort_by: SORT_BY.DESC,
      })

      if (res?.length) {
        const optionsCvs = res?.map((cv) => ({
          value: cv.id,
          label: cv.name_cv,
        }))
        setMyCvs(optionsCvs)
      }
    }
  }, [CANDIDATE])

  useEffect(() => {
    getAllOffersRequest()
    getMyCvs()
  }, [getMyCvs])

  const totalNumCandidat = useMemo(() => {
    return lastOffers?.liaisons?.length || 0
  }, [lastOffers?.liaisons?.length])

  useEffect(() => {
    async function fetchData() {
      setSearchOffers(null)

      const offerList = await requestSearchJob(search)
      if (offerList?.length > 0) {
        Promise.resolve().then(() => {
          setSearchOffers(offerList)
        })
      } else if (Array.isArray(offerList) && offerList.length === 0) {
        setSearchOffers([])
      }
    }

    Object.keys(search).length > 1 && fetchData()
  }, [search])

  useEffect(() => {
    if (searchOffers && resultsRef.current) {
      resultsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }
  }, [searchOffers])

  const { jobName } = getJobById({ config, jobId: search?.joblistId })

  const getHeader = () => {
    if (searchOffers === null) {
      return null
    } else
      return (
        <Fragment>
          <div className="text-center" ref={resultsRef}>
            <h3 className="text-orange mt-5 text-uppercase">Résultats de la recherche ({searchOffers?.length})</h3>
            {searchOffers?.map((offer) => (
              <ListOffersWrapper key={offer?.offer?.id} className="pb-3">
                <OfferDetailCard
                  setToggle={setToggle}
                  isOfferDetails
                  footer={() =>
                    footerCard({
                      setToggle,
                      history,
                      user,
                      offer: offer.offer,
                      config,
                      totalNumCandidat,
                      myCvs,
                      onShowModal,
                      dataCvSelected,
                    })
                  }
                  offerDetail={offer?.offer}
                />
              </ListOffersWrapper>
            ))}
            <h5 id="" className="text-blue text-first-capitalize mt-5">
              Soyez alerté dès qu'un poste de <span className="text-capitalize">{jobName}</span> est disponible à{" "}
              <span className="text-capitalize">{search?.city.split(", France")[0]}</span> !
            </h5>
            <p className="text-first-capitalize text-orange">
              Inscrivez-vous dès maintenant et accédez gratuitement à nos futures offres en exclusivité{" "}
            </p>
            <div className="bg-blue py-4 m-auto" style={{ borderRadius: "30px" }}>
              <div className="d-flex justify-content-center px-4">
                <div className="tab-content">
                  <S.SForm>
                    <CandidateRegister />
                  </S.SForm>
                </div>
              </div>
            </div>
            <hr className="v3-separator pb-xl-3" />
          </div>
        </Fragment>
      )
  }

  return (
    <JobsectionWrapper className="section-full bg-white content-inner-5">
      <div className="container">
        <div className="row gp-5 justify-content-between">
          <ContentLeft>
            <div className="sticky-top">
              {!CANDIDATE ? (
                <div className="quote-bx text-center mb-5">
                  <div className="quote-info">
                    <h3 className="text-uppercase">Mes recherches</h3>
                    <p>Connectez-vous en tant que candidat pour enregistrer votre CV et vos recherches</p>
                    <div className="left-job-buttons">
                      <Link to={"/candidate/register"} className="site-button site-button-gap radius-xl text-uppercase">
                        Inscription
                      </Link>
                      <Link to={"/candidate/login"} className="site-button site-button-gap radius-xl text-uppercase">
                        Connexion
                      </Link>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="popular-joboffers">
                <div className="text-center text-uppercase">
                  <h3 className="text-orange">Recherches populaires</h3>
                  <hr className="v3-separator pb-xl-3" />
                </div>
                <ul className="post-job-bx browse-job">
                  {popularSearch?.map((item, index) => {
                    return (
                      <li key={index}>
                        <div className="post-bx shadow-lg p-3 mb-5 bg-white">
                          <div className="d-flex">
                            <div className="job-post-company">
                              <span>
                                <img alt="" src={item.image.default} />
                              </span>
                            </div>
                            <div className="job-post-info">
                              <div className="populars-offers-module">
                                <span style={{ color: "#ff7200" }}>{item.name}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </ContentLeft>

          <ContentRight>
            {getHeader()}

            <div className="text-center text-uppercase pt-5">
              <h3 className="text-orange">Les dernières offres</h3>
              <hr className="v3-separator pb-xl-3" />
            </div>
            {lastOffers.length > 0 ? (
              lastOffers.map((offer) => (
                <ListOffersWrapper key={offer.id} className="pb-3">
                  <OfferDetailCard
                    setToggle={setToggle}
                    isOfferDetails
                    footer={() => {
                      if (loadingGetListOfferJoined) return <Loading />
                      const isOfferJoined = liaisonsOfferList.includes(offer.id)
                      return footerCard({
                        isOfferJoined,
                        setToggle,
                        history,
                        user,
                        offer,
                        config,
                        totalNumCandidat,
                        myCvs,
                        onShowModal,
                        dataCvSelected,
                      })
                    }}
                    offerDetail={offer}
                  />
                </ListOffersWrapper>
              ))
            ) : (
              <CardLoadingSkeleton />
            )}
          </ContentRight>
        </div>
      </div>
    </JobsectionWrapper>
  )
}

export default Jobsection
