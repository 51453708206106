import styled from "styled-components"
import { Modal } from "reactstrap"

export const Wrapper = styled(Modal)`
  .modal-content {
    background: unset;
    border: none;
  }
`

export const ListSocial = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 15px;

  svg {
    border-radius: 50%;
    transition: transform 0.2s ease;
    position: relative;

    &:hover {
      transform: scale(1.2);
    }
  }
`
