import React, { useState, useCallback, useEffect } from "reactn"
import Header from "src/markup/Layout/Header"
import { keyBy, omit } from "lodash"

import * as S from "./styles"
import Footer from "src/markup/Layout/Footer"
import View from "./View"
import Lists from "./Lists"
import { MESSAGE } from "src/constants"
import { actionMessage, getListMessage, readMessage } from "src/services/api"
import { toast } from "react-toastify"
import SidebarCandidate from "src/markup/Layout/SidebarCandidate"

const Archived = () => {
  const [messageView, setMessageView] = useState()
  const [messages, setMessages] = useState([])
  const [actionLoading, setActionLoading] = useState("")

  const getListMessages = useCallback(async () => {
    try {
      const messages = await getListMessage({
        type: MESSAGE.ARCHIVE,
      })
      setMessages(messages)
    } catch (e) {
      return e
    }
  }, [])

  useEffect(() => {
    getListMessages()
  }, [getListMessages])

  const handleActionMessage = async ({ e, actionType, messageId }) => {
    e.preventDefault()
    setActionLoading(actionType)

    try {
      const messagesKeyBy = keyBy(messages, "id")
      const dataRemove = omit(messagesKeyBy, messageId)

      await actionMessage({
        id: messageId,
        type_action: actionType,
        type_message: MESSAGE.ARCHIVE,
      })
      setMessages(Object.values(dataRemove))
      setMessageView(null)
      toast.success("Message supprimé")

      setActionLoading("")
    } catch (e) {
      setActionLoading("")
      return e
    }
  }

  const handleViewMessage = (message) => {
    setMessageView(message)
    readMessage(message.id)
  }
  return (
    <div className="page-wraper">
      <Header candidate />

      <div className="container-content">
        <div className="d-flex" style={{ flexWrap: "nowrap" }}>
          <SidebarCandidate />

          <S.Wrapper className="col-10 col-xl">
            <div className="d-flex justify-content-center">
              <S.Heading>Messages archivés</S.Heading>
            </div>
            <hr className="v3-separator pb-xl-3" />
            <Lists handleViewMessage={handleViewMessage} messageView={messageView} messages={messages} />
            {messageView && (
              <View message={messageView} handleActionMessage={handleActionMessage} actionLoading={actionLoading} />
            )}
          </S.Wrapper>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Archived
