import React, { useGlobal, useRef } from "reactn"
import { FormGroup } from "reactstrap"
import { includes } from "lodash"

import { MecCollapse, MecInputMultiple, MecSelectorCustom } from "src/markup/Components/Forms"
import { Minus, StyledForm, StyledLabel } from "src/markup/Element/CVForm/styles"
import { MecDatePicker } from "src/markup/Components/MecDatePicker"
import { ReactComponent as IconPlush } from "src/images/icons/icon-plus.svg"
import { MecButton } from "src/markup/Components"
import { ReactComponent as IconMinus } from "src/images/icons/icon-minus.svg"
import { addDays, subDays } from "src/services/config"

const Training = ({
  formik,
  handleAddFormations,
  handleCompleted,
  completed,
  inexperienced,
  handleInexperienced,
  isOpen,
  handleOpenCollapse,
  handleDeleteItem,
}) => {
  const trainingRef = useRef(null)
  const [config] = useGlobal("config")

  const isCompleted = formik?.values?.formations?.map((formation) => {
    const { domain_activity, formSchool, formCity} = formation
    return (domain_activity?.id && formSchool && formCity) || ""
  })

  function scrollTraining() {
    trainingRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  return (
    <MecCollapse
      title="Quelle(s) formation(s) avez-vous ?"
      isOpen={isOpen === "training"}
      setIsOpen={() => handleOpenCollapse("training")}
      completed={!includes(isCompleted, '') || includes(inexperienced, "formations")}
      name="isCompleted"
      className="mb-5"
    >
      <StyledForm>
        <div className="row d-flex justify-content-end align-items-center" ref={trainingRef}>
          <FormGroup
            className="d-flex justify-content-end align-items-center"
            onClick={() => handleInexperienced("formations")}
          >
            <div className="py-2 label text-align-last-left">Je n'ai pas suivi de formation</div>
            <MecInputMultiple
              type="radio"
              formik={formik}
              className="text-align-last-left w-auto custom-radio"
              checked={includes(inexperienced, "formations")}
              // name="formations"
            />
          </FormGroup>
        </div>

        {formik.values?.formations?.map((formation, index) => (
          <div key={formation.id}>
            <div className="row">
              <FormGroup className="col-12">
                <StyledLabel
                  className="py-2 text-align-last-left label-required"
                  inexperienced={includes(inexperienced, "formations")}
                >
                  Quelle est la formation suivie ?
                </StyledLabel>
                <MecSelectorCustom
                  options={config?.jobs}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                  className="text-align-last-left"
                  name="domain_activity"
                  placeholder="Sélectionnez..."
                  disabled={includes(inexperienced, "formations")}
                  colorTxtError="red"
                  onChange={(option) => {
                    formik.setFieldValue(`formations.${index}.domain_activity`, option)
                  }}
                  onBlur={() => formik.setFieldTouched(`formations.${index}.domain_activity`, true)}
                  value={formik.values.formations?.[index]?.domain_activity}
                  errors={formik.errors.formations?.[index]?.domain_activity}
                  touched={formik.touched.formations?.[index]?.domain_activity || formik.touched.domain_activity}
                />
              </FormGroup>
            </div>
            <div className="row">
              <FormGroup className="col-12 col-xl-6">
                <StyledLabel
                  className="py-2 text-align-last-left label-required"
                  inexperienced={includes(inexperienced, "formations")}
                >
                  Établissement
                </StyledLabel>
                <MecInputMultiple
                  formik={formik}
                  onChange={(e) => {
                    formik.setFieldValue(`formations.${index}.formSchool`, e.target.value)
                  }}
                  value={formik.values.formations?.[index]?.formSchool}
                  disabled={includes(inexperienced, "formations")}
                  errors={formik.errors.formations?.[index]?.formSchool}
                  touched={formik.touched.formations?.[index]?.formSchool || formik.touched.formSchool}
                  className="secondary text-align-last-left"
                  placeholder="Entrez le nom de l'établissement"
                  name="formSchool"
                />
              </FormGroup>
              <FormGroup className="col-12 col-xl-6">
                <StyledLabel
                  className="py-2 text-align-last-left label-required"
                  inexperienced={includes(inexperienced, "formations")}
                >
                  Lieu
                </StyledLabel>
                <MecInputMultiple
                  formik={formik}
                  onChange={(e) => {
                    formik.setFieldValue(`formations.${index}.formCity`, e.target.value)
                  }}
                  value={formik.values.formations?.[index]?.formCity}
                  disabled={includes(inexperienced, "formations")}
                  errors={formik.errors.formations?.[index]?.formCity}
                  touched={formik.touched.formations?.[index]?.formCity || formik.touched.formCity}
                  className="secondary text-align-last-left"
                  placeholder="Ville/pays"
                  name="formCity"
                />
              </FormGroup>
            </div>
            <div className="row">
              <FormGroup className="col-12 col-xl-6">
                <StyledLabel
                  className="py-2 text-align-last-left"
                  inexperienced={includes(inexperienced, "formations")}
                >
                  Diplôme
                </StyledLabel>
                <MecSelectorCustom
                  options={config?.diplomas} // diplomas
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                  className="text-align-last-left"
                  name="formDiploma"
                  disabled={includes(inexperienced, "formations")}
                  placeholder="Sélectionnez votre diplôme"
                  colorTxtError="red"
                  onChange={(option) => {
                    formik.setFieldValue(`formations.${index}.formDiploma`, option)
                  }}
                  value={formik.values.formations?.[index]?.formDiploma}
                />
              </FormGroup>
              <FormGroup className="col-12 col-xl-6">
                <StyledLabel
                  className="py-2 text-align-last-left"
                  inexperienced={includes(inexperienced, "formations")}
                >
                  Option
                </StyledLabel>
                <MecSelectorCustom
                  options={config?.diplomaOptions} //diplomaOptions
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                  className="text-align-last-left"
                  disabled={includes(inexperienced, "formations")}
                  name="formDiplomaOption"
                  placeholder="Quelle spécialité ?"
                  colorTxtError="red"
                  onChange={(option) => {
                    formik.setFieldValue(`formations.${index}.formDiplomaOption`, option)
                  }}
                  value={formik.values.formations?.[index]?.formDiplomaOption}
                />
              </FormGroup>
            </div>
            <div className="row">
              <FormGroup className="col-12 col-xl-6">
                <StyledLabel
                  className="py-2 text-align-last-left"
                  inexperienced={includes(inexperienced, "formations")}
                >
                  Début de formation
                </StyledLabel>
                <MecDatePicker
                  name="formEntryDate"
                  onChange={(value) => {
                    formik.setFieldValue(`formations.${index}.formEntryDate`, value)
                  }}
                  handleBlur={formik.handleBlur}
                  disabled={includes(inexperienced, "formations")}
                  errors={formik.errors.formations?.[index]?.formEntryDate}
                  touched={formik.touched.formations?.[index]?.formEntryDate || formik.touched.formEntryDate}
                  selected={
                    formik.values.formations?.[index]?.formEntryDate &&
                    new Date(formik.values.formations?.[index]?.formEntryDate)
                  }
                  value={
                    formik.values.formations?.[index]?.formEntryDate &&
                    new Date(formik.values.formations?.[index]?.formEntryDate)
                  }
                  autoComplete="off"
                  maxDate={(subDays(formik.values.formations?.[index]?.formExitDate &&
                    new Date(formik.values.formations?.[index]?.formExitDate)) || new Date())}
                  // dateFormat={formatDatepicker}
                />
              </FormGroup>
              <FormGroup className="col-12 col-xl-6">
                <StyledLabel
                  className="py-2 text-align-last-left"
                  inexperienced={includes(inexperienced, "formations")}
                >
                  Fin de formation
                </StyledLabel>
                <MecDatePicker
                  name="formExitDate"
                  onChange={(value) => {
                    formik.setFieldValue(`formations.${index}.formExitDate`, value)
                  }}
                  handleBlur={formik.handleBlur}
                  disabled={includes(inexperienced, "formations")}
                  errors={formik.errors.formations?.[index]?.formExitDate}
                  touched={formik.touched.formations?.[index]?.formExitDate || formik.touched.formExitDate}
                  selected={
                    formik.values.formations?.[index]?.formExitDate &&
                    new Date(formik.values.formations?.[index]?.formExitDate)
                  }
                  value={
                    formik.values.formations?.[index]?.formExitDate &&
                    new Date(formik.values.formations?.[index]?.formExitDate)
                  }
                  autoComplete="off"
                  minDate={addDays(formik.values.formations?.[index]?.formEntryDate &&
                    new Date(formik.values.formations?.[index]?.formEntryDate))}
                />
              </FormGroup>
            </div>
            <div className="row">
              <FormGroup className="col-12">
                <StyledLabel
                  className="py-2 text-align-last-left"
                  inexperienced={includes(inexperienced, "formations")}
                >
                  Description
                </StyledLabel>
                <MecInputMultiple
                  formik={formik}
                  onChange={(e) => {
                    formik.setFieldValue(`formations.${index}.formMissions`, e.target.value)
                  }}
                  value={formik.values.formations?.[index]?.formMissions}
                  disabled={includes(inexperienced, "formations")}
                  errors={formik.errors.formations?.[index]?.formMissions}
                  touched={formik.touched.formations?.[index]?.formMissions || formik.touched.formMissions}
                  className="secondary text-align-last-left"
                  placeholder="Décrivez, au mieux, le contenu de votre formation"
                  name="formMissions"
                />
              </FormGroup>
            </div>
            {index ? (
              <Minus onClick={() => handleDeleteItem("formations", formation.id)}>
                <IconMinus style={{ cursor: "pointer", marginRight: "15px" }} width={30} height={30} />
                Supprimer une formation
              </Minus>
            ) : null}
            {formik.values?.formations?.length > 1 && (
              <hr className="v3-separator pb-xl-3" style={{ maxWidth: "100%" }}></hr>
            )}
          </div>
        ))}

        <div className="row">
          <FormGroup
            className="col-12 row d-flex justify-content-end add-experience"
            onClick={!includes(inexperienced, "formations") ? handleAddFormations : null}
          >
            <IconPlush className={`${includes(inexperienced, "formations") && "label-disabled"}`} />
            <StyledLabel className="py-2" inexperienced={includes(inexperienced, "formations")}>
              Ajouter une formation
            </StyledLabel>
          </FormGroup>
        </div>

        <div className="w-100 d-flex justify-content-center">
          <MecButton
            icon=""
            type="primary"
            width={120}
            widthIcon={20}
            className="text-capitalize"
            onClick={(e) => {
              handleCompleted(e, "formations")
              handleOpenCollapse("profile")
              scrollTraining()
            }}
            disabled={!includes(inexperienced, "formations") && includes(isCompleted, "")}
          >
            Valider
          </MecButton>
        </div>
      </StyledForm>
    </MecCollapse>
  )
}

export default Training
