import styled, { css } from "styled-components"
import { Collapse } from "reactstrap"

export const CardDetailsOfferWrapper = styled.div`
  border: 5px solid #ff7200;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  background-color: #fff;
  padding: 30px;
  position: relative;
  margin-top: 100px;

  &::before {
    content: ${({ name_cv }) => `"${name_cv}"`};
    position: absolute;
    width: 300px;
    height: 163px;
    left: 0px;
    top: -80px;
    background: #ffffff;
    box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
    border-radius: 40px;
    font-weight: 800;
    font-size: 30px;
    line-height: 45px;
    color: #023f73;
    z-index: -1;
    padding: 20px;
  }

  .d-inline-grid {
    display: inline-grid;
  }

  @media (min-width: 1024px) {
    padding: 60px;

    &::before {
      width: 380px;
      top: -95px;
      font-size: 35px;
      line-height: 41px;
      padding: 30px;
    }
  }
`

export const DetailsOfferWrapper = styled.div``

export const DetailsOfferLeftWrapper = styled.div`
  border-right: ${(props) => props.border && "5px solid #e8eef2"};
  font-size: 1rem;
  line-height: 1.5rem;
  color: #023f73;

  @media (min-width: 1024px) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`

export const LeftLogoWrapper = styled.div`
  display: flex;
  justify-content: center;

  .title-offers {
    span:first-child {
      font-weight: 600;
      font-size: 17px;
      line-height: 25px;
      color: #ff7200;
    }
    span:last-child {
      font-weight: 800;
      font-size: 13px;
      line-height: 20px;
      color: #023f73;
    }

    .cv-avatar {
      /* transform: scale(0.6); */
    }
  }

  @media (min-width: 1024px) {
    .title-offers {
      span:first-child {
        font-size: 22px;
        line-height: 34px;
      }
      span:last-child {
        font-size: 19px;
        line-height: 27px;
      }
    }
  }

  @media (min-width: 1440px) {
    .title-offers {
      span:first-child {
        font-size: 29px;
        line-height: 39px;
      }
      span:last-child {
        font-size: 22px;
        line-height: 27px;
      }
    }
  }
`

export const LeftContentWrapper = styled.div`
  padding: 0;

  div {
    font-weight: 800;
    font-size: 18.4202px;
    line-height: 22px;
    color: #023f73;
  }

  div:nth-child(2) {
    font-weight: 400;
    font-size: 14.7361px;
    line-height: 17px;
    margin-top: 15px;
    max-width: 75%;
    margin-left: 10%;
  }

  h3 {
    font-size: 24px;
  }

  @media (min-width: 1024px) {
    padding: 20px 0;

    h3 {
      font-size: 28px;
    }
  }
`

export const DetailsOfferRightWrapper = styled.div`
  .list-skills {
    flex-wrap: wrap;
    justify-content: space-evenly;
    div {
      width: 100px;
      text-align: center;
    }
    svg {
      width: 40px;
      height: 40px;
    }
  }

  .job-description {
    list-style-type: none;
    max-width: 100%;
  }

  @media (min-width: 1440px) {
    .list-skills {
      svg {
        width: 70px;
        height: 70px;
      }
    }
  }
`

export const FooterCardWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  width: 100%;
  max-width: 800px;

  .mec-button:first-child {
    display: block !important;
  }

  .icon-center {
    svg {
      transform: rotate(90deg);
      path {
        fill: #ff7200;
      }
    }
  }
`

export const Footer = styled.div`
  .text-right {
    color: #023f73;
    margin-bottom: 20px;
  }

  @media (min-width: 1400px) {
    .text-right {
      margin-bottom: 0px;
    }
  }
`

export const TagConfirm = styled.div`
  border: 4px solid ${props => props.bg || '#37bf78'};
  padding: 3px 15px;
  font-weight: bold;
  color: ${props => props.bg || '#37bf78'};
  &:before {
    content: " ";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${props => props.bg || '#37bf78'} ;
    margin-right: 5px;
    display: inline-block;
  }
`
// Card detail
const customSpan = css`
  span:first-child {
    font-weight: bold;
    color: #ff7200;
  }

  span:nth-child(2) {
    font-weight: bold;
  }
`

export const StyledCardDetails = styled.div`
  .styled-action {
    .note {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.75rem;
      color: #ff7200;
      margin-top: 15px;
    }
  }
`

export const Card = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
  border-radius: 30px;

  & > div {
    border: none;
    box-shadow: none;
  }

  .icon-skills {
    circle {
      fill: #002d56;
    }
  }

  .footer {
    padding: 0px 60px 60px 60px;
  }
`
export const StyledDetails = styled.div`
  padding: 0px 30px;

  .title {
    font-weight: 600;
    font-size: 35px;
    line-height: 42px;
    color: #ff7200;
  }

  @media (min-width: 1200px) {
    padding: 0px 60px;
  }
`
export const Left = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: #023f73;

  h3 {
    font-weight: 500;
    font-size: 25px;
    line-height: 37px;
    color: #023f73;
    margin-top: 20px;
  }

  @media (min-width: 1200px) {
    border-right: 5px solid #e8eef2;
  }
`

export const Profile = styled.div`
  ${customSpan}
`
export const Contact = styled.div`
  .content {
    span:first-child {
      font-weight: bold;
      color: #ff7200;
    }
  }
  ${customSpan}
`
export const PointsInterest = styled.div`
  .process-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  @media (min-width: 1600px) {
    .process-wrapper {
      flex-direction: column;
      align-items: flex-end;
    }
  }
`

export const Process = styled.div`
  background: #e8eef2;
  width: 500px;
  height: 30px;
  border-radius: 30px;

  &::before {
    content: ${({ percent }) => `"${percent}%"`};
    display: block;
    background: #bcced8;
    width: ${({ percent }) => `${percent}%`};
    height: 30px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #023f73;
  }
`

export const Informations = styled.div``

export const Right = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: #023f73;

  h3 {
    font-weight: 500;
    font-size: 25px;
    line-height: 37px;
    color: #023f73;
    margin-top: 20px;
  }
`

export const CollapseDetailCV = styled(Collapse)`
  .collapsing {
    transition: height 1s ease;
  }
`
