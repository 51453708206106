import React, { useGlobal, useRef } from "reactn"
import { FormGroup } from "reactstrap"
import { find } from "lodash"

import { MecCollapse } from "src/markup/Components/Forms"
import { MecButton } from "src/markup/Components/MecButton"
import { StyledForm } from "src/markup/Element/CVForm/styles"
import { CONDITIONS } from "src/markup/Element/OfferForms/constant"
import { handleRenderField } from "../FormValidation"

const Conditions = ({ formik, handleCompleted, isOpen, handleOpenCollapse }) => {
  const conditionsRef = useRef(null)
  const [config] = useGlobal("config")

  const { values } = formik

  const dataCompare = CONDITIONS.map(({ isRequired, name }) => {
    return isRequired && values[name]
  })
  const completed = find(dataCompare, (data) => {
    return data === null || data === "" || data?.length === 0
  })

  function scrollConditions() {
    conditionsRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  return (
    <MecCollapse
      title="Conditions et rémunération"
      isOpen={isOpen === "conditions"}
      setIsOpen={() => handleOpenCollapse("conditions")}
      completed={completed === undefined}
      name="completed"
      className="mb-5"
    >
      <StyledForm>
        <div className="row" ref={conditionsRef}>
          {CONDITIONS.map((field, index) => (
            <div className={`d-flex flex-column justify-content-end col-${field.col}`} key={index}>
              {field.title && (
                <div className="col-12 sub-title text-align-last-left mb-4 " style={{ flex: 1, color: "#ff7200" }}>
                  {field.title}
                </div>
              )}
              <FormGroup className={`w-100 ${field.type === "boolean" && "d-flex mt-4"}`}>
                {field.label && (
                  <div className={`py-2 label text-align-last-left ${field.isRequired && "label-required"}`}>
                    {field.label}
                  </div>
                )}
                {handleRenderField({ formik, config, field })}
              </FormGroup>
            </div>
          ))}
        </div>

        <div className="w-100 d-flex justify-content-center">
          <MecButton
            icon=""
            type="primary"
            width={120}
            widthIcon={20}
            className="text-capitalize mt-2"
            onClick={(e) => {
              handleCompleted(e, "conditions")
              handleOpenCollapse("conditions")
              scrollConditions()
            }}
            disabled={completed !== undefined}
          >
            Valider
          </MecButton>
        </div>
      </StyledForm>
    </MecCollapse>
  )
}

export default Conditions
