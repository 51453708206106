import styled from 'styled-components'

export const Wrapper = styled.div``

export const Heading = styled.h1`
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #ff7200;

  @media(max-width: 992px) {
    font-size: 30px;
  }
`


export const WrapperContent = styled.div`
  position: relative
`
