import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const usePageTracking = () => {
    const { REACT_APP_GA_ID } = process.env;

    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (REACT_APP_GA_ID) {
            ReactGA.initialize(REACT_APP_GA_ID);
            setInitialized(true);
        }
    }, [REACT_APP_GA_ID])

    useEffect(() => {
        if (initialized) {
            ReactGA.send({
                hitType: "pageview",
                page: location.pathname + location.search
            });
        }
    }, [initialized, location]);
};

export default usePageTracking;
