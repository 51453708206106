import * as Yup from "yup";

export const GetQuoteModalInitialValues = {
  companyName: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  comment: "",
  capcha: null,
};

export const GetQuoteModalValidationSchema = () => {
  return Yup.object().shape({
    companyName: Yup.string().required("Nom de l'entreprise requis"),
    firstName: Yup.string().required("Prénom requis"),
    lastName: Yup.string().required("Nom requis"),
    email: Yup.string()
      .email("Email invalide")
      .required("Email requis"),
    phone: Yup.string()
      .matches(
        /^(\+\d{1,3}[-.\s]?)?(\(\d{1,4}\))?[-.\s]?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,4})$/,
        "Format de téléphone incorrect"
      )
      .required("Numéro de téléphone requis"),
    capcha: Yup.mixed()
      .required("Veuillez confirmer reCAPTCHA")
      .nullable()
      .test('is-verified', 'Veuillez confirmer reCAPTCHA', value => value !== null),
  });
};