import React from "react"
import PropTypes from "prop-types"
import * as S from "./styles"

export const MecInput = (props) => {
  const { formik, type, name, onBlur, value, colorTxtError, className = "primary", disabled, userType, ...rest } = props
  const { handleChange, handleBlur, values, errors, touched } = formik

  return (
    <>
      <S.Wrapper
        className={`${className} ${disabled && "disabled"}`}
        type={type}
        name={name}
        onChange={!disabled ? handleChange : () => {}}
        onBlur={handleBlur}
        error={touched[name] && !!errors[name]}
        value={values[name] || ""}
        invalid={errors[name] && touched[name]}
        userType={userType}
        {...rest}
      />
      {errors[name] && touched[name] && (
        <S.Feedback colorTxtError={colorTxtError}>{errors[name] && touched[name] && errors[name]}</S.Feedback>
      )}
    </>
  )
}

MecInput.propTypes = {
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  timeDebounce: PropTypes.number,
}

MecInput.defaultProps = {
  name: "",
}
