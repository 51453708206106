import React from "reactn"
import { get } from "lodash"
import { Document, Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer'

import { formatPrice } from "src/utils"

Font.register({ family: 'Rubik',  fontWeight: 'bold' });

const styles = StyleSheet.create({
  flex: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  header: {
    width: '100%',
    color: '#3d3d3d',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    padding: '20px 0px',
    display: 'inline-block',
  },

  headingTable: {
    padding: '8px 24px',
    backgroundColor: '#fdeee0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  label: {
    color: 'black',
    fontSize: '14px'
  },

  value: {
    color: '#FF7200',
    fontSize: '16px',
    fontWeight: 'bold',
    paddingLeft: '5px'
  },

  tableInfo: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  infoValue: {
    color: '#023F73',
    fontSize: '14px',
    fontWeight: 'bold',
    paddingLeft: '5px',
  },

  headerTable: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '0 24px',
    backgroundColor: '#FF7200',
    color: '#FFFFFF'
  },

  rows: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '15px',
  },

  columns: {
    padding: '15px 10px',
    width: '200px',
    fontSize: '10px',
    textAlign: 'center'
  },

  footerInvoice: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '24px',
    fontSize: '16px'
  },

  footerInvoiceLabel: {
    width: '80px',
    display: 'inline-block',
    fontWeight: 'bold',
    paddingRight: '15px',
    color: '#6f6f6f',
    fontSize: '12px'
  },

  footerInvoiceValue: {
    width: '150px',
    display: 'inline-block',
    textAlign: 'right',
    color: '#6f6f6f',
    fontSize: '12px'
  },

  copyRight: {
    padding: '20px',
    backgroundColor: '#fdeee0',
    color: '#FF7200',
    fontWeight: '500',
    fontSize: '14px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Rubik',
  }
});

const MyPdf = ({ currentOrder, columnsLeft, columnsRight, packageInfo }) => (
    <Document>
      <Page size="A4">
        <View>
          <View style={styles.header}><Text>Facture MecanoJob</Text></View>
          <View style={styles.headingTable}>
            <View style={styles.flex}>
              <Text style={styles.label}>Date :</Text>
              <Text style={styles.value}>
                {new Date(get(currentOrder, "createdAt", "")).toLocaleDateString("fr-FR")}
              </Text>
            </View>
            <View style={styles.flex}>
              <Text style={styles.label}>Numéro de facture :</Text>
              <Text style={styles.value}>{currentOrder?.code}</Text>
            </View>
          </View>

          <View style={styles.tableInfo} className="d-md-flex d-block align-items-center justify-content-between p-4">
            <View style={{display: 'flex', flexDirection: 'column'}}>
              {columnsLeft?.map((column, idx) => (
                <View key={idx} style={styles.flex}>
                  <Text style={styles.label}>{column.label}: </Text>
                  <Text style={styles.infoValue}>{column.value}</Text>
                </View>
              ))}
            </View>
            <View style={{display: 'flex', flexDirection: 'column'}} className="pt-md-0 pt-4">
              {columnsRight?.map((column, idx) => (
                <View key={idx} style={styles.flex}>
                  <Text style={styles.label}>{column.label}: </Text>
                  <Text style={styles.infoValue}>{column.value}</Text>
                </View>
              ))}
            </View>
          </View>

          <View className="px-md-4 px-0">
            <View>
              <View>
                <View style={styles.headerTable}>
                  <View style={styles.columns}>
                    <Text>Quantité</Text>
                  </View>
                  <View style={styles.columns}>
                    <Text>Description</Text>
                  </View>
                  <View style={styles.columns}>
                    <Text>PRIX HT</Text>
                  </View>
                  <View style={styles.columns}>
                    <Text>PRIX HT TOTAL</Text>
                  </View>
                </View>
                {get(currentOrder, "orderDetails", []).map((order, idx) => (
                  <View style={styles.rows} key={idx}>
                    <Text style={styles.columns}>{order.quantity}</Text>
                    <Text style={styles.columns}>{order.package_name}</Text>
                    <Text style={styles.columns}>{formatPrice(order.unit_ht, 2)}</Text>
                    <Text style={styles.columns}>{formatPrice(order.total_ht, 2)}</Text>
                  </View>
                ))}
              </View>
            </View>
          </View>

          <View style={styles.footerInvoice}>
            <View style={styles.flex}>
              <Text style={styles.footerInvoiceLabel}>Sous Total</Text>
              <Text style={styles.footerInvoiceValue}>{formatPrice(currentOrder?.total, 2)} €</Text>
            </View>

            <View style={styles.flex}>
              <Text style={styles.footerInvoiceLabel}>TVA {packageInfo?.taxPercent} %</Text>
              <Text style={styles.footerInvoiceValue}>{formatPrice(currentOrder?.tva, 2)} €</Text>
            </View>

            <View style={styles.flex}>
              <Text style={styles.footerInvoiceLabel}>Total</Text>
              <Text style={styles.footerInvoiceValue} className="pl-5 font-weigh-bold">{formatPrice(currentOrder?.total_ttc, 2)} €</Text>
            </View>
          </View>

          <View style={styles.copyRight}>
            <Text>
              SAS 30 MILLE - OOVOOM - capital de 27 702,00 € - 111 avenue Victor Hugo - 75116, Paris.
            </Text>
            <Text>
              RCS 889 500 021 - TVA FR 05 889500021 - contact@30mille.com - www.oovoom.com
            </Text>
          </View>
        </View>
      </Page>
    </Document>
);


export default MyPdf
