import React from "reactn"

import { GOOGLE_API_KEY } from "src/constants"
import { extractFromAddress } from "src/utils"
import * as S from "./styles"

export const MecAutocompleteLocation = ({
  formik,
  colorTxtError,
  name,
  placeholder = "Renseigner une ville",
  onPlaceSelected = ({ address_components }) => {
    const city = extractFromAddress(address_components, "locality")
    formik.setFieldValue("pres_city", city)
    formik.setFieldValue("pres_zip", extractFromAddress(address_components, "postal_code"))
    name && formik.setFieldValue(name, city)
  },
  useAddress = false,
  ...rest
}) => {
  const { values, errors, touched, handleBlur } = formik

  return (
    <>
      <S.AutocompleteLocation
        defaultValue={values[name]}
        options={{
          componentRestrictions: { country: "fr" },
          types: useAddress ? ["address"] : ["(regions)"],
        }}
        className="form-control-cv pl-4"
        id={name}
        apiKey={GOOGLE_API_KEY}
        onPlaceSelected={onPlaceSelected}
        placeholder={placeholder}
        name={name}
        onBlur={handleBlur}
        error={touched[name] && (!!errors[name] ? 1 : 0)}
        invalid={errors[name] && (touched[name] ? 1 : 0)}
        {...rest}
      />
      <S.Feedback colorTxtError={colorTxtError}>{errors[name] && touched[name] && errors[name]}</S.Feedback>
    </>
  )
}