import * as Yup from "yup"
import { FormGroup } from "reactstrap"
import { sample, sampleSize, union, uniqWith, isEqual } from "lodash"

import { MecAutocompleteLocation, MecSelectorCustom, MecInput, MecTabSwitch } from "src/markup/Components/Forms"
import { OFFER_STATUS } from "src/constants"
import { YOUR_COMPANY, POSITION, PROFILE_DEFINITION, CONDITIONS } from "src/markup/Element/OfferForms/constant"
import { getConfigByJob } from "src/services/config"
import { components } from "react-select"
import { ReactComponent as IconMinus } from "src/images/icons/icon-minus.svg"
import { Minus } from "./styles"

const Menu = (props) => {
  const optionSelectedLength = props.getValue().length || 0
  return (
    <components.Menu {...props}>
      {optionSelectedLength < 3 ? props.children : <div style={{ margin: 15 }}>Nombre de choix maximum atteint.</div>}
    </components.Menu>
  )
}

export const OfferFormInitialValues = {
  name_company: "",
  pres_city: "",
  pres_zip: "",
  recruit_recruitObj: null,
  recruit_responsability: null,
  joblistId: null,
  pres_jobOtherName: null,
  search_exp: null,
  search_minDiploma: null,
  mission: [
    {
      id: 1,
      search_mission: null,
      search_specMission: [],
    },
  ],
  search_qualityMain: [],
  search_quality2: null,
  search_quality3: null,

  skill: [
    {
      id: 1,

      search_skill: null,
      search_skillComp: [],
    },
  ],
  tools: [],

  languages: [{ filename: "french.png", id: 8, name: "Français" }],
  extra_driversLicenceOther: [],

  extra_criminalRecord: false,
  recruit_contractType: null,

  salary: null,

  adv_advantages: [],

  otherAdvantage: "",
  featured_offer: false,
  offer_logo: "",
  status: OFFER_STATUS.DRAFT,
}

export const OfferFormRandomizedValues = (config) => {
  const cities = [
    "Paris", "Lyon", "Marseille", "Lille", "Toulouse",
    "Nice", "Bordeaux", "Nantes", "Strasbourg"
  ];

  let job = sample(config?.jobs)
  let otherJob = sample(config?.otherJobs.filter((otherJob) => otherJob.joblistId === job.id))
  let mission = sample(config?.missions.filter((mission) => mission.joblistId === job.id))
  let missionSpecs = sampleSize(config?.missionSpecs.filter((missionSpecs) => missionSpecs.joblistId === job.id), 3)
  let skill = sample(config?.skills.filter((skills) => skills.joblistId === job.id))
  let skillComps = sampleSize(config?.skillsComp.filter((skillsComp) => skillsComp.joblistId === job.id), 3)
  let tools = sampleSize(config?.tools.filter((skillsComp) => skillsComp.joblistId === job.id), 3)
  let languages = uniqWith(union([{ filename: "french.png", id: 8, name: "Français" }], (sampleSize(config?.languages, 2))), isEqual)

  return (
    {
      name_company: "Garage-" + Math.floor(Math.random() * (9999)),
      pres_city: sample(cities),
      recruit_recruitObj: sample(config?.recruiterObjectives),
      recruit_responsability: sample(config?.responsabilities),
      joblistId: job,
      pres_jobOtherName: otherJob,
      search_exp: sample(config?.xp),
      search_minDiploma: sample(config?.diplomas),
      mission: [{
        id: 1,
        search_mission: mission,
        search_specMission: missionSpecs,
      }],
      search_qualityMain: sampleSize(config?.qualityMain, 3),
      search_quality2: sample(config?.quality2),
      search_quality3: sample(config?.quality3),
      skill: [{
        id: 1,
        search_skill: skill,
        search_skillComp: skillComps,
      }],
      tools: tools,
      languages: languages,
      extra_driversLicenceOther: sampleSize(config?.otherDriverLicenses, 2),
      extra_criminalRecord: Math.random() < 0.5,
      recruit_contractType: sample(config?.contractTypes),
      salary: Math.floor(Math.random() * (50000 - 10000)) + 10000,
      adv_advantages: sampleSize(config?.advantages, 3),
      otherAdvantage: "Autre-" + Math.floor(Math.random() * (9999)),
      featured_offer: false,
      offer_logo: "",
      status: OFFER_STATUS.DRAFT
    }
  )
}

const handleValidationWithType = ({ field, t }) => {
  switch (field.type) {
    case "object":
      return (
        field.isRequired &&
        Yup.object()
          .required(t("required_field"))
          .nullable(true)
      )

    case "autocomplete_location":
    case "string":
    case "input":
      return (
        field.isRequired &&
        Yup.string(t("invalid_field"))
          .trim()
          .required(t("required_field"))
          .nullable(true)
      )

    case "array_object":
      return field.isRequired && Yup.array().min(1, t("required_field"))

    case "array_mission":
      return (
        field.isRequired &&
        Yup.array().of(
          Yup.object().shape({
            [field.fieldFirstRequired]: Yup.object()
              .required(t("required_field"))
              .nullable(true),
            [field.fieldSecondRequired]: Yup.array().min(1, t("required_field")),
          })
        )
      )

    default:
      break
  }
}

export const OfferFormvalidationSchema = ({ t, isDraft }) => {
  const validationObject = {}

  YOUR_COMPANY().forEach((field) => {
    validationObject[field.name] = handleValidationWithType({ field, t })
  })

  POSITION().forEach((field) => {
    validationObject[field.name] = handleValidationWithType({ field, t })
  })

  PROFILE_DEFINITION().forEach((field) => {
    validationObject[field.name] = handleValidationWithType({ field, t })
  })

  CONDITIONS.forEach((field) => {
    validationObject[field.name] = handleValidationWithType({ field, t })
  })

  return Yup.object(isDraft ? {} : validationObject);
}

const listDependency = ["pres_jobOtherName", "mission", "skill", "tools"]

export const handleRenderField = ({ formik, config, field, handleDeleteItem }) => {
  const { errors, touched, setFieldValue, setFieldTouched, values } = formik

  switch (field.type) {
    case "autocomplete_location":
      return <MecAutocompleteLocation />

    case "input":
      return (
        <MecInput
          name={field.name}
          type={field.typeInput}
          placeholder={field.placeholder}
          formik={formik}
          min={0}
          colorTxtError="red"
          className="secondary text-align-last-left"
        />
      )

    case "object":
      return (
        <MecSelectorCustom
          options={
            field.dependency
              ? getConfigByJob({ config: config?.[field.optionKey], jobId: formik.values[field.dependency]?.id })
              : config?.[field.optionKey]
          }
          getOptionLabel={({ name }) => name}
          getOptionValue={({ id }) => id}
          className="text-align-last-left"
          name={field.name}
          placeholder={field.placeholder}
          colorTxtError="red"
          isMulti={field.isMulti}
          onChange={(option) => {
            setFieldValue(field.name, option)
            field.name === "joblistId" &&
              listDependency.forEach((dependency) =>
                formik.setFieldValue(dependency, OfferFormInitialValues[dependency])
              )
          }}
          onBlur={() => setFieldTouched(field.name, true)}
          value={values[field.name]}
          errors={errors[field.name]}
          touched={touched[field.name]}
        />
      )

    case "array_object":
      return (
        <MecSelectorCustom
          components={{ Menu }}
          options={
            field.dependency
              ? getConfigByJob({ config: config?.[field.optionKey], jobId: formik.values[field.dependency]?.id })
              : config?.[field.optionKey]
          }
          getOptionLabel={({ name }) => name}
          getOptionValue={(optionValue) => optionValue[field.fieldRequired]}
          className="text-align-last-left"
          name={field.name}
          placeholder={field.placeholder}
          colorTxtError="red"
          isMulti
          onChange={(option) => {
            formik.setFieldValue(field.name, option)
          }}
          onBlur={() => formik.setFieldTouched(field.name, true)}
          value={formik.values[field.name]}
          errors={formik.errors[field.name]}
          touched={formik.touched[field.name]}
        />
      )

    case "array_mission":
      return values[field.name]?.map((data, index) => (
        <div key={data.id}>
          <FormGroup className="w-100">
            <div className="py-2 label text-align-last-left label-required">
              {field[field.fieldFirstRequired].label}
            </div>
            <MecSelectorCustom
              options={
                field[field.fieldFirstRequired].dependency ?
                  getConfigByJob({
                    config: config?.[field[field.fieldFirstRequired].optionKey],
                    jobId: formik.values[field[field.fieldFirstRequired].dependency]?.id,
                  })
                  :
                  config?.[field[field.fieldFirstRequired].optionKey]
              }
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              className="text-align-last-left"
              name={field[field.fieldFirstRequired].name}
              placeholder={field[field.fieldFirstRequired].placeholder}
              colorTxtError="red"
              onChange={(option) => {
                setFieldValue(`${field.name + "." + index + "." + field[field.fieldFirstRequired].name}`, {
                  id: option.id,
                  name: option.name,
                })
              }}
              onBlur={() =>
                setFieldTouched(`${field.name + "." + index + "." + field[field.fieldFirstRequired].name}`, true)
              }
              value={values[field.name]?.[index]?.[field[field.fieldFirstRequired].name]}
              errors={errors[field.name]?.[index]?.[field[field.fieldFirstRequired].name]}
              touched={
                touched[field.name]?.[index]?.[field[field.fieldFirstRequired].name] ||
                touched[field[field.fieldFirstRequired].name]
              }
            />
          </FormGroup>
          <FormGroup className="w-100">
            <div className="py-2 label text-align-last-left label-required">
              {field[field.fieldSecondRequired].label}
            </div>
            <MecSelectorCustom
              options={
                field[field.fieldSecondRequired].dependency ?
                  getConfigByJob({
                    config: config?.[field[field.fieldSecondRequired].optionKey],
                    jobId: formik.values[field[field.fieldSecondRequired].dependency]?.id,
                  })
                  :
                  config?.[field[field.fieldSecondRequired].optionKey]
              }
              formik={formik}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              className="text-align-last-left"
              name={field[field.fieldSecondRequired].name} // Multiple selection
              isMulti
              colorTxtError="red"
              placeholder={field[field.fieldSecondRequired].placeholder}
              onChange={(option) => {
                setFieldValue(`${field.name + "." + index + "." + field[field.fieldSecondRequired].name}`, option)
              }}
              onBlur={() =>
                setFieldTouched(`${field.name + "." + index + "." + field[field.fieldSecondRequired].name}`, true)
              }
              value={values[field.name]?.[index]?.[field[field.fieldSecondRequired].name]}
              errors={errors[field.name]?.[index]?.[field[field.fieldSecondRequired].name]}
              touched={
                touched[field.name]?.[index]?.[field[field.fieldSecondRequired].name] ||
                touched[field.name]?.[field[field.fieldSecondRequired].name]
              }
            />
          </FormGroup>
          {index ? (
            <Minus onClick={() => handleDeleteItem(field.name, data.id)}>
              <IconMinus style={{ cursor: "pointer", marginRight: "15px" }} width={30} height={30} />
              {field.name === "mission" ? "Supprimer une mission" : "Supprimer une compétence"}
            </Minus>
          ) : null}
          {values?.[field.name]?.length > 1 && (
            <hr className="v3-separator pb-xl-3 mt-5" style={{ maxWidth: "100%" }}></hr>
          )}
        </div>
      ))

    case "boolean":
      return (
        <MecTabSwitch
          tabs={field.options}
          activeTab={formik.values[field.name] ? 0 : 1}
          className="ml-xl-3 w-100 h-auto"
          style={{ maxWidth: "300px" }}
          onChange={(e) => {
            formik.setFieldValue(field.name, !e)
          }}
        />
      )

    default:
      break
  }
}
