import React, { useCallback, useEffect, useGlobal, useState } from "reactn"
import Header from "src/markup/Layout/Header"
import styled from "styled-components"
import { useHistory } from "react-router-dom"
import { omit, keyBy } from "lodash"
import { toast } from "react-toastify"

import SidebarRecruiter from "src/markup/Layout/SidebarRecruiter"
import Footer from "src/markup/Layout/Footer"
import Card from "./Card"
import { OFFER_STATUS, SORT_BY, MECANOJOB_URL } from "src/constants"
import { pluralize } from "src/utils";
import { deleteOffer, recruiterGetListOffers } from "src/services/api"
import { useCheckPackage } from "src/hooks/useCheckPackage"
import { MecButton } from "src/markup/Components"
import { ReactComponent as IconCreate } from "src/images/icons/note.svg"
import { Loading } from "src/markup/Element/Loading"
import { ConfirmModal } from "src/markup/Components/Modals"
import { ShareSocialsModal } from "src/markup/Components/Modals"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share"
import * as S from "./styles"

const Content = styled.div`
  margin-bottom: 50px;
  margin-top: 50px;
  padding: 0 20px;

  @media (min-width: 992px) {
    padding: 0 50px;
  }
`

const ListOffer = ({ isDraft }) => {
  const history = useHistory()
  const { packages } = useCheckPackage()

  const [user] = useGlobal("user");
  const { bypass } = user;

  const [offers, setOffers] = useState([])
  const [view, setView] = useState("")
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState({ showConfirmModal: false })
  const [modalSocial, setModalSocial] = useState({ showModal: false })

  const getListOffers = useCallback(async () => {
    try {
      setLoading(true)
      const _offers = await recruiterGetListOffers({
        sort_by: SORT_BY.DESC,
        status: !isDraft ? OFFER_STATUS.PUBLISHED : OFFER_STATUS.DRAFT,
      })
      setOffers(_offers)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [isDraft])

  useEffect(() => {
    getListOffers()
  }, [getListOffers])

  const handleViewDetail = (id) => setView(id === view ? "" : id)

  const handleDeleteOffer = async (id) => {
    const shadowOffers = keyBy(offers, "id")
    const res = await deleteOffer(id)
    toast.success(res.message)
    setOffers(Object.values(omit(shadowOffers, id)))
    setModal({ showConfirmModal: false })
  }

  const onDelete = (id) => {
    setModal({
      showConfirmModal: true,
      onYes: () => handleDeleteOffer(id),
      onNo: () => setModal({ showConfirmModal: false }),
      Description: "Voulez-vous supprimer l'offre ?",
    })
  }

  const handleShareSocial = (offerId) => {
    setModalSocial({
      showModal: true,
      id: offerId,
      onNo: () => setModalSocial({ showModal: false }),
    })
  }

  const remainingOffers = packages?.reduce((sum, value) => sum + value.remaining_quantity, 0);

  return (
    <div className="page-wraper">
      <Header recruiter />

      <div className="container-content">
        <div className="d-flex" style={{ flexWrap: "nowrap" }}>
          <SidebarRecruiter />
          <Content className="col-10 col-xl">
            {loading && <Loading />}
            <div className="d-flex justify-content-center">
              <S.Heading>{isDraft ? `Brouillons (${offers?.length || 0})` : `Annonces publiées (${offers?.length || 0})`}</S.Heading>
            </div>
             <div className="d-flex justify-content-center">
              <S.Heading>{bypass ?
              "Publication illimitée d'offres"
              :
              `${pluralize({
                count: remainingOffers,
                singular: 'Offre restante',
                showCount: true,
                zero: "Aucune offre restante"
              })} à publier`}
              </S.Heading>
            </div>

            <hr className="v3-separator pb-xl-1" />

            <div className="row p-3 justify-content-center">
              <MecButton
                icon={IconCreate}
                width={350}
                height={44}
                type="primary"
                className="site-button site-button-gap radius-xl"
                onClick={() => history.push("/recruiter/myoffer/create")}
              >
                Créer une nouvelle offre d'emploi
              </MecButton>
            </div>

            <hr className="v3-separator pb-xl-1" />

            {offers?.map((offer) => (
              <Card
                isDraft={isDraft}
                offer={offer}
                key={offer.id}
                handleViewDetail={handleViewDetail}
                view={view}
                onDelete={onDelete}
                handleShareSocial={handleShareSocial}
              />
            ))}
          </Content>
        </div>
      </div>
      <Footer />
      <ConfirmModal {...modal} />
      <ShareSocialsModal {...modalSocial}>
        <FacebookShareButton url={`${MECANOJOB_URL}/offer/${modalSocial.id}`}>
          <FacebookIcon />
        </FacebookShareButton>
        <LinkedinShareButton url={`${MECANOJOB_URL}/offer/${modalSocial.id}`}>
          <LinkedinIcon />
        </LinkedinShareButton>
        <TwitterShareButton url={`${MECANOJOB_URL}/offer/${modalSocial.id}`}>
          <TwitterIcon />
        </TwitterShareButton>
      </ShareSocialsModal>
    </div>
  )
}

export default ListOffer
