import React, { useEffect, useState, useGlobal } from "reactn"
import { useLocation, useParams } from "react-router-dom"
import { AuthModal } from "src/markup/Components/Modals"
import Header from "src/markup/Layout/Header"
import { CardOffer } from "src/markup/Pages/Offer/components"
import { getOfferById } from "src/services/api"
import { isRecruiter } from "src/services/auth"
import { ContentWrapper, Heading, OfferDetailsWrapper, StyledCard } from "./styles"
import RecruiterOffer from './RecruiterOffer'
import { Loading } from "src/markup/Element/Loading"
import { populateOffer } from "src/services/config"
import { _Helmet } from "src/utils";

const Offers = () => {
  const [config] = useGlobal('config')
  const { pathname } = useLocation();
  const { id } = useParams()
  const [offer, setOffer] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let subscribe = true;

    (async () => {
      try {
        const currentOffer = await getOfferById(id)
        subscribe && setOffer(currentOffer)
      } finally {
        setLoading(false)
      }
    })();

    return () => (subscribe = false);
  }, [id])

  const RECRUITER = isRecruiter();
  const data = populateOffer(config, offer);
  const isSingleOffer = !pathname.includes("candidate");
  const isPreview = pathname.includes("preview");

  const offerMetaTitle = "MecanoJob - Offre d'emploi"
  const offerMetaDescription =
    `${offer?.name_company} recrute un ${data?.jobNames?.[0]} ${data?.recruiterObjectives} à ${offer.pres_city} !`

  return (
    <div>
      {_Helmet({
        title: offerMetaTitle,
        description: offerMetaDescription
      })}

      {isSingleOffer && <Header showTitle={false} />}

      <OfferDetailsWrapper className="content-inner-2 py-5 px-5 position-relative">
        {loading && <Loading />}
        <div className="text-center text-uppercase">
          <Heading>OFFRE DÉTAILLÉE</Heading>
        </div>
        {(RECRUITER && !isPreview)  ? (
          <RecruiterOffer offer={offer} />
        ) : (
          <ContentWrapper className="content-inner-2 pt-0">
            <StyledCard className="page-wraper mt-5">
              <CardOffer offer={offer} />
            </StyledCard>
            <AuthModal />
          </ContentWrapper>
        )}
      </OfferDetailsWrapper>
    </div>
  )
}

export default Offers
