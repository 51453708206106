import React from "reactn"
import { Button, Form, FormGroup, Input, Label, ModalBody } from "reactstrap"
import * as S from "./styles"
import { ReactComponent as IconCancel } from "src/images/icons/icon-cancel.svg"
import { useTranslation } from "react-i18next"
import { useState } from "react"

export const ShareCVModal = ({ showShareCVModal, onCancel, onSend, onSubmit }) => {
  const { t } = useTranslation()
  const [formData, setFormData] = useState({
    email: "",
    message: "",
  })

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    onSend();
  };

  return (
    <S.Wrapper isOpen={showShareCVModal} centered toggle={onCancel}>
      <ModalBody>
        <S.Cancel onClick={onCancel}>
          <IconCancel width={30} height={30} />
        </S.Cancel>
        <div className="quote-bx text-center mb-5">
          <div className="quote-info">
            <h3 className="text-uppercase">{t("share_your_cv")}</h3>
            <Form onSubmit={handleSubmit}>
              <FormGroup className="text-left">
                <Label className="w-0 text-left">Email</Label>
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  className="m-0"
                  placeholder={t("receivers_email_address")}
                  style={{ borderRadius: "30px" }}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup className="text-left">
                <Label className="w-0 text-left">Message</Label>
                <textarea
                  name="message"
                  value={formData.message}
                  className="w-100 p-3"
                  placeholder={t("enter_your_message_here")}
                  style={{ height: "100px", borderRadius: "30px" }}
                  onChange={handleChange}
                />
              </FormGroup>
              <div className="left-job-buttons">
                <Button onClick={onCancel} className="site-button site-button-gap radius-xl text-uppercase">
                  {t("cancel")}
                </Button>
                <Button type="submit" className="site-button site-button-gap radius-xl text-uppercase">
                  {t("send")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </ModalBody>
    </S.Wrapper>
  )
}
