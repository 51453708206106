import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Prompt, useHistory } from "react-router-dom"
import ConfirmModal from "./CofirmModal"

const RouteLeavingGuard = ({ shouldBlockNavigation }) => {
  const history = useHistory()
  const [lastLocation, setLastLocation] = useState(null)
  const [confirmedNavigation, setConfirmNavigation] = useState(false)
  const [toggle, setToggle] = useState(false)

  const showModal = (location) => {
    setToggle(true)
    setLastLocation(location)
  }

  const closeModal = (callback) => {
    setToggle(false)
    typeof callback === "function" && callback()
  }
  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation)
      return false
    }
    return true
  }

  const handleConfirmNavigationClick = () => {
    setConfirmNavigation(true)
    setToggle(false)
  }

  useEffect(() => {
    if (confirmedNavigation) {
      history.push(lastLocation?.pathname)
    }
  }, [confirmedNavigation, history, lastLocation?.pathname])

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser)
    return () => {
      window.removeEventListener("beforeunload", alertUser)
    }
  }, [])

  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue = ""
  }

  return (
    <>
      <Prompt when message={handleBlockedNavigation} />
      <ConfirmModal
        onCancel={closeModal}
        onConfirm={handleConfirmNavigationClick}
        toggle={toggle}
        setToggle={setToggle}
      />
    </>
  )
}

RouteLeavingGuard.propTypes = {
  when: PropTypes.bool,
  shouldBlockNavigation: PropTypes.func,
}

export default RouteLeavingGuard
