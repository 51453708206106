import React, { useEffect, useState, useGlobal, useMemo } from 'reactn'
import { useParams } from 'react-router-dom'
import { MainLayout } from 'src/markup/Layout/MainLayout'
import { apiCVDetail } from 'src/services/api'
import { convertCVData } from 'src/services/config'
import { Loading } from 'src/markup/Element/Loading'
import CVSummary from "src/markup/Components/Cards/CVSummary"
import * as S from "./styles"
import moment from 'moment'
import { get, isEmpty } from 'lodash'

import { ReactComponent as IconCar } from "src/images/icons/icon-car.svg"
import { ReactComponent as IconEmail } from "src/images/icons/emailoutline.svg"
import { ReactComponent as IconUser } from "src/images/icons/icon-user.svg"
import { ReactComponent as IconPlus } from "src/images/icons/icon-plus.svg"
import IconDepanneur from "src/images/icons/jobs/depanneur.png"
import { ReactComponent as IconTelephone } from "src/images/icons/icon-telephone.svg"
import { ReactComponent as IconAddress } from "src/images/icons/icon-address.svg"
import { ASSET_QUALITY2_URL, ASSET_QUALITY3_URL, ASSET_QUALITYMAIN_URL, FORMAT_DATE, LEVELS } from "src/constants"
import { MecTabSwitch } from 'src/markup/Components/Forms'

const CvPage = props => {
  const { recruiter, candidate } = props
  const { id } = useParams();

  const [config] = useGlobal("config")
  const [user] = useGlobal("user")

  const [isLoading, setIsLoading] = useState(true)
  const [cv, setCv] = useState({})

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true)
        const _cv = await apiCVDetail(id)
        setCv(_cv)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [id])

  const cvDetail = useMemo(() => {
    if (config && !isEmpty(cv)) {
      return convertCVData(config, cv)
    }
    return null
  }, [config, cv])

  if (!config) return null;

  return (
    <MainLayout candidate={candidate} recruiter={recruiter}>
      <div className="position-relative">
        {isLoading ? <Loading /> : (
          <div>
            <S.CardDetailsOfferWrapper name_cv={get(cvDetail, "nameCV", "")}>
              <S.DetailsOfferWrapper className="row justify-content-between">
                <S.DetailsOfferLeftWrapper className="col" border>
                  <S.LeftLogoWrapper>
                    <div className="d-flex justify-content-start w-100 title-offers mb-4">
                      <IconUser className="cv-avatar mr-5" />
                      <div className="d-flex flex-column" style={{ maxWidth: 300 }}>
                        <span>{`${get(cv, "user.surname", "")} ${get(cv, "user.name", "")}`}</span>
                        <div className="d-flex align-items-center">
                          <IconCar className="mr-2 icon-reponsive" />
                          <span>{get(cvDetail, "jobName[0].name", "")}</span>
                        </div>
                      </div>
                    </div>
                  </S.LeftLogoWrapper>

                  <CVSummary cvDetail={cvDetail} />
                </S.DetailsOfferLeftWrapper>

                <S.DetailsOfferRightWrapper className="col d-flex flex-column">
                  <div className="d-flex mb-4 list-skills">
                    {get(cvDetail, "qualityMainCard", []).length > 0 ? (
                      get(cvDetail, "qualityMainCard", []).map((quality) => (
                        <div className="d-flex flex-column align-items-center" key={quality.qualityMainId}>
                          <img
                            className="icon-skills mb-2 icon-reponsive"
                            src={`${ASSET_QUALITYMAIN_URL}${get(quality, "qualityMainCardValue[0].filename", null)}`}
                            alt={get(quality, "qualityMainCardValue[0].name", "")}
                          />
                          <span className="card-text-home text-first-capitalize">
                            {get(quality, "qualityMainCardValueG[0].name", "")}
                          </span>
                        </div>
                      ))
                    ) : (
                      <IconPlus className="dont-have-skill" />
                    )}

                    {(get(cvDetail, "quality2Card", []).length > 0 && get(cvDetail, "quality2Card", [])?.[0] !== null) ? (
                      get(cvDetail, "quality2Card", []).map((quality2) => (
                        <div className="d-flex flex-column align-items-center" key={quality2?.[0]?.id}>
                          <img
                            className="icon-skills mb-2 icon-reponsive"
                            src={`${ASSET_QUALITY2_URL}${get(quality2?.[0], "filename", "")}`}
                            alt={get(quality2?.[0], "title", "")}
                          />
                          <span className="card-text-home text-first-capitalize">{get(quality2?.[0], "title", "")}</span>
                        </div>
                      ))
                    ) : (
                      <IconPlus className="dont-have-skill" />
                    )}

                    {(get(cvDetail, "quality3Card", []).length > 0 && get(cvDetail, "quality3Card", [])?.[0] !== null) ? (
                      get(cvDetail, "quality3Card", []).map((quality3) => (
                        <div className="d-flex flex-column align-items-center" key={quality3?.[0]?.id}>
                          <img
                            className="icon-skills mb-2 icon-reponsive"
                            src={`${ASSET_QUALITY3_URL}${get(quality3?.[0], "filename", "")}`}
                            alt={get(quality3?.[0], "title", "")}
                          />
                          <span className="card-text-home text-first-capitalize">{get(quality3?.[0], "title", "")}</span>
                        </div>
                      ))
                    ) : (
                      <IconPlus className="dont-have-skill" />
                    )}
                  </div>
                  <ul className="job-description infos text-blue ml-4 w-100">
                    <li>
                      <b>Localisation :</b> {get(cvDetail, "city", "")}
                    </li>
                    <br></br>
                    <li>
                      <b>Niveau d'expérience :</b> {get(cvDetail, "levelExp[0].name", "")}
                    </li>
                    <br></br>
                    <li>
                      <b>Dernière expérience :</b>{" "}
                      {get(cvDetail, "experiences", [])
                        .map((exp) => `${exp.expJobName} [${exp.expCompany}]`)
                        .join(" - ") || "Sans expérience"}
                    </li>
                    <br></br>
                    <li>
                      <b>Diplôme : </b>
                      {get(cvDetail, "formations", []).map(
                        (form) => `${form.diplomaValue[0].name} ${form.diplomaOptionValue[0].name}`
                      ).join(" - ") || "Sans formation"}
                    </li>
                  </ul>
                </S.DetailsOfferRightWrapper>
                <S.StyledDetails className='active'>
                  <div className="title">CV détaillé</div>

                  <div className="row mt-0 mt-xl-5 mb-5">
                    <S.Left className="col-12 col-xl-4">
                      <S.Profile>
                        <h3>PROFIL</h3>
                        <div className="pl-3 pr-3">
                          Dans le cadre de mes expériences <br />
                          passées, j'ai pu me renforcer dans <br />
                          {get(cvDetail, "skills", [])
                            .map((item) => item?.[0]?.name)
                            .join(", ")}
                          . <br />
                          <br /> Maîtrisant{" "}
                          {get(cvDetail, "skillComps", [])
                            .map((item) => item?.[0]?.name)
                            .join(", ")}
                          , <br />
                          je recherche un poste dans lequel je pourrais contribuer à{" "}
                          {get(cvDetail, "mission", [])
                            .map((item) => item?.[0]?.name)
                            .join(", ")}
                          . <br /> <br />
                          Étant{" "}
                          {get(cvDetail, "qualityMain", [])
                            .map((item) => item?.[0]?.name)
                            .join(", ")}
                          , j'ai{" "}
                          {get(cvDetail, "quality2", [])
                            .map((item) => item?.[0]?.title)
                            .join(", ")}{" "}
                          et je suis{" "}
                          {get(cvDetail, "quality3", [])
                            .map((item) => item?.[0]?.name)
                            .join(", ")}
                          . Je saurais répondre à vos attentes et surtout{" "}
                          {get(cvDetail, "missionComp", [])
                            .map((item) => item?.[0]?.name)
                            .join(", ")}
                          . Je parle{" "}
                          {get(cvDetail, "languages", [])
                            .map((item) => item?.[0]?.name)
                            .join(", ")}{" "}
                          est ma langue maternelle.
                        </div>
                      </S.Profile>

                      <S.Contact>
                        <h3>CONTACT</h3>
                        <div className="pl-3 pr-3 content">
                          <div className="d-flex mb-3">
                            <IconTelephone className="mr-2" width="40" height="40" />
                            <div className="d-flex flex-column">
                              <span>TÉLÉPHONE</span>
                              <span>{user?.phonenumber}</span>
                            </div>
                          </div>

                          <div className="d-flex mb-3">
                            <IconAddress className="mr-2" width="40" height="40" />
                            <div className="d-flex flex-column">
                              <span>ADRESSE</span>
                              <span>{user?.address}</span>
                              <span>{user?.postalcode}, {user?.city}</span>
                              <span>{user?.country}</span>
                            </div>
                          </div>

                          <div className="d-flex mb-3">
                            <IconEmail className="mr-2" width="40" height="40" style={{ fill: "#FF7200" }} />
                            <div className="d-flex flex-column">
                              <span>E-MAIL</span>
                              <span>{user?.email}</span>
                            </div>
                          </div>
                        </div>
                      </S.Contact>

                      <S.PointsInterest>
                        <h3>CENTRES D'INTÉRÊT</h3>
                        <div className="pl-3 pr-3">{cv?.extra_otherInfos}</div>
                      </S.PointsInterest>

                      <S.Informations>
                        <h3>INFORMATIONS COMPLÉMENTAIRES</h3>
                        <div className="pl-3 pr-3 d-flex">
                          <div className="d-flex w-100 flex-wrap">
                            {
                              get(cvDetail, 'licenseList', []).map(elm => {
                                return (
                                  <div className='col-6 pt-3'>
                                    <img src={IconDepanneur} alt="icon-france" className="icon-reponsive" />
                                    {elm.name}
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </S.Informations>
                    </S.Left>
                    <S.Right className="col-12 col-xl-7 ml-0 ml-xl-5">
                      <S.Profile>
                        <h3>FORMATION</h3>
                        <div className="pl-3 pr-3">
                          {get(cvDetail, "formations", []).map((form) => (
                            <div className="d-flex flex-column mb-3" key={form.id}>
                              <span>
                                {get(form, "diplomaValue[0].name", "")} {get(form, "diplomaOptionValue[0].name", "")}
                              </span>
                              <span>
                                {get(form, "formSchool", "")} - {get(form, "formEntryDate", "")} -{" "}
                                {get(form, "formExitDate", "")}
                              </span>
                              <span className="mt-2">{get(form, "formMissions", "")}</span>
                            </div>
                          ))}
                        </div>
                      </S.Profile>

                      <S.Contact>
                        <h3>PARCOURS PROFESSIONNEL</h3>
                        <div className="pl-3 pr-3">
                          {get(cvDetail, "exp", []).map((ex) => (
                            <div className="d-flex flex-column mb-3" key={ex.id}>
                              <span>
                                {get(ex, "expJobName", "")} - {get(ex, "expCompany", "")}
                              </span>
                              <span>
                                {get(ex, "expEntryDate", "")}-{get(ex, "expExitDate", "")}
                              </span>
                              <span className="mt-2">{get(ex, "expMissions", "")}</span>
                            </div>
                          ))}
                        </div>
                      </S.Contact>

                      <S.PointsInterest>
                        <h3>COMPÉTENCES</h3>
                        <div className="pl-3 pr-3 process-wrapper">
                          {get(cvDetail, "tools", []).map((tool) => (
                            <div key={tool?.[0]?.id} className="mb-4">
                              <span className="mr-5 mb-2 col-12">{get(tool, "[0].name", "")}</span>
                              <MecTabSwitch
                                tabs={LEVELS}
                                activeTab={tool.level - 1}
                                className="col-12 mb-2"
                                name="tools"
                                style={{ pointerEvents: 'none' }}
                              />
                            </div>
                          ))}
                        </div>
                      </S.PointsInterest>
                    </S.Right>
                  </div>
                </S.StyledDetails>

                <S.StyledFooter className="w-100 mt-5 ">
                  <div className="text-right">
                    <span>Profil selectionné le {moment(cv?.createdAt).format(FORMAT_DATE)}</span>
                  </div>
                </S.StyledFooter>
              </S.DetailsOfferWrapper>
            </S.CardDetailsOfferWrapper>
          </div>
        )}
      </div>
    </MainLayout>
  )
}


export default CvPage
