import styled from "styled-components"
import { Table, Container } from "react-bootstrap"

export const Wrapper = styled(Container)`
  padding-top: 30px;
`

export const Box = styled.div`
  .header {
    color: #023f73;
    font-family: Raleway;
    font-weight: bold;
    font-size: 1.5rem;
  }

  @media only screen and (min-width: 1448px) {
    min-width: fit-content;
  }

  background: var(--white);
  border-radius: 15px;
  margin: 0 auto;
  padding: 30px;

  margin-bottom: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`

export const STable = styled(Table)`
  color: var(--blue600);

  thead > tr > th {
    border-top: none;
    color: inherit;
    padding: 10px 30px;
    font-weight: 800;
  }

  tbody > tr > td {
    font-family: Rubik;
    padding: 10px 30px;
    font-weight: 300;
    vertical-align: middle;
    &:first-child {
      font-weight: 400;
    }
  }

  .cart {
    &__btn-trash {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        width: 20px;
        path {
          fill: var(--white);
        }
      }
      background: var(--orange300);

      &:hover {
        background: var(--blue600);
      }
    }
  }
`

export const Heading = styled.h1`
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #ff7200;

  @media(max-width: 992px) {
    font-size: 30px;
  }
`
