import React, { useState } from "reactn"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import CardCV from "src/markup/Components/Cards/CardCV/index"
import { MecButton } from "src/markup/Components"
import { MAX_LENGTH_CV } from "src/constants"
import { StyledCardCV, StyledCardCVComponent } from "./styles"
import { ReactComponent as IconNote } from "src/images/icons/note.svg"

const CardCVComponent = ({ listCV, onDelete, totalCV, shareCV }) => {
  const [view, setView] = useState("")

  const { t } = useTranslation()
  const history = useHistory()

  const handleViewDetail = (id) => setView(id === view ? "" : id)

  return (
    <StyledCardCVComponent>
      <div className="styled-action mt-1 d-flex flex-column justify-content-center align-items-center">
        <MecButton
          icon={IconNote}
          widthIcon={24}
          height={44}
          width={250}
          type="primary"
          className="site-button site-button-gap radius-xl"
          onClick={() => history.push("/candidate/mycv/create")}
          disabled={totalCV >= MAX_LENGTH_CV}
        >
          {t("Create a new CV")}
        </MecButton>
        <div className="note">{`Vous pouvez créer jusqu'à ${MAX_LENGTH_CV} CV`}</div>
      </div>

      {listCV.map((cv) => (
        <StyledCardCV className="page-wraper" key={cv.id}>
          <CardCV data={cv} handleViewDetail={handleViewDetail} view={view} onDelete={onDelete} shareCV={shareCV} />
        </StyledCardCV>
      ))}
    </StyledCardCVComponent>
  )
}

export default CardCVComponent
