import React, { useGlobal, useState, useRef } from "reactn"
import { FormGroup } from "reactstrap"
import { includes } from "lodash"

import { MecAutocompleteLocation, MecCollapse, MecTabSwitch } from "src/markup/Components/Forms"
import { MecButton } from "src/markup/Components/MecButton"
import { StyledForm } from "src/markup/Element/CVForm/styles"
import { YOUR_COMPANY } from "src/markup/Element/OfferForms/constant"
import { MecInput } from "src/markup/Components/Forms"
import IconUploadImage from "src/images/icons/icon-upload-image.svg"
import { Image, TitleUpload, UploadImage } from "../styles"
import { uploadImage } from "src/services/api"
import { Loading } from "src/markup/Element/Loading"
import { STRING_IS_BASE64_REG_EXP } from "src/constants"

const fileExt = (filename) => filename.split('.').pop();

const YourCompany = ({ formik, handleCompleted, completed, handleOpenCollapse, isOpen, disabled }) => {
  const [user] = useGlobal("user")
  const companyRef = useRef(null)
  const [avatar, setAvatar] = useState("")
  const [loading, setLoading] = useState("")
  const { values, setFieldValue } = formik
  const isCompleted = values.name_company && values.pres_city

  const handleUploadLogo = async (e) => {
    e.preventDefault()
    const { name } = e.target.files[0]
    var reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = async () => {
      setAvatar(reader.result)
      setLoading("upload");

      const uploadData = {
        image: {
          name,
          extension: fileExt(name),
          base64: reader.result.split(",")[1],
        },
      }
      const res = await uploadImage(uploadData);
      setFieldValue("offer_logo", res.data)
      setLoading("")
    }
  }

  function scrollCompany() {
    companyRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  return (
    <MecCollapse
      title="Votre entreprise"
      isOpen={isOpen === "company"}
      setIsOpen={() => handleOpenCollapse("company")}
      completed={isCompleted || includes(completed, "yourCompany")}
      name="isCompleted"
      className="mb-5"
      disabled={disabled}
    >
      <StyledForm>
        <div className="d-flex flex-wrap justify-content-between" ref={companyRef}>
          <div className="row" style={{ flex: 1 }}>
            {YOUR_COMPANY(user)
              .filter(({ display }) => display)
              .map((field, index) => (
              <FormGroup className="col-12" key={index}>
                <div className={`py-2 label text-align-last-left ${field.isRequired && "label-required"}`}>
                  {field.label}
                </div>
                {field.type === "boolean" && (
                  <MecTabSwitch
                    tabs={field.options}
                    activeTab={formik.values[field.name] ? 0 : 1}
                    className="ml-xl-3 w-100 h-auto"
                    style={{ maxWidth: "300px" }}
                    onChange={(e) => {
                      formik.setFieldValue(field.name, !e)
                    }}
                  />
                )}
                {field.type === "autocomplete_location" && (
                  <MecAutocompleteLocation formik={formik} name={field.name} colorTxtError="red" />
                )}
                {field.type === "string" && (
                  <MecInput
                    name={field.name}
                    placeholder={field.placeholder}
                    formik={formik}
                    colorTxtError="red"
                    className="secondary text-align-last-left"
                  />
                )}
              </FormGroup>
            ))}
          </div>
          {user?.internal && formik.values.featured_offer && (
            <UploadImage className="mt-2" style={{ flex: 0.2 }}>
              {loading === "upload" && <Loading />}
              <TitleUpload>Logo ?</TitleUpload>
              <Image avatar={avatar}>
                <img
                  src={
                    (avatar && (avatar.match(STRING_IS_BASE64_REG_EXP) ? URL.createObjectURL(avatar) : avatar)) ||
                    IconUploadImage
                  }
                  alt="avatar"
                />
                <input
                  placeholder="Description"
                  type="file"
                  name="file"
                  accept="image/png, image/jpeg"
                  onChange={(e) => {
                    handleUploadLogo(e)
                  }}
                />
              </Image>
            </UploadImage>
          )}
        </div>

        <div className="w-100 d-flex justify-content-center">
          <MecButton
            icon=""
            type="primary"
            width={120}
            widthIcon={20}
            className="text-capitalize mt-2"
            onClick={(e) => {
              handleCompleted(e, "yourCompany")
              handleOpenCollapse("position")
              scrollCompany()
            }}
            disabled={!isCompleted}
          >
            Valider
          </MecButton>
        </div>
      </StyledForm>
    </MecCollapse>
  )
}

export default YourCompany
