import * as Yup from 'yup'

export const loginSchema = ({ t }) => Yup.object({
  email: Yup.string().required(t("invalid_field")),
  password: Yup.string().required(t("required_field")),
})

export const registerSchema = ({ t }) => Yup.object({
  surname: Yup.string().required(t("required_field")),
  name: Yup.string().required(t("required_field")),
  email: Yup.string().email().required(t("invalid_field")),
  password: Yup.string()
    .required(t("required_field"))
    .min(6, 'Le mot de passe doit comporter de 6 à 20 caractères')
    .max(20, 'Le mot de passe doit comporter de 6 à 20 caractères'),
  confirmPassword: Yup.string()
    .when('password', {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Les mots de passe ne correspondent pas'
      ),
    })
    .required('Confirmer votre mot de passe *'),
  policy: Yup.bool().oneOf([true], 'Le champ doit être vérifié')
})

export const recruiterRegisterSchema = ({ t }) => Yup.object({
  enterpriseName: Yup.string().required(t("invalid_field")),
  email: Yup.string().email().required(t("invalid_field")),
  password: Yup.string()
    .required(t("required_field"))
    .min(6, 'Le mot de passe doit comporter de 6 à 20 caractères')
    .max(20, 'Le mot de passe doit comporter de 6 à 20 caractères'),
  confirmPassword: Yup.string()
    .when('password', {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Les mots de passe ne correspondent pas'
      ),
    })
    .required('Confirmer votre mot de passe *'),
  policy: Yup.bool().oneOf([true], 'Le champ doit être vérifié')
})
