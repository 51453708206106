import React, { useCallback, useEffect, useGlobal, useMemo, useState } from "reactn"
import { useHistory } from "react-router-dom"
import { isEmpty, keyBy } from "lodash"
import { ReactComponent as IconPlus } from "src/images/icons/icon-plus.svg"
import { ReactComponent as TickIcon } from "src/images/icons/tick-icon.svg"
import { ReactComponent as Trash } from "src/images/icons/trash.svg"
import { MecButton } from "src/markup/Components"
import Header from "src/markup/Layout/Header"
import Footer from "src/markup/Layout/Footer"
import { createOrderInfo } from "src/services/api"
import { getCartFromStorage, saveCartToStorage } from "src/services/auth"
import { formatPrice } from "src/utils"
import * as S from "./styles"
import { Loading } from "src/markup/Element/Loading"

const MyCartRecruiter = () => {
  const [formulas] = useGlobal("formulas")
  const [cartList, setCartList] = useState({})
  const [, setQuantity] = useState(1)
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const cart = getCartFromStorage()

    if (cart.length) {
      setCartList(keyBy(cart, (el) => el.ID))
    }
  }, [history])

  useEffect(() => {
    saveCartToStorage(Object.values(cartList))
  }, [cartList])

  const deleteCart = (id) => () => {
    delete cartList[id]
    setCartList((prevCart) => ({ ...prevCart }))
  }

  const handleByPackage = useCallback(() => {
    const orders = Object.values(cartList).map((cart) => ({
      package_id: cart.ID,
      quantity: cart.count,
    }))

    setLoading(true)
    createOrderInfo({ orders })
      .then((data) => {
        history.push(`/recruiter/checkout?orderId=${data?.order?.id}`)
      })
      .catch((error) => error)
      .finally(() => setLoading(false))
  }, [cartList, history])

  const totalHT = useMemo(() => {
    if (!isEmpty(cartList)) {
      return Object.values(cartList).reduce((a, k) => a + k.PRICE * k.count, 0) || 0
    }
    return null
  }, [cartList])

  const totalTVA = useMemo(() => {
    if (formulas?.taxPercent) {
      return (totalHT * formulas?.taxPercent) / 100
    }

    return 0
  }, [formulas, totalHT])

  const totalTTC = useMemo(() => totalHT + totalTVA, [totalHT, totalTVA])

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Header showTitle={false} />

      <S.Wrapper>
        <div className="d-flex justify-content-center">
          <S.Heading>Ma commande</S.Heading>
        </div>
        <hr className="v3-separator pb-xl-3" />

        <S.Box>
          <S.STable responsive className="cart">
            <thead className="text-center">
              <tr>
                <th>Formule</th>
                <th>Quantité</th>
                <th>Prix unitaire HT</th>
                <th>Prix total HT</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Object.values(cartList).map((cart) => {
                return (
                  <tr key={cart.ID} className="text-center">
                    <td>{cart.NAME}</td>
                    <td className="text-center">1</td>
                    <td style={{ minWidth: 200 }}>{formatPrice(cart.PRICE)} €</td>
                    <td style={{ minWidth: 200 }}>{formatPrice(cart.PRICE * cart.count)} €</td>
                    <td>
                      <div className="w-100 h-100">
                        <button className="cart__btn-trash" onClick={deleteCart(cart.ID)}>
                          <Trash />
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </S.STable>

          <div
            className="d-flex align-items-center"
            onClick={() => history.push("/pricing")}
            style={{ cursor: "pointer" }}
          >
            <IconPlus width={30} />
            <div className="pl-2 text-blue font-weight-bold">Ajouter une formule</div>
          </div>
          <div className="d-flex justify-content-end w-100">
            <div className="p-5 text-blue">
              <div className="d-flex align-items-center justify-content-between">
                <div className="font-weight-bold">Total HT</div>
                <div className="pl-5">{formatPrice(totalHT)} €</div>
              </div>

              <div className="d-flex align-items-center justify-content-between">
                <div className="font-weight-bold">TVA ({formulas?.taxPercent} %)</div>
                <div className="pl-5">{formatPrice(totalTVA)} €</div>
              </div>

              <div className="d-flex align-items-center justify-content-between">
                <div className="font-weight-bold">Total TTC</div>
                <div className="pl-5">{formatPrice(totalTTC)} €</div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <MecButton onClick={handleByPackage} icon={TickIcon} width={300} widthIcon={23}>
              Valider ma commande
            </MecButton>
          </div>
        </S.Box>
      </S.Wrapper>

      <Footer />
    </>
  )
}

export default MyCartRecruiter
