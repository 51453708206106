import { setGlobal, getGlobal } from "reactn";
import { isFunction } from 'lodash';
import { not } from "src/utils";
import { API_URL, ROLE_RECRUITER, ROLE_CANDIDATE } from 'src/constants';
import axios from './axios';

export const registerUser = (payload) => axios.post(`${API_URL}/auth/signup/user`, payload);

export const loginCandidate = (email, password) => axios.post(`${API_URL}/auth/signin/candidate`, { email, password });

export const loginRecruiter = (email, password) => {
  return axios
    .post(`${API_URL}/auth/signin/recruiter`, {
      email,
      password,
    })
    .then((data) => data)
    .catch(console.error);
};

export const cacheUser = (newUser, callback) => {
  const user = getUserFromGlobal();
  const data = { ...(user || {}), ...newUser };
  setGlobal({ user: data }, () => {
    toStorage(data);
    isFunction(callback) && callback(data);
  });
};

export const toStorage = (data) => localStorage.setItem('user', JSON.stringify(data));

export const clearUser = () => localStorage.removeItem('user');

export const logout = () => setGlobal({ user: null }, clearUser);

export const loadUserFromStorage = () => {
  try {
    const userInfo = localStorage.getItem('user');

    return userInfo ? JSON.parse(userInfo) : null;
  } catch (error) {
    return null;
  }
};

export const getUserFromGlobal = () => getGlobal()?.user;

export const isAnonymous = () => not(getUserFromGlobal());

export const isCandidate = () => getUserFromGlobal()?.roles?.includes(ROLE_CANDIDATE);

export const isRecruiter = () => getUserFromGlobal()?.roles?.includes(ROLE_RECRUITER);

export const changePassword = (payload) => axios.post(`${API_URL}/auth/change-password`, payload);

export const getCartFromStorage = () => JSON.parse(localStorage.getItem('cart') ?? '[]');

export const saveCartToStorage = (cart) => localStorage.setItem('cart', JSON.stringify(cart));

export const clearCart = () => localStorage.removeItem('cart');
