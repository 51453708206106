import React from 'reactn'
import Footer from 'src/markup/Layout/Footer'

import Header from 'src/markup/Layout/Header'
import { PackagePlan } from 'src/markup/Pages/OurPackage/Recruiter'

const OurPackage = () => {
  return (
    <>
      <Header showTitle={false} />
      <PackagePlan />
      <Footer />
    </>
  )
}

export default OurPackage
