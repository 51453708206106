import { useRef } from 'reactn';
import { Helmet } from "react-helmet";
import { get, isNil, isEmpty } from 'lodash';
import numbro from 'numbro';

let fr = require('numbro/dist/languages/fr-FR.min');
numbro.registerLanguage(fr, true);
numbro.setLanguage('fr-FR');

const words = (str) => str.trim().split(/\s+/);

export const pluralize = ({ singular, plural, count, showCount, zero }) => {
  if (count === 0 && zero) {
    return zero;
  }

  let output = singular;
  if (count > 1) {
    let wordsArr = words(singular);
    output = plural || `${wordsArr.map((w) => `${w}s`).join(' ')}`;
  }

  return showCount ? `${count} ${output}` : output;
};

export const genderize = ({ male, female, both, gender }) => {
  return gender === 'm' ? male : (gender === 'f' ? female : both);
}

export const not = (elt) => isNil(elt) || isEmpty(elt) || !elt;

export const formatPrice = (x, mantissa = 0) => {
  if (isNaN(x) || !x) {
    return 0;
  }

  return numbro(x).format({ thousandSeparated: true, mantissa });
};

export const extractFromAddress = (components, type) => {
  return components?.filter((component) => component.types.indexOf(type) === 0).map((item) => item.long_name).pop();
}

export const capitalize = (str) => {
  if (!str) return '';

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
};

export const useComponentWillMount = (cb) => {
  const willMount = useRef(true);
  if (willMount.current) cb();
  willMount.current = false;
}

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

function checkFrCompanyId(number, size) {
  if (!number) return false; // check empty, null, undefined

  number = number.replace(/\s/g, '') // trim
  if (isNaN(number) || number.length !== size) return false;

  let bal = 0;
  let total = 0;
  for (let i = size - 1; i >= 0; i--) {
      const step = (number.charCodeAt(i) - 48) * (bal + 1);
      total += (step > 9) ? step - 9 : step;
      bal = 1 - bal;
  }

  return total % 10 === 0;
}

export const isSIRET = (num) => checkFrCompanyId(num, 14);
export const isSIREN = (num) => checkFrCompanyId(num, 9);
export const isVAT = (num) => {
  if (!num) return false;

  num = num.trim().replace(/\s/g, '');
  if (num.length !== 13) return false;

  const countryCode = num.substring(0, 2);
  if (!/^[a-zA-Z]+$/.test(countryCode)) return false;

  const siren = num.substring(4, num.length);
  return isSIREN(siren);
};

export const vatFromSIRET = (siret) => {
  if (!isSIRET(siret)) return '';

  const siren = siret.substring(0, 9);
  let vat = (12 + 3 * (siren % 97)) % 97;
  vat = vat < 10 ? `0${vat}` : vat;

  return `FR${vat}${siren}`;
}

export const getColorByScore = (score) => {
  let r = score < 50 ? 255 : Math.floor(255 - ((score * 2 - 100) * 255) / 100)
  let g = score > 50 ? 255 : Math.floor((score * 2 * 255) / 100)

  return `rgb(${r}, ${g}, 0)`
}

export const getLabelByScore = (score) => {
  if (score < 25) return "Mauvais"
  if (score < 50) return "Correct"
  if (score < 75) return "Bon"
  if (score < 100) return "Très bon"

  return "Excellent"
}

export const checkFormikHasValue = (valuesFormik) => Object.keys(valuesFormik).some(key => valuesFormik[key]);

export const openInNewTab = (url, setModal) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
  setModal && setModal({ showConfirmModal: false })
}

export const productionMode = () => process.env.REACT_APP_ENV === 'production';

export const conditionalTemplating = (data, key, strTemplate) => {
  if (get(data, key, undefined)) {
    return strTemplate.replace("$1", get(data, key));
  }

  return "";
}

export const _Helmet = ({ title, description }) => (
  <Helmet>
    {title && (<title>{title}</title>)}
    {title && (<meta property="og:title" content={title} />)}
    {title && (<meta name="twitter:title" content={title} />)}

    {description && (<meta name="og:description" content={description} />)}
    {description && (<meta name="description" content={description} />)}
    {description && (<meta name="twitter:description" content={description} />)}
  </Helmet>
);
