import React, { useState } from 'react';
import axios from "src/services/axios";
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import { isEmail } from "validator";
import { API_URL } from "src/constants";
import { useTranslation } from 'react-i18next';


var logo = require('./../../images/logo-mecanojob.png');

export default function Footer() {
	const Toast = Swal.mixin({
		toast: true,
		position: 'bottom',
		showConfirmButton: false,
		timer: 2000,
	});

	const [email, setEmail] = useState('');
	const {t} = useTranslation()

	const subscribeNewsletter = () => {
		if (isEmail(email)) {
			axios
				.post(`${API_URL}/auth/newsletter`, { email })
				.then(() => {
					Toast.fire({
						icon: 'success',
						title: "Félicitations, vous êtes abonné à notre newsletter."
					});
				})
				.catch(() => {
					Toast.fire({
						icon: 'error',
						title: "Une erreur inconnue s'est produite"
					});
				});
		} else {
			Toast.fire({
				icon: 'warning',
				title: "Votre email est invalide. Vérifiez votre saisie."
			});
		}
	}

	return (
		<footer className="site-footer">
			<div className="footer-top">
				<div className="container">
					<div className="row">
						<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
							<div className="widget">
								<img src={logo.default} width="280" className="m-b15-footer-special" alt="Mecanojob Logo" />
								<div className="subscribe-form m-b20">
									<div className="input-group">
										<input
											type="text"
											className="form-control"
											name="email"
											autoComplete="email"
											placeholder="Votre adresse e-mail"
											onChange={({ target }) => setEmail(target.value)}
										/>
										<span className="input-group-btn">
											<button
												name="submit"
												style={{ backgroundColor: "#ff7200" }}
												className="site-button radius-xl"
												onClick={subscribeNewsletter}>
												S'abonner
											</button>
										</span>
									</div>
								</div>
								<h6 className="m-b20 text-orange">Retrouvez-nous sur les réseaux sociaux</h6>
								<div className="list-inline m-a0">
									<a target="_blank" href="https://www.facebook.com/Mecanojob/" className="site-button white facebook circle mr-2" rel="noreferrer"><i className="fa fa-facebook"></i></a>
									<a target="_blank" href="https://www.instagram.com/mecanojob/" className="site-button white instagram circle mr-2" rel="noreferrer"><i className="fa fa-instagram"></i></a>
									<a target="_blank" href="https://linkedin.com/company/mecanojob/" className="site-button white linkedin circle" rel="noreferrer"><i className="fa fa-linkedin"></i></a>
									{/* <a href="https://oovoom.com//" className="float-right"><img className="img-fluid" src="#"></img></a> */}
								</div>
							</div>
						</div>
						<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="widget border-0">
								<h5 className="m-b20 text-orange">Liens utiles</h5>
								<ul className="list-1 w10 list-line m-l20">
									<li><Link to={'/static/legal'}>Mentions légales</Link></li>
									<li><Link to={'/static/cgu'}>Conditions d'utilisation</Link></li>
									{/* <li><Link to={'/static/rgpd'}>Données personnelles</Link></li> */}
									{/* <li><Link to={''}>Centre de sécurité</Link></li> */}
									{/* <li><Link to={'/static/accesibility'}>Accessibilité</Link></li> */}
									<li><Link to={'/static/cookies'}>Paramètres des cookies</Link></li>
								</ul>
							</div>
						</div>
						<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="widget border-0">
								<h5 className="m-b20 text-orange">Espace candidat</h5>
								<ul className="list-1 w10 list-line m-l20">
									<li><Link to={'/candidate/mycv/create'}>Créer un CV</Link></li>
									<li><Link to={'/static/helpcandidate'}>Aides</Link></li>
								</ul>
							</div>
						</div>
						<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="widget border-0">
								<h5 className="m-b20 text-orange">Espaces recruteur</h5>
								<ul className="list-1 w10 list-line m-l20">
									<li><Link to={'/recruiter/myoffer/create'}>Publier une annonce</Link></li>
									<li><Link to={'/static/hrinfos'}>Infos RH</Link></li>
									<li><Link to={'/static/helprecruiter'}>Aides</Link></li>
								</ul>
							</div>
						</div>
						<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="widget border-0">
								<h5 className="m-b20 text-orange">{t('contact')}</h5>
								<ul className="list-1 w10 list-line m-l20">
									<li>{t("call_us")}</li>
									<li>{t("write_to_us")}</li>
									<li>{t("make_an_appointment")}</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="footer-bottom">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 text-center">
							<span>© 2022 MECANOJOB by <Link target="_blank" to="//oovoom.com">OOVOOM</Link></span>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}
