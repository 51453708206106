import React, { useEffect, useGlobal, useRef } from "reactn"
import { useMainContext } from "src/markup/Layout/MainLayout/MainContext"
import { FormSearchCV, ListCardCV } from "src/markup/Pages/SearchPage/Recruiter/components"
import * as S from "./styles"

export const SearchCV = (props) => {
  const [config] = useGlobal("config")
  const { isShowResult } = useMainContext()
  const refResult = useRef(null)

  useEffect(() => {
    if (isShowResult && refResult.current) {
      refResult.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }
  }, [isShowResult])

  if (!config) return null

  return (
    <S.Wrapper>
      <div className="d-flex justify-content-center">
        <S.Heading>Rechercher un profil</S.Heading>
      </div>
      <hr className="v3-separator pb-xl-3" />

      <FormSearchCV {...props} />
      {isShowResult && (
        <div ref={refResult} className="pt-5">
          <ListCardCV />
        </div>
      )}
    </S.Wrapper>
  )
}
