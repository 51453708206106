import { includes, get, sample, sampleSize, union, uniqWith, isEqual } from "lodash"
import * as Yup from "yup"

export const CVFormInitialValues = {
  joblistId: null,
  search_exp: null,
  pres_jobOtherName: null,
  city: null,
  city_range: 15,

  name_cv: "",
  status_cv: "",

  experiences: [
    {
      id: 1,
      expJobName: "",
      expCompany: "",
      expEntryDate: "",
      expExitDate: "",
      expCity: "",
      expMissions: "",
    },
  ],

  formations: [
    {
      id: 1,
      domain_activity: null,
      formSchool: "",
      formCity: "",
      formDiploma: null,
      formDiplomaOption: null,
      formEntryDate: "",
      formExitDate: "",
      formMissions: "",
    },
  ],

  mission: [
    {
      id: 1,
      search_mission: null,
      search_specMission: [],
    },
  ],

  skill: [
    {
      id: 1,
      search_skill: null,
      search_skillComp: [],
    },
  ],

  tools: [
    {
      keyId: 1,
      id: "",
      name: "",
      level: 1,
    },
  ],

  search_qualityMain: [],
  search_quality2: null,
  search_quality3: null,
  languages: [{filename: 'french.png', id: 8, name: 'Français'}],
  extra_driversLicenceB: [],
  extra_criminalRecord: false,
  extra_otherInfos: "",
  // status_cv, 1 === Enregistrer, 2 === Publier
}

export const CVFormRandomizedValues = (config) => {
  const cities = [
    "Paris", "Lyon", "Marseille", "Lille", "Toulouse",
    "Nice", "Bordeaux", "Nantes", "Strasbourg"
  ];

  function randomDate(start, end) {
    var date = new Date(+start + Math.random() * (end - start))
    return date
  }

  let job = sample(config?.jobs)
  let otherJob = sample(config?.otherJobs.filter((otherJob) => otherJob.joblistId === job.id))
  let mission = sample(config?.missions.filter((mission) => mission.joblistId === job.id))
  let missionSpecs = sampleSize(config?.missionSpecs.filter((missionSpecs) => missionSpecs.joblistId === job.id), 3)
  let skill = sample(config?.skills.filter((skills) => skills.joblistId === job.id))
  let skillComps = sampleSize(config?.skillsComp.filter((skillsComp) => skillsComp.joblistId === job.id), 3)
  let tools = sampleSize(config?.tools.filter((skillsComp) => skillsComp.joblistId === job.id), 3)
  let searchXP = sample(config?.xp)
  let formEntryDate = randomDate(new Date(2010,1,1), new Date(2014,12,30))
  let formExitDate = randomDate(new Date(2015,1,1), new Date(2017,12,30))
  let expEntryDate = randomDate(new Date(2018,1,1), new Date(2020,12,30))
  let expExitDate = randomDate(new Date(2021,1,1), new Date(2021,12,30))
  let formDiploma = sample(config?.diplomas)
  let formDiplomaOptions = sample(config?.diplomaOptions.filter((diplomaOptions) => diplomaOptions.joblistId === job.id))
  let languages = uniqWith(union([{ filename: "french.png", id: 8, name: "Français" }], (sampleSize(config?.languages, 2))), isEqual)
  let toolLevels = tools.map(tools => ({ id: tools.id, name: tools.name, level: Math.floor(Math.random() * (4 - 1 + 1)) + 1}))

  return (
    {
      joblistId: job,
      search_exp: searchXP,
      pres_jobOtherName: otherJob,
      city: sample(cities),
      city_range: Math.floor(Math.random() * (30 - 1)) + 1,

      name_cv: "CV généré-" + Math.floor(Math.random() * (9999)),
      status_cv: "",

      experiences: [
        {
          id: 1,
          expJobName: job.name,
          expCompany: "Entreprise-" + Math.floor(Math.random() * (9999)),
          expEntryDate: expEntryDate,
          expExitDate: expExitDate,
          expCity: sample(cities),
          expMissions: "Mission-" + Math.floor(Math.random() * (9999)),
        },
      ],

      formations: [
        {
          id: 1,
          domain_activity: job,
          formSchool: "Ecole-" + Math.floor(Math.random() * (9999)),
          formCity: sample(cities),
          formDiploma: formDiploma,
          formDiplomaOption: formDiplomaOptions,
          formEntryDate: formEntryDate,
          formExitDate: formExitDate,
          formMissions: "Mission-" + Math.floor(Math.random() * (9999)),
        },
      ],

      mission: [
        {
          id: 1,
          search_mission: mission,
          search_specMission: missionSpecs,
        },
      ],

      skill: [
        {
          id: 1,
          search_skill: skill,
          search_skillComp: skillComps,
        },
      ],

      tools: toolLevels,

      search_qualityMain: sampleSize(config?.qualityMain, 3),
      search_quality2: sample(config?.quality2),
      search_quality3: sample(config?.quality3),
      languages: languages,
      extra_driversLicenceB: sampleSize(config?.otherDriverLicenses, 2),
      extra_criminalRecord: Math.random() < 0.5,
      extra_otherInfos: "Centres d'intérêts-" + Math.floor(Math.random() * (9999)),
      // status_cv, 1 === Enregistrer, 2 === Publier
    }
  )
}

Yup.addMethod(Yup.array, 'stillWorking', function (propertyName, message) {
  return this.test("stillWorking", message, function(list = []) {
    const mapper = x => get(x, propertyName);
    const set = [...list.map(mapper)];
    const idx = list.findIndex((l, i) => {
      return set[i] === undefined
    });
    return idx !== -1 ? this.createError({ path: `[[experiences.${idx}].${propertyName}]`, message }) : true;
  });
});

export const CVFormvalidationSchema = ({ t, inexperienced, isDraft }) => {
  return isDraft
    ? Yup.object({})
    : Yup.object().shape({
        joblistId: Yup.object()
          .required(t("required_field"))
          .nullable(true),
        search_exp: Yup.object()
          .required(t("required_field"))
          .nullable(true),
        pres_jobOtherName: Yup.object()
          .required(t("required_field"))
          .nullable(true),
        city: Yup.string(t("invalid_field"))
          .trim()
          .required(t("required_field"))
          .nullable(true),
        name_cv: Yup.string(t("invalid_field"))
          .required(t("required_field"))
          .nullable(true),

        experiences:
          !includes(inexperienced, "experiences") &&
          Yup.array().of(
            Yup.object().shape({
              expJobName: Yup.string(t("invalid_field"))
                .trim()
                .required(t("required_field")),
              expCompany: Yup.string(t("invalid_field"))
                .trim()
                .required(t("required_field")),
              expEntryDate: Yup.string(t("invalid_field"))
                .nullable()
                .required(t("required_field")),
              expExitDate: Yup.string(t("invalid_field"))
                .nullable(),
              expCity: Yup.string(t("invalid_field"))
                .trim()
                .required(t("required_field")),
              expMissions: Yup.string(t("invalid_field"))
                .trim()
                .required(t("required_field")),
            })
          ).stillWorking('expExitDate', t("required_field")),

        formations:
          !includes(inexperienced, "formations") &&
          Yup.array().of(
            Yup.object().shape({
              domain_activity: Yup.object()
                .required(t("required_field"))
                .nullable(true),
              formSchool: Yup.string().required(t("required_field")),
              formCity: Yup.string().required(t("required_field")),
              formEntryDate: Yup.string().nullable(),
              formExitDate: Yup.string().nullable(),
              formMissions: Yup.string(),
            })
          ),
      })
}
