import React, { useGlobal, useRef } from "reactn"
import { FormGroup } from "reactstrap"
import { includes } from "lodash"

import { MecCollapse, MecInput, MecSelectorCustom, MecTabSwitch } from "src/markup/Components/Forms"
import { StyledForm } from "src/markup/Element/CVForm/styles"
import { MecButton } from "src/markup/Components"

const levels = [
  { id: true, name: "Oui" },
  { id: false, name: "Non" },
]

const AdditionalInformation = ({ formik, handleCompleted, completed, isOpen, handleOpenCollapse }) => {
  const addiontionalInformationRef = useRef(null)
  const [config] = useGlobal("config")

  function scrollAdditionalInformation() {
    addiontionalInformationRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  const { values } = formik;
  const { extra_driversLicenceB, extra_otherInfos } = values

  const isCompleted = extra_driversLicenceB.length > 0 && extra_otherInfos

  return (
    <MecCollapse
      title="Informations complémentaires"
      isOpen={isOpen === "additionalInformation"}
      setIsOpen={() => handleOpenCollapse("additionalInformation")}
      completed={includes(completed, "additionalInformation") || isCompleted}
      name="isCompleted"
      className="mb-5"
    >
      <StyledForm>
        <div className="row" ref={addiontionalInformationRef}>
          <FormGroup className="col-12">
            <div className="py-2 label text-align-last-left">Permis</div>
            <MecSelectorCustom
              options={config?.otherDriverLicenses} // get otherDriverLicenses
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              formik={formik}
              isMulti
              className="text-align-last-left"
              name="extra_driversLicenceB" // Multiple selection
              colorTxtError="red"
              placeholder="Sélectionnez un ou plusieurs permis"
              onChange={(option) => {
                formik.setFieldValue("extra_driversLicenceB", option)
              }}
              value={formik.values.extra_driversLicenceB}
            />
          </FormGroup>
        </div>

        <div className="row">
          <FormGroup className="col-12 d-flex flex-wrap flex-xl-nowrap">
            <div className="py-2 label text-align-last-left label-required">Casier judiciaire vierge ?</div>
            <MecTabSwitch
              tabs={levels}
              activeTab={formik.values.extra_criminalRecord ? 0 : 1}
              className="ml-xl-3 w-100 h-auto"
              style={{maxWidth: "350px"}}
              name="extra_criminalRecord"
              onChange={(e) => {
                formik.setFieldValue("extra_criminalRecord", !e)
              }}
            />
          </FormGroup>
        </div>

        <div className="row">
          <FormGroup className="col-12">
            <div className="py-2 label text-align-last-left">Centres d'intérêt</div>
            <MecInput
              formik={formik}
              className="secondary text-align-last-left"
              cols="50"
              as="textarea"
              name="extra_otherInfos"
              placeholder="Quels sont vos centres d'intérêt ?"
            />
          </FormGroup>
        </div>

        <div className="w-100 d-flex justify-content-center">
          <MecButton
            icon=""
            type="primary"
            width={120}
            widthIcon={20}
            className="text-capitalize"
            onClick={(e) => {
              handleCompleted(e, "additionalInformation")
              handleOpenCollapse("additionalInformation")
              scrollAdditionalInformation()
            }}
          >
            Valider
          </MecButton>
        </div>
      </StyledForm>
    </MecCollapse>
  )
}

export default AdditionalInformation
