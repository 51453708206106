import styled from "styled-components"

export const MessageNew = styled.div`
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  background-color: #fff;
  padding: 30px;
  position: relative;
  text-align-last: left;
  margin-bottom: 30px;

  .d-inline-grid {
    display: inline-grid;
  }

  @media (min-width: 1024px) {
    /* padding: 60px; */

    &::before {
      width: 380px;
      top: -95px;
      font-size: 35px;
      line-height: 41px;
      padding: 30px;
    }
  }
`

export const Header = styled.div`
  color: #023f73;

  .label {
    font-weight: 800;
  }

  @media (min-width: 1024px) {
    height: 70px;
  }
`

export const Content = styled.div`
  color: #023f73;
  padding: 0px 20px;
  
  hr {
    width: 100%;
    border: 2px solid #e8eef2;
  }
`

export const Footer = styled.div`
  gap: 40px;

  .change-fill {
    path {
      fill: #fff;
    }
  }
`

export const Heading = styled.h1`
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #ff7200;

  @media(max-width: 992px) {
    font-size: 30px;
  }
`
