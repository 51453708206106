import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import App from './App'
import * as serviceWorker from './serviceWorker'
import 'src/services/i18n'
import ScrollToTop from 'src/components/scrollToTop'
import 'react-toastify/dist/ReactToastify.css'

if (process.env.REACT_APP_ENV === "production") {
  console.log = () => {};
  console.info = () => {};
  console.warn = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const root = document.getElementById('root');

let renderMethod = ReactDOM.render;

if (root && root.innerHTML !== "") {
  renderMethod = ReactDOM.hydrate;
}

const app = (
  <BrowserRouter>
    <ScrollToTop>
      <App />
      <ToastContainer />
    </ScrollToTop>
  </BrowserRouter>
);

renderMethod(app, root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
