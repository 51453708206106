import React, { Component } from "react";
// import {Link} from 'react-router-dom';
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";

class StaticLegal extends Component {
  render() {
    return (
      <div className="page-wraper">
        <Header showTitle={false} />
        <div className="page-content bg-light">
          <div className="col" style={{ textAlignLast: "center", marginBottom: "50px" }}>
            <div className="d-flex justify-content-center">
              <h1 className="m-b5 text-uppercase text-orange pt-5">Mentions légales</h1>
            </div>
            <hr className="v3-separator" />
          </div>

          <div className="justify-content-left m-5">
            <p>
              Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique (LCEN ou LEN), le site
              mecanojob.com précise les mentions légales et indique l'identité des différents intervenants.
            </p>
            <h3 className="m-b5 text-uppercase text-orange pt-5">Edition du site</h3>
            <p>
              Le site mecanojob.com est édité par la société 30 MILLE OOVOOM, société par actions simplifiée au capital de 27.702 euros, dont le siège social
              est situé 111 avenue Victor Hugo 75116 – Paris, Immatriculé à Paris sous le RCS 889 500 021 dont le numéro de TVA intracommunautaire est le FR
              05889500021.
            </p>
            <h3 className="m-b5 text-uppercase text-orange pt-5">Responsable de publication</h3>
            <p>SAS OOVOOM représentée par Sandra Bibas</p>
            <h3 className="m-b5 text-uppercase text-orange pt-5">Hébergeur</h3>
            <p>
              <p>Le site mecanojob.com est hébergé par la société Amazon Web Services LLC</p>

              <p>Adresse: P.O. Box 81226, Seattle, WA 98108-1226</p>
              <p>
                Le stockage des données est exclusivement réalisé dans les serveurs de données localisés en France, et une sauvegarde au sein des Data Center
                localisés dans l'Union Européenne.
              </p>
              <p>Nous contacter</p>
              <p>
                • Par email :{" "}
                <a className="text-blue-link" href="mailto:contact@oovoom.com">
                  contact@oovoom.com
                </a>{" "}
              </p>
              <p>• Par téléphone : 01 80 82 44 44 </p>
            </p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default StaticLegal;
