import React from "reactn";
import { Link } from "react-router-dom";
import { ModalBody } from "reactstrap";
import { AuthModalWrapper, Cancel } from "./styles";
import { ReactComponent as IconCancel } from "src/images/icons/icon-cancel.svg"

export const AuthModal = ({ toggle, setToggle }) => {
  return (
    <AuthModalWrapper
      isOpen={toggle}
      centered
      toggle={() => setToggle((prevState) => !prevState)}
    >
      <ModalBody>
        <Cancel onClick={() => setToggle((prevState) => !prevState)}>
          <IconCancel width={30} height={30} />
        </Cancel>
        <div className="quote-bx text-center mb-5">
          <div className="quote-info">
            <h3 className="text-uppercase">Mes recherches</h3>
            <p>Connectez-vous en tant que candidat pour enregistrer votre CV et vos recherches</p>
            <div className="left-job-buttons">
              <Link
                to={"/candidate/register"}
                className="site-button site-button-gap radius-xl text-uppercase"
              >
                Inscription
              </Link>
              <Link
                to={"/candidate/login"}
                className="site-button site-button-gap radius-xl text-uppercase"
              >
                Connexion
              </Link>
            </div>
          </div>
        </div>
      </ModalBody>
    </AuthModalWrapper>
  );
};
