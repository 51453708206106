import { keys } from "lodash"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { useCallback, useDispatch } from "reactn"
import { ModalBody } from "reactstrap"
import { MecButton } from "src/markup/Components"
import { useGetDataModal } from "src/services"
import { apiEditStatusCv } from "src/services/api"
import * as S from "./styles"

export const ConfirmApplyCv = ({ onClose }) => {
  const history = useHistory()
  const onShowModal = useDispatch("confirmApplyCv")
  const { isOpen, dataCvSelected } = useGetDataModal();

  const onCancel = useCallback(() => {
    Promise.resolve(onShowModal(null)).then(onClose())
  }, [onClose, onShowModal])

  const onOk = useCallback(async () => {
    const payload = dataCvSelected[keys(dataCvSelected)[0]].bodyRequest
    await apiEditStatusCv(payload)
    toast.success('Candidature envoyée', {
      autoClose: 2000,
      theme: "colored",
    })
    await Promise.resolve(onCancel()).then(() => history.push("/candidate/application/sent"))
  }, [onCancel, dataCvSelected, history])

  return (
    <S.Wrapper isOpen={isOpen} centered toggle={onCancel}>
      <ModalBody>
        <div className="quote-bx text-center mb-5">
          <div className="quote-info">
            <p>Voulez-vous postuler pour cet emploi ?</p>
            <div className="d-flex justify-content-center w-100">
              <div className="px-2">
                <MecButton width={100} type="outlineNotHover" textColor="white" onClick={onCancel}>
                  Annuler
                </MecButton>
              </div>
              <div className="px-2">
                <MecButton width={100} onClick={onOk}>
                  Accepter
                </MecButton>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </S.Wrapper>
  )
}
