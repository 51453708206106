import React, { useState } from "react"
import Header from "../../Layout/Header"
import Footer from "../../Layout/Footer"
import Modal from "react-modal"
import CookieConsent from "react-cookie-consent"

const StaticCookies = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <div className="page-wraper">
      <Header showTitle={false} />
      <div className="page-content bg-light" style={{paddingBottom : '136px'}}>
        <CookieConsent
          location="bottom"
          buttonText="Acceptez tout"
          declineButtonText="Refuser"
          enableDeclineButton
          onDecline={() => console.log("L'utilisateur refuse les cookies non essentiels.")}
          onAccept={() => console.log("L'utilisateur a accepté les cookies.")}
          style={{ background: "#2B373B", color: "white" }}
          buttonStyle={{ color: "white", backgroundColor: "#4CAF50" }}
          declineButtonStyle={{ color: "white", backgroundColor: "#f44336" }}
        >
          Nous utilisons des cookies pour personnaliser votre expérience{" "}
          <span
            onClick={() => setModalIsOpen(true)}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Personnaliser les cookies
          </span>
        </CookieConsent>

        <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
        </Modal>
      </div>
      <Footer />
    </div>
  )
}

export default StaticCookies
