import React, { memo, useGlobal, useState } from "reactn"
import { get, isEmpty, values } from "lodash"
import { toast } from "react-toastify"
import { Loading } from "src/markup/Element/Loading"
import { useMainContext } from "src/markup/Layout/MainLayout/MainContext"
import { CardJob } from "src/markup/Pages/SearchPage/Candidate/components"
import { useGetFavoriteOffer } from "src/markup/Pages/SearchPage/Candidate/hooks/useGetFavoriteOffer"
import { useGetJobLiaisons } from "src/markup/Pages/SearchPage/Candidate/hooks/useGetJobLiaisons"
import { handleFavoriteOffer } from "src/services/api"
import { populateOffer } from "src/services/config"
import * as S from "./styles"

export const ListCardJob = memo(() => {
  const [config] = useGlobal("config")
  const { listJob = [] } = useMainContext()
  const [loadingById, setLoadingById] = useState(null)
  const {
    loadingGetListOfferJoined,
    liaisonsOfferList,
    setAsyncLiaisonsOfferList,
  } = useGetJobLiaisons()

  const {
    isGetList,
    offerFavoriteIdList,
    favoriteIdList,
    setAsyncFavoriteIdList,
  } = useGetFavoriteOffer()

  return (
    <S.StyledCardComponent>
      {isGetList && <Loading />}
      <div className="d-flex justify-content-center">
        <S.Heading>Résultats de la recherche ({values(listJob)?.length || 0})</S.Heading>
      </div>
      <hr className="v3-separator pb-xl-3" />

      {values(listJob).map((result) => {
        const { offer, score, offerId } = result
        const otherData = {
          score,
        }

        if (isEmpty(offer)) return null
        const data = populateOffer(config, offer)

        if (isEmpty(data)) return null

        const onAddFavorite = async () => {
          try {
            setLoadingById(offerId)
            const data = await handleFavoriteOffer(offerId)
            toast.success(data.message, {
              autoClose: 2000,
              theme: "colored",
            })
            setAsyncFavoriteIdList([...favoriteIdList, offer.id])
          } finally {
            setLoadingById(null)
          }
        }

        const onRemoveFavorite = async () => {
          try {
            setLoadingById(offerId)
            const data = await handleFavoriteOffer(offerId)
            toast.success(data.message, {
              autoClose: 2000,
              theme: "colored",
            })

            const index = favoriteIdList.indexOf(offerId)
            favoriteIdList.splice(index, 1)
            setAsyncFavoriteIdList([...favoriteIdList])
          } catch (e) {
            toast.error(e.message, {
              autoClose: 2000,
              theme: "colored",
            })

            const index = favoriteIdList.indexOf(offer.id)
            favoriteIdList.splice(index, 1)
            setAsyncFavoriteIdList(favoriteIdList)
          } finally {
            setLoadingById(null)
          }
        }

        const cardProps = { offer, data, offerId }
        const footerProps = {
          callback: setLoadingById,
          loadingGetListOfferJoined,
          offer,
          data,
          favoriteIdList,
          liaisonsOfferList,
          setAsyncLiaisonsOfferList,
          offerFavoriteIdList,
          onRemoveFavorite,
          onAddFavorite,
        }

        const loading = loadingById === offer.id

        return (
          <S.StyledCard key={offer.id} className="page-wraper mt-5">
            {loading && <Loading />}
            <S.CardDetailsOfferWrapper
              isPrefix={get(offer, "featured_offer", false)}
              disabled={loadingById && !loading}
            >
              <CardJob {...cardProps} footerProps={footerProps} otherData={otherData} />
            </S.CardDetailsOfferWrapper>
          </S.StyledCard>
        )
      })}
    </S.StyledCardComponent>
  )
})
