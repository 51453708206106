import styled, { css } from "styled-components";
import { ReactComponent as SlideMultipleSearch } from "src/images/icons/slidemultiplesearch.svg";
import { ReactComponent as AppIndicator } from "src/images/icons/appindicator.svg";
import { ReactComponent as Profile } from "src/images/icons/profile.svg";
import { ReactComponent as GroupWork } from "src/images/icons/groupwork.svg";
import { ReactComponent as UserAccount } from "src/images/icons/useraccount.svg";
import { ReactComponent as Advertisement } from "src/images/icons/advertisement.svg";

const baseIconStyle = css`
  color: #ffffff;
  width: 30px;
  &:hover {
    opacity: 0.8;
  }
  @media (min-width: 1200px) {
    display: none;
  }
`

export const SidebarWrapper = styled.div`
  max-width: 60px;
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s;

  .nav-sidebar-wrapper {
    padding-left: 0px;
    overflow: initial;
    z-index: ${(props) => (props.activeSub ? 2 : 1)};

    .menu-sidebar {
      cursor: pointer;
      position: relative;
      margin: 0 !important;
      height: 60px;

      .sidebar-item {
        border-radius: 0px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .menu-title {
          display: none;
        }
      }

      .is-active {
        background: #ff7101;
      }

      .sub-menu-wrapper {
        display: none;
        border-radius: 10px;
        box-shadow: 0px 8px 30px rgb(0 0 0 / 5%);
        width: 200px;
        transition: all ease-out 0.4s;
        z-index: 2;
        opacity: 0;
        max-height: 0;
        margin: 0;
        position: absolute;
        left: 65px;
        top: 0;
      }
      .active-sub {
        max-height: 500px;

        display: block;
        background: #ff7101;
        opacity: 1;
        padding: 20px 0px;
      }

      .sub-item-title {
        color: #012e57;
        &:hover {
          color: #ffffff;
          transition-delay: 0.5s;
          transition: ease-in-out 0.1s;
        }
      }

      .is-active > .sub-item-title {
        color: #ffffff;
      }

      @media (min-width: 1200px) {
        height: fit-content;

        .sidebar-item {
          border-radius: 100px 0 0 100px;
          height: 38px;
          justify-content: flex-start;
          padding-left: 20px;


          .menu-title {
            font-size: 14.6315px;
            line-height: 17px;
            display: block;
          }
        }
        .sub-menu-wrapper {
          display: block;
          position: initial;
          max-height: 100%;
          opacity: 1;
          box-shadow: none;
          background: transparent;
          top: initial;
          padding: 10px 0px 20px;
        }

        .active-sub {
          box-shadow: none;
          background: transparent;
          top: initial;
        }

        .is-active > .sub-item-title {
          color: #ff7101;
        }

        .sub-item-title {
          color: #012e57;
          margin-left: 35px;
          &:hover {
            color: #ff7101;
          }
        }
      }
    }
    @media (min-width: 1200px) {
      padding-left: 50px;
      overflow: hidden;
    }
  }

  @media (min-width: 1200px) {
    min-width: 250px;
    max-width: 250px;
  }
`;

export const SlideMultipleSearchIcon = styled(SlideMultipleSearch)`
  ${baseIconStyle}
`;

export const AppIcon = styled(AppIndicator)`
  ${baseIconStyle}
`;

export const ProfileIcon = styled(Profile)`
  ${baseIconStyle}
`;

export const GroupWorkIcon = styled(GroupWork)`
  ${baseIconStyle}
`;

export const AdvertisementIcon = styled(Advertisement)`
  ${baseIconStyle}
`;

export const UserAccountIcon = styled(UserAccount)`
  ${baseIconStyle}
`;
