import React, { useGlobal, useRef } from "reactn"
import { FormGroup } from "reactstrap"
import { find, includes } from "lodash"

import { MecCollapse } from "src/markup/Components/Forms"
import { MecButton } from "src/markup/Components/MecButton"
import { StyledForm, StyledLabel } from "src/markup/Element/CVForm/styles"
import { POSITION } from "src/markup/Element/OfferForms/constant"
import { handleRenderField } from "../FormValidation"
import { ReactComponent as IconPlush } from "src/images/icons/icon-plus.svg"

const Position = ({ formik, handleCompleted, handleAddMission, isOpen, handleOpenCollapse, handleDeleteItem, disabled }) => {
  const positionRef = useRef(null)
  const [config] = useGlobal("config")
  const [user] = useGlobal("user")

  const { values } = formik

  const dataCompare = POSITION().map((data) => {
    return values[data.name]
  })

  const completed = find(dataCompare, (data) => {
    return (
      data === null ||
      (data?.length &&
        includes(
          data.map((d) => d.search_mission === null || d.search_specMission.length === 0),
          true
        )) ||
      data === ""
    )
  })

  function scrollPosition() {
    positionRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  return (
    <MecCollapse
      title="Le poste à pourvoir"
      isOpen={isOpen === "position"}
      setIsOpen={() => handleOpenCollapse("position")}
      completed={completed === undefined}
      name="completed"
      className="mb-5"
      disabled={disabled}
    >
      <StyledForm>
        <div className="row" ref={positionRef}>
          {POSITION(user?.gender).map((field, index) => (
            <div className={`d-flex flex-column justify-content-end col-${field.col}`} key={index}>
              {field.title && (
                <div className="col-12 sub-title text-align-last-left mb-4 " style={{ flex: 1, color: "#ff7200" }}>
                  {field.title}
                </div>
              )}
              <FormGroup className="w-100">
                {field.label && (
                  <div className={`py-2 label text-align-last-left ${field.isRequired && "label-required"}`}>
                    {field.label}
                  </div>
                )}
                {handleRenderField({ formik, config, field, handleDeleteItem })}
              </FormGroup>
            </div>
          ))}
        </div>

        <div className="row  d-flex justify-content-end">
          <FormGroup className="d-flex justify-content-end add-experience" onClick={handleAddMission}>
            <IconPlush />
            <StyledLabel className="py-2">Ajouter une mission</StyledLabel>
          </FormGroup>
        </div>

        <div className="w-100 d-flex justify-content-center">
          <MecButton
            icon=""
            type="primary"
            width={120}
            widthIcon={20}
            className="text-capitalize mt-2"
            onClick={(e) => {
              handleCompleted(e, "position")
              handleOpenCollapse("profileDefinition")
              scrollPosition()
            }}
            disabled={completed !== undefined}
          >
            Valider
          </MecButton>
        </div>
      </StyledForm>
    </MecCollapse>
  )
}

export default Position
