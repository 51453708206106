import React, { useCallback } from 'reactn'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { MecInput } from 'src/markup/Components/Forms'
import * as S from 'src/markup/Pages/AuthPage/ForgotPassword/Recruiter/styles'
import tickIcon from 'src/images/gallery/tick-icon.png'
import { sendOtpCode } from 'src/services/api'

export const RecruiterForgot = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const handleSendOtpCode = useCallback(async (values) => {
    try {
      await sendOtpCode(values);
      history.push('/recruiter/confirm-password');
    } catch (error) {
      console.log(error);
    }
  }, [history])

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required(t("invalid_field")),
    }),
    onSubmit: (values) => handleSendOtpCode(values)
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <S.Heading>
        MOT
        <div className='py-3'>
          DE PASSE OUBLIÉ
        </div>
      </S.Heading>
      <S.NormalText className='py-4 text-center'>
        Un e-mail vous sera envoyé pour réinitialiser votre mot de passe
      </S.NormalText>
      <div>
        <MecInput
          name='email' placeholder='E-mail'
          formik={formik}
          colorTxtError='var(--blue600)' className='mb-3'/>
      </div>
      <div className='mx-auto'>
        <S.Button>
          <div className='d-flex justify-content-center'>
            <div className='pr-2'>
              <img src={tickIcon} alt='tick-icon' />
            </div>
            <div>Valider</div>
          </div>
        </S.Button>
      </div>
    </form>
  )
}
