import React, { useGlobal, useState, useEffect, useMemo } from "reactn"
import { useTranslation } from "react-i18next"
import { isEmpty, filter, find } from "lodash"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import { useFormik } from "formik"
import moment from 'moment'

import * as S from "./styles"
import { MecButton } from "src/markup/Components"
import { populateOffer } from "src/services/config"
import { ASSET_QUALITYMAIN_URL, ASSET_QUALITY2_URL, ASSET_QUALITY3_URL, OFFER_STATUS, FORMULAS } from "src/constants"
import { capitalize, pluralize, genderize, formatPrice, openInNewTab } from "src/utils"

import { ReactComponent as IconCar } from "src/images/icons/icon-car.svg"
import { ReactComponent as IconView } from "src/images/icons/eye.svg"
import { ReactComponent as IconRemove } from "src/images/icons/icon-remove.svg"
import { ReactComponent as IconEdit } from "src/images/icons/icon-edit.svg"
import { ReactComponent as IconShare } from "src/images/icons/icon-share.svg"
import { ReactComponent as IconDraft } from "src/images/icons/icon-draft.svg"
import { ReactComponent as IconPlus } from "src/images/icons/icon-plus.svg"
import { ReactComponent as IconUser } from "src/images/icons/icon-user.svg"
import { ReactComponent as IconCancel } from "src/images/icons/icon-cancel.svg"
import { ReactComponent as IconCalendar } from "src/images/icons/calendar.svg"
import { ReactComponent as IconCheck } from "src/images/icons/check2square.svg"
import { ReactComponent as IconCreate } from "src/images/icons/icon-create.svg"
import { ReactComponent as IconChecked } from "src/images/icons/icon-checked.svg"
import { ReactComponent as IconPowerClock } from "src/images/icons/power-clock.svg"

import { OfferFormvalidationSchema } from "./FormValidation"
import { useCheckPackage } from "src/hooks/useCheckPackage"
import { apiGetOfferStatus, recruiterUpdateOffer } from "src/services/api"
import { ConfirmModal } from "src/markup/Components/Modals"

const Card = ({
  isDraft, offer, handleViewDetail, view, onDelete,
  handleShareSocial, hasHandle = true, isOwner = true,
}) => {
  const [initial, setInitial] = useState({})
  const [modal, setModal] = useState({ showConfirmModal: false });
  const [status, setStatus] = useState('ACTIVE')
  const { hasPackage } = useCheckPackage()

  const [config] = useGlobal("config")
  const [user] = useGlobal("user")
  const { t } = useTranslation()
  const history = useHistory()

  const gender = user?.gender?.toLowerCase()

  const offerPackage = find(FORMULAS, (formula => formula.ID === offer?.offerPackage?.myPackage?.package_id))

  useEffect(() => {
    const data = populateOffer(config, offer)
    const xp = (() => genderize({ male: "xp_m", female: "xp_f", both: "xp", gender }))()
    const quanlityMain = (() =>
      genderize({ male: "qualityMain_m", female: "qualityMain_f", both: "qualityMains", gender }))()

    setInitial({
      name_company: offer?.name_company,
      pres_city: offer?.pres_city,
      pres_zip: offer?.pres_zip,
      recruit_recruitObj:
        offer?.recruitmentJobs?.length > 0
          ? {
              id: offer?.recruitmentJobs?.[0]?.objectiveId,
              name: data?.recruiterObjectives?.[0],
            }
          : null,
      recruit_responsability:
        offer?.recruitmentJobs?.length > 0
          ? {
              id: offer?.recruitmentJobs?.[0]?.responsabilityId,
              name: data?.responsabilities?.[0],
            }
          : null,
      joblistId: offer?.joblistJobs?.[0]?.mainJobId
        ? {
            id: offer?.joblistJobs?.[0]?.mainJobId,
            name: data?.jobNames?.[0],
          }
        : null,
      pres_jobOtherName: offer?.joblistJobs?.[0]?.mainJobId
        ? {
            joblistId: offer?.joblistJobs?.[0]?.mainJobId,
            id: offer?.joblistJobs?.[0]?.otherJobId,
            name: data?.otherJobNames?.[0],
          }
        : null,
      search_exp: data?.[xp]?.[0],

      search_minDiploma: offer?.joblistJobs?.[0]?.mainJobId
        ? {
            joblistId: offer?.joblistJobs?.[0]?.mainJobId,
            id: offer?.formationJobs?.[0]?.xpId,
            name: data?.diplomas?.[0],
          }
        : null,
      mission:
        offer?.missionJobs?.length > 0
          ? data?.customMission
          : [
              {
                id: 1,
                search_mission: null,
                search_specMission: [],
              },
            ],
      search_qualityMain: data?.[quanlityMain],
      search_quality2: data?.quality2?.[0],
      search_quality3: data?.quality3?.[0],
      skill:
        offer?.skillJobs?.length > 0
          ? data?.customSkill
          : [
              {
                id: 1,

                search_skill: null,
                search_skillComp: [],
              },
            ],
      tools: offer?.toolsJobs?.map((tool, index) => ({
        joblistId: offer?.joblistJobs?.[0]?.mainJobId,
        id: tool.toolId,
        name: data?.tools?.[index],
      })),
      languages: data?.languages,
      extra_driversLicenceOther: offer?.driverLicenseOtherJobs?.map((driverLicanLicense, index) => ({
        id: driverLicanLicense.licenseId,
        name: data?.driverLicenseOthers?.[index],
      })),
      extra_criminalRecord: offer?.extra_criminalRecord,
      recruit_contractType: offer?.recruitmentJobs?.[0]?.contractId
        ? {
            id: offer?.recruitmentJobs?.[0]?.contractId,
            name: data?.diplomas?.[0],
          }
        : null,
      salary: offer?.salary,
      adv_advantages: offer?.advantages?.map((advantage, index) => ({
        id: advantage.advantageId,
        name: data?.advantages?.[index],
      })),
      otherAdvantage: offer?.otherAdvantage,
      featured_offer: offer?.featured_offer,
      offer_logo: offer?.offer_logo,
      status: offer?.offer_logo,
    })
    setStatus(offer.status_active)
  }, [offer, config, gender])

  const formik = useFormik({
    initialValues: initial,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: () => OfferFormvalidationSchema({ t, isDraft: false }),
    onSubmit: async (values) => {
      try {
        const shadowValues = { ...values }

        shadowValues.joblistId = shadowValues?.joblistId?.id
        shadowValues.pres_jobOtherName = shadowValues?.pres_jobOtherName?.id
        shadowValues.search_exp = shadowValues?.search_exp?.id
        shadowValues.mission = shadowValues?.mission?.map((mission) => ({
          search_mission: mission.search_mission,
          search_specMission: mission.search_specMission.map((specMission) => ({
            id: specMission.id,
            name: specMission.name,
          })),
        }))

        shadowValues.search_qualityMain = shadowValues?.search_qualityMain?.map((qualityMain) => ({
          qualityMainId: qualityMain.qualityMainId,
          name: qualityMain.name,
        }))
        shadowValues.search_quality2 = shadowValues?.search_quality2?.id
        shadowValues.search_quality3 = shadowValues?.search_quality3?.id
        shadowValues.skill = shadowValues?.skill?.map((skill) => ({
          search_skill: { ...skill.search_skill, joblistId: shadowValues.joblistId },
          search_skillComp: skill.search_skillComp.map((skillComp) => ({
            id: skillComp.id,
            name: skillComp.name,
          })),
        }))

        if (hasPackage) {
          await recruiterUpdateOffer({ ...shadowValues, id: offer.id })
          toast.success("L'offre a été mise à jour avec succès")

          history.push("/recruiter/myoffer")
        } else {
          setModal({
            showConfirmModal: true,
            onYes: () => openInNewTab('/pricing', setModal),
            onNo: () => setModal(false),
            Description: "Veuillez souscrire à une formule pour pouvoir publier d'autres offres",
          });
          return;
        }
      } catch (error) {
        return error
      }
    },
  })
  const { handleSubmit, setFieldValue, isSubmitting, errors } = formik

  const data = populateOffer(config, offer)

  const jobLiaisonInfo = useMemo(() => {
    if (offer) {
      const totalAccepted = filter(offer?.liaisons, liaison => liaison.accepted).length ?? 0
      const totalRejected = filter(offer?.liaisons, liaison => liaison.rejected).length ?? 0;

      return {
        totalAccepted,
        totalRejected,
        totalCandidates: offer?.liaisons?.length || 0,
        totalAgenda: offer?.agendas?.length || 0
      }
    }

    return null;

  }, [offer])

  const getExpiryDate = useMemo(() => {
    if (!offer || !offer.offerPackage) {
      return ""
    }

    return `Votre offre expire le ${moment(offer?.offerPackage?.expiryDate).format('DD/MM/YYYY')}`
  }, [offer])

  if (isEmpty(data)) return null

  // qualityMainCard
  const data_qualityMains = gender ? data?.[`qualityMains_${gender}`] : data?.qualityMains

  const qualityMainCard = data?.qualityMains.map((qualityMain, index) => {
    return qualityMain ? (
      <div className="d-flex flex-column align-items-center" key={index}>
        <img className="" src={ASSET_QUALITYMAIN_URL + qualityMain.filename} alt={index + 1} />
        <div className="text-first-capitalize card-text" style={{ textAlignLast: "center" }}>
          {data_qualityMains[index].name}
        </div>
      </div>
    ) : null
  })

  // quality2Card
  const quality2Card = data?.quality2.map((Quality2, index) => {
    return Quality2 ? (
      <div className="d-flex flex-column align-items-center" key={index}>
        <img className="" src={ASSET_QUALITY2_URL + Quality2.filename} alt={index + 1} />
        <div className="text-first-capitalize card-text" style={{ textAlignLast: "center" }}>
          {Quality2.title}
        </div>
      </div>
    ) : null
  })

  // quality3Card
  const data_quality3 = gender ? data?.[`quality3_${gender}`] : data?.quality3
  const quality3Card = data?.quality3.map((Quality3, index) => {
    return Quality3 ? (
      <div className="d-flex flex-column align-items-center" key={index}>
        <img className="" src={ASSET_QUALITY3_URL + Quality3.filename} alt={index + 1} />
        <div className="text-first-capitalize card-text" style={{ textAlignLast: "center" }}>
          {data_quality3[index].title}
        </div>
      </div>
    ) : null
  })

  // driverLicenseOthers
  const driverLicenseOthers = data?.driverLicenseOthers.map((driverOtherLicences, index) => {
    return driverOtherLicences ? (
      <li key={index} className="m-1">
        {driverOtherLicences}
      </li>
    ) : null
  })

  // languages
  const languages = data?.languages.map((languagesJobs, index) => {
    return languagesJobs ? <li key={index}>{languagesJobs.name}</li> : null
  })

  const handleStatusOffer = async (id) => {
    const res = await apiGetOfferStatus(id)
    toast.success(res.message)
    setStatus(prevState => prevState === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')
    setModal(false)
  }

  const onShowModalConfirmStatus = offerId => {
    setModal({
      showConfirmModal: true,
      onYes: () => handleStatusOffer(offerId),
      onNo: () => setModal(false),
      Description: "Êtes-vous sûr de vouloir modifier le statut de cette offre ?",
    });
  }

  return (
    <S.Card>
      <S.Content className="row justify-content-between">
        <S.ContentLeft border>
          <S.LeftLogoWrapper>
            <div className="d-flex justify-content-start w-100 title-offers mb-4">
              <IconCar className="mr-2 icon-reponsive" width={100} height={100} />
              <div className="d-flex flex-column" style={{ flex: 1 }}>
                <span>{data?.jobNames?.[0]}</span>
                <span>{offer?.name_company}</span>
              </div>
            </div>
          </S.LeftLogoWrapper>

          <S.LeftContentWrapper>
            <div className="ml-3">Résumé de l'offre</div>
            <div>
              {genderize({
                male: "Doté",
                female: "Dotée",
                both: "Doté(e)",
                gender,
              })}{" "}
              d'un {data?.diplomas[0]} ou niveau équivalent, vous disposez d'un profil{" "}
              <span className="text-first-lowercase">{data?.xp?.[0]?.name}</span> et vous êtes doté de{" "}
              <span className="text-first-lowercase">{data?.xp?.[0]?.status}</span>  d'expérience en tant que :{" "}
              {data?.otherJobNames[0]}.
            </div>
          </S.LeftContentWrapper>
        </S.ContentLeft>

        <S.ContentRight className="d-flex flex-column justify-content-between" border>
          <div className="d-flex mb-4 list-skills">
            {qualityMainCard.length ? qualityMainCard : <IconPlus className="dont-have-skill" />}
            {quality2Card.length ? quality2Card : <IconPlus className="dont-have-skill" />}
            {quality3Card.length ? quality3Card : <IconPlus className="dont-have-skill" />}
          </div>
          <div className="d-flex align-items-center">
            <ul className="col-9 job-description infos text-blue ml-4 w-100">
              <li>
                <b>Localisation :</b> {[capitalize(offer.pres_city), offer.pres_zip].filter(Boolean).join(', ')}
              </li>
              <br></br>
              <li>
                <b>Contrat :</b> {data?.contractTypes[0]}
              </li>
              <br></br>
              <li>
                <b>Salaire :</b> {formatPrice(offer.salary)} € Brut annuel
              </li>
            </ul>
            {offerPackage && (
              <S.WIcon>
                {offerPackage.ICON}
                <S.Package>{offerPackage.NAME}</S.Package>
              </S.WIcon>
            )}
          </div>
        </S.ContentRight>

        {(!isDraft && isOwner) && (
            <S.InformationDetail className="d-flex w-100 flex-column justify-content-center align-items-center">
              <S.Detail>
                <div className="d-flex align-items-center">
                  <IconUser width={40} height={40} className="mr-2 orange" />
                  {pluralize({
                    singular: 'candidat',
                    count: jobLiaisonInfo?.totalCandidates,
                    showCount: true,
                  })}
                </div>
                <div className="d-flex align-items-center">
                  <IconCheck width={40} height={40} className="mr-2 orange" />
                  {pluralize({
                    singular: 'retenu',
                    count: jobLiaisonInfo?.totalAccepted,
                    showCount: true,
                  })}
                </div>
                <div className="d-flex align-items-center">
                  <IconCancel width={40} height={40} className="mr-2" />
                  {pluralize({
                    singular: 'rejeté',
                    count: jobLiaisonInfo?.totalRejected,
                    showCount: true,
                  })}
                </div>
                <div className="d-flex align-items-center">
                  <IconCalendar width={40} height={40} className="mr-2 orange" />
                  {pluralize({
                    singular: 'entretien',
                    count: jobLiaisonInfo?.totalAgenda,
                    showCount: true,
                  })}
                </div>
              </S.Detail>
            </S.InformationDetail>
          )}

        <S.WrapperDetails active={view === offer.id}>
          <S.StyledDetails>
            <div className="title">Offre détaillée</div>

            <div className="row mt-0 mt-xl-5 mb-5">
              <S.Left className="col-12 col-xl-4">
                <S.Profile>
                  <h3>Compétences</h3>
                  <div className="pl-3 pr-3">
                    <S.IconCreate className="d-flex align-items-center mb-3">
                      <IconCreate className="mr-2 comp-icon" />
                      <div className="d-flex flex-column">
                        <span>{data?.missions[0]}</span>
                      </div>
                    </S.IconCreate>
                    <S.IconCreate className="d-flex align-items-center mb-3">
                      <IconCreate className="mr-2 comp-icon" />
                      <div className="d-flex flex-column">
                        <span>{data?.missionComp[0]}</span>
                      </div>
                    </S.IconCreate>
                  </div>
                </S.Profile>

                <S.MasterWrapper>
                  <h3>Permis requis</h3>
                  <ul className="pl-4 pr-4 mt-2 mb-0">{driverLicenseOthers}</ul>
                </S.MasterWrapper>

                <S.MasterWrapper>
                  <h3>Langues requises</h3>
                  <ul className="pl-4 pr-4 mt-2 mb-0">{languages}</ul>
                </S.MasterWrapper>

                <S.MasterWrapper>
                  <h3>Maîtrise d'outils</h3>
                  <ul className="pl-4 pr-4 mt-2 mb-0">
                    {data?.tools.map((tool, index) => (
                      <li key={index}>{tool}</li>
                    ))}
                  </ul>
                </S.MasterWrapper>

                <S.MasterWrapper>
                  <h3>Casier judiciaire vierge requis ?</h3>
                  <ul className="pl-4 pr-4 mt-2 mb-0">
                    <li> {offer.extra_criminalRecord ? "Requis" : "Non requis."}</li>
                  </ul>
                </S.MasterWrapper>
              </S.Left>
              <S.Right className="col-12 col-xl-7 ml-0 ml-xl-5">
                <S.MasterWrapper className="d-flex align-items-center">
                  <h3 className="mr-4">Niveau d'expérience :</h3>
                  <span>{data?.xp?.[0]?.status}</span>
                </S.MasterWrapper>

                <S.MasterWrapper className="d-flex align-items-center">
                  <h3 className="mr-4">Niveau d'étude minimal :</h3>
                  <span>{data?.diplomas[0]}</span>
                </S.MasterWrapper>

                <S.MasterWrapper>
                  <h3>Objectif du recrutement</h3>
                  <span className="ml-5 d-block">
                    {data?.name_company} recherche un candidat {data?.recruiterObjectives[0]}.
                  </span>
                </S.MasterWrapper>

                <S.MasterWrapper>
                  <h3>Missions</h3>
                  <span className="ml-5 d-block">
                    Sous la responsabilité {data?.responsabilities[0]}, vous serez{" "}
                    {genderize({
                      male: "amené",
                      female: "amenée",
                      both: "amené(e)",
                      gender,
                    })}{" "}
                    à {data?.missions[0]} ou encore à {data?.missionComp[0]}.
                  </span>
                </S.MasterWrapper>

                <S.MasterWrapper>
                  <h3>Ce que l'on propose</h3>
                  <S.ContentLeftWrapper className="col-12">
                    <ul className="pl-4 pr-4 mt-2 mb-0">
                      <li>
                        Avantages :
                        <ul className="pl-4 pr-4 mt-2 mb-0 d-inline-grid sub-list">
                          {data?.advantages.filter(Boolean).map((adv, index) => (
                            <li key={index}>{adv}</li>
                          ))}
                        </ul>
                      </li>
                      {offer.otherAdvantage && <li>Autre : <ul className="pl-4 pr-4 mt-2 mb-0 d-inline-grid sub-list" style={{listStyle: "none"}}><li>{offer.otherAdvantage}</li></ul></li>}
                    </ul>
                  </S.ContentLeftWrapper>
                </S.MasterWrapper>
              </S.Right>
            </div>
          </S.StyledDetails>

          {(!isDraft && isOwner) && (
            <S.InformationDetail className="row w-100 flex-column justify-content-center align-items-center mb-3">
              <MecButton
                icon={IconView}
                height={44}
                width={350}
                type="outlineNotHover"
                className="site-button site-button-gap radius-xl"
                onClick={() => openInNewTab(`/recruiter/offer-preview/${offer.id}`)}
              >
                Voir du point de vue du candidat
              </MecButton>
            </S.InformationDetail>
          )}
        </S.WrapperDetails>

        <S.StyledFooter className="w-100 mt-3">
          {isDraft ? (
            <S.FooterDate className="pb-3">
              <IconDraft className="mr-2" width="30" height="30" />
              <span>
                Brouillon enregistré le {data.sentDate}
              </span>
            </S.FooterDate>
          ) : (
            <S.FooterDate className="pb-3">
              {/* <IconSend className="mr-2" width="30" height="30" /> */}
              {/* <span>
                Publiée le {data.sentDate}
              </span> */}
              {getExpiryDate && (<span className="pl-1"> - {getExpiryDate}</span>)}
            </S.FooterDate>
          )}

          <S.FooterAction className="flex-wrap flex-lg-nowrap justify-content-center justify-content-xl-end" onSubmit={handleSubmit}>
            {(isDraft && isEmpty(errors)) && (
              <MecButton
                icon={IconChecked}
                type="primary isSubmit"
                width={250}
                height={44}
                widthIcon={20}
                className="text-capitalize"
                onClick={(e) => {
                  setFieldValue("status", OFFER_STATUS.PUBLISHED)
                }}
              >
                {isSubmitting ? "..." : "Publier"}
              </MecButton>
            )}
            <MecButton
              icon={IconView}
              height={44}
              width={250}
              type="primary"
              className="site-button site-button-gap radius-xl"
              onClick={(e) => {
                e.preventDefault();
                handleViewDetail(offer.id)
              }}
            >
              {view === offer.id ? 'Réduire' : 'Voir'}
            </MecButton>

            {hasHandle &&
              <MecButton
                icon={IconEdit}
                height={44}
                width={250}
                type="primary"
                className="site-button site-button-gap radius-xl"
                onClick={() => history.push(`/recruiter/myoffer/update/${offer.id}`)}
                disabled={!hasHandle}
              >
                Modifier
              </MecButton>
            }

            <MecButton
              icon={IconPowerClock}
              height={44}
              width={250}
              type="primary"
              className="site-button site-button-gap radius-xl"
              onClick={(e) => {
                e.preventDefault();
                onShowModalConfirmStatus(offer.id)
              }}
            >
              {status === 'ACTIVE' ? 'Désactiver' : 'Activer'}
            </MecButton>

            {hasHandle &&
              <MecButton
                icon={IconRemove}
                height={44}
                width={250}
                type="primary"
                className="site-button site-button-gap radius-xl"
                disabled={!hasHandle}
                onClick={(e) => {
                  e.preventDefault();
                  onDelete(offer.id)
                }}
              >
                Supprimer
              </MecButton>
            }

            {!isDraft && (
              <MecButton
                icon={IconShare}
                height={44}
                width={250}
                type="primary"
                className="site-button site-button-gap radius-xl"
                onClick={(e) => {
                  e.preventDefault();
                  handleShareSocial(offer.id)
                }}
              >
                Partager
              </MecButton>
            )}
          </S.FooterAction>
        </S.StyledFooter>
      </S.Content>
      <ConfirmModal {...modal} />
    </S.Card>
  )
}

export default Card
