import styled from "styled-components"
import Select from "react-select"
import { useHistory } from 'react-router-dom'

import { MecButton } from "src/markup/Components"

export const SelectCV = ({ options, onChange, placeholder, ...rest }) => {
  const history = useHistory();

  const CustomStyle = {
    option: (base, state) => {
      let backgroundColor = "white"

      if (state.isSelected) {
        backgroundColor = "var(--orange300)"
      }

      return {
        ...base,
        backgroundColor,
      }
    },
  }

  return options?.length > 0 ? (
    <SelectCVWrapper
      styles={CustomStyle}
      placeholder={placeholder || "Sélectionner un CV"}
      options={options}
      classNamePrefix="select"
      onChange={onChange}
      {...rest}
    />
  ) : (
    <MecButton
      type="primary"
      height={44}
      onClick={() => history.push("/candidate/mycv/create")}>
      Créer un CV
    </MecButton>
  )
}

const SelectCVWrapper = styled(Select)`
  .select__control {
    display: flex;
    align-items: center;
    height: auto;
    min-width: 200px;
    background: white;
    border-radius: 20px;
    border: 2px solid var(--orange300);
    outline: none;
    overflow: hidden;
    cursor: pointer;
    &:focus,
    &:hover,
    &:target,
    &:active {
      border: 2px solid var(--orange300);
      outline: none;
    }
  }

  .select__single-value,
  .select__placeholder {
    color: var(--orange300);
    font-weight: bold;
    font-size: 0.875rem;
  }

  .select__menu {
    border: 2px solid var(--orange300);
    border-radius: 20px;
    cursor: pointer;
    font-weight: 500;
    min-width: 200px;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #ff7200;

    & > div {
      cursor: pointer;

      border-radius: 20px;
    }
  }

  @media (max-width: 768px) {
    .select__control {
      font-size: 14px;
    }
    .select__single-value,
    .select__placeholder {
    }
  }
`
