import React from "reactn";

import {
  ListPartnerWrapper,
  PartnerComponentWrapper,
  PartnerWrapper,
} from "./styles";
import LogoOovoomDriver from "src/images/logo-oovoom-driver.png";
import LogoGlass from "src/images/logo-glass.png";
import LogoBeranget from "src/images/logo-beranget.png";
import LogoJump from "src/images/logo-jump.png";
import LogoVulco from "src/images/logo-vulco.png";

const listPartners = [
  {
    image: LogoOovoomDriver,
    name: "Oovoom",
    href: "https://oovoom.com"
  },
  {
    image: LogoGlass,
    name: "A+Glass",
    href: "https://www.aplusglass.com",
  },
  {
    image: LogoBeranget,
    name: "Beranget",
  },
  {
    image: LogoJump,
    name: "JumboPneus",
    href: "https://www.jumbopneus.fr",
  },
  {
    image: LogoVulco,
    name: "Vulco",
    href: "https://www.vulco.fr",
  }
];

const MecPartnerComponent = () => {
  return (
    <PartnerComponentWrapper className="container content-inner-2">
      <div className="section-head text-center text-uppercase">
        <h3 className="text-orange">Nos entreprises partenaires</h3>
        <hr className="v3-separator pb-xl-3" />
      </div>

      <ListPartnerWrapper>
        <PartnerWrapper className="row">
          <div className="col-12">
            {listPartners.map((partner, index) => (
              <a target={"_blank"} rel="noreferrer" href={partner.href} key={index}>
                <img src={partner.image} alt={`logo-${partner.name}`}/>
              </a>
            ))}
          </div>
          <div className="col-12">
            {listPartners.map((partner, index) => (
              <a target={"_blank"} rel="noreferrer" href={partner.href} key={index}>
                <img src={partner.image} alt={`logo-${partner.name}`}/>
              </a>
            ))}
          </div>
        </PartnerWrapper>
      </ListPartnerWrapper>
    </PartnerComponentWrapper>
  );
};

export default MecPartnerComponent;
