import React from "reactn";
import { Helmet } from "react-helmet";

const defaultConfig = {
  siteName: 'MecanoJob.com',
  organization: 'OOVOOM',
  baseUrl: 'https://mecanojob.com',
  description: 'MecanoJob.com - Trouvez votre prochain job !'
}

const SchemaORG = ({
  datePublished,
  defaultTitle,
  description,
  image,
  isBlogPost,
  baseUrl,
  organization,
  title,
  url,
  dateModified,
}) => {
  const baseSchema = [
    {
      "@context": "http://schema.org",
      "@type": "Website",
      url,
      name: title,
      alternateName: defaultTitle,
    },
  ];

  const schema = isBlogPost
    ? [
        ...baseSchema,
        {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": url,
                name: title,
                image,
              },
            },
          ],
        },
        {
          "@context": "http://schema.org",
          "@type": "BlogPosting",
          url,
          name: title,
          alternateName: defaultTitle,
          headline: title,
          image: {
            "@type": "ImageObject",
            url: image,
          },
          description,
          author: {
            "@type": "Organization",
            name: (organization ?? defaultConfig.organization),
          },
          publisher: {
            "@type": "Organization",
            url: baseUrl,
            name: (organization ?? defaultConfig.organization),
          },
          mainEntityOfPage: {
            "@type": "Website",
            "@id": baseUrl,
          },
          datePublished,
          ...(dateModified && { dateModified }),
        },
      ]
    : baseSchema;

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

const SEO = ({
  siteUrl,
  title = "MecanoJob - Le recrutement auto par OOVOOM",
  defaultTitle = "MecanoJob",
  author,
  description,
  keywords,
  twitter,
  date,
  isBlogPost,
  dateModified,
}) => {
  const metaDescription = description ?? defaultConfig.description;
  const url = siteUrl ?? defaultConfig.baseUrl;

  const publicAssets = {
    metaImage: `${url}/opengraph-logo.png`,
    favicon16: `${url}/favicon-16x16.png`,
    favicon32: `${url}/favicon-32x32.png`,
    favicon48: `${url}/favicon-48x48.png`,
    apple180: `${url}/apple-touch-icon.png`,
    apple512: `${url}/favicon-512x512.png`,
    safariTab: `${url}/safari-pinned-tab.svg`,
  };

  const {
    metaImage, favicon16, favicon32, favicon48, apple180, apple512, safariTab
  } = publicAssets;

  return (
    <>
      <Helmet titleTemplate={title}>
        <link rel="icon" sizes="16x16" type="image/png" href={favicon16} />
        <link rel="icon" sizes="32x32" type="image/png" href={favicon32} />
        <link rel="apple-touch-icon" sizes="180x180" type="image/png" href={apple180} />
        <link rel="apple-touch-startup-image" sizes="512x512" type="image/png" href={apple512.default} />

        <link rel="mask-icon" href={safariTab} color="#ff7200" />
        <link rel="shortcut icon" href={favicon48} />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charSet="utf-8" />
        <meta name="theme-color" content="#002e57" />
        <title>{title}</title>

        <link rel="canonical" key={url} href={url} />

        <meta name="description" content={metaDescription} />

        <meta
          name="keywords"
          content={keywords ? keywords.join() : metaDescription}
        />

        <meta
          name="google-site-verification"
          content="5dbqwMHgmfL-mf80tJ__nxWrqe1KJ6bVgf5KE_KR8uE"
        />

        <meta property="og:url" content={url} />
        <meta property="og:site_name" content={defaultConfig.siteName} />
        <meta property="og:title" content={title} />
        <meta name="og:description" content={metaDescription} />
        <meta property="og:type" content={isBlogPost ? "article" : "website"} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:image:secure_url" content={metaImage} />

        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:creator" content={twitter} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta name="twitter:image" content={metaImage} />
      </Helmet>

      <SchemaORG
        url={url}
        title={title}
        image={metaImage}
        description={metaDescription}
        datePublished={date ?? false}
        canonicalUrl={url}
        author={author}
        baseUrl={siteUrl}
        dateModified={dateModified}
        defaultTitle={defaultTitle}
        isBlogPost={isBlogPost ?? false}
      />
    </>
  );
};

export default SEO;
