import styled from 'styled-components'

export const RecruiterHomeWrapper = styled.div`
  .container {
    z-index: 1;

    .content-wrapper {
      background-color: #fff;
      box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
      border-radius: 30px;

      & > div {
        border: none;
        box-shadow: none;
      }

      & > div:first-child {
        padding-bottom: 0;
      }

      .mec-button:first-child,
      .send-details {
        display: none;
      }
    }
    .list-skills {
      svg {
        circle {
          fill: ${(props) => (props.isUrgent ? '#ff7300' : '#002d56')};
        }
      }
    }
  }
`
export const Heading = styled.h1`
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #ff7200;

  @media(max-width: 992px) {
    font-size: 30px;
  }
`

