import styled, { css } from "styled-components"
import { Form } from "reactstrap"
import Select from "react-select";

const CustomLabelCommon = css`
  .label {
    font-weight: 800;
    font-size: 19px;
    line-height: 28px;
    color: #023f73;
    display: flex;
  }

  .custom-radio {
    min-width: 50px;
    margin-left: 15px;
    height: 20px;
  }

  .label-disabled {
    color: #bec7cc;
    circle {
      fill: #bec7cc;
    }
  }

  .label-required:after {
    content: "*";
    font-weight: 500;
    font-size: 53px;
    color: #ff7200;
    padding-left: 15px;
  }
`

const WrapperCommon = css`
  width: 100%;
  padding: 0 20px;

  ${CustomLabelCommon}

  .add-experience {
    svg {
      transform: scale(0.7);
    }
    .label {
      font-weight: 500;
      margin-left: 15px;
    }
  }

  .sub-title {
    font-weight: 800;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #023f73;

    &:before {
      content: "";
      width: 15px;
      height: 15px;
      background: #ff7200;
      border-radius: 100%;
      display: inline-block;
      margin-right: 15px;
    }
  }

  .w-250 {
    width: 250px;
  }

  @media (min-width: 1221px) {
    padding: 0 50px;
  }
`
export const WrapperFacturation = styled(Form)`
  ${WrapperCommon}
`

export const WrapperPayment = styled.div`
  ${WrapperCommon}
`

export const PaymentListCard = styled.div``

export const SelectCard = styled(Select)`
 &.select--is-disabled {
    opacity: 0.5;
  }
  .select__control {
    background: #e8eef2;
    padding: 5px 7px 5px 20px;
    color: var(--blue600);
    border-radius: 20px;
    border: ${(props) => props.error ? "2px solid red" : "none"};
    outline: none;
    overflow: hidden;
    text-align-last: left;
    cursor: pointer;
  }
  .select__control:focus,
  .select__control:active,
  .select__control:target,
  .select__control:hover {
    border: ${(props) => props.error ? "2px solid red" : "none"};
  }
 
  .select__menu,
  .select__option {
    cursor: pointer;
    background: #e8eef2;
    border-radius: 20px;
    overflow: hidden;
    text-align: left;
  }

  .select__option:hover {
    background: #cad9e0;
  }
  .select__option--is-selected {
    color: #678399;
    background: #cad9e0;
  }
  .select__control:focus,
  .select__control:active,
  .select__control:target,
  .select__control:hover {
    border: ${(props) => (props.error ? '2px solid red' : 'none')};
  }

  .select__menu,
  .select__option {
    cursor: pointer;
    background: #e8eef2;
    border-radius: 20px;
    overflow: hidden;
    opacity: 1;
    z-index: 999;
  }

  .select__option {
    padding-left: 28px
  }

  .select__option:hover {
    background: #cad9e0;
  }
  .select__option--is-selected {
    color: #678399;
    background: #cad9e0;
  }
`

export const Add = styled.div`
  cursor: pointer;
  position: relative;
  left: calc(100% - 395px);
`

export const Label = styled.div`
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: #023f73;
  text-align: center;
`

export const Card = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: hidden;
  transition: max-height 0.5s;
  height: 100%;
  max-height: ${(props) => (props.isAddCard ? "3000px" : 0)};
`

export const TitleCard = styled.div`
  font-weight: 800;
  font-size: 26px;
  line-height: 39px;
  color: rgb(2, 63, 115);
  margin-top: 30px;
  text-align: center;
`

export const ImgCard = styled.img`
  margin: 30px 0;
`
