import React from "reactn"
import { conditionalTemplating } from "src/utils";
import {
  LeftContentWrapper,
} from "src/markup/Components/Cards/CardCV/styles"

const CVSummary = ({ cvDetail }) => {
  return (
    <LeftContentWrapper>
        <div className="ml-3">Résumé du profil</div>
        {cvDetail && (
            <div>
              {conditionalTemplating(
                cvDetail,
                "skillsResume[0][0].name",
                "Dans le cadre de mes expériences passées, j'ai pu me renforcer dans $1."
              )}
              {"\n\n"}

              {conditionalTemplating(cvDetail, "skillCompsResume[0][0].name", "Maitrisant $1, ")}

              {conditionalTemplating(
                cvDetail, "mission[0][0].name",
                "je recherche un poste dans lequel je pourrais contribuer à $1"
              )}
              {conditionalTemplating(cvDetail, "mission[1][0].name", "ou à $1.")}
              {"\n\n"}

              {conditionalTemplating(cvDetail, "qualityMain[0][0].name", "Etant $1")}

              {conditionalTemplating(cvDetail, "qualityMain[0][1].name", " et $1")}

              {conditionalTemplating(cvDetail, "quality2[0][0].name", ", j'ai $1")}

              {conditionalTemplating(cvDetail, "quality3[0][0].name", " et je suis  $1")}
            </div>
        )}
    </LeftContentWrapper>
  )
}

export default CVSummary
