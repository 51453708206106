import React, { useGlobal, useRef } from "reactn"
import { FormGroup } from "reactstrap"
import { includes } from "lodash"

import { MecCollapse } from "src/markup/Components/Forms"
import { MecButton } from "src/markup/Components/MecButton"
import { StyledForm, StyledLabel } from "src/markup/Element/CVForm/styles"
import { PROFILE_DEFINITION } from "src/markup/Element/OfferForms/constant"
import { ReactComponent as IconPlush } from "src/images/icons/icon-plus.svg"
import { handleRenderField } from "../FormValidation"

const ProfileDefinition = ({
  formik,
  handleCompleted,
  handleAddSkill,
  isOpen,
  handleOpenCollapse,
  handleDeleteItem,
}) => {
  const profileDefinitionRef = useRef(null)
  const [config] = useGlobal("config")
  const [user] = useGlobal("user")

  const { values } = formik
  const { search_qualityMain, search_quality2, search_quality3, skill, languages } = values

  const completed =
    search_qualityMain &&
    search_quality2 &&
    search_quality3 &&
    !includes(
      skill.map((d) => d.search_skill === null || d.search_skillComp.length === 0),
      true
    ) &&
    languages?.[0]?.id
      ? true
      : false

  function scrollProfileDefinintion() {
    profileDefinitionRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  return (
    <MecCollapse
      title="Définition du profil"
      isOpen={isOpen === "profileDefinition"}
      setIsOpen={() => handleOpenCollapse("profileDefinition")}
      completed={completed}
      name="completed"
      className="mb-5"
    >
      <StyledForm>
        <div className="row" ref={profileDefinitionRef}>
          {PROFILE_DEFINITION(user?.gender).map((field, index) => (
            <div className={`d-flex flex-column justify-content-end col-${field.col}`} key={index}>
              {field.title && (
                <div className="col-12 sub-title text-align-last-left mb-4 " style={{ flex: 1, color: "#ff7200" }}>
                  {field.title}
                </div>
              )}
              <FormGroup className={`w-100 ${field.type === "boolean" && "d-flex flex-wrap flex-xl-nowrap mt-4"}`}>
                {field.label && (
                  <div className={`py-2 label text-align-last-left ${field.isRequired && "label-required"}`}>
                    {field.label}
                  </div>
                )}
                {handleRenderField({ formik, config, field, handleDeleteItem })}
                {field.fieldFirstRequired && (
                  <div className="row  d-flex justify-content-end">
                    <FormGroup className="d-flex justify-content-end add-experience" onClick={handleAddSkill}>
                      <IconPlush />
                      <StyledLabel className="py-2">Ajouter une compétence</StyledLabel>
                    </FormGroup>
                  </div>
                )}
              </FormGroup>
            </div>
          ))}
        </div>

        <div className="w-100 d-flex justify-content-center">
          <MecButton
            icon=""
            type="primary"
            width={120}
            widthIcon={20}
            className="text-capitalize mt-2"
            onClick={(e) => {
              handleCompleted(e, "position")
              handleOpenCollapse("conditions")
              scrollProfileDefinintion()
            }}
            disabled={!completed}
          >
            Valider
          </MecButton>
        </div>
      </StyledForm>
    </MecCollapse>
  )
}

export default ProfileDefinition
