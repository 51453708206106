import React from "react"
import IpadImg from "../../../../images/ipad-fomula.png"
import DirectionTopImg from "../../../../images/direction-top.svg"
import DirectionRightImg from "../../../../images/direction-right.svg"
import DirectionBottomImg from "../../../../images/direction-bottom.svg"
import "./index.scoped.scss"

const FEATURES = [
  {
    title: "Agenda interactif",
    description: "Planifiez et gérez vos entretiens en toute simplicité.",
    imgSrc: DirectionTopImg,
    imgAlt: "Flèche directionnelle vers le haut",
    className: "agenda",
  },
  {
    title: "Matching",
    description: "Évaluez rapidement l’adéquation de chaque candidat grâce à notre algorithme de matching intelligent.",
    imgSrc: DirectionRightImg,
    imgAlt: "Flèche directionnelle vers la droite",
    className: "matching",
  },
  {
    title: "Tableau de bord",
    description: "Suivi des candidatures en temps réel.",
    imgSrc: DirectionBottomImg,
    imgAlt: "Flèche directionnelle vers le bas",
    className: "dashboard",
  },
]

const ManagementTool = () => (
  <section className="management-tools">
    <div className="management-tools__title">
      <h3>OUTIL DE GESTION INTÉGRÉ</h3>
    </div>
    <div className="management-tools__content">
      <img src={IpadImg} alt="Aperçu de l'outil de gestion" className="management-tools__ipad" />
      {FEATURES.map(({ title, description, imgSrc, imgAlt, className }, index) => (
        <div key={index} className={`management-tools__feature management-tools__feature--${className}`}>
          {className === "agenda" ? (
            <>
              <p className="feature__title">{title}</p>
              <p className="feature__description">{description}</p>
              <img src={imgSrc} alt={imgAlt} />
            </>
          ) : (
            <>
              <img src={imgSrc} alt={imgAlt} />
              <p className="feature__title">{title}</p>
              <p className="feature__description">{description}</p>
            </>
          )}
        </div>
      ))}
    </div>
  </section>
)

export default ManagementTool
