import React from "reactn"
import { Redirect, Route, Switch } from "react-router-dom"
import { isRecruiter, isCandidate, saveCartToStorage } from "../services/auth"

// Home
import Homepage from "./Pages/Homepage"
// Landing page
import Formulas from "./Pages/Formulas"

// Candidate \\
// CVs
import CandidateMyCV from "./Pages/Candidate/CandidateMyCV"
import CandidateCreateCV from "./Pages/Candidate/CandidateCreateCV"

// Message
import CandidateMessageNew from "./Pages/Candidate/Message/New"
import CandidateMessageReceivedAndSent from "./Pages/Candidate/Message/ReceivedAndSent"
import CandidateMessageArchived from "./Pages/Candidate/Message/Archived"

// Applications
import CandidateApplicationContainer from "./Pages/Candidate/CandidateApplicationContainer"

// Recruiter \\
// Offers
import RecruiterMyOffers from "./Pages/Recruiter/RecruiterMyOffers/ListOffers"
import RecruiterCreateOffer from "./Pages/Recruiter/RecruiterCreateOffer"

// Message
import RecruiterMessageNew from "./Pages/Recruiter/Message/New"
import RecruiterMessageReceivedAndSent from "./Pages/Recruiter/Message/ReceivedAndSent"
import RecruiterMessageArchived from "./Pages/Recruiter/Message/Archived"

// Applications
import RecruiterApplicationContainer from "./Pages/Recruiter/RecruiterApplicationContainers"

//Searches
import SearchPage from "src/markup/Pages/SearchPage"

// Static \\
import StaticLegal from "./Pages/Static/StaticLegal"
import StaticCGU from "./Pages/Static/StaticCGU"
import StaticRGPD from "./Pages/Static/StaticRGPD"
import StaticAccesibility from "./Pages/Static/StaticAccesibility"
import StaticCookies from "./Pages/Static/StaticCookies"
import StaticHelpCandidate from "./Pages/Static/StaticHelpCandidate"
import StaticHelpRecruiter from "./Pages/Static/StaticHelpRecruiter"
import StaticHRInfos from "./Pages/Static/StaticHRInfos"

// Header Jobs \\

// Header Formations \\

// Header Enterprises \\

// Static Actualities \\

// Misc \\
import Error404 from "./Pages/Error404"
// import UnderConstruction from "./Pages/UnderConstruction";

import { AUTH, PROFILE, AGENDA, SEARCH } from "src/constants"

import AuthPage from "src/markup/Pages/AuthPage"
import VerifyEmailPage from "src/markup/Pages/AuthPage/VerifyEmailPage"
import ProfilePage from "src/markup/Pages/Profile"

import Offer from "src/markup/Pages/Offer"

import AgendaPage from "src/markup/Pages/Agenda"

import MyCartRecruiter from "src/markup/Pages/MyCartRecruiter"
import InvoiceRecruiter from "src/markup/Pages/InvoicePage"
import Checkout from "src/markup/Pages/Checkout"

import CvPage from "src/markup/Pages/CvPage"
import { useEffect } from "react"
import { CvDetail } from "./Pages/SearchPage/Recruiter/components/CardCV/CvDetail"

export default function Markup() {
  const RECRUITER = isRecruiter()
  const CANDIDATE = isCandidate()

  useEffect(() => {
    if (RECRUITER) {
      const pendingCartItem = localStorage.getItem("pendingCartItem")
      if (pendingCartItem) {
        localStorage.removeItem("pendingCartItem")
        const item = JSON.parse(pendingCartItem)
        const newCart = [{ ...item, count: 1 }]
        saveCartToStorage(newCart)
        window.location.href = "/recruiter/cart"
      }
    }
  }, [RECRUITER])

  return (
    <Switch>
      {/* <Route path="/" exact component={UnderConstruction} /> */}
      <Route path="/" exact component={Homepage} />
      <Route path="/pricing" exact component={Formulas} />

      <Route path="/offer/:id" exact component={Offer} />
      <Route path="/recruiter/offer-preview/:id" exact component={Offer} />
      <Route path="/recruiter/agenda/cv/:id" exact component={CvPage} recruiter />

      {/* Static */}
      <Route path="/static/legal" exact component={StaticLegal} />
      <Route path="/static/cgu" exact component={StaticCGU} />
      <Route path="/static/rgpd" exact component={StaticRGPD} />
      <Route path="/static/accesibility" exact component={StaticAccesibility} />
      <Route path="/static/cookies" exact component={StaticCookies} />
      <Route exact path="/static/helpcandidate" component={StaticHelpCandidate} />
      <Route path="/static/hrinfos" exact component={StaticHRInfos} />
      <Route exact path="/static/helprecruiter" component={StaticHelpRecruiter} />

      {RECRUITER ? (
        <Route path="/recruiter/checkout" component={Checkout} />
      ) : (
        <Redirect from="/recruiter/checkout" to="/recruiter/login" />
      )}

      {/* Register */}
      <Route exact path="/candidate/register" component={() => <AuthPage site={AUTH.REGISTER} candidate />} />
      <Route exact path="/recruiter/register" component={() => <AuthPage site={AUTH.REGISTER} recruiter />} />

      {/* Login */}
      {!CANDIDATE ? (
        <Route exact path="/candidate/login" component={() => <AuthPage site={AUTH.LOGIN} candidate />} />
      ) : (
        <Redirect from="/candidate/login" to="/candidate/mycv" />
      )}
      {!RECRUITER ? (
        <Route exact path="/recruiter/login" component={() => <AuthPage site={AUTH.LOGIN} recruiter />} />
      ) : (
        <Redirect from="/recruiter/login" to="/recruiter/myoffer" />
      )}

      {/* Forgot password */}
      <Route
        exact
        path="/candidate/forgot-password"
        component={() => <AuthPage site={AUTH.FORGOT_PASSWORD} candidate />}
      />
      <Route
        exact
        path="/recruiter/forgot-password"
        component={() => <AuthPage site={AUTH.FORGOT_PASSWORD} recruiter />}
      />

      {/* Confirm password */}
      <Route
        exact
        path="/candidate/confirm-password"
        component={() => <AuthPage site={AUTH.CONFIRM_PASSWORD} candidate />}
      />
      <Route
        exact
        path="/recruiter/confirm-password"
        component={() => <AuthPage site={AUTH.CONFIRM_PASSWORD} recruiter />}
      />
      <Route exact path="/check-email" component={VerifyEmailPage} />
      {/* Profile */}
      {CANDIDATE ? (
        <Route exact path="/candidate/profile" component={() => <ProfilePage candidate site={PROFILE.INFORMATION} />} />
      ) : (
        <Redirect from="/candidate/profile" to="/candidate/login" />
      )}
      {RECRUITER ? (
        <Route exact path="/recruiter/profile" component={() => <ProfilePage recruiter site={PROFILE.INFORMATION} />} />
      ) : (
        <Redirect from="/recruiter/profile" to="/recruiter/login" />
      )}

      {/* Profile Password */}
      {CANDIDATE ? (
        <Route
          exact
          path="/candidate/password"
          component={() => <ProfilePage candidate site={PROFILE.UPDATE_PASSWORD} />}
        />
      ) : (
        <Redirect from="/candidate/password" to="/candidate/login" />
      )}
      {CANDIDATE ? (
        <Route
          exact
          path="/candidate/password"
          component={() => <ProfilePage candidate site={PROFILE.UPDATE_PASSWORD} />}
        />
      ) : (
        <Redirect from="/candidate/password" to="/candidate/login" />
      )}
      {RECRUITER ? (
        <Route
          exact
          path="/recruiter/password"
          component={() => <ProfilePage recruiter site={PROFILE.UPDATE_PASSWORD} />}
        />
      ) : (
        <Redirect from="/recruiter/password" to="/recruiter/login" />
      )}

      {/* Candidate */}
      {CANDIDATE ? (
        <Route exact path="/candidate/searchjob" component={() => <SearchPage site={SEARCH.JOB} candidate />} />
      ) : (
        <Redirect from="/candidate/searchjob" to="/candidate/login" />
      )}

      {CANDIDATE ? (
        <Route
          exact
          path="/candidate/saved-search"
          component={() => <SearchPage site={SEARCH.SAVED_SEARCH} candidate />}
        />
      ) : (
        <Redirect from="/candidate/saved-search" to="/candidate/login" />
      )}

      {CANDIDATE ? (
        <Route path="/candidate/mycv" exact component={CandidateMyCV} />
      ) : (
        <Redirect from="/candidate/mycv" to="/candidate/login" />
      )}
      {CANDIDATE ? (
        <Route path="/candidate/mycv/draft" exact component={() => <CandidateMyCV />} />
      ) : (
        <Redirect from="/candidate/mycv" to="/candidate/login" />
      )}
      {CANDIDATE ? (
        <Route exact path="/candidate/mycv/create" component={CandidateCreateCV} />
      ) : (
        <Redirect from="/candidate/mycv/create" to="/candidate/login" />
      )}

      {CANDIDATE ? (
        <Route exact path="/candidate/mycv/update/:id" component={CandidateCreateCV} />
      ) : (
        <Redirect from="/candidate/mycv/update" to="/candidate/login" />
      )}

      {CANDIDATE ? (
        <Route exact path="/candidate/message/new" component={CandidateMessageNew} />
      ) : (
        <Redirect from="/candidate/message/new" to="/candidate/login" />
      )}
      {CANDIDATE ? (
        <Route
          exact
          path="/candidate/message/received"
          component={() => <CandidateMessageReceivedAndSent mode="RECEIVED" />}
        />
      ) : (
        <Redirect from="/candidate/message/received" to="/candidate/login" />
      )}
      {CANDIDATE ? (
        <Route exact path="/candidate/message/sent" component={() => <CandidateMessageReceivedAndSent mode="SENT" />} />
      ) : (
        <Redirect from="/candidate/message/sent" to="/candidate/login" />
      )}
      {CANDIDATE ? (
        <Route exact path="/candidate/message/archived" component={() => <CandidateMessageArchived />} />
      ) : (
        <Redirect from="/candidate/message/archived" to="/candidate/login" />
      )}
      {CANDIDATE ? (
        <Route exact path="/candidate/mycv/edit/:id" component={CandidateCreateCV} />
      ) : (
        <Redirect from="/candidate/mycv/edit/:id" to="/candidate/login" />
      )}
      {/** TODO : the view value 'normal' does not appear in the code. What's the point? */}
      {CANDIDATE ? (
        <Route
          exact
          path="/candidate/application/sent"
          component={() => <CandidateApplicationContainer mode={"sent"} view={"normal"} />}
        />
      ) : (
        <Redirect from="/candidate/application/sent" to="/candidate/login" />
      )}
      {CANDIDATE ? (
        <Route
          exact
          path="/candidate/offers/:id"
          component={() => <CandidateApplicationContainer mode={"sent"} view={"details"} />}
        />
      ) : (
        <Redirect from="/candidate/offers/:id" to="/candidate/login" />
      )}
      {CANDIDATE ? (
        <Route
          exact
          path="/candidate/application/accepted"
          component={() => <CandidateApplicationContainer mode={"accepted"} />}
        />
      ) : (
        <Redirect from="/candidate/application/accepted" to="/candidate/login" />
      )}
      {CANDIDATE ? (
        <Route
          exact
          path="/candidate/application/declined"
          component={() => <CandidateApplicationContainer mode={"declined"} />}
        />
      ) : (
        <Redirect from="/candidate/application/declined" to="/candidate/login" />
      )}

      {CANDIDATE ? (
        <Route
          exact
          path="/candidate/offer/favorite"
          component={() => <SearchPage site={SEARCH.SAVED_OFFER} candidate />}
        />
      ) : (
        <Redirect from="/candidate/offer/favorite" to="/candidate/login" />
      )}

      {/* Recruiter */}
      {RECRUITER ? (
        <Route exact path="/recruiter/myoffer/create" component={RecruiterCreateOffer} />
      ) : (
        <Redirect from="/recruiter/myoffer/create" to="/recruiter/login" />
      )}
      {RECRUITER ? (
        <Route exact path="/recruiter/myoffer/update/:id" component={RecruiterCreateOffer} />
      ) : (
        <Redirect from="/recruiter/myoffer/update/:id" to="/recruiter/login" />
      )}
      {RECRUITER ? (
        <Route path="/recruiter/myoffer" exact component={() => <RecruiterMyOffers isDraft={false} />} />
      ) : (
        <Redirect from="/recruiter/myoffer" to="/recruiter/login" />
      )}
      {RECRUITER ? (
        <Route path="/recruiter/myoffer/draft" exact component={() => <RecruiterMyOffers isDraft />} />
      ) : (
        <Redirect from="/recruiter/myoffer" to="/recruiter/login" />
      )}
      {RECRUITER ? (
        <Route exact path="/recruiter/message/new" component={RecruiterMessageNew} />
      ) : (
        <Redirect from="/recruiter/message/new" to="/recruiter/login" />
      )}
      {RECRUITER ? (
        <Route
          exact
          path="/recruiter/message/received"
          component={() => <RecruiterMessageReceivedAndSent mode="RECEIVED" />}
        />
      ) : (
        <Redirect from="/recruiter/message/received" to="/recruiter/login" />
      )}
      {RECRUITER ? (
        <Route exact path="/recruiter/message/sent" component={() => <RecruiterMessageReceivedAndSent mode="SENT" />} />
      ) : (
        <Redirect from="/recruiter/message/sent" to="/recruiter/login" />
      )}
      {RECRUITER ? (
        <Route exact path="/recruiter/message/archived" component={() => <RecruiterMessageArchived />} />
      ) : (
        <Redirect from="/recruiter/message/archived" to="/recruiter/login" />
      )}
      {RECRUITER ? (
        <Route exact path="/recruiter/myoffer/update/:id" component={RecruiterCreateOffer} />
      ) : (
        <Redirect from="/recruiter/myoffer/update/:id" to="/recruiter/login" />
      )}
      {RECRUITER ? (
        <Route
          exact
          path="/recruiter/application/received"
          component={() => <RecruiterApplicationContainer mode={"received"} />}
        />
      ) : (
        <Redirect from="/recruiter/application/received" to="/recruiter/login" />
      )}
      {RECRUITER ? (
        <Route
          exact
          path="/recruiter/application/selected"
          component={() => <RecruiterApplicationContainer mode={"selected"} />}
        />
      ) : (
        <Redirect from="/recruiter/application/selected" to="/recruiter/login" />
      )}
      {RECRUITER ? (
        <Route
          exact
          path="/recruiter/application/accepted"
          component={() => <RecruiterApplicationContainer mode={"accepted"} />}
        />
      ) : (
        <Redirect from="/recruiter/application/accepted" to="/recruiter/login" />
      )}
      {RECRUITER ? (
        <Route
          exact
          path="/recruiter/application/declined"
          component={() => <RecruiterApplicationContainer mode={"declined"} />}
        />
      ) : (
        <Redirect from="/recruiter/application/declined" to="/recruiter/login" />
      )}

      {CANDIDATE ? (
        <Route
          exact
          path="/candidate/agenda/interviews"
          component={() => <AgendaPage candidate site={AGENDA.INTERVIEW} />}
        />
      ) : (
        <Redirect from="/candidate/agenda/interviews" to="/candidate/login" />
      )}

      {CANDIDATE ? (
        <Route exact path="/candidate/agenda/past" component={() => <AgendaPage candidate site={AGENDA.PAST} />} />
      ) : (
        <Redirect from="/candidate/agenda/past" to="/candidate/login" />
      )}

      {RECRUITER ? (
        <Route exact path="/recruiter/agenda/list" component={() => <AgendaPage recruiter site={AGENDA.INTERVIEW} />} />
      ) : (
        <Redirect from="/recruiter/agenda/list" to="/recruiter/login" />
      )}

      {RECRUITER ? (
        <Route
          exact
          path="/recruiter/agenda/schedule-meeting"
          component={() => <AgendaPage recruiter site={AGENDA.SCHEDULE} />}
        />
      ) : (
        <Redirect from="/recruiter/agenda/schedule-meeting" to="/recruiter/login" />
      )}

      <Route path="/candidate/searchjob/result" exact component={() => <SearchPage site={SEARCH.RESULT} candidate />} />
      {/* TODO : adds check on role */}

      <Route path="/recruiter/cart" component={MyCartRecruiter} />

      {RECRUITER ? (
        <Route path="/recruiter/search-cv" component={() => <SearchPage site={SEARCH.CV} recruiter />} />
      ) : (
        <Redirect from="/recruiter/search-cv" to="/recruiter/login" />
      )}

      {RECRUITER ? (
        <Route path="/recruiter/saved-search" component={() => <SearchPage site={SEARCH.SAVED_SEARCH} recruiter />} />
      ) : (
        <Redirect from="/recruiter/saved-search" to="/recruiter/login" />
      )}

      {RECRUITER ? (
        <Route path="/recruiter/invoice" component={InvoiceRecruiter} />
      ) : (
        <Redirect from="/recruiter/invoice" to="/recruiter/login" />
      )}

      {RECRUITER ? (
        <Route exact path="/recruiter/cv/:cvId" component={CvDetail} />
      ) : (
        <Redirect from="/recruiter/cv/:cvId" to="/recruiter/login" />
      )}

      <Route component={Error404} />
    </Switch>
  )
}
