import styled from 'styled-components'

export const Wrapper = styled.div`
  .height-layout {
    height: calc(100vh - 100px);
  }
  .underline {
    border-bottom: 1px solid var(--white);
    padding-bottom: 1px;
  }

  @media (max-width: 768px) {
    .height-layout {
      height: 100vh;
    }
}
`


export const SForm = styled.div`
  min-width: 520px;
  max-width: 520px;
  width: 100%;
  text-align: center;

  @media (max-width: 992px) {
    min-width: 100%;
  }
`
