import { ReactComponent as Autonome } from "src/images/icons/autonome.svg"
import { ReactComponent as Sportive } from "src/images/icons/sportive.svg"
import { ReactComponent as Active } from "src/images/icons/active.svg"
import { ReactComponent as Accompagnee } from "src/images/icons/accompagnee.svg"

export const { REACT_APP_ENV } = process.env;

const MECANOJOB_URL_BY_ENV = {
  local: "http://localhost:8080",
  development: "https://dev.mecanojob.com",
  production: 'https://mecanojob.com'
}
export const MECANOJOB_URL = MECANOJOB_URL_BY_ENV['local'];

const API_URL_BY_ENV = {
  local: "http://localhost:8080/api",
  development: "https://api-dev.mecanojob.com/api",
  production: "https://api.mecanojob.com/api"
};
console.log('REACT_APP_ENV', REACT_APP_ENV);
export const API_URL = API_URL_BY_ENV[REACT_APP_ENV];
console.log('API_URL', API_URL);

export const ASSET_LANG_URL = "https://mecanoassets.s3.eu-west-3.amazonaws.com/lang/";
export const ASSET_SKILL_URL = "https://mecanoassets.s3.eu-west-3.amazonaws.com/skill/";
export const ASSET_SKILLCOMP_URL = "https://mecanoassets.s3.eu-west-3.amazonaws.com/skillComp/";
export const ASSET_QUALITYMAIN_URL = "https://mecanoassets.s3.eu-west-3.amazonaws.com/qualityMain/";
export const ASSET_QUALITY2_URL = "https://mecanoassets.s3.eu-west-3.amazonaws.com/quality2/";
export const ASSET_QUALITY3_URL = "https://mecanoassets.s3.eu-west-3.amazonaws.com/quality3/";

export const ROLE_RECRUITER = "ROLE_RECRUITER";
export const ROLE_CANDIDATE = "ROLE_CANDIDATE";

export const GOOGLE_API_KEY = "AIzaSyC9dBPlkD6Et2xXq9FzWzonFgAbnjbTtK4";
export const DISTANCE_RANGES = [
  { value: 0, label: "0 km" },
  { value: 10, label: "10 km" },
  { value: 20, label: "20 km" },
  { value: 30, label: "30 km" },
  { value: 40, label: "40 km" },
  { value: 50, label: "50 km" },
];

export const AUTH = {
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
}

export const PROFILE = {
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  INFORMATION: 'INFORMATION'
}

export const ROLE = {
  RECRUITER: 'RECRUITER',
  CANDIDATE: 'CANDIDATE',
}

export const MODAL = {
  CONFIRM_AUTH: 'CONFIRM_AUTH',
  CONFIRM_MY_CART: 'CONFIRM_MY_CART',
  CONFIRM_APPLY_CV: 'CONFIRM_APPLY_CV',
  CONFIRM_MODAL: 'CONFIRM_MODAL',
}

export const AGENDA = {
  INTERVIEW: 'INTERVIEW',
  SCHEDULE: 'SCHEDULE',
}

export const SEARCH = {
  JOB: 'SEARCH_JOB',
  RESULT: 'SEARCH_JOB_RESULT',
  CV: 'SEARCH_CV',
  SAVED_SEARCH: 'SAVED_SEARCH',
  SAVED_OFFER: 'SAVED_OFFER',
}

export const FORMAT_DATE = 'DD/MM/YYYY'

export const FORMAT_DATE_FR = 'dd/MM/yyyy'

export const CV_STATUS = {
  DRAFT: 1,
  PUBLISHED: 2,
}

export const SORT_BY = {
  DESC: 'DESC',
  ASC: 'ASC'
}

export const DEFAULT_LIMIT = {
  LATEST_OFFER_HOMEPAGE: 10,
  FEATURED_OFFER_HOMEPAGE: 3,
}

export const OFFER_STATUS = {
  DRAFT: 1,
  PUBLISHED: 2,
}

export const OFFER_TYPE = {
  NORMAL: 1,
  MECANOJOB: 2,
}

export const AGENDA_STATUS = {
  WAITING: 1,
  ACCEPTED: 2,
  REJECTED: 3,
}

export const DURATION_AGENDA = {
  THIRTY_MIN: '30 min',
  ONE_HOUR: '1h',
  ONE_HOUR_THIRTY_MIN: '1h30',
  TWO_HOURS: '2h',
}

export const MESSAGE = {
  RECEIVED: 1,
  SENT: 2,
  ARCHIVE: 3
}

export const ACTION_MESSAGE = {
  ARCHIVE: 1,
  DELETED: 2,
}

export const PHONE_REG_EXP = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const FRENCH_PHONE_REG_EXP = /^(0|\+33)[1-9]([-.]?[0-9]{2}){3}([-.]?[0-9]{2})$/;

export const POSTAL_CODE_REG_EXP = /^(?:[0-8]\d|9[0-8])\d{3}$/;

export const URL_WEB_REG_EXP = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/

export const STRING_IS_BASE64_REG_EXP = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/

export const LEVELS = [
  { id: 1, name: "Débutant" },
  { id: 2, name: "Intermédiaire" },
  { id: 3, name: "Confirmé" },
  { id: 4, name: "Expert" },
]

export const MAX_LENGTH_CV = 3;

export const STATUS_CODE = {
    PLEASE_BUY_PACKAGE: 1001,
}

export const FORMULAS = [
  {
    ID: 1,
    NAME: "Autonome",
    CODE: "AUTONOME",
    PRICE: 60,
    ICON: <Autonome width={70} height={70} />
  },
  {
    ID: 2,
    NAME: "Accompagnée",
    CODE: "ACCOMPANIED",
    PRICE: 1000,
    ICON: <Accompagnee width={70} height={70} />
  },
  {
    ID: 3,
    NAME: "Active",
    CODE: "ACTIVE",
    PRICE: 3000,
    ICON: <Active width={70} height={70} />
  },
  {
    ID: 4,
    NAME: "Sportive",
    CODE: "SPORTIVE",
    PRICE: 6000,
    ICON: <Sportive width={70} height={70} />
  },
];
