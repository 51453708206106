import React from "reactn"
import { Link } from "react-router-dom"
import { ModalBody } from "reactstrap"
import { ProfileModalWrapper } from "./styles"

export const ProfileModal = ({ toggle, title, content, to }) => {
  return (
    <ProfileModalWrapper isOpen={toggle} centered>
      <ModalBody>
        <div className="quote-bx text-center mb-5">
          <div className="quote-info">
            <h3 className="text-uppercase">{title}</h3>
            <p>{content}</p>
            <div className="left-job-buttons">
              <Link to={to} className="site-button site-button-gap radius-xl text-uppercase">
                OK
              </Link>
            </div>
          </div>
        </div>
      </ModalBody>
    </ProfileModalWrapper>
  )
}
