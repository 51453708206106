import styled from "styled-components"

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  z-index: 9;


  background: rgba(255, 255, 255, 0.2);
`
