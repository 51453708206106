import styled from 'styled-components'
import Select from 'react-select'

export const Wrapper = styled.div`
`

export const Heading = styled.h3`
  position: relative;
  color: var(--orange300);
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`

export const Title = styled.div`
  color: #023F73;
  font-size: 34px;
  line-height: 51px;
  font-weight: bold;
  font-family: Raleway;
  padding-left: 0;

`

export const WrapperContent = styled.div`

  background: #FFFFFF;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  padding: 40px;
  width: 100%;

  .label {
    color: #023F73;
    font-weight: 800;
    font-size: 22px;
    line-height: 33px
  }

  @media (max-width: 768px) {
    padding: 20px;
    .label {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
`


export const TextNote = styled.div`
  width: 100%;
  color: var(--orange300);
  font-weight: 400;
  font-size: 15px;

  padding-top: 30px;
`
export const SelectComponent = styled(Select)`
  .select__control {
    background: #E8EEF2;
    padding: 5px 7px 5px 20px;
    color: var(--blue600);
    border-radius: 20px;
    border: none;
    outline: none;
    overflow: hidden;
  }
`

export const UploadImage = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 999;
    cursor: pointer;
  }
`

export const Image = styled.div`
  background: #ffffff;
  padding: 10px;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  position: relative;
  cursor: pointer;

  img {
    border-radius: ${(props) => props.avatar && "50%"};
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const Asterisk = styled.span`
  color: #ff7200;
  padding-left: 5px;
`
