import { Collapse } from "reactstrap"
import styled from "styled-components"

export const CardDetailsOfferWrapper = styled.div`
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  background-color: #fff;
  padding: 30px;
  position: relative;
  margin-top: ${(props) => (props.isPrefix ? "180px" : "0px")} !important;

  &::before {
    content: "Urgent";
    position: absolute;
    width: 300px;
    height: 163px;
    left: -5px;
    top: -80px;
    background: #ffffff;
    box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
    border-radius: 40px;
    text-align: center;
    font-weight: 800;
    font-size: 26px;
    line-height: 38px;
    color: #023f73;
    z-index: -1;
    padding: 20px;
    display: ${(props) => (props.isPrefix ? "block" : "none")};
  }

  border: ${(props) => (props.isPrefix ? "none" : "5px solid #ff7200")};
  
  .d-inline-grid {
    display: inline-grid;
  }

  .icon-responsive {
    width: 40px;
    height: 40px;
  }

  @media (min-width: 1024px) {
    padding: 60px;
    &::before {
      width: 380px;
      top: -95px;
      font-size: 35px;
      line-height: 41px;
      padding: 30px;
    }
  }

  @media (min-width: 1440px) {
    .icon-responsive {
      width: 70px;
      height: 70px;
    }
  }
`

export const DetailsOfferWrapper = styled.div``

export const DetailsOfferLeftWrapper = styled.div`
  border-right: ${(props) => props.border && "5px solid #e8eef2"};
  font-size: 1rem;
  line-height: 1.5rem;
  color: #023f73;

  @media (min-width: 1024px) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`

export const LeftLogoWrapper = styled.div`
  display: flex;
  justify-content: center;

  .title-offers {
    span:first-child {
      font-weight: 600;
      font-size: 17px;
      line-height: 25px;
      color: #023f73;
    }
    span:last-child {
      font-weight: 800;
      font-size: 13px;
      line-height: 20px;
      color: #ff7200;
    }
  }

  @media (min-width: 1024px) {
    .title-offers {
      span:first-child {
        font-size: 22px;
        line-height: 34px;
      }
      span:last-child {
        font-size: 19px;
        line-height: 27px;
      }
    }
  }

  @media (min-width: 1440px) {
    .title-offers {
      span:first-child {
        font-size: 30px;
        line-height: 39px;
      }
      span:last-child {
        font-size: 22px;
        line-height: 27px;
      }
    }
  }
`

export const LeftTitleWrapper = styled.div`
  text-align: center;
  padding: 20px 0;

  div {
    font-weight: 800;
    font-size: 17px;
    line-height: 20px;
    color: #023f73;
  }

  div:last-child {
    font-weight: 900;
    font-size: 25px;
    line-height: 31px;
    color: #ff7200;
  }
  @media (min-width: 1024px) {
    padding: 30px 0;
    div:last-child {
      font-size: 32px;
      line-height: 38px;
    }
  }
`

export const LeftContentWrapper = styled.div`
  padding: 0;

  div {
    font-weight: 800;
    font-size: 18.4202px;
    line-height: 22px;
    color: #023f73;
  }

  div:nth-child(2) {
    font-weight: 400;
    font-size: 14.7361px;
    line-height: 17px;
    margin-top: 15px;
    max-width: 75%;
    margin-left: 10%;
  }

  h3 {
    font-size: 24px;
  }

  @media (min-width: 1024px) {
    padding: 20px 0;

    h3 {
      font-size: 28px;
    }
  }
`

export const DetailsOfferRightWrapper = styled.div`
  .list-skills {
    flex-wrap: wrap;
    justify-content: space-evenly;
    overflow-x: auto;
    div {
      width: 100px;
      text-align: center;
    }
    svg {
      width: 40px;
      height: 40px;
    }
  }

  .job-description {
    list-style-type: none;
  }

  @media (min-width: 1440px) {
    .list-skills {
      svg {
        width: 70px;
        height: 70px;
      }
    }
  }
`

export const WrapperLogo = styled.div`
  circle {
    fill: #002d56;
  }
`

export const TagConfirm = styled.div`
  border: 4px solid ${props => props.bg || '#37bf78'};
  padding: 3px 15px;
  font-weight: bold;
  color: ${props => props.bg || '#37bf78'};
  &:before {
    content: " ";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${props => props.bg || '#37bf78'} ;
    margin-right: 5px;
    display: inline-block;
  }
`

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const TextBlue = styled.div`
  color: #023f73;
`

export const CollapseDetailCV = styled(Collapse)`
  .collapsing {
    transition: height 1s ease;
  }
`
