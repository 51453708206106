import styled from "styled-components"
import { Form } from "reactstrap"

export const Wrapper = styled.div`
  margin-bottom: 50px;
  margin-top: 65px;
  padding: 0 20px;

  .btn-move-new {
    position: absolute;
    right: 20px;
  }

  @media (min-width: 992px) {
    padding: 0 50px;

    .btn-move-new {
      right: 50px;
    }
  }
`

export const MessageReceivedAndSent = styled(Form)`
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  background-color: #fff;
  padding: 30px;
  position: relative;
  text-align-last: left;
  margin-bottom: 30px;

  .d-inline-grid {
    display: inline-grid;
  }

  @media (min-width: 1024px) {
    /* padding: 60px; */

    &::before {
      width: 380px;
      top: -95px;
      font-size: 35px;
      line-height: 41px;
      padding: 30px;
    }
  }
`

export const Header = styled.div`
  color: #023f73;

  .label {
    font-weight: 800;
  }

  hr {
    border: 2px solid #e8eef2;
  }

  @media (min-width: 992px) {
    height: 80px;
  }
`

export const Content = styled.div`
  margin-top: 40px;
  padding: 0 20px;
  color: #023f73;
`

export const ListWrapper = styled.div`
  height: 190px;
  overflow-y: scroll;
`

export const List = styled.div`
  margin-top: 40px;
  padding-right: 100px;
  color: #023f73;
  cursor: pointer;
  font-weight: ${(props) => props.active && 800};
  font-weight: 700;

  &:hover {
    border-bottom: 1px solid #ddd;
  }
`

export const Footer = styled.div`
  gap: 40px;
  margin-top: 40px;

  .change-fill {
    path {
      fill: #fff;
    }
  }
`
