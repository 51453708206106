import React, { useRef } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./index.scoped.scss"
import TestimonialImg1 from "./../../../../images/testimonial-1.png"
import TestimonialImg2 from "./../../../../images/testimonial-2.png"
import QuotationIcon from "./../../../../images/icons/quotation.svg"
const testimonials = [
  {
    name: "MAXIME",
    role: "Candidat, mécanicien automobile",
    text: "Grâce à MécanoJob, j’ai trouvé un emploi en moins d’une semaine ! Le site est clair, et le matching avec les offres m’a permis de postuler aux annonces les plus adaptées à mon profil.",
    image: TestimonialImg2,
  },
  {
    name: "Sophie",
    role: "Recruteur, Responsable RH en concession",
    text: "Recruter des profils techniques n’a jamais été aussi simple. Le matching nous fait gagner un temps précieux et les entretiens sont mieux ciblés. En quelques jours, nous avons trouvé le bon candidat !",
    image: TestimonialImg1,
  },
]

const TestimonialSlider = () => {
  const sliderRef = useRef(null)
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "260px",
    autoplay: true,
    autoplaySpeed: 3000,
  }

  return (
    <div className="testimonial-slider">
      <Slider ref={sliderRef} {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <div className="testimonial-content">
              <div className="testimonial-text">
              <img src={QuotationIcon} className="mb-4" alt=""></img>
                <p className="testimonial-quote">“{testimonial.text}”</p>
                <strong>{testimonial.name}</strong>
                <p className="testimonial-role">{testimonial.role}</p>
              </div>
              <img src={testimonial.image} alt={testimonial.name} className="testimonial-img" />
            </div>
          </div>
        ))}
      </Slider>

      <button className="prev-btn" onClick={() => sliderRef.current.slickPrev()}>
        ◀
      </button>
      <button className="next-btn" onClick={() => sliderRef.current.slickNext()}>
        ▶
      </button>
    </div>
  )
}

export default TestimonialSlider
