import moment from "moment";
import { FRENCH_PHONE_REG_EXP, POSTAL_CODE_REG_EXP } from "src/constants"
import * as Yup from "yup"

const maxDate = moment().subtract("years", 14).toDate();

export const validationProfileCandidate = ({ t }) => Yup.object({
  gender: Yup.object({
    value: Yup.string().required(t("required_field")),
    label: Yup.string().required(t("required_field")),
  }).nullable(true),
  name: Yup.string().required(t("required_field")),
  surname: Yup.string().required(t("required_field")),
  email: Yup.string().email().required(t("required_field")),
  phonenumber: Yup.string()
    .matches(FRENCH_PHONE_REG_EXP, t("invalid_field"))
    .required(t("required_field"))
    .nullable(true),
  birthday: Yup.date()
    .nullable()
    .test('min-age-required', t("invalid_field"), (birthDate) => birthDate <= maxDate)
    .required(t("required_field")),
  address: Yup.string().required(t("required_field")).nullable(true),
  city: Yup.string().required(t("required_field")).nullable(true),
  country: Yup.string().required(t("required_field")).nullable(true),

  postalcode: Yup.string()
    .matches(POSTAL_CODE_REG_EXP, t("invalid_field"))
    .required(t("required_field"))
    .nullable(true),
})
