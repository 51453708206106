import { memo } from "react"
import PhoneImg from "../../../../images/phone-fomula.png"
import { MecButton } from "src/markup/Components"
import ProfileIcon from "../../../../images/icons/profile-circle.svg"
import SpeakerIcon from "../../../../images/icons/loudspeaker-icon.svg"
import MagnifyingIcon from "../../../../images/icons/magnifying.svg"
import ClapIcon from "../../../../images/icons/clap.svg"
import "./index.scoped.scss"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useTranslation } from "react-i18next"
import ProcessStep from "./ProcessStep"

const PROCESS_STEPS = [
  {
    id: "define",
    icon: ProfileIcon,
    title: "Définition du profil",
    description: "Nous vous aidons à définir vos besoins",
  },
  {
    id: "source",
    icon: SpeakerIcon,
    title: "Diffusion & sourcing",
    description: "Nous diffusons et sélectionnons les meilleurs candidats",
  },
  {
    id: "analyze",
    icon: MagnifyingIcon,
    title: "Analyse et entretiens",
    description: "Vérification des CV, vérifications du parcours entretiens, suivi",
  },
  {
    id: "recruit",
    icon: ClapIcon,
    title: "Recrutement garanti !",
    description: "",
  },
]

const ROUTES = {
  REGISTER: "/recruiter/register",
}

const HowItWorks = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const handleRegister = () => {
    history.push(ROUTES.REGISTER)
  }

  return (
    <section className="how-it-works">
      <div className="title">
        <h3>{t("how_does_it_work").toUpperCase()}</h3>
      </div>
      <div className="diagonal-label">{t("talent_hunter_offer").toUpperCase()}</div>
      <div className="content">
        <div className="process-row w-100">
          <div className="phone-container">
            <img src={PhoneImg} alt="Phone Application" className="phone-image" loading="lazy" />
          </div>
          <div>
            <div className="steps-container">
              <div className="divider" />
              {PROCESS_STEPS.map((step) => (
                <ProcessStep key={step.id} step={step} />
              ))}
            </div>
            <div className="mt-5">
              <MecButton
                onClick={handleRegister}
                className="w-auto m-auto px-3"
                aria-label="Publier une offre d'emploi"
              >
                {t("post_a_job_offer")}
              </MecButton>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowItWorks
