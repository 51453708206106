import * as Yup from 'yup'

export const updatePasswordSchema = ({ t }) => Yup.object({
  password: Yup.string()
    .required(t('required_field'))
    .min(6, 'Le mot de passe doit comporter de 6 à 20 caractères')
    .max(20, 'Le mot de passe doit comporter de 6 à 20 caractères'),
  new_password: Yup.string()
    .required(t('required_field'))
    .min(6, 'Le mot de passe doit comporter de 6 à 20 caractères')
    .max(20, 'Le mot de passe doit comporter de 6 à 20 caractères'),
  confirm_new_password: Yup.string()
    .when('new_password', {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref('new_password')],
        'Les mots de passe ne correspondent pas'
      ),
    })
    .required(t('required_field')),
})
