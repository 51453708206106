import { includes, get } from "lodash"
import * as Yup from "yup"

export const CVFormInitialValues = {
  joblistId: null,
  search_exp: null,
  pres_jobOtherName: null,
  city: null,
  city_range: null,

  name_cv: "",
  status_cv: "",

  experiences: [
    {
      id: 1,
      expJobName: "",
      expCompany: "",
      expEntryDate: "",
      expExitDate: "",
      expCity: "",
      expMissions: "",
    },
  ],

  formations: [
    {
      id: 1,
      domain_activity: null,
      formSchool: "",
      formCity: "",
      formDiploma: null,
      formDiplomaOption: null,
      formEntryDate: "",
      formExitDate: "",
      formMissions: "",
    },
  ],

  mission: [
    {
      id: 1,
      search_mission: null,
      search_specMission: [],
    },
  ],

  skill: [
    {
      id: 1,
      search_skill: null,
      search_skillComp: [],
    },
  ],

  tools: [
    {
      keyId: 1,
      id: "",
      name: "",
      level: 1,
    },
  ],

  search_qualityMain: [],
  search_quality2: null,
  search_quality3: null,
  languages: [{filename: 'french.png', id: 8, name: 'Français'}],
  extra_driversLicenceB: [],
  extra_criminalRecord: false,
  extra_otherInfos: "",
  // status_cv, 1 === Enregistrer, 2 === Publier
}

Yup.addMethod(Yup.array, 'stillWorking', function (propertyName, message) {
  return this.test("stillWorking", message, function(list = []) {
    const mapper = x => get(x, propertyName);
    const set = [...list.map(mapper)];
    const idx = list.findIndex((l, i) => {
      return set[i] === undefined
    });
    return idx !== -1 ? this.createError({ path: `[[experiences.${idx}].${propertyName}]`, message }) : true;
  });
});

export const CVFormvalidationSchema = ({ t, inexperienced, isDraft }) => {
  return isDraft
    ? Yup.object({})
    : Yup.object().shape({
        joblistId: Yup.object()
          .required(t("required_field"))
          .nullable(true),
        search_exp: Yup.object()
          .required(t("required_field"))
          .nullable(true),
        pres_jobOtherName: Yup.object()
          .required(t("required_field"))
          .nullable(true),
        city: Yup.string(t("invalid_field"))
          .trim()
          .required(t("required_field"))
          .nullable(true),
        name_cv: Yup.string(t("invalid_field"))
          .required(t("required_field"))
          .nullable(true),

        experiences:
          !includes(inexperienced, "experiences") &&
          Yup.array().of(
            Yup.object().shape({
              expJobName: Yup.string(t("invalid_field"))
                .trim()
                .required(t("required_field")),
              expCompany: Yup.string(t("invalid_field"))
                .trim()
                .required(t("required_field")),
              expEntryDate: Yup.string(t("invalid_field"))
                .nullable()
                .required(t("required_field")),
              expExitDate: Yup.string(t("invalid_field"))
                .nullable(),
              expCity: Yup.string(t("invalid_field"))
                .trim()
                .required(t("required_field")),
              expMissions: Yup.string(t("invalid_field"))
                .trim()
                .required(t("required_field")),
            })
          ).stillWorking('expExitDate', t("required_field")),

        formations:
          !includes(inexperienced, "formations") &&
          Yup.array().of(
            Yup.object().shape({
              domain_activity: Yup.object()
                .required(t("required_field"))
                .nullable(true),
              formSchool: Yup.string().required(t("required_field")),
              formCity: Yup.string().required(t("required_field")),
              formEntryDate: Yup.string().nullable(),
              formExitDate: Yup.string().nullable(),
              formMissions: Yup.string(),
            })
          ),
      })
}
