import styled from "styled-components";
import ImageLanding1 from "src/images/image-landingpage-1.png";
import ImageLanding2 from "src/images/image-landingpage-2.png";
import ImageLanding3 from "src/images/image-landingpage-3.png";
import ImageLandingBackground1 from "src/images/image-landingpage-background-1.png";
import ImageLandingBackground from "src/images/image-landingpage-background.png";
import ImageLandingDots from "src/images/image-landingpage-dots.png";

export const PageWrapper = styled.div`
  .dez-bnr-inr {
    background: linear-gradient(90deg, #ff7200 0%, #f7931e 100%);
    overflow: hidden;
    position: relative;
    height: calc(100vh - 50px);

    &::after,
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
    }

    &::before {
      background-position: -10% -40%, 115% 155%, right;
      background-image: url(${ImageLandingDots}), url(${ImageLandingDots}),
        url(${ImageLandingBackground});
    }

    &::after {
      background-position: right;
      background-image: url(${ImageLandingBackground1});
    }
  }

  h3.text-orange {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: center;
    color: #ff7200;
  }

  @media (min-width: 992px) {
    height: calc(100vh - 110px);
  }
`;

export const FindWrapper = styled.div`
  width: 100%;
  max-width: 995px !important;
  /* margin-left: 260px; */

  .dez-bnr-inr-entry {
    min-height: 350px;
  }

  @media (min-width: 1440px) {
    .dez-bnr-inr-entry {
      min-height: 450px;
    }
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;

  &::before {
    pointer-events: none;
    content: "";
    position: absolute;
    display: block;
    width: 100vw;
    height: 150vh;
    top: -25%;
    right: -205%;
    background-image: url(${ImageLanding1}), url(${ImageLanding2}),
      url(${ImageLanding3});
    background-repeat: no-repeat;
    background-position: 35% 90%, 45% 10%, 65% 44%;
    transform: scale(0.5);
    z-index: 1;
  }

  @media (min-width: 1440px) {
    &::before {
      pointer-events: none;
      height: 100vh;
      top: 0%;
      background-position: 25% 100%, 30% 0%, 50% 35%;
      transform: scale(0.7);
    }
  }
`;

export const FormWrapper = styled.form`
  .input-group {
    width: 100%;
    max-width: 500px;

    .form-control {
      height: 50px !important;
      border-radius: 15px !important;
      margin-bottom: 35px;
    }
    #joblistId {
      z-index: 2;
    }
  }
`;

export const JobsectionWrapper = styled.div`
  .sticky-top {
    h3.text-orange {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  .job-time {
    ul {
      list-style: none;
      margin: 0;
    }
  }
  @media (min-width: 1440px) {
    .container {
      /* max-width: 1620px !important; */
      .title-offers {
        span {
          font-weight: 600;
          font-size: 27px;
          line-height: 32px;
          color: #ff7200;
        }

        span:last-child {
          display: none;
        }
      }
    }
  }
`;

export const ListOffersWrapper = styled.div`
margin-bottom: 1rem;
  & > div {
    border: none;
    padding: 30px 30px 0px 30px;

    h3.text-orange {
      display: none;
    }
  }
  .list-skills,
  .title-offers {
    svg {
      width: 55px;
      height: 55px;
      circle {
        fill: #002d56;
      }
    }
  }
`;

export const ContentLeft = styled.div`
  flex: 0 0 100%;
  max-width: 100%;

  @media (min-width: 1440px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
`

export const ContentRight = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  @media (min-width: 1440px) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
`
