import React, { memo } from "reactn"
import { useCheckPackage } from "src/hooks/useCheckPackage"
import { useMainContext } from "src/markup/Layout/MainLayout/MainContext"
import { CardCV } from "src/markup/Pages/SearchPage/Recruiter/components"
import { Heading, StyledCardCV, StyledCardCVComponent } from "./styles"

export const ListCardCV = memo(() => {
  const { listCV } = useMainContext()
  const { hasPackage } = useCheckPackage()

  return (
    <StyledCardCVComponent>
      <div className="d-flex justify-content-center">
        <Heading>Résultats de la recherche ({listCV?.length || 0})</Heading>
      </div>
      <hr className="v3-separator pb-xl-3" />

      {listCV.map(({ cv, cvId, score, jobId, otherJobId, id }) => {
        const otherData = {
          cvId,
          score,
          jobId,
          otherJobId,
          hasPackage
        }
        return (
          <StyledCardCV className="page-wraper" key={id}>
            <CardCV data={cv} otherData={otherData} />
          </StyledCardCV>
        )
      })}
    </StyledCardCVComponent>
  )
})
