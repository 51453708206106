import styled from "styled-components";
import { Modal } from "reactstrap";

export const Wrapper = styled(Modal)`
  .modal-content {
    background: unset;
    border: none;
  }
`;

export const Cancel = styled.div`
  position: absolute;
  z-index: 2;
  right: calc(0px + 35px);
  cursor: pointer;
  top: 35px;
}`
