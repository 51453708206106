import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 600px;
  margin: 0 auto;
  @media(max-width: 768px) {
    width: 100%;
  }
`

export const Heading = styled.h1`
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #ff7200;

  @media(max-width: 992px) {
    font-size: 30px;
  }
`

export const WrapperContent = styled.div`

  background: #FFFFFF;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  padding: 40px;
  width: 100%;

  .label {
    color: #023F73;
    font-weight: 800;
    font-size: 22px;
    line-height: 33px
  }


  @media (max-width: 992px) {
  

  }

  @media (max-width: 768px) {
    padding: 20px;
    .label {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
`

export const Title = styled.div`
  color: #023F73;
  font-weight: 800;
  font-size: 32px;
  line-height: 48px
`