import React, { useGlobal, useRef } from "reactn"
import { FormGroup } from "reactstrap"
import { filter } from "lodash"

import { MecCollapse, MecSelectorCustom } from "src/markup/Components/Forms"
import { MecButton } from "src/markup/Components/MecButton"
import { StyledForm } from "src/markup/Element/CVForm/styles"
import { genderize } from "src/utils"
import { CVFormInitialValues } from "../FormValidation"

import { MecRange } from "src/markup/Components"
import { InputLocation } from "src/markup/Pages/SearchPage/Recruiter/components/InputLocation"

const PositionLooking = ({ formik, handleCompleted, isOpen, handleOpenCollapse }) => {
  const positionLookingRef = useRef(null)
  const [config] = useGlobal("config")
  const [user] = useGlobal("user")
  const { gender } = user

  const xp = (() => genderize({ male: "xp_m", female: "xp_f", both: "xp", gender }))()

  const isCompleted = formik.values.joblistId && formik.values.search_exp && formik.values.pres_jobOtherName && formik.values.city && formik.values.city_range

  function scrollPositionLooking() {
    positionLookingRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  return (
    <MecCollapse
      title="Quel poste recherchez-vous ?"
      isOpen={isOpen === "positionLooking"}
      setIsOpen={() => handleOpenCollapse("positionLooking")}
      completed={isCompleted}
      name="isCompleted"
      className="mb-5"
    >
      <StyledForm>
        <div className="row" ref={positionLookingRef}>
          <FormGroup className="col-12 col-xl-6">
            <div className="py-2 label text-align-last-left label-required">Intitulé du poste</div>
            <MecSelectorCustom
              options={config?.jobs}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              className="text-align-last-left"
              name="joblistId"
              placeholder="Sélectionnez votre job"
              colorTxtError="red"
              onChange={(option) => {
                formik.setFieldValue("joblistId", option)
                formik.setFieldValue("pres_jobOtherName", CVFormInitialValues.pres_jobOtherName)
                formik.setFieldValue("mission", CVFormInitialValues.mission)
                formik.setFieldValue("skill", CVFormInitialValues.skill)
                formik.setFieldValue("tools", CVFormInitialValues.tools)
              }}
              onBlur={() => formik.setFieldTouched("joblistId", true)}
              value={formik.values["joblistId"]}
              errors={formik.errors["joblistId"]}
              touched={formik.touched["joblistId"]}
            />
          </FormGroup>
          <FormGroup className="col-12 col-xl-6">
            <div className="py-2 label text-align-last-left label-required">Mais encore ?</div>
            <MecSelectorCustom
              options={filter(config?.otherJobs, (job) => job.joblistId === formik.values.joblistId?.id)} // otherJobs
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              className="text-align-last-left"
              name="pres_jobOtherName"
              placeholder="Sélectionnez votre job"
              colorTxtError="red"
              onChange={(option) => {
                formik.setFieldValue("pres_jobOtherName", option)
              }}
              onBlur={() => formik.setFieldTouched("pres_jobOtherName", true)}
              value={formik.values["pres_jobOtherName"]}
              errors={formik.errors["pres_jobOtherName"]}
              touched={formik.touched["pres_jobOtherName"]}
            />
          </FormGroup>
        </div>
        <div className="row">
          <FormGroup className="col-12">
            <div className="py-2 label text-align-last-left label-required">Niveau d'expérience</div>
            <MecSelectorCustom
              options={config?.[xp]}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              className="text-align-last-left"
              name="search_exp"
              placeholder="Sélectionnez votre niveau d'expérience"
              colorTxtError="red"
              onChange={(option) => {
                formik.setFieldValue("search_exp", option)
              }}
              onBlur={() => formik.setFieldTouched("search_exp", true)}
              value={formik.values.search_exp}
              errors={formik.errors.search_exp}
              touched={formik.touched.search_exp || formik.touched.search_exp}
            />
          </FormGroup>
        </div>
        <div className="row">
          <FormGroup className="col-12">
            <div className="py-2 label text-align-last-left label-required">Dans quelle ville ?</div>
            <InputLocation
              placeholder={"Entrez le nom d'une ville"}
              formik={formik}
              name="city"
              className="secondary w-100"
              style={{ height: 45, minWidth: "auto" }}
            />
          </FormGroup>
        </div>
        <div className="row">
          <FormGroup className="col-12">
            <div className="py-2 label text-align-last-left label-required">Rayon autour de la ville</div>
            <MecRange
              onValueChanged={(option) => {
                formik.setFieldValue("city_range", option)
              }}
              name="city_range"
              value={formik.values.city_range}
              formik={formik}
              min={0}
              max={30}
            />
          </FormGroup>
        </div>
        <div className="w-100 d-flex justify-content-center">
          <MecButton
            icon=""
            type="primary"
            width={120}
            widthIcon={20}
            className="text-capitalize mt-2"
            onClick={(e) => {
              handleCompleted(e, "positionLooking")
              handleOpenCollapse("experiences")
              scrollPositionLooking()
            }}
            disabled={!isCompleted}
          >
            Valider
          </MecButton>
        </div>
      </StyledForm>
    </MecCollapse>
  )
}

export default PositionLooking
