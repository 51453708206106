import React, { useState, useEffect, useCallback, useRef, useGlobal } from "reactn"
import { FormGroup } from "reactstrap"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { Loading } from "src/markup/Element/Loading";
import { MecCollapse, MecInput } from "src/markup/Components/Forms"
import { MecButton } from "src/markup/Components/MecButton"
import { FacturationInitialValues, FacturationValidationSchema } from "../FormValidation"
import * as S from "./styles"
import { getProfileById, updateProfile } from "src/services/api"
import { vatFromSIRET } from "src/utils"

const initialValues = {
  name_company: null,
  vat_number: null,
  address: null,
  code_postal: null,
  city: null,
  country: null,
  telephone: null,
}

const FacturationCollapse = ({ order, handleOpenCollapse, isOpen }) => {
  const [formValues, setFormValues] = useState(initialValues)
  const facturationRef = useRef(null);
  const [user] = useGlobal('user');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation()

  const onSubmit = useCallback(async (values) => {
    try {
        setLoading(true);

        const formValues = {
          Enterprisename: values.name_company,
          vat_number: values.vat_number,
          address: values.address,
          postalcode: values.code_postal,
          city: values.city,
          country: values.country,
          phonenumber: values.telephone,
        }
        await updateProfile(formValues)
        toast.success(`Mise à jour du profil réussie.`);
        handleOpenCollapse("paiement")
        scrollPositionLooking()
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false)
      }
  }, [handleOpenCollapse])

  const formik = useFormik({
    initialValues: FacturationInitialValues(formValues),
    enableReinitialize: true,
    validationSchema: () => FacturationValidationSchema({ t }),
    onSubmit: (values) => onSubmit(values)
  })

  const { handleSubmit, isSubmitting, values } = formik

  const isCompleted =
    values.name_company &&
    values.address &&
    values.city &&
    values.vat_number &&
    values.code_postal &&
    values.country &&
    values.telephone

  const fetchBillingInfo = useCallback(async () => {
    try {
      setLoading(true);
      const profile = await getProfileById(user.id);

      const formValues = {
        name_company: profile.Enterprisename,
        vat_number: profile.vat_number || vatFromSIRET(profile.SIRET),
        address: profile.address,
        code_postal: profile.postalcode,
        city: profile.city,
        country: profile.country,
        telephone: profile.phonenumber,
      }
      setFormValues(formValues);
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false);
    }
  }, [user.id])

  useEffect(() => {
    fetchBillingInfo();
  }, [fetchBillingInfo])

  function scrollPositionLooking() {
    facturationRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  return (
    <MecCollapse
      title="Facturation"
      isOpen={isOpen === 'facturation'}
      setIsOpen={() => handleOpenCollapse("facturation")}
      completed={isCompleted}
      name="isCompleted"
      className="mb-5"
    >
      <S.WrapperFacturation onSubmit={handleSubmit}>
        {loading && <Loading />}
        <div className="row">
          <div
            className="col-12 text-align-last-left mb-4"
            style={{ fontWeight: 800, fontSize: "20px", lineHeight: "30px", color: "#023f73" }}
          >
            ADRESSE DE FACTURATION
          </div>

          <FormGroup className="col-12 col-xl-6">
            <div className="py-2 label text-align-last-left label-required">Nom de la société</div>
            <MecInput
              name="name_company"
              placeholder=""
              formik={formik}
              colorTxtError="red"
              className="secondary text-align-last-left"
            />
          </FormGroup>
          <FormGroup className="col-12 col-xl-6">
            <div className="py-2 label text-align-last-left label-required">Numéro TVA Intracommunautaire</div>
            <MecInput
              name="vat_number"
              placeholder=""
              formik={formik}
              colorTxtError="red"
              className="secondary text-align-last-left"
            />
          </FormGroup>
          <FormGroup className="col-12 col-xl-6">
            <div className="py-2 label text-align-last-left label-required">Adresse</div>
            <MecInput
              name="address"
              placeholder=""
              formik={formik}
              colorTxtError="red"
              className="secondary text-align-last-left"
            />
          </FormGroup>
          <FormGroup className="col-12 col-xl-6">
            <div className="py-2 label text-align-last-left label-required">Code postal</div>
            <MecInput
              name="code_postal"
              placeholder=""
              formik={formik}
              colorTxtError="red"
              className="secondary text-align-last-left"
            />
          </FormGroup>
          <FormGroup className="col-12 col-xl-6">
            <div className="py-2 label text-align-last-left label-required">Ville</div>
            <MecInput
              name="city"
              placeholder=""
              formik={formik}
              colorTxtError="red"
              className="secondary text-align-last-left"
            />
          </FormGroup>
          <FormGroup className="col-12 col-xl-6">
            <div className="py-2 label text-align-last-left label-required">Pays</div>
            <MecInput
              name="country"
              placeholder=""
              formik={formik}
              colorTxtError="red"
              className="secondary text-align-last-left"
            />
          </FormGroup>
          <FormGroup className="col-12">
            <div className="py-2 label text-align-last-left">Téléphone</div>
            <MecInput
              name="telephone"
              placeholder=""
              formik={formik}
              colorTxtError="red"
              className="secondary text-align-last-left"
            />
          </FormGroup>
        </div>
        <div className="w-100 d-flex justify-content-center">
          <MecButton
            icon=""
            type="primary"
            width={120}
            widthIcon={20}
            className="text-capitalize mt-2"
            disabled={!isCompleted}
          >
            {isSubmitting ? "..." : "Valider"}
          </MecButton>
        </div>
      </S.WrapperFacturation>
    </MecCollapse>
  )
}

export default FacturationCollapse
