import { useCallback, useEffect, useState } from "react"
import { getLiaisons } from "src/services/api"
import { isCandidate } from "src/services/auth"

export const useGetJobLiaisons = () => {
  const [loading, setLoading] = useState(false)
  const [liaisonsOfferList, setLiaisonsOfferList] = useState([])
  const [force, setForce] = useState(null);
  const CANDIDATE = isCandidate()

  const getAsyncLiaisonsOfferList = useCallback(async () => {
    try {
      setLoading(true)
      const { offersIdList } = await getLiaisons()
      setLiaisonsOfferList(offersIdList)
    } finally {
      setLoading(false)
    }
  }, [])

  const setAsyncLiaisonsOfferList = (offerId) => {
    setLiaisonsOfferList((prev) => {
      setLiaisonsOfferList([...prev, offerId])
    })
  }

  useEffect(() => {
    if (CANDIDATE) {
      getAsyncLiaisonsOfferList()
    }
  }, [force, getAsyncLiaisonsOfferList, CANDIDATE])

  return {
    setForce,
    liaisonsOfferList,
    setAsyncLiaisonsOfferList,
    loadingGetListOfferJoined: loading,
  }
}
