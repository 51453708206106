import React from 'react'
import { MainLayout } from 'src/markup/Layout/MainLayout'

import {
  CandidateUpdatePassword,
  RecruiterUpdatePassword,
} from 'src/markup/Pages/Profile/UpdatePassword'

import {
  CandidateInformation,
  RecruiterInformation,
} from 'src/markup/Pages/Profile/Information'

import { PROFILE } from 'src/constants'

const childRecruiter = {
  [PROFILE.UPDATE_PASSWORD]: RecruiterUpdatePassword,
  [PROFILE.INFORMATION]: RecruiterInformation,
}

const childCandidate = {
  [PROFILE.UPDATE_PASSWORD]: CandidateUpdatePassword,
  [PROFILE.INFORMATION]: CandidateInformation,
}

const Profile = (props) => {
  const { recruiter, site } = props
  const Component = recruiter ? childRecruiter[site] : childCandidate[site]

  return (
    <MainLayout {...props}>
      <Component />
    </MainLayout>
  )
}

export default Profile
