import ExpertIcon from "../../../../images/icons/expert.svg";
import VisibilityIcon from "../../../../images/icons/visibility.svg";
import PersonalMonitorIcon from "../../../../images/icons/personal-monitor.svg";
import { memo } from "react";
import "./index.scoped.scss";

const features = [
  {
    id: "expert",
    title: "Des experts du secteur",
    icon: ExpertIcon,
    description: "Des professionnels spécialisés qui comprennent vos besoins.",
  },
  {
    id: "visibility",
    title: "Une visibilité maximale",
    icon: VisibilityIcon,
    description: "Multi-diffusion de vos offres sur 35 JobBoards (Indeed, Monster, Figaro Emploi etc.)",
  },
  {
    id: "monitoring",
    title: "Un suivi personnalisé",
    icon: PersonalMonitorIcon,
    description: "Entretiens structurés et garantie de remplacement pendant la période d’essai.",
  },
];

const FeatureCard = memo(({ feature }) => (
  <div className="feature-card">
    <img src={feature.icon} alt={feature.title} />
    <p className="feature-title">{feature.title}</p>
    <p className="feature-description">{feature.description}</p>
  </div>
));

function WhyChooseUs() {
  return (
    <section className="why-choose-us">
      <div className="title">
        <h3>POURQUOI CHOISIR MECANOJOB ?</h3>
      </div>
      <div className="feature-content">
        {features.map((feature) => (
          <FeatureCard key={feature.id} feature={feature} />
        ))}
      </div>
    </section>
  );
}

export default memo(WhyChooseUs);
