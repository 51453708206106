import React from "reactn";

import {
  ListPartnerWrapper,
  PartnerComponentWrapper,
  PartnerWrapper,
} from "./styles";
import LogoOovoomDriver from "src/images/logo-oovoom-driver.png";
import LogoGlass from "src/images/logo-glass.png";
import LogoBeranget from "src/images/logo-beranget.png";
import LogoJump from "src/images/logo-jump.png";
import LogoVulco from "src/images/logo-vulco.png";
import LogoMec from "src/images/logo-mec.jpg";
import LogoPointS from "src/images/logo-points.png";
import LogoRenault from "src/images/logo-renault.png";

const listPartners = [
  {
    image: LogoOovoomDriver,
    name: "Oovoom",
    href: "https://oovoom.com"
  },
  {
    image: LogoGlass,
    name: "A+Glass",
    href: "https://www.aplusglass.com",
  },
  {
    image: LogoBeranget,
    name: "Beranget",
  },
  {
    image: LogoJump,
    name: "JumboPneus",
    href: "https://www.jumbopneus.fr",
  },
  {
    image: LogoVulco,
    name: "Vulco",
    href: "https://www.vulco.fr",
  },
  {
    image: LogoRenault,
    name: "Renault",
    href: "https://www.vulco.fr",
  },
  {
    image: LogoPointS,
    name: "PointS",
    href: "https://www.vulco.fr",
  },
  {
    image: LogoMec,
    name: "Mec",
    href: "https://www.vulco.fr",
  }
];

const MecPartnerComponent = () => {
  return (
    <PartnerComponentWrapper className="container content-inner-2">
      <div className="section-head text-center text-uppercase">
        <h3 className="text-orange">Nos entreprises partenaires</h3>
        <hr className="v3-separator pb-xl-3" />
      </div>

      <ListPartnerWrapper>
        <PartnerWrapper className="row">
          <div className="col-12">
            {listPartners.map((partner, index) => (
              <a target={"_blank"} rel="noreferrer" href={partner.href} key={index}>
                <img 
                  src={partner.image} 
                  alt={`logo-${partner.name}`}
                  style={{
                    width: '150px',
                    height: '80px',
                    objectFit: 'contain',
                    margin: '10px'
                  }}
                />
              </a>
            ))}
          </div>
          <div className="col-12">
            {listPartners.map((partner, index) => (
              <a target={"_blank"} rel="noreferrer" href={partner.href} key={index}>
                <img 
                  src={partner.image} 
                  alt={`logo-${partner.name}`}
                  style={{
                    width: '150px',
                    height: '80px',
                    objectFit: 'contain',
                    margin: '10px'
                  }}
                />
              </a>
            ))}
          </div>
        </PartnerWrapper>
      </ListPartnerWrapper>
    </PartnerComponentWrapper>
  );
};

export default MecPartnerComponent;
