import React from 'reactn'
import { FormGroup } from "reactstrap"
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { registerSchema } from 'src/markup/Pages/AuthPage/validationSchema'
import { registerUser } from 'src/services/auth'

import { MecCheckBox, MecInput } from 'src/markup/Components/Forms'
import * as S from 'src/markup/Pages/AuthPage/Register/Candidate/styles'

import tickIcon from 'src/images/gallery/tick-icon.png'

export const CandidateRegister = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      surname: '',
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      policy: false,
      role: 'candidate',
    },
    enableReinitialize: true,
    validationSchema: () => registerSchema({ t }),
    onSubmit: async (values) => {
      const user = await registerUser(values)
      if (user) {
        toast.success(`L'inscription a été effectuée avec succès`, {
          autoClose: 2000,
          theme: 'colored',
        })
        history.push('/candidate/login')
      }
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <S.Heading>
        <span>INSCRIPTION</span>
      </S.Heading>
      <FormGroup>
        <MecInput
          name="surname"
          placeholder="Prénom"
          formik={formik}
          colorTxtError="var(--orange300)"
          userType="candidate"
        />
      </FormGroup>
      <FormGroup>
        <MecInput
          name="name"
          placeholder="Nom"
          formik={formik}
          colorTxtError="var(--orange300)"
          userType="candidate"
        />
      </FormGroup>
      <FormGroup>
        <MecInput
          name="email"
          placeholder="E-mail"
          formik={formik}
          colorTxtError="var(--orange300)"
          userType="candidate"
        />
      </FormGroup>
      <FormGroup>
        <MecInput
          type="password"
          name="password"
          placeholder="Mot de passe"
          formik={formik}
          colorTxtError="var(--orange300)"
          userType="candidate"
        />
      </FormGroup>
      <FormGroup className="pb-3">
        <MecInput
          type="password"
          name="confirmPassword"
          placeholder="Confirmer le mot de passe"
          formik={formik}
          colorTxtError="var(--orange300)"
          userType="candidate"
        />
      </FormGroup>

      <FormGroup className="d-flex flex-wrap">
        <MecCheckBox>Je souhaite recevoir les actualités de MecanoJob par mail</MecCheckBox>
        <MecCheckBox
          onChange={() => formik.setFieldValue('policy', !formik.values.policy)}
          errors={formik.errors.policy}
          colorCheckboxError="var(--orange300)">
          J'ai lu et j'accepte les{" "}
          <Link to="/static/cgu" className="underline">
            CGU
          </Link>{" "}
            et la{" "}
          <Link to="/static/rgpd" className="underline">
            politique de protection{" "}
          </Link>
          *
        </MecCheckBox>
      </FormGroup>

      <div className="mx-auto">
        <S.Button>
          <div className="d-flex justify-content-center">
            <div className="pr-2">
              <img src={tickIcon} alt="tick-icon" />
            </div>
            <div>Valider</div>
          </div>
        </S.Button>
      </div>

      <Link to="/candidate/login">
        <S.NormalText className="text-bottom">
          Déjà inscrit ?{' '}
          <span className="underline">Connectez-vous</span>
        </S.NormalText>
      </Link>
    </form>
  )
}
