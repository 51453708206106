/* eslint-disable no-useless-concat */
import React, { useGlobal, useRef } from "reactn"
import { FormGroup } from "reactstrap"
import { isEmpty, includes, filter } from "lodash"

import { MecCollapse, MecSelectorCustom, MecTabSwitch } from "src/markup/Components/Forms"
import { StyledForm, Minus, StyledLabel } from "src/markup/Element/CVForm/styles"
import { ReactComponent as IconPlush } from "src/images/icons/icon-plus.svg"
import { MecButton } from "src/markup/Components"
import { components } from "react-select"
import { ReactComponent as IconMinus } from "src/images/icons/icon-minus.svg"
import { genderize } from "src/utils"
import { LEVELS } from "src/constants"

const Menu = (props) => {
  const optionSelectedLength = props.getValue().length || 0
  return (
    <components.Menu {...props}>
      {optionSelectedLength < 3 ? props.children : <div style={{ margin: 15 }}>Nombre de choix maximum atteint.</div>}
    </components.Menu>
  )
}

const Profile = ({
  formik,
  handleAddMission,
  handleAddSkill,
  handleAddTools,
  handleCompleted,
  completed,
  isOpen,
  handleOpenCollapse,
  handleDeleteItem,
}) => {
  const profileRef = useRef(null)
  const [config] = useGlobal("config")
  const [user] = useGlobal("user")
  const {gender} = user;
  const qualityMain = (() => genderize({ male: "qualityMain_m", female: "qualityMain_f", both: "qualityMain", gender }))()
  const quality3 = (() => genderize({ male: "quality3_m", female: "quality3_f", both: "quality3", gender }))()

  function scrollProfile() {
    profileRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  const { values } = formik
  const { search_qualityMain, search_quality2, search_quality3, mission, skill, tools, languages } = values
  const missionSuccess = mission.map(item => item.search_mission === null || item.search_specMission.length === 0)
  const skillSuccess = skill.map(item => item.search_skill === null || item.search_skillComp.length === 0)
  const toolsSuccess = tools.map(item => item.name === '')

  const isCompleted =
  search_qualityMain.length > 0 && !isEmpty(search_quality2) && !isEmpty(search_quality3)
  && !includes(missionSuccess, true) && !includes(skillSuccess, true) && !includes(toolsSuccess, true)
  && languages.length > 0

  return (
    <MecCollapse
      title="Quel est votre profil ?"
      isOpen={isOpen === "profile"}
      setIsOpen={() => handleOpenCollapse("profile")}
      completed={!!isCompleted}
      name="isCompleted"
      className="mb-5"
    >
      <StyledForm>
        <div className="row" ref={profileRef}>
          <div className="col-12 sub-title text-align-last-left">QUALITÉS</div>
          <FormGroup className="col-12">
            <div className="py-2 label text-align-last-left">Quelles sont vos principales qualités ?</div>
            <MecSelectorCustom
              components={{ Menu }}
              options={config?.[qualityMain]} // qualityMain
              getOptionLabel={({ name }) => name}
              getOptionValue={({ qualityMainId }) => qualityMainId}
              className="text-align-last-left"
              name="search_qualityMain" // Multiple Select
              placeholder="Quelles sont les 3 qualités qui vous caractérisent le mieux ?"
              colorTxtError="red"
              isMulti
              onChange={(option) => {
                formik.setFieldValue("search_qualityMain", option)
              }}
              value={formik.values.search_qualityMain}
            />
          </FormGroup>
        </div>

        <div className="row">
          <FormGroup className="col-12 col-xl-6">
            <div className="py-2 label text-align-last-left">Vous avez aussi...</div>
            <MecSelectorCustom
              options={config?.quality2} // quality2
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              className="text-align-last-left"
              name="search_quality2"
              placeholder="Sélectionnez..."
              colorTxtError="red"
              onChange={(option) => {
                formik.setFieldValue("search_quality2", option)
              }}
              value={formik.values.search_quality2}
            />
          </FormGroup>
          <FormGroup className="col-12 col-xl-6">
            <div className="py-2 label text-align-last-left">Vous êtes également...</div>
            <MecSelectorCustom
              options={config?.[quality3]} //quality3_f
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              className="text-align-last-left"
              name="search_quality3"
              placeholder="Sélectionnez..."
              colorTxtError="red"
              onChange={(option) => {
                formik.setFieldValue("search_quality3", option)
              }}
              value={formik.values.search_quality3}
            />
          </FormGroup>
        </div>

        <div className="col-12 sub-title text-align-last-left">COMPÉTENCES ET SAVOIRS-FAIRE</div>
        {formik.values.mission.map((mission, index) => (
          <div key={mission.id}>
            <div className="row">
              <FormGroup className="col-12">
                <div className="py-2 label text-align-last-left">Vous savez...</div>
                <MecSelectorCustom
                  options={filter(config?.missions, (mission) => mission.joblistId === values.joblistId?.id)} // missions
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                  className="text-align-last-left"
                  name="search_mission"
                  placeholder="Sélectionnez..."
                  colorTxtError="red"
                  onChange={(option) => {
                    formik.setFieldValue(`${"mission" + "." + index + "." + "search_mission"}`, option)
                  }}
                  value={formik.values.mission?.[index]?.search_mission}
                />
              </FormGroup>
              <FormGroup className="col-12">
                <div className="py-2 label text-align-last-left">Et plus particulièrement...</div>
                <MecSelectorCustom
                  options={filter(config?.missionSpecs, (missionSpec) => missionSpec.joblistId === values.joblistId?.id)} //missionSpecs
                  formik={formik}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                  className="text-align-last-left"
                  name="search_specMission" // Multiple selection
                  isMulti
                  colorTxtError="red"
                  placeholder="Sélectionnez..."
                  onChange={(option) => {
                    formik.setFieldValue(`${"mission" + "." + index + "." + "search_specMission"}`, option)
                  }}
                  value={formik.values.mission?.[index]?.search_specMission}
                />
              </FormGroup>
            </div>
            {index ? (
              <Minus onClick={() => handleDeleteItem("mission", mission.id)}>
                <IconMinus style={{ cursor: "pointer", marginRight: "15px" }} width={30} height={30} />
                Supprimer un savoir-faire
              </Minus>
            ) : null}
            {formik.values?.mission?.length > 1 && <hr className="v3-separator pb-xl-3" style={{ maxWidth: "100%" }}></hr>}
          </div>
        ))}

        <div className="row">
          <FormGroup className="col-12 row d-flex justify-content-end add-experience" onClick={handleAddMission}>
            <IconPlush />
            <StyledLabel className="py-2">Ajouter un savoir-faire</StyledLabel>
          </FormGroup>
        </div>

        {formik.values.skill.map((skill, index) => (
          <div key={skill.id}>
            <div className="row">
              <FormGroup className="col-12">
                <div className="py-2 label text-align-last-left">Vous maîtrisez...</div>
                <MecSelectorCustom
                  options={filter(config?.skills, (skill) => skill.joblistId === values.joblistId?.id)} // skills
                  formik={formik}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                  className="text-align-last-left"
                  name="search_skill"
                  placeholder="Sélectionnez..."
                  colorTxtError="red"
                  onChange={(option) => {
                    formik.setFieldValue(`${"skill" + "." + index + "." + "search_skill"}`, {
                      joblistId: option.joblistId,
                      id: option.id,
                      name: option.name,
                    })
                  }}
                  value={formik.values.skill?.[index]?.search_skill}
                />
              </FormGroup>
              <FormGroup className="col-12">
                <div className="py-2 label text-align-last-left">Et plus particulièrement...</div>
                <MecSelectorCustom
                  options={filter(config?.skillsComp, (skillsComp) => skillsComp.joblistId === values.joblistId?.id)} // skillsComp
                  formik={formik}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                  className="text-align-last-left"
                  name="search_skillComp" // Multiple selection
                  isMulti
                  colorTxtError="red"
                  placeholder="Sélectionnez..."
                  onChange={(option) => {
                    formik.setFieldValue(`${"skill" + "." + index + "." + "search_skillComp"}`, option)
                  }}
                  value={formik.values.skill?.[index]?.search_skillComp}
                />
              </FormGroup>
            </div>
            {index ? (
              <Minus onClick={() => handleDeleteItem("skill", skill.id)}>
                <IconMinus style={{ cursor: "pointer", marginRight: "15px" }} width={30} height={30} />
                Supprimer une compétence
              </Minus>
            ) : null}
            {formik.values?.skill?.length > 1 && <hr className="v3-separator pb-xl-3" style={{ maxWidth: "100%" }}></hr>}
          </div>
        ))}

        <div className="row">
          <FormGroup className="col-12 row d-flex justify-content-end add-experience" onClick={handleAddSkill}>
            <IconPlush />
            <StyledLabel className="py-2">Ajouter une compétence</StyledLabel>
          </FormGroup>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="py-2 label text-align-last-left">Quels outils maîtrisez-vous ?</div>
            {formik.values?.tools.map((tool, index) => (
              <div key={tool.id}>
                <FormGroup className="d-flex flex-wrap mb-4">
                  <div className="col-12 col-xl-6 mb-4 mb-xl-0 p-0 pr-xl-4">
                    <MecSelectorCustom
                      options={filter(config?.tools, (tool) => tool.joblistId === values.joblistId?.id)} // tools
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      formik={formik}
                      className="text-align-last-left"toolsValue
                      name="id"
                      colorTxtError="red"
                      placeholder="Sélectionnez..."
                      onChange={(option) => {
                        formik.setFieldValue(`${"tools" + "." + index}`, {
                          ...formik.values.tools[index],
                          id: option.id,
                          name: option.name,
                        })
                      }}
                      value={formik.values.tools?.[index]}
                    />
                  </div>
                  <MecTabSwitch
                    tabs={LEVELS}
                    activeTab={tool.level - 1}
                    onChange={(e) => {
                      formik.setFieldValue(`${"tools" + "." + index + "." + "level"}`, e + 1)
                    }}
                    className="col-12 col-xl-6"
                    name="tools"
                  />
                </FormGroup>
                {index ? (
                  <Minus onClick={() => handleDeleteItem("tools", tool.keyId || tool.id)} className="mb-4" style={{ transform: "translate(-220px, 0px)" }}>
                    <IconMinus style={{ cursor: "pointer", marginRight: "15px" }} width={30} height={30} />
                    Supprimer un outil
                  </Minus>
                ) : null}
              </div>
            ))}
          </div>
        </div>

        <FormGroup className="d-flex justify-content-end add-experience" onClick={handleAddTools}>
          <IconPlush />
          <StyledLabel className="py-2">Ajouter un outil</StyledLabel>
        </FormGroup>

        <div className="row">
          <FormGroup className="col-12">
            <div className="py-2 label text-align-last-left">Langue(s)</div>
            <MecSelectorCustom
              options={config?.languages} //languages
              formik={formik}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              className="text-align-last-left"
              name="languages" // Multiple selection
              isMulti
              placeholder="Quelle(s) langue(s) parlez-vous ?"
              onChange={(option) => {
                formik.setFieldValue("languages", option)
              }}
              value={formik.values.languages}
            />
          </FormGroup>
        </div>

        <div className="w-100 d-flex justify-content-center">
          <MecButton
            icon=""
            type="primary"
            width={120}
            widthIcon={20}
            className="text-capitalize mt-2"
            onClick={(e) => {
              handleCompleted(e, "profile")
              handleOpenCollapse("additionalInformation")
              scrollProfile()
            }}
          >
            Valider
          </MecButton>
        </div>
      </StyledForm>
    </MecCollapse>
  )
}

export default Profile
