import React from "react";
import * as S from "./styles";
import PropTypes from "prop-types";

export const MecButton = (props) => {
  const {
    children,
    icon,
    type,
    isSubmit,
    className,
    style,
    isBg,
    bgIcon,
    borderColor,
    radius,
    textColor,
    minWidth,
    width,
    height,
    widthIcon,
    heightIcon,
    onClick,
    disabled,
    isAuth,
    iconAuth,
  } = props;

  const Icon = icon;
  const IconAuth = iconAuth

  return (
    <S.Wrapper
      className="mec-button"
      style={style}
      minWidth={minWidth}
      width={width}
      height={height}
    >
      <S.Button
        type={isSubmit && "submit"}
        isBg={isBg}
        radius={radius}
        borderColor={borderColor}
        textColor={textColor}
        className={`${className} flex-center font-weight-bold site-button ${type}`}
        onClick={onClick}
        isDisabled={disabled}
      >
        {icon && !isAuth && (
          <S.WrapperImage
            widthIcon={widthIcon}
            heightIcon={heightIcon}
            bgIcon={bgIcon}
          >
            <Icon />
          </S.WrapperImage>
        )}
        <div>{children}</div>
        {iconAuth && isAuth && (
          <S.WrapperImage
            widthIcon={widthIcon}
            heightIcon={heightIcon}
            bgIcon={bgIcon}
            className='ml-2'
          >
            <IconAuth />
          </S.WrapperImage>
        )}
      </S.Button>
    </S.Wrapper>
  );
};

MecButton.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  isBg: PropTypes.bool,
  bgIcon: PropTypes.string,
  borderColor: PropTypes.string,
  textColor: PropTypes.string || PropTypes.string,
  minWidth: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  widthIcon: PropTypes.number,
  heightIcon: PropTypes.number,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

MecButton.defaultProps = {
  type: "primary",
};
