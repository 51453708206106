import React from 'reactn'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { MecInput } from 'src/markup/Components/Forms'
import { ProfileButton } from 'src/markup/Pages/Profile/components/ProfileButton'
import { onUpdateProfile, useGetProfile } from '../useGetProfile'
import * as S from './styles'
import { validationProfileRecruiter } from './validationSchema'

export const RecruiterInformation = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const profile = useGetProfile()

  const {
    surname,
    name,
    field_function,
    email,
    phonenumber,
    telephone_portable,
    SIRET,
    Enterprisename,
    enterprisephonenumber,
    enterpriseweb,
    address,
    postalcode,
    city,
    country,
  } = profile || {}

  const initialValues = {
    surname,
    name,
    field_function,
    email,
    phonenumber,
    telephone_portable,
    SIRET,
    Enterprisename,
    enterprisephonenumber,
    enterpriseweb,
    address,
    postalcode,
    city,
    country,
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: () => validationProfileRecruiter({ t }),
    onSubmit: (values) => onUpdateProfile(values)
  })

  const { isSubmitting } = formik

  return (
    <S.Wrapper>
      <div className="d-flex justify-content-center">
        <S.Heading>Mon profil recruteur</S.Heading>
      </div>
      <hr className="v3-separator pb-xl-3" />

      <S.WrapperContent>
        <form>
          <S.Title>Présentez-vous</S.Title>
          <div className="row justify-content-between col-12">
            {[
              { label: 'Prénom', name: 'surname', required: true },
              { label: 'Nom', name: 'name', required: true },
              { label: 'Fonction', name: 'field_function', required: true },
              { label: 'Adresse e-mail', name: 'email', required: true, disabled: true },
              { label: 'Téléphone', name: 'phonenumber', required: true },
              {
                label: 'Téléphone portable',
                name: 'telephone_portable',
                required: false,
              },
            ].map((item, idx) => (
              <div key={idx} className="col-lg-6 col-12">
                <div className="py-2 label">
                  {item.label}
                  {item.required && <S.Asterisk>*</S.Asterisk>}
                </div>
                <MecInput
                  name={item.name}
                  formik={formik}
                  className={'secondary'}
                  disabled={item.disabled}
                />
              </div>
            ))}
          </div>

          <S.Title className='mt-5'>Entreprise</S.Title>
          <div className="row justify-content-between col-12">
            {[
              { label: 'SIRET', name: 'SIRET', required: true },
              {
                label: 'Nom de la société',
                name: 'Enterprisename',
                required: true,
              },
              {
                label: 'Téléphone de l\'entreprise',
                name: 'enterprisephonenumber',
                required: false,
              },
              {
                label: 'Site web',
                name: 'enterpriseweb',
                required: false,
                placeholder: 'https://exemple.com',
              },
              { label: 'Adresse', name: 'address', required: true },
              { label: 'Code postal', name: 'postalcode', required: true },
              { label: 'Ville', name: 'city', required: true },
              { label: 'Pays', name: 'country', required: true },
            ].map((item, idx) => (
              <div key={idx} className="col-lg-6 col-12" >
                <div className="py-2 label">
                  {item.label}
                  {item.required && <S.Asterisk>*</S.Asterisk>}
                </div>
                <MecInput
                  placeholder={item.placeholder}
                  name={item.name}
                  formik={formik}
                  className="secondary"
                />
              </div>
            ))}
          </div>
        </form>
      </S.WrapperContent>
      <S.TextNote>* Champ obligatoire</S.TextNote>
      <ProfileButton
        className="offset-lg-7 offset-md-6 offset-0"
        onCancel={history.goBack}
        isSubmitting={isSubmitting}
        onSubmit={formik.handleSubmit}
      />
    </S.Wrapper>
  )
}
