import React, { Component } from "react";
// import {Link} from 'react-router-dom';
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";

class StaticCGU extends Component {
  render() {
    return (
      <div className="page-wraper">
        <Header showTitle={false} />
        <div className="page-content bg-light">
          <div className="col" style={{ textAlignLast: "center", marginBottom: "50px" }}>
            <div className="d-flex justify-content-center">
              <h1 className="m-b5 text-uppercase text-orange pt-5">Conditions générales d'utilisation</h1>
            </div>
            <hr className="v3-separator" />
            <div className="d-flex justify-content-center">
              <h2>En vigueur au 01/04/2021</h2>
            </div>
          </div>

          <div className="justify-content-left m-5">
            <div>
              <p>
                Les présentes conditions générales d'utilisation (dites <b>« CGU »</b>) ont pour objet l'encadrement juridique des modalités de mise à
                disposition du site et des services par la SAS 30 Mille Oovoom et de définir les conditions d'accès et d'utilisation des services par
                <b>« l'Utilisateur »</b>. Les présentes CGU sont accessibles sur le site à la rubrique <b>«CGU – Conditions générales d'utilisation»</b>.
              </p>

              <p>
                Toute inscription ou utilisation du site implique l'acceptation sans aucune réserve ni restriction des présentes CGU par l'utilisateur. Lors de
                l'inscription sur le site via le Formulaire d'inscription, chaque utilisateur accepte expressément les présentes CGU en cochant la case
                précédant le texte suivant : « Je reconnais avoir lu et compris les CGU et je les accepte ». En cas de non-acceptation des CGU stipulées dans le
                présent contrat, l'Utilisateur se doit de renoncer à l'accès des services proposés par le site. Le site mecanojob.com se réserve le droit de
                modifier unilatéralement et à tout moment le contenu des présentes CGU.
              </p>
            </div>

            <div>
              <h3 className="m-b5 text-uppercase text-orange">Article 1 : Les mentions légales</h3>
            </div>

            <div>
              <p>
                L'édition du site mecanojob.com est assurée par la Société SAS 30 Mille Oovoom au capital de 27.702 euros, immatriculée au RCS de Paris Bsous le
                numéro 88950002100015, dont le siège social est situé au 111 Avenue Victor Hugo - 75116 Paris – France
              </p>
              <p>
                <div>Numéro de téléphone : 01 80 82 44 44 </div>
                <div>
                  Adresse e-mail :{" "}
                  <a className="text-blue-link" href="mailto:contact@30mille.com">
                    contact@30mille.com
                  </a>
                  .{" "}
                </div>
                <div>La Directrice de la publication est : Bibas Sandra </div>
                <div>Numéro de TVA intracommunautaire : FR 05889500021</div>
              </p>

              <p>
                L'hébergeur du site mecanojob.com est la société AWS Amazon Web Services LLC, dont le siège social est situé au P.O. Box 81226, Seattle, WA
                98108-1226
              </p>
            </div>

            <div>
              <h3 className="m-b5 text-uppercase text-orange">ARTICLE 2 : Accès au site</h3>
            </div>

            <div>
              <div>Le site mecanojob.com permet à l'Utilisateur un accès gratuit ou payant aux services de recrutement : </div>

              <div>Le site internet propose les services suivants :</div>
              <div>Recrutement.</div>

              <div>
                <b>Espace candidats :</b> création des profils candidats et transferts des données aux recruteurs susceptibles d'être intéressés.
              </div>
              <div>
                <b>Espace professionnels :</b> création du profil recruteur, transmission des offres d'emploi aux candidats susceptibles d'y répondre.
              </div>
              <div className="mb-3">
                Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais supportés par l'Utilisateur pour
                accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge.
              </div>

              <div className="mb-5">
                L'Utilisateur non membre n'a pas accès aux services réservés. Pour cela, il doit s'inscrire en remplissant le formulaire. En acceptant de
                s'inscrire aux services réservés, l'Utilisateur membre s'engage à fournir des informations sincères et exactes concernant son état civil et ses
                coordonnées, notamment son adresse e-mail. Pour accéder aux services, l'Utilisateur doit ensuite s'identifier à l'aide de son identifiant et de
                son mot de passe qui lui seront communiqués après son inscription. Tout Utilisateur membre régulièrement inscrit pourra également solliciter sa
                désinscription en se rendant à la page dédiée sur son espace personnel. Celle-ci sera effective dans un délai raisonnable. Tout événement dû à
                un cas de force majeure ayant pour conséquence un dysfonctionnement du site ou serveur et sous réserve de toute interruption ou modification en
                cas de maintenance, n'engage pas la responsabilité de mecanojob.com. Dans ces cas, l'Utilisateur accepte ainsi ne pas tenir rigueur à l'éditeur
                de toute interruption ou suspension de service, même sans préavis. L'Utilisateur a la possibilité de contacter le site par messagerie
                électronique à l'adresse e-mail de l'éditeur communiqué à l'ARTICLE 1.
              </div>
            </div>

            <div>
              <h3 className="m-b5 text-uppercase text-orange">ARTICLE 3 : Collecte des données RGPD</h3>
            </div>

            <div>
              <div>
                Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément à la loi
                n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.
              </div>
              <div>
                Le site est déclaré à la CNIL sous le numéro 111111. En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles.
              </div>
              <div>
                L'Utilisateur exerce ce droit :
              </div>
              <div>
                · par mail à l'adresse e-mail {" "}
                <a className="text-blue-link" href="mailto:contact@30mille.com">
                  contact@30mille.com
                </a>,
              </div>
              <div className="mb-5">· via son espace personnel ;</div>
              <div className="mb-3">
                Conformément aux lois et règlements en vigueur relatives à la protection des données personnelles, Oovoom - MecanoJob recueille certaines
                informations ou données personnelles. Oovoom - MecanoJob garantie notamment le respect du Règlement (UE) 2016/679 du Parlement Européen et du
                Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre
                circulation de ces données ainsi que la loi n˚78-17 « Informatique et Libertés » du 6 janvier 1978.
              </div>
              <h5 className="mb-3 ml-4">(a) Données collectées</h5>
              <div className="mb-3">
                Lors de son inscription, les Candidats ainsi que les Recruteurs sont informés des données qui sont indispensables à Oovoom - MecanoJob pour
                réaliser le traitement et sont signalées par un astérisque.
              </div>
              <div className="mb-3">
                Les données collectées sur la Plateforme sont entre autres les suivantes : le nom, le prénom, l'adresse courriel, l'adresse, le code postal, le
                numéro de téléphone et le numéro d'immatriculation du véhicule.
              </div>
              <h5 className="mb-3 ml-4">(b) Utilisation des données collectées</h5>
              <div className="mb-3">
                Le traitement desdites données est indispensable à Oovoom - MecanoJob pour permettre aux Candidats et recruteurs(i) d'accéder à la Plateforme et
                de l'utiliser, (ii) d'accéder à leur Espace Personnel et (iii) de répondre à une offre d'emploi ou d'accéder à des profils. De plus, ces données
                nous permettent d'améliorer et développer la Plateforme.
              </div>
              <div className="mb-3">
                Le Candidat autorise Oovoom - MecanoJob à fournir toutes les informations requises que nous détenons le concernant aux recruteurs d'Oovoom -
                MecanoJob.
              </div>
              <div className="mb-3">
                Oovoom - MecanoJob informe par la présente le Candidat que les données à caractère personnel marquées comme étant obligatoires dans le
                formulaire d'inscription sont utilisées exclusivement dans le cadre des Services et sont destinées à Oovoom - MecanoJob et/ou ses
                sous-traitants, en plus des Recruteurs. Ces derniers prennent toutes les précautions nécessaires pour respecter et préserver la sécurité des
                données collectées.
              </div>
              <div className="mb-3">
                Les Candidats et les Recruteurs autorisent Oovoom - MecanoJob à fournir certaines informations à ses prestataires techniques afin de le faire
                bénéficier de certaines fonctions de la Plateforme (forum, avis, commentaires, ...)
              </div>
              <div className="mb-3">
                Oovoom - MecanoJob s'engage à ne pas transmettre les données personnelles qui lui sont confiées à des sociétés tierces à des fins de démarchage
                commercial.
              </div>
              <h5 className="mb-3 ml-4">(c) Durée de conservation des données collectées</h5>
              <div className="mb-3">
                Les données des Particuliers sont conservées pour la durée nécessaire à la réalisation des Services et conformément aux obligations légales et
                réglementaires.
              </div>
              <h5 className="mb-3 ml-4">(d) Droits des Particuliers</h5>
              <div className="mb-3">
                Le Particulier bénéficie, d'un droit d'accès, de rectification, de suppression et d'opposition à l'utilisation de ses données personnelles qu'il
                peut exercer en adressant une demande accompagnée d'une copie de sa carte d'identité à l'adresse courriel suivante :{" "}
                <a className="text-blue-link" href="mailto:contact@30mille.com">
                  contact@30mille.com
                </a>{" "}
                ou par format papier à l'adresse indiquée dans le Préambule.
              </div>
              <div className="mb-3">
                Si malgré les efforts employés par Oovoom pour préserver la confidentialité de vos données personnelles, vous estimez que vos droits ne sont pas
                respectés, nous vous rappelons que vous disposez de la possibilité de saisir votre autorité nationale de contrôle en matière de traitement de
                données (en France : https://www.cnil.fr/fr/plaintes).
              </div>
              <h5 className="mb-3 ml-4">(e) Traitement des publications des Candidats</h5>
              <div className="mb-3">
                Oovoom - MecanoJob se réserve le droit de supprimer ou modifier le contenu publié par les Candidats sur la Plateforme, sans préavis, en
                particulier les notes ou profils qui resteraient non utilisés pendant plus de 3 mois. Le Candidar reconnait et accepte que Oovoom - MecanoJob
                n'est pas dans l'obligation de sauvegarder les contenus publiés ni d'en fournir une copie, et Oovoom - MecanoJob ne se porte nullement garant de
                la confidentialité desdits contenus.
              </div>
              <div className="mb-3">
                Tout le contenu publié par le Candidat sur la Plateforme, quelle que soit sa nature, demeure sous l'entière responsabilité du Candidat.
              </div>
              <div className="mb-3">
                Le Candidat s'engage à concéder gratuitement et de façon exclusive et irrévocable, le droit à Oovoom - MecanoJob d'utiliser lesdits contenus
                sans restriction spatio-temporelle, de les diffuser publiquement sur la Plateforme ou sur tout autre support en ligne et hors ligne, de les
                conserver en bases de données, de les reproduire et les mettre à disposition du public, de les traduire dans d'autres langues, et de les
                exploiter à des fins de marketing. Le Candidat renonce définitivement à toute revendication relative aux contenus concédés par ses soins.
              </div>
              <div className="mb-3">
                Le Candidat reconnait et garantit que l'ensemble des contenus publiés sur la Plateforme sont libres de droits qui en restreindraient
                l'utilisation par Oovoom - MecanoJob.
              </div>
              <h5 className="mb-3 ml-4">(f) Avertissement</h5>
              <div className="mb-3">
                Le candidat reconnaît et accepte expressément que, dès lors qu'il fournit des informations personnelles sur internet, ces informations peuvent
                être collectées et utilisées par des tiers.
              </div>
              <h5 className="mb-3 ml-4">(g) Collecte et utilisation des informations non personnelles</h5>
              <div className="mb-3">
                À l'instar de nombreuses organisations commerciales, Oovoom - MecanoJob surveille l'utilisation de cette Plateforme en recueillant des
                informations globales. Oovoom - MecanoJob pourrait recueillir automatiquement des informations non personnelles vous concernant, telles que le
                type de navigateur internet que vous utilisez, les pages que vous visitez ou le site web qui vous a dirigé vers notre Plateforme. Ces
                informations ne permettent pas de vous identifier et ne sont utilisées que pour nous aider à fournir un service efficace sur cette Plateforme.
              </div>
              <h5 className="mb-3 ml-4">(h) Utilisation des Cookies</h5>
              <div className="mb-3">
                Oovoom reçoit et enregistre des informations de votre navigateur lorsque vous utilisez la Plateforme et utilise des « Cookies » pour collecter
                ces informations. Les « Cookies » sont de petits fichiers de données amovibles, ne contenant aucune information personnelle, qui sont stockés
                par votre navigateur internet. Ils vous permettent de transporter des informations sur notre Plateforme sans avoir à les saisir à nouveau. Ils
                nous permettent d'analyser le trafic sur le web et d'améliorer nos services en ligne. Ils ne peuvent pas être utilisés pour vous identifier.
                Vous pouvez configurer votre navigateur web pour qu'il vous informe des demandes de placement de « Cookies » ou pour qu'il refuse complètement
                les « Cookies ». Vous pouvez également supprimer les fichiers qui contiennent des cookies.
              </div>
              <div className="mb-3">En cas de refus des « Cookies », Oovoom - MecanoJob ne garantit pas le fonctionnement optimal de la Plateforme.</div>
              <div className="mb-3">
                Le Candidat et recruteur reconnaissent et acceptent qu'Oovoom se réserve la possibilité d'implanter un « Cookie » dans son ordinateur ou son
                téléphone mobile afin d'enregistrer toute information relative à la navigation sur la Plateforme.
              </div>
              <div className="mb-3">
                Si vous ne souhaitez pas qu'un « Cookie », soit implanté dans votre ordinateur ou votre téléphone mobile, voici la procédure à suivre en
                fonction du navigateur internet :
              </div>
              <div className="ml-4">
                (i) Pour Internet Explorer, suivez la procédure décrite dans le lien suivant «
                <a
                  className="text-blue-link"
                  href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies
                </a>
                »,
              </div>
              <div className="ml-4">
                (ii) Pour Firefox, suivez la procédure décrite dans le lien suivant «
                <a
                  className="text-blue-link"
                  href="https://support.mozilla.org/fr/kb/protection-renforcee-contre-pistage-firefox-ordinateur?redirectslug=Activer+et+d%C3%A9sactiver+les+cookies&redirectlocale=fr"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://support.mozilla.org/fr/kb/protection-renforcee-contre-pistage-firefox-ordinateur?redirectslug=Activer+et+d%C3%A9sactiver+les+cookies&redirectlocale=fr
                </a>
                »,
              </div>
              <div className="ml-4">
                (iii) Pour Google Chrome, suivez la procédure décrite dans le lien suivant «
                <a className="text-blue-link" href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en" target="_blank" rel="noreferrer">
                  https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en
                </a>
                »,
              </div>
              <div className="ml-4 mb-5">
                (iv) Pour Safari, suivez la procédure décrite dans le lien suivant «
                <a
                  className="text-blue-link"
                  href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                </a>
                »,
              </div>
            </div>

            <h3 className="m-b5 text-uppercase text-orange">ARTICLE 4 : Propriété intellectuelle</h3>
            <div>
              Les sources des informations diffusées sur le site mecanojob.com sont réputées fiables mais le site ne garantit pas qu'il soit exempt de défauts,
              d'erreurs ou d'omissions.
            </div>
            <div>
              Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré des mises à jour régulières, le site
              mecanojob.com ne peut être tenu responsable de la modification des dispositions administratives et juridiques survenant après la publication. De
              même, le site ne peut être tenue responsable de l'utilisation et de l'interprétation de l'information contenue dans ce site.
            </div>
            <div>
              L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot de passe, quelle que soit sa forme, est interdite. Il assume
              les risques liés à l'utilisation de son identifiant et mot de passe. Le site décline toute responsabilité.
            </div>
            <div>
              Le site mecanojob.com ne peut être tenu pour responsable d'éventuels virus qui pourraient infecter l'ordinateur ou tout matériel informatique de
              l'Internaute, suite à une utilisation, à l'accès, ou au téléchargement provenant de ce site.
            </div>
            <div className="ml-4n mb-5">
              La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.
            </div>

            <h3 className="m-b5 text-uppercase text-orange">ARTICLE 6 : Liens hypertextes</h3>
            <div className="ml-4n mb-5">
              Des liens hypertextes peuvent être présents sur le site. L'Utilisateur est informé qu'en cliquant sur ces liens, il sortira du site mecanojob.com.
              Ce dernier n'a pas de contrôle sur les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur
              contenu.
            </div>

            <h3 className="m-b5 text-uppercase text-orange">ARTICLE 7 : Cookies</h3>
            <div>L'Utilisateur est informé que lors de ses visites sur le site, un cookie peut s'installer automatiquement sur son logiciel de navigation.</div>
            <div>
              Les cookies sont de petits fichiers stockés temporairement sur le disque dur de l'ordinateur de l'Utilisateur par votre navigateur et qui sont
              nécessaires à l'utilisation du site mecanojob.com. Les cookies ne contiennent pas d'information personnelle et ne peuvent pas être utilisés pour
              identifier quelqu'un. Un cookie contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à la fin de la
              visite de l'Utilisateur, d'autres restent.
            </div>
            <div>L'information contenue dans les cookies est utilisée pour améliorer le site mecanojob.com.</div>
            <div>En naviguant sur le site, L'Utilisateur les accepte.</div>
            <div>L'Utilisateur doit toutefois donner son consentement quant à l'utilisation de certains cookies.</div>
            <div>A défaut d'acceptation, l'Utilisateur est informé que certaines fonctionnalités ou pages risquent de lui être refusées.</div>
            <div className="ml-4n mb-5">
              L'Utilisateur pourra désactiver ces cookies par l'intermédiaire des paramètres figurant au sein de son logiciel de navigation
            </div>

            <h3 className="m-b5 text-uppercase text-orange">ARTICLE 8 : Publication par l'Utilisateur</h3>
            <div>Le site permet aux membres de publier les contenus suivants :</div>
            <div>Vos profils (candidats ou recruteurs).</div>
            <div>
              Dans ses publications, le membre s'engage à respecter les règles de la Netiquette (règles de bonne conduite de l'internet) et les règles de droit
              en vigueur.
            </div>
            <div>
              Le site peut exercer une modération sur les publications et se réserve le droit de refuser leur mise en ligne, sans avoir à s'en justifier auprès
              du membre.
            </div>
            <div>
              Le membre reste titulaire de l'intégralité de ses droits de propriété intellectuelle. Mais en publiant une publication sur le site, il cède à la
              société éditrice le droit non exclusif et gratuit de représenter, reproduire, adapter, modifier, diffuser et distribuer sa publication,
              directement ou par un tiers autorisé, dans le monde entier, sur tout support (numérique ou physique), pour la durée de la propriété
              intellectuelle. Le Membre cède notamment le droit d'utiliser sa publication sur internet et sur les réseaux de téléphonie mobile.
            </div>
            <div>La société éditrice s'engage à faire figurer le nom du membre à proximité de chaque utilisation de sa publication.</div>
            <div>
              Tout contenu mis en ligne par l'Utilisateur est de sa seule responsabilité. L'Utilisateur s'engage à ne pas mettre en ligne de contenus pouvant
              porter atteinte aux intérêts de tierces personnes. Tout recours en justice engagé par un tiers lésé contre le site sera pris en charge par
              l'Utilisateur.
            </div>
            <div className="mb-5">
              Le contenu de l'Utilisateur peut être à tout moment et pour n'importe quelle raison supprimé ou modifié par le site, sans préavis.
            </div>

            <h3 className="m-b5 text-uppercase text-orange">ARTICLE 9 : Droit applicable et juridiction compétente</h3>
            <div>
              La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux
              français seront seuls compétents pour en connaître.
            </div>
            <div className="mb-3">
              Pour toute question relative à l'application des présentes CGU, vous pouvez joindre l'éditeur aux coordonnées inscrites à l'ARTICLE 1.
            </div>
            <div>
              CGU réalisées partiellement sur{" "}
              <a className="text-blue-link" href="http://legalplace.fr/" target="_blank" rel="noreferrer">
                http://legalplace.fr/
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default StaticCGU;
