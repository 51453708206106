import React, { useEffect, useGlobal, useMemo, useState } from "reactn"
import { get } from "lodash"
import { ReactComponent as IconAddress } from "src/images/icons/icon-address.svg"
import { ReactComponent as IconEmail } from "src/images/icons/icon-email.svg"
import { ReactComponent as IconTelephone } from "src/images/icons/icon-telephone.svg"
import IconDepanneur from "src/images/icons/jobs/depanneur.png"
import { Loading } from "src/markup/Element/Loading"
import { apiCVDetail } from "src/services/api"
import { convertCVData } from "src/services/config"
import { Contact, Informations, Left, PointsInterest, Profile, Right, StyledCardDetails, StyledDetails } from "./styles"
import { LEVELS } from "src/constants"
import { MecTabSwitch } from "src/markup/Components/Forms"

export const CardDetail = ({ cvId }) => {
  const [config] = useGlobal("config")
  const [user] = useGlobal("user")
  const [loading, setLoading] = useState(true)
  const [cvDetail, setCvDetail] = useState(null)

  useEffect(() => {
    cvId &&
      (async () => {
        try {
          setLoading(true)
          const data = await apiCVDetail(cvId)
          setCvDetail(data)
        } finally {
          setLoading(false)
        }
      })()
  }, [cvId])

  const cv = useMemo(() => {
    if (config && cvDetail) {
      return convertCVData(config, cvDetail)
    }

    return null
  }, [config, cvDetail])

  return (
    <StyledCardDetails>
      {loading && <Loading />}
      <StyledDetails>
        <div className="title">CV détaillé</div>
        <div className="row mt-0 mt-xl-5 mb-5">
          <Left className="col-12 col-xl-4">
            <Profile>
              <h3>PROFIL</h3>
              <div className="pl-3 pr-3">
                Dans le cadre de mes expériences <br />
                passées, j'ai pu me renforcer dans <br />
                {get(cv, "skills", [])
                  .map((item) => item?.[0]?.name)
                  .join(", ")}
                . <br />
                <br /> Maîtrisant{" "}
                {get(cv, "skillComps", [])
                  .map((item) => item?.[0]?.name)
                  .join(", ")}
                , <br />
                je recherche un poste dans lequel je pourrais contribuer à{" "}
                {get(cv, "mission", [])
                  .map((item) => item?.[0]?.name)
                  .join(", ")}
                . <br /> <br />
                Étant{" "}
                {get(cv, "qualityMain", [])
                  .map((item) => item?.[0]?.name)
                  .join(", ")}
                , j'ai{" "}
                {get(cv, "quality2", [])
                  .map((item) => item[0].title)
                  .join(", ")}{" "}
                et je suis{" "}
                {get(cv, "quality3", [])
                  .map((item) => item?.[0]?.name)
                  .join(", ")}
                . Je saurais répondre à vos attentes et surtout{" "}
                {get(cv, "missionComp", [])
                  .map((item) => item?.[0]?.name)
                  .join(", ")}
                . Je parle{" "}
                {get(cv, "languages", [])
                  .map((item) => item?.[0]?.name)
                  .join(", ")}{" "}
                est ma langue maternelle.
              </div>
            </Profile>

            <Contact>
              <h3>CONTACT</h3>
              <div className="pl-3 pr-3 content">
                <div className="d-flex mb-3">
                  <IconTelephone className="mr-2" width="40" height="40" />
                  <div className="d-flex flex-column">
                    <span>TÉLÉPHONE</span>
                    <span>{user?.phonenumber}</span>
                  </div>
                </div>

                <div className="d-flex mb-3">
                  <IconAddress className="mr-2" width="40" height="40" />
                  <div className="d-flex flex-column">
                    <span>ADRESSE</span>
                    <span>{user?.address}</span>
                    <span>
                      {user?.postalcode}, {user?.city}
                    </span>
                    <span>{user?.country}</span>
                  </div>
                </div>

                <div className="d-flex mb-3">
                  <IconEmail className="mr-2" width="40" height="40" />
                  <div className="d-flex flex-column">
                    <span>E-MAIL</span>
                    <span>{user?.email}</span>
                  </div>
                </div>
              </div>
            </Contact>

            <PointsInterest>
              <h3>CENTRES D'INTÉRÊT</h3>
              <div className="pl-3 pr-3">{cvDetail?.extra_otherInfos}</div>
            </PointsInterest>

            <Informations>
              <h3>INFORMATIONS COMPLÉMENTAIRES</h3>
              <div className="pl-3 pr-3 d-flex">
                <div className="mr-5 d-flex flex-column">
                  {cvDetail?.extra_driversLicenceB && (
                    <>
                      <img src={IconDepanneur} alt="icon-france" className="icon-reponsive" />
                      <span>Permis B</span>
                    </>
                  )}
                </div>
              </div>
            </Informations>
          </Left>
          <Right className="col-12 col-xl-7 ml-0 ml-xl-5">
            <Profile>
              <h3>FORMATION</h3>
              <div className="pl-3 pr-3">
                {Array.isArray(cvDetail?.formations) ? (
                  cvDetail.formations.map((form) => (
                    <div className="d-flex flex-column mb-3" key={form?.id || Math.random()}>
                      {(form?.diplomaValue?.[0]?.name || form?.diplomaOptionValue?.[0]?.name) && (
                        <span>
                          {form?.diplomaValue?.[0]?.name} {form?.diplomaOptionValue?.[0]?.name}
                        </span>
                      )}

                      {(form?.formSchool || form?.formEntryDate || form?.formExitDate) && (
                        <span>
                          {form?.formSchool} {form?.formEntryDate && "- " + form.formEntryDate}{" "}
                          {form?.formExitDate && "- " + form.formExitDate}
                        </span>
                      )}
                      {form?.formMissions && <span className="mt-2">{form.formMissions}</span>}
                    </div>
                  ))
                ) : (
                  <div>Aucune formation disponible</div>
                )}
              </div>
            </Profile>

            <Contact>
              <h3>PARCOURS PROFESSIONNEL</h3>
              <div className="pl-3 pr-3">
                {get(cv, "exp", []).map((ex, index) => (
                  <div className="d-flex flex-column mb-3" key={ex?.id || index}>
                    <span>
                      {get(ex, "expJobName", "")} - {get(ex, "expCompany", "")}
                    </span>
                    <span>
                      {get(ex, "expEntryDate", "")}-{get(ex, "expExitDate", "")}
                    </span>
                    <span className="mt-2">{get(ex, "expMissions", "")}</span>
                  </div>
                ))}
              </div>
            </Contact>

            <PointsInterest>
              <h3>COMPÉTENCES</h3>
              <div className="pl-3 pr-3 process-wrapper">
                {get(cv, "tools", []).map((tool) => (
                  <div key={tool?.[0]?.id} className="mb-4">
                    <span className="mr-5 col-12 mb-2">{get(tool, "[0].name", "")}</span>
                    <MecTabSwitch
                      tabs={LEVELS}
                      activeTab={tool.level - 1}
                      className="col-12 mb-2"
                      name="tools"
                      style={{ pointerEvents: "none" }}
                    />
                  </div>
                ))}
              </div>
            </PointsInterest>
          </Right>
        </div>
      </StyledDetails>
    </StyledCardDetails>
  )
}
