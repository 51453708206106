import React, { memo, useGlobal, useMemo, useState, useCallback } from "reactn"
import { get } from "lodash"
import { useTranslation } from "react-i18next"
import { ASSET_QUALITY2_URL, ASSET_QUALITY3_URL, ASSET_QUALITYMAIN_URL } from "src/constants"
import { ReactComponent as IconView } from "src/images/icons/eye.svg"
import { ReactComponent as IconCar } from "src/images/icons/icon-car.svg"
import { ReactComponent as IconUser } from "src/images/icons/icon-user.svg"
import { MecButton } from "src/markup/Components"
import { convertCVData, getJobById } from "src/services/config"
import { getColorByScore, getLabelByScore, openInNewTab } from "src/utils"
import { CardDetail } from "./CardDetail"
import {
  CardDetailsOfferWrapper,
  CollapseDetailCV,
  DetailsOfferLeftWrapper,
  DetailsOfferRightWrapper,
  DetailsOfferWrapper,
  Footer,
  FooterCardWrapper,
  LeftLogoWrapper,
  TagConfirm,
} from "./styles"
import CVSummary from "src/markup/Components/Cards/CVSummary"
import { apiEditStatusCv } from "src/services/api"
import { toast } from "react-toastify"
import { ConfirmModal } from "src/markup/Components/Modals"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

export const CardCV = memo(({ data, otherData }) => {
  const { t } = useTranslation()
  const [config] = useGlobal("config")
  const [user] = useGlobal("user")
  const history = useHistory()
  const { cvId, jobId, otherJobId, score, hasPackage } = otherData

  const [isOpen, setIsOpen] = useState(false)
  const [modal, setModal] = useState({ showConfirmModal: false })

  const handleCollapse = () => {
    if (hasPackage) {
      
      history.push({
        pathname: `/recruiter/cv/${cvId}`,
        state: { data, otherData },
      });
    } else {
      setModal({
        showConfirmModal: true,
        onYes: () => openInNewTab("/pricing", setModal),
        onNo: () => setModal(false),
        Description: "Veuillez souscrire à une formule pour pouvoir consulter le détail d'un CV",
      })
    }
  }

  const cvDetail = useMemo(() => {
    if (config && data) {
      return convertCVData(config, data)
    }
    return null
  }, [config, data])

  const handleSelectedCV = useCallback(async () => {
    try {
      await apiEditStatusCv({
        cvId: data?.id,
        offerId: null,
        recruiterId: user?.id,
        candidateId: data?.userId,
        query: {
          selected: true,
        },
      })
      toast.success("CV enregistré dans CV sélectionnés")
    } catch (error) {
      console.log(error)
    }
  }, [data?.id, data?.userId, user?.id])

  const { jobName } = getJobById({ config, jobId, otherJobId })

  return (
    <>
      <CardDetailsOfferWrapper name_cv={get(cvDetail, "nameCV", "")}>
        <DetailsOfferWrapper className="row justify-content-between">
          <DetailsOfferLeftWrapper className="col" border>
            <LeftLogoWrapper>
              <div className="d-flex justify-content-start w-100 title-offers mb-4">
                <IconUser className="cv-avatar mr-5" />
                <div className="d-flex flex-column justify-content-around">
                  <span>{`${get(data, "user.surname", "")} ${get(data, "user.name", "")}`}</span>
                  <div className="d-flex align-items-center">
                    <IconCar className="mr-2 icon-reponsive" />
                    <span>{jobName}</span>
                  </div>
                </div>
              </div>
            </LeftLogoWrapper>

            <CVSummary cvDetail={cvDetail} />
          </DetailsOfferLeftWrapper>
          <DetailsOfferRightWrapper className="col d-flex flex-wrap flex-column justify-content-between">
            <div className="d-flex mb-4 list-skills">
              {get(cvDetail, "qualityMainCard", []).map((quality) => (
                <div className="d-flex flex-column align-items-center" key={quality.qualityMainId}>
                  <img
                    className="icon-skills mb-2 icon-reponsive"
                    src={`${ASSET_QUALITYMAIN_URL}${get(quality, "qualityMainCardValue[0].filename", null)}`}
                    alt={get(quality, "qualityMainCardValue[0].name", "")}
                  />
                  <span className="card-text-home text-first-capitalize">
                    {" "}
                    {get(quality, "qualityMainCardValueG[0].name", "")}{" "}
                  </span>
                </div>
              ))}

              {get(cvDetail, "quality2Card", []).map((quality2) => (
                <div className="d-flex flex-column align-items-center" key={quality2[0].id}>
                  <img
                    className="icon-skills mb-2 icon-reponsive"
                    src={`${ASSET_QUALITY2_URL}${get(quality2[0], "filename", "")}`}
                    alt={get(quality2[0], "title", "")}
                  />
                  <span className="card-text-home text-first-capitalize">{get(quality2[0], "title", "")}</span>
                </div>
              ))}

              {get(cvDetail, "quality3Card", []).map((quality3) => (
                <div className="d-flex flex-column align-items-center" key={quality3?.[0].id}>
                  <img
                    className="icon-skills mb-2 icon-reponsive"
                    src={`${ASSET_QUALITY3_URL}${get(quality3?.[0], "filename", "")}`}
                    alt={get(quality3?.[0], "title", "")}
                  />
                  <span className="card-text-home text-first-capitalize">{get(quality3?.[0], "title", "")}</span>
                </div>
              ))}
            </div>
            <ul className="job-description infos text-blue ml-4 w-100">
              <li>
                <b>Localisation :</b> {get(cvDetail, "city", "")}
              </li>
              <br></br>
              <li>
                <b>Niveau d'expérience :</b> {get(cvDetail, "levelExp[0].name", "")}
              </li>
              <br></br>
              <li>
                <b>Dernière expérience :</b>{" "}
                {get(data, "experiences", [])
                  .map((exp) => `${exp.expJobName} [${exp.expCompany}]`)
                  .join(" - ") || "Sans expérience"}
              </li>
              <br></br>
              <li>
                <b>Diplôme :</b>{" "}
                {get(cvDetail, "formations", [])
                  .map((form) =>
                    form?.diplomaValue ? `${form?.diplomaValue?.[0]?.name} ${form?.diplomaOptionValue?.[0]?.name}` : ""
                  )
                  .join(" - ") || "Sans formation"}
              </li>
            </ul>
          </DetailsOfferRightWrapper>

          <Footer className="w-100 d-flex align-items-center justify-content-between mt-5">
            <FooterCardWrapper className="flex-wrap">
              <div className="d-flex justify-content-end w-100">
                <TagConfirm bg={getColorByScore(score)}>{`${score} % : ${getLabelByScore(score)}`}</TagConfirm>
              </div>
              <MecButton
                icon={!isOpen && IconView}
                widthIcon={24}
                height={44}
                type="primary"
                onClick={handleCollapse}
                className="site-button site-but ton-gap radius-xl"
              >
                {isOpen ? "Réduire" : t("See")}
              </MecButton>
              <MecButton
                widthIcon={24}
                height={44}
                type="primary"
                className="site-button site-button-gap radius-xl"
                onClick={handleSelectedCV}
              >
                Sélectionner le CV
              </MecButton>
            </FooterCardWrapper>
          </Footer>
        </DetailsOfferWrapper>
      </CardDetailsOfferWrapper>

      <ConfirmModal {...modal} />
    </>
  )
})
