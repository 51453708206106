import styled from 'styled-components'
import Select from 'react-select'

export const SelectComponent = styled(Select)`
  &.select--is-disabled {
    opacity: 0.5;
  }
  .select__control {
    background: #e8eef2;
    padding: 5px 7px 5px 20px;
    color: var(--blue600);
    border-radius: 20px;
    border: ${(props) => props.error ? "2px solid red" : "none"};
    outline: none;
    overflow: hidden;
    text-align-last: left;
    cursor: pointer;
  }
  .select__control:focus,
  .select__control:active,
  .select__control:target,
  .select__control:hover {
    border: ${(props) => props.error ? "2px solid red" : "none"};
  }

  .select__menu,
  .select__option {
    cursor: pointer;
    background: #e8eef2;
    border-radius: 20px;
    overflow: hidden;
    text-align: left;
  }

  .select__option:hover {
    background: #cad9e0;
  }
  .select__option--is-selected {
    color: #678399;
    background: #cad9e0;
  }
  .select__control:focus,
  .select__control:active,
  .select__control:target,
  .select__control:hover {
    border: ${(props) => (props.error ? '2px solid red' : 'none')};
  }

  .select__menu,
  .select__option {
    cursor: pointer;
    background: #e8eef2;
    border-radius: 20px;
    overflow: hidden;
    opacity: 1;
    z-index: 999;
  }

  .select__option {
    padding-left: 28px
  }

  .select__option:hover {
    background: #cad9e0;
  }
  .select__option--is-selected {
    color: #678399;
    background: #cad9e0;
  }
`

export const Feedback = styled.div`
  color: ${({ colorTxtError = "red" }) => colorTxtError};
  font-size: 14px;
  padding: 10px 0 0 0;

  width: 100%;
  text-align-last: left;
`;
