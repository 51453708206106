import React, { useCallback } from 'reactn'
import { FormGroup } from "reactstrap"
import { useFormik } from 'formik'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { MecCheckBox, MecInput } from 'src/markup/Components/Forms'
import { recruiterRegisterSchema } from 'src/markup/Pages/AuthPage/validationSchema'
import { registerUser } from 'src/services/auth'

import * as S from 'src/markup/Pages/AuthPage/Register/Recruiter/styles'
import tickIcon from 'src/images/gallery/tick-icon.png'

export const RecruiterRegister = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleRegister = useCallback(async (values) => {
    try {
      const user = await registerUser(values)
      if (user) {
        toast.success(`Veuillez vérifier votre email pour activer le compte`, {
          autoClose: 2000,
          theme: 'colored',
        })
        history.push(`/${values.role}/login`);
      }
    } catch (error) {
      console.log(error);
    }
  }, [history]);

  const formik = useFormik({
    initialValues: {
      enterpriseName: '',
      email: '',
      password: '',
      confirmPassword: '',
      policy: false,
      role: 'recruiter',
    },
    validationSchema: () => recruiterRegisterSchema({ t }),
    onSubmit: (values) => handleRegister(values),
  })

  const { isSubmitting, handleSubmit } = formik

  return (
    <form onSubmit={handleSubmit}>
      <S.Heading>
        <span>INSCRIPTION</span>
      </S.Heading>
      <FormGroup>
        <MecInput
          name="enterpriseName"
          placeholder="Raison sociale"
          formik={formik}
          colorTxtError="var(--blue600)"
        />
      </FormGroup>
      <FormGroup>
        <MecInput
          type="email"
          name="email"
          placeholder="E-mail"
          formik={formik}
          colorTxtError="var(--blue600)"
        />
      </FormGroup>
      <FormGroup>
        <MecInput
          type="password"
          name="password"
          placeholder="Mot de passe"
          formik={formik}
          colorTxtError="var(--blue600)"
        />
      </FormGroup>
      <FormGroup className="pb-3">
        <MecInput
          type="password"
          name="confirmPassword"
          placeholder="Confirmer le mot de passe"
          formik={formik}
          colorTxtError="var(--blue600)"
        />
      </FormGroup>

      <FormGroup className="d-flex flex-wrap">
        <MecCheckBox recruiter>Je souhaite recevoir les actualités de MecanoJob par mail</MecCheckBox>
        <MecCheckBox recruiter onChange={() => formik.setFieldValue('policy', !formik.values.policy)} errors={formik.errors.policy} colorCheckboxError="var(--blue600)">
          J'ai lu et j'accepte les{" "}
          <Link target="_blank" to="/static/cgu" className="underline">
            CGU
          </Link>{" "}
          et la{" "}
          <Link target="_blank"  to="/static/rgpd" className="underline">
            politique de protection{" "}
          </Link>
          *
        </MecCheckBox>
      </FormGroup>

      <div className="mx-auto">
        <S.Button disabled={isSubmitting}>
          {isSubmitting ? (
            '...'
          ) : (
            <div className="d-flex justify-content-center">
              <div className="pr-2">
                <img src={tickIcon} alt="tick-icon" />
              </div>
              <div>Valider</div>
            </div>
          )}
        </S.Button>
      </div>

      <Link to="/recruiter/login">
        <S.NormalText className="text-bottom">
          Déjà inscrit ?{' '}
          <span className="underline">Connectez-vous</span>
        </S.NormalText>
      </Link>
    </form>
  )
}
