import React, { Component } from "react";
// import {Link} from 'react-router-dom';
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";

class StaticHelpRecruiter extends Component {
  render() {
    return (
      <div className="page-wraper">
        <Header showTitle={false} />
        <div className="page-content bg-light">
          <div className="col" style={{ textAlignLast: "center", marginBottom: "50px" }}>
            <div className="d-flex justify-content-center">
              <h1 className="m-b5 text-uppercase text-orange pt-5">Aides Recruteurs</h1>
            </div>
            <hr className="v3-separator" />
          </div>
          <div className="justify-content-left m-5">

            <div className="mb-3">Notre outil de gestion des candidatures, pensé pour vous, pour faciliter la relation avec vos candidats.</div>

            <h3>Bienvenue Sur MecanoJob !</h3>

            <h1>Paramétrage de compte</h1>

            <h3>Créer son compte</h3>

            <div>Sur la page d'accueil, vous pouvez cliquer sur l'accès recrtueur en haut à droitte de l‘écran.</div>
            <div className="mb-3">Si vous possédez déjà un compte Garagiste sur Oovoom, vous pouvez vous connecter avec vos identifiants habituels.</div>

            <div>Dans le cas contraire, cliquer sur «créer un compte» sous le bouton «Valider», remplisser le formulaire et c'est parti !</div>
            <div className="mb-3">
                Si vous avez oublié votre mot de passe, il suffit de cliquer sur «mot de passe oublié» sur la page de connexion, entrer votre e-mail et nous vous enverrons un lien de réinitialisation de votre mot de
                passe.
            </div>

            <h3>Paramétrer votre compte</h3>

            <div className="mb-3">Pour accéder à vos paramètres, cliquez sur le bouton engrenage en haut à droite de votre espace personnel.</div>

            <div>Vous pouvez entrer un nom et prénom, qui seront afficher lors de vos échanges avec les candidats.</div>
            <div className="mb-3">Si vous souhaitez rester anonyme, nous vous conseillons de prendre un nom générique du type : Equipe RH</div>

            <div>Vous pouvez modifier votre adresse mail et votre mot de passe</div>
            <div className="mb-3">Attention ! si vous modifier l'adresse mail dans vos paramètres, votre mail de connexion sera également modifié.</div>

            <h3>Comprendre ce qu'est MecanoJob</h3>

            <div className="mb-3">Chez MecanoJob, notre objectif est d'offrir aux candidats la meilleure expérience possible et de les aider à trouver un métier qui leur correspond dans le domaine de l'automobile.</div>

            <div>Pour cela, nous avons choisis d'offrir à nos recruteurs la meilleure expérience possible.</div>
            <div className="mb-3">C'est pourquoi nous avons créé notre propre plateforme.</div>

            <div>MecanoJob est une plateforme de recrutement très simple et intuitif. Elle permettra à vos équipes de travailler ensemble sur les recrutements, et candidatures.</div>
            <div className="mb-3">Vous pourrez dire adieu aux dizaines d'échanges de CV par email, et aux lettres de motivation imprimés sur votre bureau.</div>

            <div className="mb-3">L'outil a cette caractéristique appréciée d'avoir un design simple et moderne, et ne nécessite aucune formation particulière par rapport aux grands acteurs spécialisés dans le marché. Il vous permettra
            de générer rapidement des offres et traiter vos candidatures en s'adaptant à vos process de recrutement.</div>

            <h3>Le parcours d'un candidat sur MecanoJob</h3>

            <div>1 - Recherche d'une offre d'emploi</div>
            <div className="mb-3">Un candidat peut chercher sur MecanoJob des offres directement en utilisant des filtres de localisation, de type de contrat, d'intitulé de poste.</div>

            <div>2 - Envoi et suivi de candidature sur un formulaire</div>
            <div>Envoi d'une candidature et suivi depuis MecanoJob</div>
            <div>
                Une fois que le candidat a jeté son dévolu sur une offre d'emploi et qu'il souhaite y candidater depuis son espace candidat, il va être invité à renseigner plusieurs informations : certaines obligatoires,
                d'autres facultatives.
            </div>
            <div>Une fois sa candidature envoyée, le candidat reçoit dans son espace un accusé de réception, et peut consulter le suivi de sa candidature.</div>
            <div>Lorsque celle-ci a été ouverte par un recruteur, le statut change de "en attente", à "vue".</div>
            <div>S'il est déplacé dans la colonne "refusée", le statut sera enregistré dans l'onglet «refusées».</div>
            <div>C'est la raison pour laquelle il est essentiel de prévenir ses candidats de l'avancée de leur candidature.</div>
            <div className="mb-3">Le recruteur et le candidat peuvent échanger par mail, depuis leurs tableaux de bord respectifs. Le candidat peut consulter ses échanges à tout moment depuis la plateforme ou dans sa boîte mail.</div>

            <div>3 - Gestion des données personnelles</div>
            <div className="mb-3">Un candidat peut demander aux entreprises de supprimer ses données personnelles par email à cette adresse : <a className="text-blue-link" href="mailto:contact@30mille.com">contact@30mille.com</a>, ou directement au recruteur avec qui il échangeait.</div>

            <div>4 - Comportement d'un candidat potentiel en veille sur la plateforme</div>
            <div>Un candidat peut être en recherche active comme expliqué précédemment, ou être en veille sur la plateforme.</div>
            <div className="mb-3">Il a la possibilité de sauvegarder ses recherches sur la plateforme, et peut choisir d'être notifié par email.</div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default StaticHelpRecruiter;
