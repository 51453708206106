import { memo } from "react"
import Footer from "src/markup/Layout/Footer"
import PricingPlan from "./PricingPlan"
import WhyChooseUs from "./WhyChooseUs"
import HowItWorks from "./HowItWorks"
import ManagementTools from "./ManagementTools"
import Testimonials from "./Testimonials"
import Header from "./Header"
import "./index.scoped.scss"
const PageContent = () => (
  <div>
    <WhyChooseUs />
    <HowItWorks />
    <ManagementTools />
    <PricingPlan />
    <Testimonials />
  </div>
)
const Formulas = () => {
  return (
    <div className="page-wraper">
      <Header />
      <PageContent />
      <Footer />
    </div>
  )
}
export default memo(Formulas)
