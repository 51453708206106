import styled, { css } from "styled-components"

export const CardDetailsOfferWrapper = styled.div`
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  background-color: #fff;
  padding: 30px;
  position: relative;
  text-align-last: left;
  margin-top: ${(props) => (props.isPrefix ? "100px" : "50px")} !important;

  &::before {
    content: ${({ nameCV }) => `"${nameCV || "Nom du CV"}"`};
    position: absolute;
    width: auto;
    height: 163px;
    left: 0px;
    top: -60px;
    background: #ffffff;
    box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
    border-radius: 40px;
    text-align: center;
    font-weight: 800;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #023f73;
    z-index: -1;
    padding: 20px;
    display: ${(props) => (props.isPrefix ? "block" : "none")};
  }

  .d-inline-grid {
    display: inline-grid;
  }

  .cv-avatar {
    width: 80px;
    height: 80px;
  }

  @media (min-width: 1200px) {
    padding: 60px;

    &::before {
      top: -95px;
      font-size: 35px;
      line-height: 41px;
      padding: 30px;
    }
  }

  @media (min-width: 1440px) {
    .cv-avatar {
      width: 100px;
      height: 100px;
    }
  }
`

export const DetailsOfferWrapper = styled.div``

export const DetailsOfferLeftWrapper = styled.div`
  border-right: ${(props) => props.border && "5px solid #e8eef2"};
  font-size: 1rem;
  line-height: 1.5rem;
  color: #023f73;

  @media (min-width: 1024px) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`

export const LeftLogoWrapper = styled.div`
  display: flex;
  justify-content: center;

  .title-offers {
    span:first-child {
      font-weight: 600;
      font-size: 17px;
      line-height: 25px;
      color: #ff7200;
    }
    span:last-child {
      font-weight: 800;
      font-size: 13px;
      line-height: 20px;
      color: #023f73;
    }
  }

  @media (min-width: 1024px) {
    .title-offers {
      span:first-child {
        font-size: 22px;
        line-height: 34px;
      }
      span:last-child {
        font-size: 19px;
        line-height: 27px;
      }
    }
  }

  @media (min-width: 1440px) {
    .title-offers {
      span:first-child {
        font-size: 30px;
        line-height: 39px;
      }
      span:last-child {
        font-size: 22px;
        line-height: 27px;
      }
    }
  }
`

export const LeftContentWrapper = styled.div`
  padding: 0;

  div {
    font-weight: 800;
    font-size: 18.4202px;
    line-height: 22px;
    color: #023f73;
  }

  .content > div {
    font-weight: 400;
    font-size: 14.7361px;
    line-height: 17px;
    margin-top: 15px;
    max-width: 75%;
    margin-left: 10%;
  }

  h3 {
    font-size: 24px;
  }

  @media (min-width: 1024px) {
    padding: 20px 0;

    h3 {
      font-size: 28px;
    }
  }
`

export const DetailsOfferRightWrapper = styled.div`
  .list-skills {
    text-align-last: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
    div {
      width: 100%;
      max-width: 100px;
      text-align: center;
    }
    svg,
    img {
      width: 50px;
      height: 50px;
      object-fit: contain;

      circle {
        fill: #002d56;
      }
    }
  }

  .job-description {
    list-style-type: none;
    max-width: 100%;
  }

  @media (min-width: 1440px) {
    .list-skills {
      div {
        text-align: center;
      }
      svg,
      img {
        width: 70px;
        height: 70px;
      }
    }
  }
`

export const FooterCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  width: 100%;
  max-width: 800px;

  .mec-button {
    width: 45%;
  }

  .mec-button:first-child {
    display: block !important;
  }

  & > .custom-btn-select-footer {
    width: 300px;
  }

  .icon-center {
    svg {
      transform: rotate(90deg);
      path {
        fill: #ff7200;
      }
    }
  }

  @media (min-width: 1200px) {
    .mec-button {
      width: auto;
    }

    flex-wrap: nowrap;
  }
`
export const StyledFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .text-right {
    color: var(--orange300);
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    min-width: 250px;

    .custom-icon {
      path {
        fill: var(--orange300);
      }
    }
  }

  @media (min-width: 1201px) {
    flex-wrap: nowrap;
  }

  @media (min-width: 1440px) {
    justify-content: space-between;
    .text-right {
      margin-bottom: 0px;
    }
  }
`

const customSpan = css`
  span:first-child {
    font-weight: bold;
    color: #ff7200;
  }

  span:nth-child(2) {
    font-weight: bold;
  }
`

export const StyledCardDetails = styled.div`
  .styled-action {
    .note {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.75rem;
      color: #ff7200;
      margin-top: 15px;
    }
  }
`

export const Card = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
  border-radius: 30px;

  & > div {
    border: none;
    box-shadow: none;
  }

  .icon-skills {
    circle {
      fill: #002d56;
    }
  }

  .footer {
    padding: 0px 60px 60px 60px;
  }
`
export const StyledDetails = styled.div`
  overflow: hidden;
  transition: all 0.3s ease-out;
  max-height: 0;
  transform: scaleY(0);
  transform-origin: top;

  .title {
    font-weight: 600;
    font-size: 35px;
    line-height: 42px;
    color: #ff7200;
  }

  &.active {
    max-height: 100%;
    transform: scaleY(1);
  }
`
export const Left = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: #023f73;

  h3 {
    font-weight: 500;
    font-size: 25px;
    line-height: 37px;
    color: #023f73;
    margin-top: 20px;
  }

  @media (min-width: 1200px) {
    border-right: 5px solid #e8eef2;
  }
`

export const Profile = styled.div`
  ${customSpan}
`
export const Contact = styled.div`
  .content {
    span:first-child {
      font-weight: bold;
      color: #ff7200;
    }
  }
  ${customSpan}
`
export const PointsInterest = styled.div`
  .process-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  @media (min-width: 1600px) {
    .process-wrapper {
      flex-direction: column;
      align-items: flex-end;
    }
  }
`

export const Process = styled.div`
  background: #e8eef2;
  width: 500px;
  height: 30px;
  border-radius: 30px;

  &::before {
    content: ${({ percent }) => `"${percent}%"`};
    display: block;
    background: #bcced8;
    width: ${({ percent }) => `${percent}%`};
    height: 30px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #023f73;
  }
`

export const Informations = styled.div``

export const Right = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: #023f73;

  h3 {
    font-weight: 500;
    font-size: 25px;
    line-height: 37px;
    color: #023f73;
    margin-top: 20px;
  }
`
