const { memo } = require("react");

const FeatureCard = memo(({ feature }) => (
    <div className="feature-card">
      <img src={feature.icon} alt={feature.title} />
      <p className="feature-title">{feature.title}</p>
      <p className="feature-description">{feature.description}</p>
    </div>
  ));

export default FeatureCard;