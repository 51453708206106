import React, { useState, useMemo, useGlobal } from "reactn"
import { isEmpty } from "lodash"
import {
  FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton
} from "react-share"
import { toast } from "react-toastify"
import { MECANOJOB_URL } from "src/constants"
import { ConfirmModal, ShareSocialsModal } from "src/markup/Components/Modals"
import Card from "src/markup/Pages/Recruiter/RecruiterMyOffers/ListOffers/Card"
import { deleteOffer } from "src/services/api"
import { useHistory, useParams } from 'react-router-dom'

const RecruiterOffer = ({ offer }) => {
  const [user] = useGlobal('user')
  const history = useHistory()
  const { id } = useParams()
  const [view, setView] = useState("")
  const [modal, setModal] = useState({ showConfirmModal: false })
  const [modalSocial, setModalSocial] = useState({ showModal: false })

  const handleViewDetail = (id) => setView(id === view ? "" : id)

  const hasHandle = useMemo(() => offer.userId === user.id, [offer, user])

  const handleDeleteOffer = async () => {
    const res = await deleteOffer(id)
    toast.success(res.message)
    setModal({ showConfirmModal: false })
    history.push('/')
  }

  const onDelete = () => {
    setModal({
      showConfirmModal: true,
      onYes: () => handleDeleteOffer(id),
      onNo: () => setModal({ showConfirmModal: false }),
      Description: "Voulez-vous supprimer l'offre ?",
    })
  }

  const handleShareSocial = (offerId) => {
    setModalSocial({
      showModal: true,
      id: offerId,
      onNo: () => setModalSocial({ showModal: false }),
    })
  }

  if (isEmpty(offer)) return null;
  return (
    <>
        <Card
          offer={offer}
          handleViewDetail={handleViewDetail}
          view={view}
          onDelete={onDelete}
          handleShareSocial={handleShareSocial}
          hasHandle={hasHandle}
          isOwner={user?.id === offer?.userId}
        />

      <ConfirmModal {...modal} />
      <ShareSocialsModal {...modalSocial}>
        <FacebookShareButton url={`${MECANOJOB_URL}/offer/${modalSocial.id}`}>
          <FacebookIcon />
        </FacebookShareButton>
        <LinkedinShareButton url={`${MECANOJOB_URL}/offer/${modalSocial.id}`}>
          <LinkedinIcon />
        </LinkedinShareButton>
        <TwitterShareButton url={`${MECANOJOB_URL}/offer/${modalSocial.id}`}>
          <TwitterIcon />
        </TwitterShareButton>
      </ShareSocialsModal>
    </>
  )
}

export default RecruiterOffer
