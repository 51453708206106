import React, { useEffect, useGlobal, useRef, useCallback } from "reactn"
import { CV_STATUS, SORT_BY } from "src/constants"
import { useMainContext } from "src/markup/Layout/MainLayout/MainContext"
import {
  FormSearchJob,
  ListCardJob,
} from "src/markup/Pages/SearchPage/Candidate/components"
import { getMyListCVCandidate } from "src/services/api"
import { isCandidate } from "src/services/auth"
import * as S from "./styles"

export const SearchJob = (props) => {
  const [config] = useGlobal("config")
  const { isShowResult, _getCvList } = useMainContext()
  const refResult = useRef(null)
  const CANDIDATE = isCandidate()

  const getMyCvs = useCallback(
    async () => {
      if (CANDIDATE) {
        const res = await getMyListCVCandidate({
          status: CV_STATUS.PUBLISHED,
          sort_by: SORT_BY.DESC,
        })
        const optionsCvs = (res || []).map((cv) => ({
          value: cv.id,
          label: cv.name_cv,
        }))
        _getCvList(optionsCvs)
      }
    }, [CANDIDATE, _getCvList])

  useEffect(() => {
    if (isShowResult && refResult.current) {
      refResult.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }
  }, [isShowResult])

  useEffect(() => {
    getMyCvs()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!config) return null

  return (
    <S.Wrapper>
      <div className="d-flex justify-content-center">
        <S.Heading>Rechercher un job</S.Heading>
      </div>
      <hr className="v3-separator pb-xl-3" />
      <FormSearchJob {...props} />
      {isShowResult && (
        <div ref={refResult} className="pt-5">
          <ListCardJob />
        </div>
      )}
    </S.Wrapper>
  )
}
