import styled from "styled-components"
import { Form } from "reactstrap"

export const Content = styled(Form)`
  margin-bottom: 50px;
  padding: 0 20px;
  z-index: 0;
  @media (min-width: 992px) {
    padding: 0 50px;
  }
`

export const Filter = styled.div`
  height: 100px;

  .label {
    font-weight: 800;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #ff7200;
    margin-bottom: 10px;
  }
`
