import React, { useCallback, useDispatch, useEffect, useState, useGlobal } from "reactn"
import { useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import { CV_STATUS, ROLE, SORT_BY } from "src/constants"
import { ReactComponent as IconEye } from "src/images/icons/eye.svg"
import { ReactComponent as IconNote } from "src/images/icons/note.svg"
import { ReactComponent as Wrench } from "src/images/icons/wrench.svg"
import { MecButton } from "src/markup/Components"
import { SelectCV } from 'src/markup/Pages/SearchPage/Candidate/components'
import { getMyListCVCandidate } from "src/services/api"
import { isCandidate, isRecruiter } from "src/services/auth"
import { useGetDataModal } from "src/services"
import { useGetJobLiaisons } from "src/markup/Pages/SearchPage/Candidate/hooks/useGetJobLiaisons"
import { Loading } from "src/markup/Element/Loading"

export const GuestFooter = ({ onApply, offer }) => {
  const history = useHistory()

  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="pr-2">
        <MecButton
          icon={IconEye}
          width={90}
          type="primary"
          className="site-button site-button-gap radius-xl text-capitalize"
          onClick={() => history.push(`/offer/${offer.id}`)}
        >
          Voir
        </MecButton>
      </div>
      <div className="pr-0">
        <MecButton
          icon={IconNote}
          width={200}
          height={44}
          widthIcon={24}
          type="primary"
          className="site-button site-button-gap radius-xl"
          onClick={onApply}
        >
          Vite, je postule !
        </MecButton>
      </div>
    </div>
  )
}

export const CandidateFooter = ({ offer, otherCandidateProps }) => {
  const CANDIDATE = isCandidate()
  const history = useHistory()
  const [user] = useGlobal("user")
  const onShowModal = useDispatch("confirmApplyCv")
  const { dataCvSelected } = useGetDataModal()

  const [myCvs, setMyCvs] = useState([])

  const getMyCvs = useCallback(async () => {
    if (CANDIDATE) {
      const res = await getMyListCVCandidate({
        status: CV_STATUS.PUBLISHED,
        sort_by: SORT_BY.DESC,
      })

      if (res?.length) {
        const optionsCvs = res.map((cv) => ({
          value: cv.id,
          label: cv.name_cv,
        }))
        setMyCvs(optionsCvs)
      }
    }
  }, [CANDIDATE])

  useEffect(() => {
    getMyCvs()
  }, [getMyCvs])

  const onChangeSelect = (option) => {
    const payload = {
      [offer.id]: {
        dataView: option,
        bodyRequest: {
          cvId: option.value,
          offerId: offer.id,
          recruiterId: offer.userId,
          candidateId: user.id,
        },
      },
    }
    onShowModal(payload)
  }

  const cvSelected = !isEmpty(dataCvSelected) && dataCvSelected[offer.id] ? dataCvSelected[offer.id]?.dataView : null

  return (
    <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
      <div className="col-5 pl-0">
        <MecButton
          icon={IconEye}
          width={90}
          type="primary"
          className="site-button site-button-gap radius-xl text-capitalize"
          onClick={() => history.push(`/offer/${offer.id}`)}
        >
          Voir
        </MecButton>
      </div>
      <div className="col-7 pr-0">
        {!otherCandidateProps.isOfferJoined ? (
          <MecButton
            icon={Wrench}
            width={100}
            widthIcon={20}
            type="primary"
            onClick={() => history.push("/candidate/application/sent")}
          >
            Modifier
          </MecButton>
        ) : (
          <SelectCV
            className="drop-up"
            value={cvSelected}
            options={myCvs}
            onChange={onChangeSelect}
          />
        )}
      </div>
    </div>
  )
}

export const FooterOfferUrgent = (props) => {
  const onShowModal = useDispatch("showModalAuth")

  const RECRUITER = isRecruiter()
  const CANDIDATE = isCandidate()
  const {
    loadingGetListOfferJoined,
    liaisonsOfferList,
  } = useGetJobLiaisons()

  const onApply = () => {
    if (!RECRUITER || !CANDIDATE) {
      onShowModal({ payload: ROLE.CANDIDATE })
    }
  }
  const isOfferJoined = liaisonsOfferList.includes(props.offer.id)
  const otherCandidateProps = { isOfferJoined }

  return (
    <>
      {loadingGetListOfferJoined ? (
        <Loading />
      ) : (
        <>
          {CANDIDATE && <CandidateFooter {...props} otherCandidateProps={otherCandidateProps} />}
        </>
      )}
      {!CANDIDATE && <GuestFooter {...props} onApply={onApply} />}
    </>
  )
}
