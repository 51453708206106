import React, { useCallback, useState } from "react"
import { useGlobal } from "reactn"
import "./index.scoped.scss"
import { MecButton } from "src/markup/Components"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import GetQuoteModal from "src/markup/Components/Modals/GetQuoteModal"
import { isRecruiter, saveCartToStorage } from "src/services/auth"
import { useTranslation } from "react-i18next"
import { OFFER_FEATURES, PRICING_PLANS } from "../constants"
import PriceCard from "./PriceCard"
import FeatureList from "./FeatureList"

const PricingPlan = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [formulas] = useGlobal("formulas")
  const { packages } = formulas ?? {}

  const toggle = () => setIsOpenModal(!isOpenModal)

  const handleAddToCart = useCallback((packageNum) => {
    const selectedPackage = packages[packageNum]
    
    if (!isRecruiter()) {
      localStorage.setItem('pendingCartItem', JSON.stringify(selectedPackage))
      history.push("/recruiter/register")
      return
    }

    const newCart = [{ ...selectedPackage, count: 1 }]
    saveCartToStorage(newCart)
    history.push("/recruiter/cart")
  }, [packages, history])

  return (
    <section className="pricing-plan">
      <div className="pricing-plan__title">
        <h3>{t("our_prices")}</h3>
      </div>
      <div className="pricing-plan__content">
        <div className="pricing-plan__content-list">
          {PRICING_PLANS.map((plan, index) => (
            <PriceCard key={index} {...plan} onSelect={handleAddToCart} />
          ))}
        </div>
        <div className="pricing-plan__offer">
          <div className="pricing-plan__offer-title">{t('talent_hunter_offer')}</div>
          <div className="pricing-plan__offer-content">
            <FeatureList items={OFFER_FEATURES} />
            <div className="d-flex mt-auto">
              <MecButton onClick={toggle} className="w-auto h-auto px-5">
                {t("get_a_quote")}
              </MecButton>
            </div>
          </div>
        </div>
      </div>
      <GetQuoteModal isOpen={isOpenModal} toggle={toggle} />
    </section>
  )
}

export default PricingPlan
