import styled from "styled-components"
import { Form } from "reactstrap"

export const Wrapper = styled(Form)``

export const Heading = styled.h1`
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #ff7200;

  @media(max-width: 992px) {
    font-size: 30px;
  }
`

export const WrapperContent = styled.div`
  box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
  border-radius: 30px;
  background-color: #fff;
  padding: 30px;
  position: relative;
  text-align-last: center;
  margin-bottom: 30px;

  .react-datepicker-popper {
    width: 100%;
  }

  @media (min-width: 992px) {
    padding: 50px;
  }
`
