import React from 'react'
import AuthLayout from 'src/markup/Layout/AuthLayout'

import bgRecruiter from 'src/images/gallery/background-recruiter.svg'
import bgCandidate from 'src/images/gallery/background-candidate.svg'
import privacyCandidate from 'src/images/gallery/privacy-candidate.svg'
import bgForgot from 'src/images/gallery/forgot-recruiter.svg'

import { CandidateLogin, RecruiterLogin } from 'src/markup/Pages/AuthPage/Login'
import {
  CandidateRegister,
  RecruiterRegister,
} from 'src/markup/Pages/AuthPage/Register'
import {
  CandidateForgot,
  RecruiterForgot,
} from 'src/markup/Pages/AuthPage/ForgotPassword'
import {
  CandidateConfirmPassword,
  RecruiterConfirmPassword,
} from 'src/markup/Pages/AuthPage/ConfirmPassword'

import { AUTH } from 'src/constants'

const bgMatchCandidate = {
  [AUTH.LOGIN]: bgCandidate,
  [AUTH.REGISTER]: bgCandidate,
  [AUTH.FORGOT_PASSWORD]: privacyCandidate,
  [AUTH.CONFIRM_PASSWORD]: privacyCandidate,
}

const bgMatchRecruiter = {
  [AUTH.LOGIN]: bgRecruiter,
  [AUTH.REGISTER]: bgRecruiter,
  [AUTH.FORGOT_PASSWORD]: bgForgot,
  [AUTH.CONFIRM_PASSWORD]: bgForgot,
}

const childCandidate = {
  [AUTH.LOGIN]: CandidateLogin,
  [AUTH.REGISTER]: CandidateRegister,
  [AUTH.FORGOT_PASSWORD]: CandidateForgot,
  [AUTH.CONFIRM_PASSWORD]: CandidateConfirmPassword,
}

const childRecruiter = {
  [AUTH.LOGIN]: RecruiterLogin,
  [AUTH.REGISTER]: RecruiterRegister,
  [AUTH.FORGOT_PASSWORD]: RecruiterForgot,
  [AUTH.CONFIRM_PASSWORD]: RecruiterConfirmPassword,
}

const AuthPage = (props) => {
  const { recruiter, candidate, site } = props
  const bg = recruiter ? bgMatchRecruiter[site] : bgMatchCandidate[site]
  const Component = recruiter ? childRecruiter[site] : childCandidate[site]
  
  return (
    <AuthLayout isCandidate={candidate} bg={bg}>
      <Component  {...props} />
    </AuthLayout>
  )
}

export default AuthPage
