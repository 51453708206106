import React, { useEffect, useGlobal, useState } from "reactn"
import { useParams } from "react-router-dom"
import CVForm from "src/markup/Element/CVForm"
import { apiCVDetail } from "src/services/api"
import { convertDetailCV } from "src/services/config"
import Footer from "../../Layout/Footer"
import Header from "../../Layout/Header"
import SidebarCandidate from "../../Layout/SidebarCandidate"
import { Loading } from "src/markup/Element/Loading"
import { CVFormInitialValues } from "src/markup/Element/CVForm/FormValidation";

export default function CandidateCreateCV() {
  const [config] = useGlobal("config")
  const { id = null } = useParams()
  const [loading, setLoading] = useState(false)
  const [initialFormValue, setInitialFromValue] = useState(CVFormInitialValues)
  const [cv, setCv] = useState()

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          setLoading(true)
          if (config) {
            const _cv = await apiCVDetail(id)
            setCv(_cv)
            _cv && setInitialFromValue(convertDetailCV(config, _cv))
          }
        } finally {
          setLoading(false)
        }
      })()
    } else {
      setInitialFromValue(CVFormInitialValues)
    }
  }, [id, config])

  if (!initialFormValue) return null

  return (
    <div className="page-wraper">
      <Header candidate />

      <div className="container-content">
        <div className="d-flex position-relative">
          <SidebarCandidate />
          {(id && loading) && <Loading /> }
          <div style={{ textAlignLast: "center", marginBottom: "50px", width: 'calc(100% - 60px)' }}>
            <div className="d-flex justify-content-center">
              <h1 className="m-b5 text-uppercase text-orange mt-5">
                {id ? `Modifier CV #${id}` : "CRÉEZ VOTRE PROFIL"}
              </h1>
            </div>
            <hr className="v3-separator pb-xl-3" />

            <div className="container">
              <CVForm initialFormValue={initialFormValue} cvId={id} cv={cv} />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}
