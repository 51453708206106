import styled from "styled-components";

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 800;
  font-size: 27px;
  line-height: 31px;
  color: #023f73;
`;

export const TitleWrapper = styled.div`
  text-align: center;
  padding: 20px 0;

  div {
    font-weight: 800;
    font-size: 17px;
    line-height: 20px;
    color: #023f73;
  }

  div:last-child {
    font-weight: 900;
    font-size: 26px;
    line-height: 32px;
    color: #ff7200;
    margin-top: 15px;
  }
  @media (min-width: 1024px) {
    padding: 30px 0;
    div:last-child {
      font-size: 32px;
      line-height: 38px;
    }
  }
`;

export const MasterWrapper = styled.div`
  & > div:first-child {
    font-weight: 800;
    font-size: 22.5336px;
    line-height: 26px;
    color: #023f73;
  }
  & > ul {
    list-style-type: none;
    line-height: 30px;
    li {
      display: flex;
    }
    li:before {
      content: "\f111";
      font-family: FontAwesome;
      display: inline-block;
      margin-left: 0px;
      margin-right: 10px;
      font-size: 10px;
      color: #ff7200;
    }
  }
`;

export const ContentWrapper = styled.div``;

export const ContentLeftWrapper = styled.div`
  border-right: 5px solid #e8eef2;

  ul {
    color: #ff7200;
    font-weight: bold;
  }
  .sub-list {
    font-weight: 500;
    font-size: 18.0269px;
    line-height: 21px;
    color: #023f73;
    list-style-type: disc;
    list-style-position: inside;
  }

  li span {
    color: var(--blue600);
    font-weight: bold;
  }
`;

export const ContentRightWrapper = styled.div`
  & > div:first-child {
    font-weight: 800;
    font-size: 22.5336px;
    line-height: 26px;
    color: #023f73;
  }
  & > ul {
    list-style-type: none;
    line-height: 30px;
    li {
      display: block;
    }
    li:before {
      content: "\f111";
      font-family: FontAwesome;
      display: inline-block;
      margin-left: 0px;
      margin-right: 10px;
      font-size: 10px;
      color: #ff7200;
    }
  }
`;

export const FooterWrapper = styled.div`
  & > div:first-child {
    width: 100%;
    max-width: 550px;
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    color: #023f73;
    text-align: center;
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    & > div:first-child {
      font-size: 22px;
      line-height: 33px;
    }
  }
`;
