import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';

var error404 = require('./../../images/404.png');

class Error404 extends Component{
	render() {
		return(
			<div className="page-wraper">
				<Header showTitle={false} />
				<div className="page-content">
					<div className="section-full content-inner-1 bg-white">
						<div className="container">
							<div className="row">
							<img className="img-fluid mx-auto" src={error404.default} alt="Error404"></img>
								<div className="col-lg-12 col-md-12 col-sm-12 mb-5 error-page text-center">
									<Link to={"/"} className="site-button mt-5">Retour à l'acceuil</Link>
								</div>
							</div>
						</div>
					</div>

				</div>
				<Footer />
			</div>
		)
	}
}
export default Error404;