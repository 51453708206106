import React, { useGlobal, useEffect, useState, useCallback } from "reactn"
import { get } from "lodash"
import { PDFDownloadLink } from '@react-pdf/renderer'

import { MecButton } from "src/markup/Components"
import { Loading } from "src/markup/Element/Loading"
import { formatPrice } from "src/utils"
import { getOrderById, getProfileById } from "src/services/api"
import * as S from "./styles"
import MyPdf from "./myPdf"

const Invoice = ({ isShow, id, onHide }) => {
  const [loading, setLoading] = useState(true)
  const [formulas] = useGlobal("formulas");
  const [user] = useGlobal("user");
  const [currentOrder, setCurrentOrder] = useState({})
  const [profile, setProfile] = useState(null);

  const getOrderAsync = async (id) => {
    try {
      const { data } = await getOrderById(id)
      setCurrentOrder(data)
    } finally {
      setLoading(false)
    }
  }

  const getProfileAsync = useCallback(async () => {
    try {
      const res = await getProfileById(user.id)
      setProfile(res)
    } finally {
      setLoading(false)
    }
  }, [user.id])

  useEffect(() => {
    if (isShow) {
      getOrderAsync(id);
      getProfileAsync()
    }
  }, [getProfileAsync, id, isShow])

  const columnsLeft = [
    { label: "Nom de l'entreprise", value: "SAS Oovoom" },
    { label: "Adresse", value: "111 Avenue Victor Hugo" },
    { label: "Ville", value: "Paris" },
    { label: "Code postal", value: "75116"},
    { label: "Pays", value: "France" },
    { label: "Numéro RCS", value: "889 500 021" },
    { label: "Numéro de TVA", value: "FR05 889 500 021" },
  ]

  const columnsRight = [
    { label: "Nom du client", value: get(profile, "Enterprisename", "") },
    { label: "Adresse", value: get(profile, "address", "") },
    { label: "Ville", value: get(profile, "city", "") },
    { label: "Code postal", value: get(profile, "postalcode", "") },
    { label: "Pays", value: get(profile, "country", "") },
    (get(profile, "SIRET", null) && { label: "Numéro de SIRET", value: profile.SIRET })
  ].filter(Boolean)

  return (
    <S.WrapperModal show={isShow} onHide={onHide}>
      <S.Wrapper>
        <S.Header>Facture</S.Header>

        {loading ? (
          <Loading />
        ) : (
          <>
            <S.HeadingTable className="d-md-flex d-block align-items-center justify-content-between px-4 py-2">
              <div>
                <span className="label">Date :</span>
                <span className="value">
                  {new Date(get(currentOrder, "createdAt", "")).toLocaleDateString("fr-FR")}
                </span>
              </div>
              <div>
                <span className="label">Numéro de facture :</span>
                <span className="value">{currentOrder.code}</span>
              </div>
            </S.HeadingTable>

            <S.TableInfo className="d-md-flex d-block align-items-center justify-content-between p-4">
              <div>
                {columnsLeft.map((column, idx) => (
                  <div key={idx}>
                    <span className="label">{column.label} : </span>
                    <strong className="value">{column.value}</strong>
                  </div>
                ))}
              </div>
              <div className="pt-md-0 pt-4">
                {columnsRight.map((column, idx) => (
                  <div key={idx}>
                    <span className="label">{column.label} : </span>
                    <strong className="value">{column.value}</strong>
                  </div>
                ))}
              </div>
            </S.TableInfo>
            <div className="px-md-4 px-0">
              <S.TablePlan>
                <tbody>
                  <tr>
                    <td>
                      <span className="label">Quantité</span>
                    </td>
                    <td>
                      <span className="label">Description</span>
                    </td>
                    <td>
                      <span className="label">PRIX HT</span>
                    </td>
                    <td>
                      <span className="label">PRIX HT TOTAL</span>
                    </td>
                  </tr>
                  {get(currentOrder, "orderDetails", []).map((order, idx) => (
                    <tr key={idx}>
                      <td>{order.quantity}</td>
                      <td>{order.package_name}</td>
                      <td>{formatPrice(order.unit_ht, 2)}</td>
                      <td>{formatPrice(order.total_ht, 2)}</td>
                    </tr>
                  ))}
                </tbody>
              </S.TablePlan>
            </div>

            <S.FooterInvoice className="offset-md-8 offset-0 py-3 px-3">
              <div className="d-flex align-items-center pb-2 justify-content-between">
                <div className="font-weight-bold">Total HT</div>
                <div className="pl-5">{formatPrice(currentOrder.total, 2)} €</div>
              </div>

              <div className="d-flex align-items-center pb-2 justify-content-between">
                <div className="font-weight-bold">TVA {formulas?.taxPercent} %</div>
                <div className="pl-5">{formatPrice(currentOrder.tva, 2)} €</div>
              </div>

              <div className="d-flex align-items-center pb-2 justify-content-between">
                <div className="font-weight-bold">Total TTC</div>
                <div className="pl-5 font-weigh-bold">{formatPrice(currentOrder.total_ttc, 2)} €</div>
              </div>
            </S.FooterInvoice>

            <S.CopyRight>
              SAS 30 MILLE - OOVOOM - capital de 27 702,00 € - 111 avenue Victor Hugo - 75116 PARIS
              <br />RCS 889 500 021 - TVA FR 05 889500021 - contact@30mille.com - www.oovoom.com
            </S.CopyRight>

          </>
        )}
      </S.Wrapper>
      <div className="w-100 mx-auto d-flex align-items-center justify-content-center my-3">
        <MecButton width={150} type="outlineNotHover" onClick={onHide}>
          Annuler
        </MecButton>

        <PDFDownloadLink
          document={
            <MyPdf
              currentOrder={currentOrder}
              columnsLeft={columnsLeft}
              columnsRight={columnsRight}
              packageInfo={formulas}
            />
          }
          fileName={`Facture MecanoJob - ${currentOrder.code}.pdf`}>
          {({ loading }) =>
            {
              return <MecButton width={150} className='ml-4'>
                        {loading ? 'Chargement...' : 'Télécharger'}
                    </MecButton>
            }
          }
        </PDFDownloadLink>
      </div>
    </S.WrapperModal>
  )
}

export default Invoice
