import React, { useEffect, useCallback, useState, useGlobal } from "reactn"
import * as S from "./styles"
import { Card } from "src/markup/Pages/Agenda/Candidate/components"
import { candidateListAgenda } from "src/services/api"
import { Loading } from "src/markup/Element/Loading"

export const AgendaPastList = () => {
  const [agendas, setAgendas] = useState([])
  const [config] = useGlobal("config")
  const [loading, setLoading] = useState(false)

  const fetchApi = useCallback(async () => {
    try {
      setLoading(true)
      const res = await candidateListAgenda({ type: 'past' })
      setAgendas(res)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchApi()
  }, [fetchApi])

  const updateAgendaSuccess = useCallback(() => {
    fetchApi()
  }, [fetchApi])

  return (
    <S.Wrapper>
      <div className="d-flex justify-content-center">
        <S.Heading>Agenda</S.Heading>
      </div>
      <hr className="v3-separator pb-xl-3" />

      <S.WrapperContent>
        {loading && <Loading />}
        <div className='row align-items-center justify-content-between'>
          {agendas.map((agenda) => (
            <div key={agenda.id} className="col-12 pb-4" style={{zIndex: 1}}>
              <Card isPrefix agenda={agenda} config={config} updateAgendaSuccess={updateAgendaSuccess} />
            </div>
          ))}
        </div>
      </S.WrapperContent>
    </S.Wrapper>
  )
}
