import { memo } from 'react'
import { MecButton } from "src/markup/Components"
import FeatureList from './FeatureList'

const PriceCard = memo(({ title, price, duration, features, packageNum, onSelect }) => (
  <div className="pricing-plan__card">
    <div className="pricing-plan__card-title">{title}</div>
    <div className="pricing-plan__card-price mt-2">{price}</div>
    <span className="text-blue">{duration}</span>
    <FeatureList items={features} />
    <div className="d-flex mt-auto">
      <MecButton
        type="primary"
        onClick={(e) => {
          e.preventDefault()
          onSelect(packageNum)
        }}
        className="mt-auto w-auto mx-auto px-5 radius-md"
      >
        CHOISIR
      </MecButton>
    </div>
  </div>
))

export default PriceCard