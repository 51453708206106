import React from "react"
import "./index.scoped.scss"
import { MecButton } from "src/markup/Components"
import CheckIcon from "./../../../../images/icons/check.svg"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const PRICES = [
  { title: "Standard", price: "GRATUIT", give: ["1 annonce", "Accès basique", "Support standard"] },
  { title: "Premium", price: "20€", give: ["5 annonces", "Mise en avant", "Support prioritaire"] },
  { title: "Business", price: "50€", give: ["10 annonces", "Publicité premium", "Statistiques avancées"] },
]

const OFFER_FEATURES = [
  "Définition des profils adaptés à vos besoins",
  "Diffusion des offres sur MécanoJob et nos 35 JobBoards",
  "Sourcing ciblé dans notre base et nos CVthèques",
  "Analyse des candidatures par nos experts",
  "Entretiens structurés et suivi personnalisé pour une intégration",
]

const FeatureList = ({ items }) => (
  <ul className="feature-list">
    {items.map((feature, index) => (
      <li key={index}>
        <img src={CheckIcon} alt="" className="mr-3" />
        {feature}
      </li>
    ))}
  </ul>
)

export default function PricingPlan() {
  const history = useHistory()

  const PriceCard = ({ title, price, give }) => (
    <div className="pricing-plan__card">
      <div className="pricing-plan__card-title">{title}</div>
      <div className="pricing-plan__card-price">{price}</div>
      <FeatureList items={give} />
      <div className="d-flex mt-auto">
        <MecButton
          onClick={(e) => {
            e.preventDefault()
            history.push("/recruiter/login")
          }}
          className="mt-auto w-auto mx-auto px-5 radius-md"
        >
          CHOISIR
        </MecButton>
      </div>
    </div>
  )
  return (
    <section className="pricing-plan">
      <div className="pricing-plan__title">
        <h3>NOS TARIFS</h3>
      </div>
      <div className="pricing-plan__content">
        {PRICES.map((plan, index) => (
          <PriceCard key={index} {...plan} />
        ))}
        <div className="pricing-plan__offer mt-5">
          <div className="pricing-plan__offer-title">OFFRE CHASSEUR DE TALENTS</div>
          <div className="d-flex p-5 justify-content-between">
            <FeatureList items={OFFER_FEATURES} />
            <div className="d-flex mt-auto">
              <MecButton
                onClick={(e) => {
                  e.preventDefault()
                  history.push("/recruiter/login")
                }}
                className="w-auto h-auto px-5"
              >
                OBTENIR UN DEVIS
              </MecButton>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
