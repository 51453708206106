import styled from "styled-components"

export const Box = styled.div`
  border-radius: 30px;
  line-height: 2;
  padding: 50px 50px 30px 50px;
  margin-bottom: 0.5rem;
  box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 1024px) {
    width: calc(100% / 3 - 40px);
    max-width: 420px;
    min-width: 350px;
  }
`

export const Left = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

export const Infor = styled.div`
  width: 100%;
`

export const TitleInfor = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  & > span:nth-child(1) {
    margin-bottom: 30px;
  }
`

export const Summary = styled.div`
  width: 100%;

  margin: 30px 0;
`

export const Footer = styled.div`
  width: 100%;

  margin: 30px 0;
  & > span:nth-child(1) {
    width: 100%;
    height: 40px;

    display: flex;
    gap: 20px;

    & > span {
      border-radius: 30px;
    }

    & > br {
      display: none;
    }
  }
`
