import React from "react"
import PropTypes from "prop-types"
import * as S from "./styles"

export const MecInputMultiple = (props) => {
  const {
    formik,
    type,
    name,
    onBlur,
    value,
    colorTxtError,
    disabled,
    className = "primary",
    onChange,
    errors: customErrors,
    touched: customTouched,
    ...rest
  } = props

  const { handleChange, handleBlur } = formik

  return (
    <>
      <S.Wrapper
        className={className}
        type={type}
        name={name}
        onChange={onChange || handleChange}
        onBlur={handleBlur}
        error={customTouched && !!customErrors}
        disabled={disabled}
        isDisabled={disabled}
        value={value}
        {...rest}
      />
      {customTouched && customErrors && (
        <S.Feedback colorTxtError={colorTxtError}>{customTouched && customErrors}</S.Feedback>
      )}
    </>
  )
}

MecInputMultiple.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  timeDebounce: PropTypes.number,
}

MecInputMultiple.defaultProps = {
  name: ''
}
