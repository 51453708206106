import React from "reactn"
import { ModalBody } from "reactstrap"
import * as S from "./styles"
import { ReactComponent as IconCancel } from "src/images/icons/icon-cancel.svg"

export const ConfirmModal = ({
  showConfirmModal,
  onNo,
  onYes,
  Description = "Voulez-vous supprimer l'article ?",
  Title = "Confirmer",
  TitleBtnLeft = "Non",
  TitleBtnRight = "Oui",
  onToggle = onNo
}) => {
  return (
    <S.Wrapper isOpen={showConfirmModal} centered toggle={onToggle}>
      <ModalBody>
        <S.Cancel onClick={onToggle}>
          <IconCancel width={30} height={30} />
        </S.Cancel>
        <div className="quote-bx text-center mb-5">
          <div className="quote-info">
            <h3 className="text-uppercase">{Title}</h3>
            <p>{Description}</p>
            <div className="left-job-buttons">
              <div onClick={onNo} className="site-button site-button-gap radius-xl text-uppercase">
                {TitleBtnLeft}
              </div>
              <div onClick={onYes} className="site-button site-button-gap radius-xl text-uppercase">
                {TitleBtnRight}
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </S.Wrapper>
  )
}
