import { Link } from "react-router-dom"
import MecLogo from "../../../../images/logo-mecanojob.png"
import PcImg from "../../../../images/pc.png"
import { MecButton } from "src/markup/Components"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import "./index.scoped.scss"
import { useTranslation } from "react-i18next"

const ROUTES = {
  HOME: "/",
  LOGIN: "/recruiter/login",
  REGISTER: "/recruiter/register",
}

const Header = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const handleLogin = (e) => {
    e.preventDefault()
    history.push(ROUTES.LOGIN)
  }

  const handleRegister = (e) => {
    e.preventDefault()
    history.push(ROUTES.REGISTER)
  }

  return (
    <div className="header-wrapper">
      <div className="header-wrapper__top">
        <div className="logo-header-wrapper w-100">
          <Link to={ROUTES.HOME}>
            <img src={MecLogo} alt="Mecanojob logo" className="logo" />
          </Link>
        </div>
        <MecButton
          widthIcon={24}
          type={"secondary"}
          className="btn-login w-auto ml-auto h-auto"
          onClick={handleLogin}
          aria-label="Connexion"
        >
          {t("login").toUpperCase()}
        </MecButton>
      </div>
      <div className="header-content">
        <div className="find-talent mt-5">
          <h1>
            Trouvez <span className="text-orange">vos talents</span> en quelques clics
          </h1>
          <p>Facilitez vos recrutements avec MécanoJob, le spécialiste des métiers techniques</p>
          <div>
            <MecButton
              onClick={handleRegister}
              className="find-button px-4 h-auto"
              aria-label="Publier une offre d'emploi"
            >
              {t('post_a_job_offer')}
            </MecButton>
          </div>
        </div>
        <div className="pc-image">
          <img src={PcImg} alt="PC" />
        </div>
      </div>
    </div>
  )
}

export default Header
