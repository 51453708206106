import React from 'react'
import { MecButton } from 'src/markup/Components'
import { ReactComponent as CancelIcon } from 'src/images/icons/cancel-icon.svg'
import { ReactComponent as SaveIcon } from 'src/images/icons/save-icon.svg'

export const ProfileButton = ({
  isSubmitting,
  className,
  onCancel,
  onSubmit,
  onUnRegister
}) => {
  return (
    <div className={`d-flex mt-3 flex-wrap ${className} `}>
      <div className="col-6 mt-2">
        <MecButton
          onClick={onCancel}
          type="outlineNotHover"
          icon={CancelIcon}
          widthIcon={22}
          height={45}
          radius={15}
        >
          Annuler
        </MecButton>
      </div>
      <div className="col-6 mt-2">
        <MecButton
          onClick={onSubmit}
          icon={SaveIcon}
          disabled={isSubmitting}
          type="primary"
          widthIcon={18}
          height={45}
          radius={15}
        >
          {isSubmitting ? '...' : 'Enregistrer'}
        </MecButton>
      </div>
      <div className="col-6 mt-2">
        <MecButton
          onClick={onUnRegister}
          icon={SaveIcon}
          disabled={isSubmitting}
          type="outlineNotHover"
          widthIcon={18}
          height={45}
          radius={15}
        >
          {isSubmitting ? '...' : 'Se désinscrire'}
        </MecButton>
      </div>
    </div>
  )
}
