import React, { useCallback, useEffect, useState, useGlobal } from "reactn";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { FormGroup } from "reactstrap";
import { isEmpty } from "lodash";
import CreditCard from "src/images/credit-card.png";
import { ReactComponent as IconChecked } from "src/images/icons/icon-checked-outline.svg";
import { ReactComponent as IconPlush } from "src/images/icons/icon-plus.svg";
import { ReactComponent as IconRemove } from "src/images/icons/icon-remove.svg";
import { MecCollapse } from "src/markup/Components/Forms";
import { MecButton } from "src/markup/Components/MecButton";
import { Loading } from "src/markup/Element/Loading";
import { clearCart } from "src/services/auth"
import {
  apiCreatePaymentIntent, deleteCard,
  getCards, payment,
  getProfileById
} from "src/services/api";
import SplitForm from "./SplitForm";
import * as S from "./styles";
import { vatFromSIRET } from "src/utils"

const PaiementCollapse = ({ order, handleOpenCollapse, isOpen }) => {
  const [card, setCard] = useState({});
  const [cardOptions, setCardOptions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAddCard, setIsAddCard] = useState(false);
  const [user] = useGlobal('user');

  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const fetchListCard = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getCards();
      const cards = res.rows.map((item) => ({
        value: item.id,
        label: `${item.brand} *** *** *** ${item.last4}`,
      }));
      setCardOptions(cards);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);

  const handleRemoveCard = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        setLoading(true);
        const res = await deleteCard(card?.value);
        toast.success(res.message);

        const cards = cardOptions.filter((item) => item.value !== card?.value);
        setCardOptions(cards);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [card?.value, cardOptions]
  );

  const handlePayment = useCallback(async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      if (!stripe || !elements) {
        return;
      }

      // check payment info
      const profile = await getProfileById(user.id);
      const checkBillInfo = profile.Enterprisename && (profile.vat_number || vatFromSIRET(profile.SIRET))
        && profile.address && profile.postalcode && profile.city && profile.country
        && profile.phonenumber;

      if (!checkBillInfo) {
        toast.warn("Veuillez mettre à jour vos informations de paiement avant de procéder à l'achat")
        return;
      }

      const params = {
        orderId: order?.data?.id,
        cardId: card?.value,
      };
      const intents = await apiCreatePaymentIntent(params);
      const { payment_method, client_secret } = intents;

      setLoading(true);
      stripe
        .confirmCardPayment(client_secret, {
          payment_method,
        })
        .then(async (result) => {
          const { error } = result;

          if (error) {
            toast.error(error.message);
            return;
          }

          const { status } = result.paymentIntent;

          if (status === "succeeded") {
            setLoading(true);
            await payment({
              payment_intent_id: result.paymentIntent.id,
              order_id: order?.data?.id,
            });
            clearCart();
            toast.success('Paiement réussi')
            history.push("/recruiter/invoice");
          } else {
            toast.error('Échec du paiement')
          }
        })
        .catch((error) => {
          console.log(error);
        })
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [card?.value, elements, history, order?.data?.id, stripe, user.id]);

  const addCardSuccess = useCallback(() => {
    fetchListCard();
    setIsAddCard(false);
  }, [fetchListCard]);

  const handleSelectChange = useCallback((value) => {
    setCard(value);
  }, []);

  const onCancelAddCard = useCallback(() => {
    setIsAddCard(false);
  }, [])

  useEffect(() => {
    fetchListCard();
  }, [fetchListCard]);

  return (
    <MecCollapse
      title="Paiement"
      isOpen={isOpen === 'paiement'}
      setIsOpen={() => handleOpenCollapse("paiement")}
      completed={!isEmpty(card)}
      name="isCompleted"
      className="mb-5"
    >
      <S.WrapperPayment>
        <S.PaymentListCard className="row flex-wrap flex-lg-nowrap justify-content-center">
          {loading && <Loading />}
          <FormGroup className="w-100 mr-4">
            <S.SelectCard
              value={card}
              options={cardOptions}
              onChange={handleSelectChange}
              classNamePrefix="select"
              className="basic-single"
              noOptionsMessage={() => 'Aucune carte de paiement enregistrée'}
              placeholder={"Sélectionner une carte de paiement"}
            />
          </FormGroup>

          <FormGroup
            className="d-flex justify-content-center"
            style={{ gap: "20px" }}
          >
            <MecButton
              icon={IconRemove}
              type="primary"
              widthIcon={20}
              width={200}
              height={45}
              onClick={(e) => handleRemoveCard(e)}
              disabled={isEmpty(card)}
            >
              Supprimer la carte{" "}
            </MecButton>
            <MecButton
              icon={IconChecked}
              type="primary"
              widthIcon={40}
              className="text-capitalize"
              width={150}
              height={45}
              style={{ fill: "none" }}
              disabled={isEmpty(card)}
              onClick={(e) => handlePayment(e)}
            >
              Payer
            </MecButton>
          </FormGroup>
        </S.PaymentListCard>

        <S.Add
          className="row align-items-center"
          onClick={() => setIsAddCard(!isAddCard)}
        >
          <IconPlush className="mr-2" width={30} height={30} />
          <S.Label className="py-2">
            Ajouter un nouveau moyen de paiement
          </S.Label>
        </S.Add>

        {isAddCard && (
          <>
            <S.TitleCard>AJOUTER UN MOYEN DE PAIEMENT</S.TitleCard>
            <S.ImgCard
              src={CreditCard}
              alt="Credit card"
              width={350}
              height={225}
            />
            <SplitForm
              onSuccess={addCardSuccess}
              onCancel={onCancelAddCard}
            />
          </>
        )}
      </S.WrapperPayment>
    </MecCollapse>
  );
};

export default PaiementCollapse;
