import { useCallback, useEffect, useState } from "reactn"
import { getListFavoriteOffer } from "src/services/api"
import { keyBy } from "lodash"

export const useGetFavoriteOffer = () => {
  const [isGetList, setIsGetList] = useState(true)
  const [favoriteList, setFavoriteList] = useState({})
  const [favoriteIdList, setFavoriteIdList] = useState([])
  const [force, setForce] = useState(null)

  const getAsyncOfferList = useCallback(async () => {
    try {
      const { data } = await getListFavoriteOffer()
      const keyByFavoriteList = keyBy(data, "id")

      setFavoriteList(keyByFavoriteList)
      setFavoriteIdList(Object.keys(keyByFavoriteList).map(key => parseInt(key)))
    } finally {
      setIsGetList(false)
    }
  }, [])

  const setAsyncFavoriteIdList = (newData) => {
    setFavoriteIdList(newData);
  }

  useEffect(() => {
    getAsyncOfferList()
  }, [force, getAsyncOfferList])

  return {
    isGetList,
    setAsyncFavoriteIdList,
    favoriteList,
    setFavoriteList,
    favoriteIdList,
    setForce
  }
}
