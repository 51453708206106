import React, { useDispatch, useGlobal, useState } from "reactn"
import { get, isEmpty } from "lodash"
import { ROLE } from "src/constants"
import { ReactComponent as StartIcon } from "src/images/icons/ic-start.svg"
import { ReactComponent as IconNote } from "src/images/icons/note.svg"
import { MecButton } from "src/markup/Components"
import { useMainContext } from "src/markup/Layout/MainLayout/MainContext"
import { SelectCV } from 'src/markup/Pages/SearchPage/Candidate/components'
import { isCandidate, isRecruiter } from "src/services/auth"
import { apiEditStatusCv } from "src/services/api"
import { toast } from "react-toastify"

export const GuestFooter = ({ onApply, data }) => (
  <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
    <div className="d-flex justify-content-end ml-auto">
      <MecButton
        style={{ display: "block" }}
        icon={IconNote}
        widthIcon={20}
        width={300}
        type="primary h-100"
        className="site-button site-button-gap radius-xl"
        onClick={onApply}
      >
        Vite, je postule !
      </MecButton>
    </div>
  </div>
)

export const CandidateFooter = (props) => {
  const {
    offer,
    favoriteIdList,
    liaisonsOfferList,
    setAsyncLiaisonsOfferList,
    handleCollapse,
    onRemoveFavorite,
    onAddFavorite,
    callback,
    isOpen,
  } = props
  const [user] = useGlobal("user")
  const { profile } = useMainContext()
  const [cvSelected, setCvSelected] = useState(null)

  const onChangeSelect = async (option) => {
    const payload = {
      cvId: option.value,
      offerId: offer.id,
      recruiterId: offer.userId,
      candidateId: user.id,
    }
    setCvSelected(payload)
  }

  const onApply = async () => {
    try {
      callback(offer.id)
      await apiEditStatusCv(cvSelected)
      toast.success('CV envoyé', {
        autoClose: 2000,
        theme: "colored",
      })

      setAsyncLiaisonsOfferList(offer.id)
    } finally {
      callback(null)
    }
  }

  const isFavorite = favoriteIdList.includes(offer.id)

  const isOfferJoined = liaisonsOfferList.includes(offer.id)

  return (
    <div
      className="d-flex flex-wrap justify-content-between align-items-center">
      <div className="d-flex flex-wrap align-items-center">
        <div className="pr-2 mt-2">
          <MecButton
            type="primary"
            icon={StartIcon}
            widthIcon={15}
            height={35}
            onClick={isFavorite ? onRemoveFavorite : onAddFavorite}
          >
            {isFavorite ? "Supprimer des favoris" : "Mettre en favoris"}
          </MecButton>
        </div>
        <div className="pr-2 mt-2">
          <MecButton
            type="primary"
            onClick={handleCollapse}>
              {isOpen ? "Réduire l'offre détaillée" : "Voir l'offre détaillée"}
          </MecButton>
        </div>

        {!isOfferJoined && (
          <>
            <div className="pr-2 mt-2">
              <SelectCV
                options={get(profile, "cvList", [])}
                onChange={onChangeSelect} />
            </div>
            <div>
              <MecButton
                type="primary" height={35} onClick={onApply}
                disabled={isEmpty(cvSelected)}>
                Envoyer CV
              </MecButton>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export const FooterOffer = (props) => {
  const onShowModal = useDispatch("showModalAuth")

  const RECRUITER = isRecruiter()
  const CANDIDATE = isCandidate()

  const onApply = () => {
    if (!RECRUITER || !CANDIDATE) {
      onShowModal({ payload: ROLE.CANDIDATE })
    }
  }

  return CANDIDATE ? (
    <CandidateFooter {...props} />
  ) : (
    <GuestFooter {...props} onApply={onApply} />
  );
}
