import React, { useGlobal } from "reactn"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { isEmpty, get } from "lodash"

import {
  CardDetailsOfferWrapper,
  DetailsOfferWrapper,
  DetailsOfferLeftWrapper,
  DetailsOfferRightWrapper,
  LeftContentWrapper,
  LeftLogoWrapper,
  FooterCardWrapper,
  StyledFooter,
} from "./styles"
import { MecButton } from "src/markup/Components"
import { ASSET_QUALITYMAIN_URL, ASSET_QUALITY2_URL, ASSET_QUALITY3_URL } from "src/constants"

import { ReactComponent as IconCar } from "src/images/icons/icon-car.svg"
import { ReactComponent as IconView } from "src/images/icons/eye.svg"
import { ReactComponent as IconClock } from "src/images/icons/icon-clock.svg"
import { ReactComponent as IconCancel } from "src/images/icons/icon-cancel.svg"

import { populateOffer } from "src/services/config"
import { formatPrice, genderize } from "src/utils"

const WORDING_BY_MODE = {
  sent: "envoyée",
  accepted: "retenue",
  declined: "déclinée",
  favorite: "favorite enregistrée",
}

const FooterCard = ({ state, t, history, mode, data, currentOffer }) => {
  const ShowsDate = () => (
    <>
      {/* <img src={IconSend} alt="icon-send" className="mr-2" />
      <span>Candidature {WORDING_BY_MODE[mode]} le {data.sentDate}</span> */}
    </>
  )

  const FooterAction = () => {
    return (
      <FooterCardWrapper>
        <MecButton
          height={44}
          width={250}
          type="primary"
          className="site-button site-button-gap radius-xl custom-btn-footer"
          onClick={() => history.push(`/candidate/offers/${get(currentOffer, "offer.id", "")}`)}
        >
          {t("See the detailed offer")}
        </MecButton>
        <MecButton
          height={44}
          width={250}
          type="primary"
          className="site-button site-button-gap radius-xl custom-btn-footer"
          onClick={() => history.push(`/candidate/mycv/update/${get(currentOffer, "cvId", "")}`)}
        >
          {t("See the CV sent")}
        </MecButton>
      </FooterCardWrapper>
    )
  }

  switch (state) {
    case "view":
      return (
        <>
          <div className="text-right">
            <ShowsDate />

            {mode === "sent" && (
              <>
                <IconView className="mr-2 ml-4 custom-icon" width={24} height={24} />
                <span>{t("View")}</span>
              </>
            )}
          </div>

          <FooterAction />
        </>
      )

    case "hold":
      return (
        <>
          <div className="text-right">
            <ShowsDate />

            {mode === "sent" && (
              <>
                <IconClock className="mr-2 ml-4 custom-icon" width={24} height={24} />
                <span>{t("On hold")}</span>
              </>
            )}
          </div>
          <FooterAction />
        </>
      )

    case "closed":
      return (
        <>
          <div className="text-right">
            <ShowsDate />

            {mode === "declined" && (
              <>
                <IconCancel className="mr-2 ml-4" width={24} height={24} />
                <span>{t("Refusée")}</span>
              </>
            )}
          </div>
          <div className="text-right">
            <IconCancel className="mr-2 ml-4" width={24} height={24} />
            <span>{t("Offer closed or deleted")}</span>
          </div>
        </>
      )

    default:
      return <h3 className="text-orange mt-4">Offre détaillée</h3>
  }
}

const Card = ({ mode, state, offer: currentOffer }) => {
  const [config] = useGlobal("config")
  const [user] = useGlobal("user")
  const { t } = useTranslation()
  const history = useHistory()

  const gender = user?.gender?.toLowerCase()

  if (isEmpty(currentOffer.offer)) return null;
  const data = populateOffer(config, currentOffer.offer)

  if (isEmpty(data)) return null

  // qualityMainCard
  const data_qualityMains = gender ? data?.[`qualityMains_${gender}`] : data?.qualityMains

  const qualityMainCard = data?.qualityMains.map((qualityMain, index) => {
    return qualityMain ? (
      <div className="d-flex flex-column align-items-center" key={index}>
        <img className="" src={ASSET_QUALITYMAIN_URL + qualityMain.filename} alt={index + 1} />
        <div className="text-first-capitalize card-text" style={{ textAlignLast: "center" }}>
          {data_qualityMains[index].name}
        </div>
      </div>
    ) : null
  })

  // quality2Card
  const quality2Card = data?.quality2.map((Quality2, index) => {
    return Quality2 ? (
      <div className="d-flex flex-column align-items-center" key={index}>
        <img className="" src={ASSET_QUALITY2_URL + Quality2.filename} alt={index + 1} />
        <div className="text-first-capitalize card-text" style={{ textAlignLast: "center" }}>
          {Quality2.title}
        </div>
      </div>
    ) : null
  })

  // quality3Card
  const data_quality3 = gender ? data?.[`quality3_${gender}`] : data?.quality3
  const quality3Card = data?.quality3.map((Quality3, index) => {
    return Quality3 ? (
      <div className="d-flex flex-column align-items-center" key={index}>
        <img className="" src={ASSET_QUALITY3_URL + Quality3.filename} alt={index + 1} />
        <div className="text-first-capitalize card-text" style={{ textAlignLast: "center" }}>
          {data_quality3[index].title}
        </div>
      </div>
    ) : null
  })

  return (
    <CardDetailsOfferWrapper className="container">
      <DetailsOfferWrapper className="row justify-content-between">
        <DetailsOfferLeftWrapper border>
          <LeftLogoWrapper>
            <div className="d-flex justify-content-start align-items-center w-100 title-offers mb-4">
              <IconCar className="mr-2 icon-reponsive" />
              <div className="d-flex flex-column" style={{ maxWidth: 300 }}>
                <span>{data?.jobNames?.[0]}</span>
                <span>{get(currentOffer, "offer.name_company", "")}</span>
              </div>
            </div>
          </LeftLogoWrapper>

          <LeftContentWrapper>
            <div className="ml-3">Résumé de l'offre</div>
            <div>
              {genderize({
                male: "Doté",
                female: "Dotée",
                both: "Doté(e)",
                gender,
              })}{" "}
              d'un {data?.diplomas[0]} ou niveau équivalent, vous disposez d'un profil{" "}
              <span className="text-first-lowercase">{data?.xp?.[0]?.name}</span> et vous êtes doté de{" "}
              <span className="text-first-lowercase">{data?.xp?.[0]?.status}</span>  d'expérience en tant que :{" "}
              {data?.otherJobNames[0]}.
            </div>
          </LeftContentWrapper>
        </DetailsOfferLeftWrapper>

        <DetailsOfferRightWrapper className="d-flex flex-column justify-content-between" border>
          <div className="d-flex  mb-4 list-skills">
            {qualityMainCard}
            {quality2Card}
            {quality3Card}
          </div>
          <ul className="job-description infos text-blue ml-4 w-100">
            <li>
              <b>{t("Location")} :</b> {get(currentOffer,"offer.pres_city", "")}, {get(currentOffer,"offer.pres_zip", "")}
            </li>
            <br></br>
            <li>
              <b>{t("Contract")} :</b> {data?.contractTypes[0]}
            </li>
            <br></br>
            <li>
              <b>{t("Salary")} :</b> {formatPrice(get(currentOffer,"offer.salary", 0))} € Brut annuel
            </li>
          </ul>
        </DetailsOfferRightWrapper>

        <StyledFooter className="w-100 mt-5 ">
          <FooterCard history={history} state={state} t={t} mode={mode} data={data} currentOffer={currentOffer} />
        </StyledFooter>
      </DetailsOfferWrapper>
    </CardDetailsOfferWrapper>
  )
}

export default Card
