import React, { useGlobal, useState, useEffect } from "reactn"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"

import YourCompany from "src/markup/Element/OfferForms/YourCompany"
import Position from "src/markup/Element/OfferForms/Position"
import ProfileDefinition from "src/markup/Element/OfferForms/ProfileDefinition"
import { OfferFormInitialValues, OfferFormvalidationSchema, OfferFormRandomizedValues } from "./FormValidation"
import { MecButton } from "src/markup/Components/MecButton"

import { StyledCVForm as StyledOfferForm } from "src/markup/Element/CVForm/styles"
import { ReactComponent as IconRegister } from "src/images/icons/icon-register.svg"
import { ReactComponent as IconCancel } from "src/images/icons/icon-cancel.svg"
import { ReactComponent as IconChecked } from "src/images/icons/icon-checked.svg"
import Conditions from "./Conditions"
import { OFFER_STATUS, STATUS_CODE } from "src/constants"
import {
  recruiterCreateOffer,
  recruiterUpdateOffer,
} from "src/services/api"
import RouteLeavingGuard from "src/markup/Components/RouteLeavingGuard"
import { useGetProfile } from "src/markup/Pages/Profile/Information/useGetProfile"
import { ProfileModal } from "src/markup/Components/Modals"
import { ConfirmModal } from "src/markup/Components/Modals";
import { openInNewTab, not, productionMode } from "src/utils";
import { useCheckPackage } from "src/hooks/useCheckPackage"
import { Loading } from "src/markup/Element/Loading"
import { isEmpty } from "lodash"

const convertFormValues = (values) => {
  let shadowValues = { ...values }

  shadowValues.joblistId = shadowValues?.joblistId?.id
  shadowValues.pres_jobOtherName = shadowValues?.pres_jobOtherName?.id
  shadowValues.search_exp = shadowValues?.search_exp?.id
  shadowValues.mission = shadowValues?.mission?.map((mission) => ({
    search_mission: mission.search_mission,
    search_specMission: mission.search_specMission.map((specMission) => ({
      id: specMission.id,
      name: specMission.name,
    })),
  }))

  shadowValues.search_qualityMain = shadowValues?.search_qualityMain?.map((qualityMain) => ({
    qualityMainId: qualityMain.qualityMainId,
    name: qualityMain.name,
  }))
  shadowValues.search_quality2 = shadowValues?.search_quality2?.id
  shadowValues.search_quality3 = shadowValues?.search_quality3?.id
  shadowValues.skill = shadowValues?.skill?.map((skill) => ({
    search_skill: { ...skill.search_skill, joblistId: shadowValues.joblistId },
    search_skillComp: skill.search_skillComp.map((skillComp) => ({
      id: skillComp.id,
      name: skillComp.name,
    })),
  }))

  return shadowValues
}

const OfferForm = ({ initial, offerId, isModifiedInput }) => {
  const [completed, setCompleted] = useState([])
  const [isOpen, setIsOpen] = useState("company")
  const [isDraft, setIsDraft] = useState(false)
  const [isRouteGuard, setRouteGuard] = useState(true)
  const [toggle, setToggle] = useState(false)
  const [modal, setModal] = useState({ showConfirmModal: false });

  const [config] = useGlobal('config')
  const [user] = useGlobal("user")

  const profile = useGetProfile()
  const { t } = useTranslation()
  const history = useHistory()
  const { packages, hasPackage } = useCheckPackage();

  const handleAddEditOffer = async (values) => {
    try {
      setRouteGuard(false)
      const shadowValues = convertFormValues(values)

      if (offerId) { // EDIT MODE
        const res = await recruiterUpdateOffer({ ...shadowValues, id: offerId });

        if (res?.statusCode === STATUS_CODE.PLEASE_BUY_PACKAGE) {
          setModal(
            {
              showConfirmModal: true,
              onYes: () => openInNewTab('/pricing', setModal),
              onNo: () => setModal(false),
              Description: "Veuillez souscrire à une formule pour pouvoir publier d'autres offres",
            });

          return;
        } else {
          toast.success("L'offre a été mise à jour avec succès")
        }
      } else { // CREATE MODE
        const { status } = shadowValues;
        const { bypass } = user;

        if (!bypass && status === OFFER_STATUS.PUBLISHED) {
          if (hasPackage) {
            await recruiterCreateOffer({
              ...shadowValues,
              packageId: packages[0].id
            });
            toast.success("L'offre a été créée avec succès")
          } else {
            setModal({
              showConfirmModal: true,
              onYes: () => openInNewTab('/pricing', setModal),
              onNo: () => setModal(false),
              Description: "Veuillez souscrire à une formule pour pouvoir publier d'autres offres",
            });

            return;
          }
        } else {
          await recruiterCreateOffer({
            ...shadowValues,
          });
        }
      }

      history.push(shadowValues.status === OFFER_STATUS.DRAFT ? "/recruiter/myoffer/draft" : "/recruiter/myoffer")
    } catch (error) {
      console.error(error);
      return error
    }
  }

  useEffect(() => {
    if (profile?.surname === null || profile?.postalcode === null) {
      setToggle(true)
      setRouteGuard(false)
    }
  }, [profile])

  const formik = useFormik({
    initialValues: offerId ? initial : OfferFormInitialValues,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: () => OfferFormvalidationSchema({ t, isDraft }),
    onSubmit: (values) => handleAddEditOffer(values),
  })

  const { handleSubmit, isSubmitting, setFieldValue, values, setValues, errors } = formik

  const handleCompleted = (e, value) => {
    e.preventDefault()
    setCompleted((prevState) => [...prevState, value])
  }

  const handleAddMission = () => {
    const data = [
      ...values.mission,
      {
        id: values.mission[values.mission.length - 1].id + 1,
        search_mission: null,
        search_specMission: [],
      },
    ]

    if (data.length > 5) {
      toast.warn("Le nombre maximum de missions est atteint")
    } else {
      setFieldValue("mission", data)
    }
  }

  const handleAddSkill = () => {
    const data = [
      ...values.skill,
      {
        id: values.skill[values.skill.length - 1].id + 1,
        search_skill: null,
        search_skillComp: [],
      },
    ]
    if (data.length > 5) {
      toast.warn("Le nombre maximum de compétences est atteint")
    } else {
      setFieldValue("skill", data)
    }
  }

  const handleOpenCollapse = (collapse) => {
    setIsOpen((prev) => (prev === collapse ? "" : collapse))
  }

  const handleDeleteItem = (item, index) => {
    const shadow = [...values[item]]
    setValues({
      ...values,
      [item]: shadow.filter((ite) => ite.id !== index),
    })
  }

  const handleRandomFill = () => {
    setValues(OfferFormRandomizedValues(config))
  }

  return (
    <>
      {isRouteGuard && <RouteLeavingGuard shouldBlockNavigation={() => true} />}

      {!productionMode() &&
        <div className="mb-4" style={{ textAlign: "-webkit-right" }} >
          <MecButton
            icon={IconChecked}
            type="primary"
            width={300}
            height={50}
            widthIcon={20}
            onClick={(e) => {
              e.preventDefault();
              handleRandomFill();
            }}>
              [DEV] Remplir aléatoirement
          </MecButton>
        </div>
      }

      <StyledOfferForm onSubmit={handleSubmit}>
        {not(config) && <Loading />}
        <YourCompany
          formik={formik}
          handleCompleted={handleCompleted}
          completed={completed}
          isOpen={isOpen}
          handleOpenCollapse={handleOpenCollapse}
          disabled={isModifiedInput}
        />
        <Position
          formik={formik}
          handleCompleted={handleCompleted}
          completed={completed}
          handleAddMission={handleAddMission}
          isOpen={isOpen}
          handleOpenCollapse={handleOpenCollapse}
          handleDeleteItem={handleDeleteItem}
          disabled={isModifiedInput}
        />
        <ProfileDefinition
          formik={formik}
          handleCompleted={handleCompleted}
          completed={completed}
          handleAddSkill={handleAddSkill}
          isOpen={isOpen}
          handleOpenCollapse={handleOpenCollapse}
          handleDeleteItem={handleDeleteItem}
        />
        <Conditions
          formik={formik}
          handleCompleted={handleCompleted}
          completed={completed}
          isOpen={isOpen}
          handleOpenCollapse={handleOpenCollapse}
        />

        <div className="w-100 d-flex flex-wrap justify-content-center button-action pb-5">
          <MecButton
            icon={IconCancel}
            type="primary"
            width={300}
            height={50}
            widthIcon={20}
            className="text-capitalize outline"
            onClick={(e) => {
              e.preventDefault();
              history.push("/recruiter/myoffer")
            }}
          >
            Annuler
          </MecButton>

          <MecButton
            icon={IconRegister}
            type="primary"
            width={300}
            height={50}
            widthIcon={20}
            className="text-capitalize"
            onClick={(e) => {
              setIsDraft(true)
              setFieldValue("status", OFFER_STATUS.DRAFT)
            }}
            disabled={
              isSubmitting || values.status === OFFER_STATUS.PUBLISHED
            }
          >
            {isSubmitting ? "..." : "Enregistrer"}
          </MecButton>

          <MecButton
            icon={IconChecked}
            type="primary"
            width={300}
            height={50}
            widthIcon={20}
            className="text-capitalize"
            onClick={(e) => {
              setIsDraft(false)
              setFieldValue("status", OFFER_STATUS.PUBLISHED)
            }}
            disabled={isSubmitting || !isEmpty(errors)}
          >
            {isSubmitting ? "..." : "Publier"}
          </MecButton>
        </div>

        <ProfileModal
          toggle={toggle}
          title="METTRE À JOUR LE PROFIL"
          content="Vous devez remplir toutes les informations de votre profil avant de créer une offre"
          to="/recruiter/profile"
        />
      </StyledOfferForm>

      {/* confirm modal */}
      <ConfirmModal {...modal} />
    </>
  )
}

export default OfferForm
