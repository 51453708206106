import styled from "styled-components"
import Autocomplete from "react-google-autocomplete"

export const AutocompleteLocation = styled(Autocomplete)`
  background: #e8eef2;
  padding: 12px 7px 12px 20px;
  color: var(--blue600);
  border-radius: 20px;
  border: ${(props) => props.error ? "2px solid red" : "none"};
  outline: none;
  overflow: hidden;
  text-align-last: left;
  cursor: pointer;

  height: 52px;
  width: 100%;
`

export const Feedback = styled.div`
  color: ${({ colorTxtError = "red" }) => colorTxtError};
  font-size: 14px;
  padding: 10px 0 0 0;

  width: 100%;
  text-align-last: left;
`
