import styled from "styled-components";

export const Wrapper = styled.input`
  color: var(--blue600);
  padding: 12px 7px 12px 20px;
  width: 100%;

  border-radius: 20px;

  &.primary {
    background: var(--white);
  }

  &.secondary {
    background: #e8eef2;
  }
  background: ${(props) => props.isDisabled && "#f0f5f7"} !important;
  opacity: ${(props) => props.isDisabled && "0.8"} !important;

  outline: none;
  border: ${(props) => props.error ? "2px solid red" : "none"};

  &:focus,
  &:active,
  &:target,
  &:hover {
    border: ${(props) => props.error ? "2px solid red" : "none"};
  }
`;

export const Feedback = styled.div`
  color: ${({ colorTxtError = "red" }) => colorTxtError};
  font-size: 14px;
  padding: 10px 0 0 0;

  width: 100%;
  text-align-last: left;
`;
