/* eslint-disable array-callback-return */

import { setGlobal } from "reactn";
import moment from 'moment'
import "moment/locale/fr";
import axios from "./axios";
import { map, get, filter, find, keyBy } from 'lodash';
import { not } from "src/utils";
import { API_URL } from "src/constants"

export const fetchConfigData = () => {
  axios
    .get(`${API_URL}/infos/config`)
    .then(({ result }) => {
      setGlobal({ config: result });
    })
    .catch(console.log);
}

export const fetchFormulas = () => {
  axios
    .get(`${API_URL}/infos/packages`)
    .then((data) => {
      setGlobal({ formulas: data });
    })
    .catch(console.log);
}

export const populateOffer = (config, offer) => {
  const data = {};
  if (!config) return data;

  data.name_company = get(offer, "name_company", "");
  data.pres_city = get(offer, "pres_city", "");

  data.salary = get(offer, "salary", 0);
  data.extra_criminalRecord = get(offer, "extra_criminalRecord", false);
  data.otherAdvantage = get(offer, "otherAdvantage", "");

  const jobListIds = map(offer.joblistJobs, 'mainJobId');
  data.jobNames = config.jobs.filter(({ id }) => jobListIds.includes(id)).map(({ name }) => name);

  const otherJobListIds = map(offer.joblistJobs, 'otherJobId');
  data.otherJobNames = config.otherJobs.filter(({ id }) => otherJobListIds.includes(id)).map(({ name }) => name);

  const qualityMainIds = map(offer.qualityMainJobs, 'qualityMainId');
  data.qualityMains = config.qualityMain.filter(({ id }) => qualityMainIds.includes(id));
  data.qualityMains_m = config.qualityMain_m.filter(({ id }) => qualityMainIds.includes(id));
  data.qualityMains_f = config.qualityMain_m.filter(({ id }) => qualityMainIds.includes(id));

  const quality2Ids = map(offer.quality2Jobs, 'quality2Id');
  data.quality2 = config.quality2.filter(({ id }) => quality2Ids.includes(id));

  const quality3Ids = map(offer.quality3Jobs, 'quality3Id');
  data.quality3 = config.quality3.filter(({ id }) => quality3Ids.includes(id));
  data.quality3_m = config.quality3_m.filter(({ id }) => quality3Ids.includes(id));
  data.quality3_f = config.quality3_f.filter(({ id }) => quality3Ids.includes(id));

  const contractTypes = map(offer.recruitmentJobs, 'contractId');
  data.contractTypes = config.contractTypes.filter(({ id }) => contractTypes.includes(id)).map(({ name }) => name);

  const skillIds = map(offer.skillJobs, 'skillId');
  data.skills = config.skills.filter(({ id }) => skillIds.includes(id)).map(({ name }) => name);

  const skillCompIds = map(offer.skillCompJobs, 'skillCompId');
  data.skillsComp = config.skillsComp.filter(({ id }) => skillCompIds.includes(id)).map(({ name }) => name);

  const driverLicenseOtherIds = map(offer.driverLicenseOtherJobs, 'licenseId');
  data.driverLicenseOthers = config.otherDriverLicenses.filter(({ id }) => driverLicenseOtherIds.includes(id)).map(({ name }) => name);

  const languageIds = map(offer.languagesJobs, 'langId');
  data.languages = config.languages.filter(({ id }) => languageIds.includes(id));

  const toolIds = map(offer.toolsJobs, 'toolId');
  data.tools = config.tools.filter(({ id }) => toolIds.includes(id)).map(({ name }) => name);

  const diplomaIds = map(offer.formationJobs, 'xpId');
  data.diplomas = config.diplomas.filter(({ id }) => diplomaIds.includes(id)).map(({ name }) => name);

  const xpIds = map(offer.experienceJobs, 'lvlId');
  data.xp = config.xp.filter(({ id }) => xpIds.includes(id));
  data.xp_m = config.xp_m.filter(({ id }) => xpIds.includes(id));
  data.xp_f = config.xp_f.filter(({ id }) => xpIds.includes(id));

  const recruiterObjectiveIds = map(offer.recruitmentJobs, 'objectiveId');
  data.recruiterObjectives = config.recruiterObjectives.filter(({ id }) => recruiterObjectiveIds.includes(id)).map(({ name }) => name);

  const responsabilityIds = map(offer.recruitmentJobs, 'responsabilityId');
  data.responsabilities = config.responsabilities.filter(({ id }) => responsabilityIds.includes(id)).map(({ name }) => name);

  const missionIds = map(offer.missionJobs, 'missionId');
  data.missions = config.missions.filter(({ id }) => missionIds.includes(id)).map(({ name }) => name);

  const missionSpecIds = map(offer.missionCompJobs, 'missionCompId');
  data.missionComp = config.missionSpecs.filter(({ id }) => missionSpecIds.includes(id)).map(({ name }) => name);

  const advantageIds = map(offer.advantages, 'advantageId');
  data.advantages = config.advantages.filter(({ id }) => advantageIds.includes(id)).map(({ name }) => name);

  data.sentDate = new Date(offer.createdAt).toLocaleDateString("fr-FR");

  const customMission = get(offer, 'missionJobs', [])?.map((eMission, idx) => {
    const search_mission = get(config, "missions", [])?.find((mission) => mission.id === eMission.missionId)

    const specMissionMatched = get(offer, 'missionCompJobs', [])?.filter(m => m.missionId === eMission.id);
    const search_specMission = specMissionMatched?.map(el => {
      const value = get(config, "missionSpecs", []).find(specMission => specMission.id === el.missionCompId)
      return value;
    })

    return {
      id: idx + 1,
      search_mission,
      search_specMission
    };
  })

  data.customMission = customMission

  const customSkill = get(offer, 'skillJobs', []).map((skill, idx) => {
    const search_skill = get(config, "skills", []).find((cSkill) => cSkill?.id === skill?.skillId)

    const skillCompMatched = get(offer, 'skillCompJobs', []).filter(s => s?.skillId === skill?.id);
    const search_skillComp = skillCompMatched.map(el => {
      const value = get(config, "skillsComp", []).find(cSkillComp => cSkillComp.id === el.skillCompId)
      return value;
    })

    return {
      id: idx + 1,
      search_skill,
      search_skillComp
    };
  })

  data.customSkill = customSkill;

  return data;
}

export const convertCVData = (config, cv) => {
  let data = {};
  if (!config || !cv) return data;

  // name cv
  const nameCV = get(cv, 'name_cv', '');
  const city = get(cv, 'city', '');

  // jobName
  const jobName = cv?.joblistCVs?.map((joblistcvs) => {
    const jobNameValue = config?.jobs.find((jobs) => joblistcvs.jobId === jobs?.id);
    if (not(jobNameValue)) return null;
    return jobNameValue;
  });

  // jobOtherName
  const jobOtherName = cv?.joblistCVs?.map((joblistcvs) => {
    const jobOtherNameValue = config?.otherJobs.filter((jobOtherNames) => joblistcvs.otherJobId === jobOtherNames?.id);
    if (not(jobOtherNameValue)) return null;
    return jobOtherNameValue;
  });

  // qualityMain
  const gender = cv?.user?.gender?.toLowerCase();
  const config_qualityMain = gender ? config?.[`qualityMain_${gender}`] : config?.qualityMain;
  // expYears
  var expYears = 0;
  cv?.experiences?.map((xps) => {
    const entryDate = new Date(xps.expEntryDate);
    const exitDate = new Date(xps.expExitDate);
    const date = exitDate.getTime() - entryDate.getTime();
    expYears += Math.round(date / (1000 * 60 * 60 * 24 * 30 * 12));

    return expYears;
  });

  const qualityMain = cv?.qualityMains?.map((qualityMain) => {
    const qualityMainValue = config_qualityMain?.filter((qualityMains) => qualityMain.qualityMainId === qualityMains.id);
    if (not(qualityMainValue)) return null;
    return qualityMainValue;
  });

  // qualityMainCard
  const qualityMainCard = cv?.qualityMains?.map((qualityMain, index) => {
    const qualityMainCardValue = config?.qualityMain?.filter((qualityMains) => qualityMain.qualityMainId === qualityMains.id);
    const qualityMainCardValueG = config_qualityMain?.filter((qualityMains) => qualityMain.qualityMainId === qualityMains.id);
    if (not(qualityMainCardValue)) return null;
    return {
      ...qualityMain,
      qualityMainCardValue,
      qualityMainCardValueG
    }
  });

  // quality2
  const quality2 = cv?.quality2s?.map((Quality2, index) => {
    const quality2Value = config?.quality2.filter((Quality2s) => Quality2.quality2Id === Quality2s.id);
    if (not(quality2Value)) return null;
    return quality2Value;
  });

  // quality2Card
  const quality2Card = cv?.quality2s?.map((Quality2, index) => {
    const quality2CardValue = config?.quality2.filter((Quality2s) => Quality2.quality2Id === Quality2s.id);
    if (not(quality2CardValue)) return null;
    return quality2CardValue
  });

  // quality3
  const quality3 = cv?.quality3s?.map((Quality3, index) => {
    const config_quality3 = gender ? config?.[`quality3_${gender}`] : config?.quality3;

    const quality3Value = config_quality3?.filter((Quality3s) => Quality3.quality3Id === Quality3s.id);
    if (not(quality3Value)) return null;
    return quality3Value
  });

  // quality3Card
  const quality3Card = cv?.quality3s?.map((Quality3, index) => {
    const quality3CardValue = config?.quality3?.filter((Quality3s) => Quality3.quality3Id === Quality3s.id);
    if (not(quality3CardValue)) return null;
    return quality3CardValue;
  });

  // exp
  const exp = cv?.experiences?.map((exp, index) => {
    var expEntryDate = new Date(exp.expEntryDate).toLocaleDateString("fr-FR");
    var expExitDate = new Date(exp.expExitDate).toLocaleDateString("fr-FR");
    return {
      ...exp,
      expEntryDate,
      expExitDate
    }
  });

  // level exp cv
  const levelExp = config?.xp_m.filter((xpm) => xpm.id === cv?.experienceCV?.lvlId)

  // formation
  const formations = get(cv, "formations", []).map((form, index) => {
    var formEntryDate = Boolean(form?.formEntryDate) && new Date(form.formEntryDate).toLocaleDateString("fr-FR");
    var formExitDate = Boolean(form?.formExitDate) &&  new Date(form.formExitDate).toLocaleDateString("fr-FR");
    const diplomaValue = config?.diplomas?.filter((formations) => form?.diploma === formations?.id);
    const diplomaOptionValue = config?.diplomaOptions?.filter((formations) => form?.diplomaOption === formations?.id);
    if (not(diplomaValue)) return null;
    if (not(diplomaOptionValue)) return null;

    return {
      ...form,
      formEntryDate,
      formExitDate,
      diplomaValue,
      diplomaOptionValue
    }
  });

  // skill
  const skills = cv.skills.map((skill, index) => {
    const skillValue = config?.skills.filter((skills) => skill?.skillId === skills?.id);
    if (not(skillValue)) return null;
    return skillValue
  });

  // skillResume
  const skillsResume = cv.skills.map((skill, index) => {
    const skillValue = config?.skills.filter((skills) => skill?.skillId === skills?.id);
    if (not(skillValue)) return null;
    return skillValue
  });

  // skillComp
  const skillComps = cv.skillComps.map((skillComp, index) => {
    const skillCompValue = config?.skillsComp.filter((skillComps) => skillComp.skillCompId === skillComps.id);
    if (not(skillCompValue)) return null;
    return skillCompValue;
  });

  // skillCompResume
  const skillCompsResume = cv.skillComps.map((skillComp, index) => {
    const skillCompValue = config?.skillsComp.filter((skillComps) => skillComp.skillCompId === skillComps.id);
    if (not(skillCompValue)) return null;
    return skillCompValue;
  });

  // languages
  const languages = cv.languages.map((lang, index) => {
    const languagesValue = config?.languages?.filter((languages) => lang?.langId === languages.id);
    if (not(languagesValue)) return null;
    return languagesValue;
  });

  // tools
  const tools = cv.tools.map((tool, index) => {
    const toolsValue = config?.tools?.filter((tools) => tool?.toolId === tools.id);
    if (not(toolsValue)) return null;
    return {
      ...toolsValue,
      level: tool.level_use_tool
    };
  });

  // missions
  const mission = cv?.missions?.map((missions, index) => {
    const missionValue = config?.missions.filter((mission) => missions.missionId === mission.id);
    if (not(missionValue)) return null;
    return missionValue;
  });

  // missionsComps
  const missionComp = cv?.missionComps?.map((missions, index) => {
    const missionValue = config?.missionSpecs.filter((mission) => missions.missionId === mission.id);
    if (not(missionValue)) return null;
  });

  //sentDate
  var sentDate = new Date(cv.createdAt).toLocaleDateString("fr-FR");

  const licenseList = get(cv, 'driverLicenses', []).map((l) =>
    get(config, 'otherDriverLicenses', []).find((driverLicense) => driverLicense.id === l.licenseId && { name: driverLicense.name }) )

  return {
    nameCV,
    city,
    jobName,
    jobOtherName,
    config_qualityMain,
    expYears,
    skills,
    skillsResume,
    skillComps,
    missionComp,
    mission,
    tools,
    languages,
    skillCompsResume,
    formations,
    exp,
    levelExp,
    quality3Card,
    quality3,
    quality2Card,
    quality2,
    qualityMainCard,
    qualityMain,
    sentDate,
    licenseList
  };
}

export const getConfigByJob = ({ config, jobId }) =>
  jobId ? filter(config, (job) => job.joblistId === jobId) : [];

export const getJobById = ({ config, otherJobId, jobId }) => {
  const jobName = jobId ? find(config.jobs, (job) => job.id === jobId)?.name : '';
  const otherJobName = otherJobId ? find(config.otherJobs, (job) => job.id === otherJobId)?.name : '';

  return {
    jobName,
    otherJobName
  }
}

export const parseDateTime = (datetime) => {
  return new moment(datetime).locale('fr').format('LLL');
}

export const convertDetailCV = (config, cv) => {
  const name_cv = get(cv, "name_cv", "")
  const city = get(cv, "city", "")
  const city_range = get(cv, "city_range", "")
  const status_cv = get(cv, "status", null)
  const extra_otherInfos = get(cv, "extra_otherInfos", "")

  const pres_jobOtherName = get(cv, "joblistCVs", []).map(({ otherJobId }) => {
    const otherJobKeyBy = keyBy(get(config, "otherJobs", []), "id")
    return otherJobKeyBy[otherJobId]
  })[0]

  // tools
  const currentTools = get(cv, "tools", [])
  const configTools = get(config, "tools", [])

  const tools = currentTools.length > 0 ? currentTools.map((currentTool) => {
    const toolsValue = configTools.filter((configTool) => currentTool.toolId === configTool.id)
    if (not(toolsValue)) return null
    return {
      ...toolsValue[0],
      level: currentTool.level_use_tool,
    }
  }) : [
    {
      keyId: 1,
      id: "",
      name: "",
      level: 1,
    },
  ]

  const extra_criminalRecord = get(cv, "extra_criminalRecord", false)

  const extra_driversLicenceB = get(cv, "driverLicenses", []).map((driverLicense) => {
    const values =  get(config, "otherDriverLicenses", []).find((licence) => licence.id === driverLicense.licenseId)
    return { ...values };
  })

  const languages = get(cv, "languages", []).map((lang) => {
    const languagesValue = get(config, "languages", []).find((languages) => lang.langId === languages.id)
    return languagesValue
  })

  const search_quality3 = get(cv, "quality3s", []).map((current) => {
    const value = get(config, "quality3", []).find((config) => {
      if (config.quality3Id === current.quality3Id) {
        const { filename, id, name, title } = config
        return { filename, id, name, title }
      }
    })
    return value;
  })[0]

  const search_quality2 = get(cv, "quality2s", []).map((current) => {
    const value = get(config, "quality2", []).find((config) => {
      if (config.id === current.quality2Id) {
        const { filename, id, name, title } = config
        return { filename, id, name, title }
      }
    })
    return value
  })[0]

  const search_qualityMain = get(cv, "qualityMains", []).map((current) => {
    const value = get(config, "qualityMain", []).find((config) => {
      if (config.qualityMainId === current.qualityMainId) {
        const { filename, qualityMainId, id, name } = config
        return { filename, qualityMainId, id, name }
      }
    })
    if (not(value)) return null
    return value
  })

  const configJobs = get(config, "jobs", [])
  const configDiplomas = get(config, "diplomas", [])
  const configDiplomaOptions = get(config, "diplomaOptions", [])

  const formations = cv?.formations.length > 0 ? get(cv, "formations", []).map((formation, idx) => {
    const { domain_activity, diploma, diplomaOption, formSchool, formCity, formEntryDate, formExitDate, formMissions } =
      formation
    const jobKeyBy = keyBy(configJobs, "id")
    const diplomaKeyBy = keyBy(configDiplomas, "id")
    const configDiplomaOptionsKeyBy = keyBy(configDiplomaOptions, "id")

    return {
      id: idx,
      domain_activity: jobKeyBy[domain_activity],
      formSchool,
      formCity,
      formDiploma: diplomaKeyBy[diploma],
      formDiplomaOption: configDiplomaOptionsKeyBy[diplomaOption],
      formEntryDate,
      formExitDate,
      formMissions,
    }
  }) : [
    {
      id: 1,
      domain_activity: null,
      formSchool: "",
      formCity: "",
      formDiploma: null,
      formDiplomaOption: null,
      formEntryDate: "",
      formExitDate: "",
      formMissions: "",
    },
  ]

  const experiences = cv?.experiences.length > 0 ? get(cv, "experiences", []).map((experience, idx) => ({
    ...experience,
    id: idx,
  })) : [
    {
      id: 1,
      expJobName: "",
      expCompany: "",
      expEntryDate: "",
      expExitDate: "",
      expCity: "",
      expMissions: "",
    },
  ]

  const joblistId = get(cv, "joblistCVs", []).map(({jobId}) => {
    const value = get(config, 'jobs', []).find((job) => job.id === jobId);
    return value;
  })[0];

  const skill = get(cv, 'skills', []).map((skill, idx) => {
    const search_skill = get(config, "skills", []).find((cSkill) => cSkill.id === skill.skillId)

    const skillCompMatched = get(cv, 'skillComps', []).filter(s => s.skillId === skill.id);
    const search_skillComp = skillCompMatched.map(el => {
      const value = get(config, "skillsComp", []).find(cSkillComp => cSkillComp.id === el.skillCompId)
      return value;
    })

    return {
      id: idx + 1,
      search_skill,
      search_skillComp
    };
  })

  const mission = get(cv, 'missions', []).map((eMission, idx) => {
    const search_mission = get(config, "missions", []).find((mission) => mission.id === eMission.missionId)

    const specMissionMatched = get(cv, 'missionComps', []).filter(m => m.missionId === eMission.id);
    const search_specMission = specMissionMatched.map(el => {
      const value = get(config, "missionSpecs", []).find(specMission => specMission.id === el.missionCompId)
      return value;
    })

    return {
      id: idx + 1,
      search_mission,
      search_specMission
    };
  })

  const search_exp = get(config, 'xp', []).find(expCV => expCV.expId  === get(cv, 'experienceCV', []).lvlId);

  return {
    joblistId,
    search_exp,
    mission,
    skill,
    pres_jobOtherName,
    name_cv,
    status_cv,
    city,
    city_range,
    experiences,
    formations,
    tools,
    search_qualityMain,
    search_quality2,
    search_quality3,
    languages,
    extra_driversLicenceB,
    extra_criminalRecord,
    extra_otherInfos,
  }
};

export const convertOfferDataForm = (config, offer) => {
  const data = {};
  if (!config) return data;

  const recruiterObjectiveIds = map(offer.recruitmentJobs, 'objectiveId');
  data.recruiterObjectives = config.recruiterObjectives.filter(({ id }) => recruiterObjectiveIds.includes(id)).map(({ name }) => name);

  return data;
}

export const addDays = (date, day = 1) => {
  date && date.setDate(date.getDate() + day);
  return date;
}

export const subDays = (date, day = 1) => {
  date && date.setDate(date.getDate() - day);
  return date;
}
