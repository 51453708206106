import React from "reactn";
import PropTypes from "prop-types";

import IconArrow from "src/images/icons/icon-arrow.svg";
import { ReactComponent as IconCirCleNonCheck } from "src/images/icons/icon-circle-non-check.svg";
import { ReactComponent as IconChecked } from "src/images/icons/icon-checked.svg";
import {
  CollapseTitle,
  CollapseWrapper,
  StyledCollapse,
  StyledCollapseWrapper,
} from "src/markup/Components/Forms/MecCollapse/styles";

export const MecCollapse = ({ title, isOpen, setIsOpen, children, className, completed, disabled, ...rest }) => {
  return (
    <StyledCollapseWrapper {...rest} className={className}>
      <CollapseWrapper>
        <CollapseTitle
          onClick={() => setIsOpen((prevState) => !prevState)}
          isOpen={isOpen}
        >
          <div className="title">
            {completed ? (
              <IconChecked className="icon-checked" />
            ) : (
              <IconCirCleNonCheck />
            )}
            <span>{title}</span>
          </div>
          <img src={IconArrow} alt="icon-arrow" className="title-icon" />
        </CollapseTitle>
        <StyledCollapse isOpen={isOpen} disabled={disabled}>{children}</StyledCollapse>
      </CollapseWrapper>
    </StyledCollapseWrapper>
  );
};

MecCollapse.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  children: PropTypes.node.isRequired,
};

MecCollapse.defaultProps = {
  completed: false,
};
