import React, { useEffect, useCallback, useState, useGlobal } from "reactn";
import { get } from 'lodash'
import { capitalize, formatPrice, genderize } from 'src/utils'

import {
  CardWrapper,
  CardContent,
  CardInfor,
  CardTitle,
  UrgentCardWrapper,
  CardLogo,
} from "./styles";
import LogoDEFAULT from "src/images/logo-mecanojob-2.png";
import { getAllOffers } from 'src/services/api'
import { DEFAULT_LIMIT, OFFER_STATUS } from "src/constants";
import { populateOffer } from "src/services/config";
import CardLoadingSkeleton from 'src/markup/Pages/Homepage/OffersUrgent/CardLoadingSkeleton'

import { FooterOfferUrgent } from "src/markup/Pages/Homepage/OffersUrgent/FooterOfferUrgent"

const MecUrgentCard = () => {
  const [listOfferUrgent, setListOfferUrgent] = useState([])
  const [config] = useGlobal('config');

  const getFeaturedOffers = useCallback(async () => {
    try {
      const res = await getAllOffers({
        status: OFFER_STATUS.PUBLISHED,
        featured: true,
        limit: DEFAULT_LIMIT.FEATURED_OFFER_HOMEPAGE,
      })

      if (res?.length) {
        setListOfferUrgent(res);
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getFeaturedOffers()
  }, [getFeaturedOffers])

  return (
    <UrgentCardWrapper>
      {listOfferUrgent.length > 0 ? listOfferUrgent.map((offerUrgent) => {
        const offer = populateOffer(config, offerUrgent);
        const gender = offerUrgent?.user?.gender?.toLowerCase() || null

        return (
          <CardWrapper key={offerUrgent.id} className="tween-card">
            <CardLogo className="d-flex justify-content-center">
              <img
                src={offerUrgent.offer_logo || LogoDEFAULT}
                alt="logo"
              />
            </CardLogo>

            <CardTitle>
              <div>RECRUTE</div>
              <div>{get(offer, 'jobNames[0]', '')}</div>
            </CardTitle>

            <CardContent>
              <div className="ml-3">Résumé de l'offre</div>
              <div>
                {genderize({
                  male: "Doté",
                  female: "Dotée",
                  both: "Doté(e)",
                  gender,
                })}{" "}
                d'un {get(offer, 'diplomas[0]', '')} ou niveau équivalent, vous disposez d'un profil{" "}
                <span className="text-first-lowercase">{get(offer, 'xp[0].name', '')}</span> et vous êtes doté de{" "}
                <span className="text-first-lowercase">{get(offer, 'xp[0].status', '')}</span>  d'expérience en tant que :{" "}
                {get(offer, 'otherJobNames[0]', '')}.
              </div>
            </CardContent>

            <CardInfor>
              <ul className="job-description infos text-blue">
                <li>
                  <b>Localisation :</b> {[capitalize(offerUrgent?.pres_city), offerUrgent?.pres_zip].filter(Boolean).join(', ')}
                </li>
                <br></br>
                <li>
                  <b>Contrat :</b> {offer?.contractTypes?.[0]}
                </li>
                <br></br>
                <li>
                  <b>Salaire :</b> {formatPrice(offerUrgent.salary)} € Brut annuel
                </li>
              </ul>
            </CardInfor>
            <FooterOfferUrgent offer={offerUrgent} />
          </CardWrapper>
        )
      }) : [...Array(3)].map((item,index) => <CardLoadingSkeleton key={index} />)}
    </UrgentCardWrapper>
  )
};

export default MecUrgentCard;
