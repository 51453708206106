import React from "react";
import PropTypes from "prop-types";
import { StyledTabSwitch, Tab, TabSwitchWrapper } from "./styles";

const propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.array,
  activeTab: PropTypes.number,
  onChange: PropTypes.func,
};

const defaultProps = {};

export const MecTabSwitch = (props) => {
  const {
    className,
    tag: Tag,
    tabs,
    activeTab,
    onChange,
    ...attributes
  } = props;

  return (
    <TabSwitchWrapper className={className} {...attributes}>
      <StyledTabSwitch as={Tag} className={`active-tab-${activeTab}`}>
        {tabs.map(({ name }, idx) => (
          <Tab
            key={idx}
            className={"tab" + (activeTab === idx ? " active" : "")}
            onClick={() => onChange(idx)}
          >
            <span>{name}</span>
          </Tab>
        ))}
      </StyledTabSwitch>
    </TabSwitchWrapper>
  );
};

MecTabSwitch.propTypes = propTypes;
MecTabSwitch.defaultProps = defaultProps;
