import React, { useCallback } from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { MecInput } from 'src/markup/Components/Forms'
import { ProfileButton } from 'src/markup/Pages/Profile/components/ProfileButton'
import * as S from 'src/markup/Pages/Profile/UpdatePassword/styles'
import { updatePasswordSchema } from 'src/markup/Pages/Profile/UpdatePassword/validationSchema'
import { changePassword } from 'src/services/auth'
import { useHistory } from 'react-router-dom'

export const RecruiterUpdatePassword = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      password: '',
      new_password: '',
      confirm_new_password: '',
    },
    validationSchema: () => updatePasswordSchema({ t }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const res = await changePassword(values)
        if (res) {
          toast.success(`Mot de passe mis à jour`, {
            autoClose: 2000,
            theme: 'colored',
          })
          resetForm()
        }
      } catch (error) {
        console.log({ error })
      }
    },
  })

  const onBack = useCallback(() => {
    history.push('/recruiter/myoffer')
  }, [history])

  return (
    <S.Wrapper>
      <div className="d-flex justify-content-center">
        <S.Heading>Mot de passe</S.Heading>
      </div>
      <hr className="v3-separator pb-xl-3" />

      <S.WrapperContent>
        <form>
        <S.Title>Modifier mon mot de passe</S.Title>
          <div>
            <div className="py-2 label">Mot de passe actuel</div>
            <MecInput
              name="password"
              type="password"
              formik={formik}
              className="secondary"
            />
          </div>
          <div>
            <div className="py-2 label">Nouveau mot de passe</div>
            <MecInput
              name="new_password"
              type="password"
              formik={formik}
              className="secondary"
            />
          </div>
          <div>
            <div className="py-2 label">Confirmer le mot de passe</div>
            <MecInput
              name="confirm_new_password"
              type="password"
              formik={formik}
              className="secondary"
            />
          </div>
        </form>
      </S.WrapperContent>
      <ProfileButton
        onSubmit={formik.handleSubmit}
        className="mt-4"
        onCancel={onBack}
      />
    </S.Wrapper>
  )
}
