import React from 'react'

import styled from 'styled-components'

const Checkbox = styled.div`
  display: inline-block;

  .checkbox {
    cursor: pointer;
    position: relative;
    color: ${({errors, colorCheckboxError}) => errors ? colorCheckboxError : 'var(--white)'};
    width: 100%;

    & > a {
      transition: all 0s;
      color: var(--white);
    }
  }

  .checkbox > input {
    color: ${({errors, colorCheckboxError}) => errors ? colorCheckboxError : 'var(--white)'};
    height: 17px;
    width: 17px;
    appearance: none;
    border: 1px solid ${({errors, colorCheckboxError}) => errors ? colorCheckboxError : 'var(--white)'};
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    margin-right: 10px;
  }

  .checkbox > input:checked {
    background-color: ${({recruiter}) => recruiter ?  'var(--blue600)' : 'var(--orange300)'}
  }
`

export const MecCheckBox = ({ recruiter, onChange, children, errors, colorCheckboxError }) => {
  return (
    <Checkbox recruiter={recruiter} errors={errors} colorCheckboxError={colorCheckboxError}>
      <label className='checkbox'>
        <input type='checkbox' onChange={onChange} />
        {children}
      </label>
    </Checkbox>
  )
}
