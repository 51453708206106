import React, { useGlobal } from 'reactn'
import { isEmpty, get } from 'lodash'
import { populateOffer } from 'src/services/config'
import {
  CardDetailsOfferWrapper,
  DetailsOfferWrapper,
  DetailsOfferLeftWrapper,
  DetailsOfferRightWrapper,
  LeftContentWrapper,
  LeftLogoWrapper,
} from 'src/markup/Components/Cards/OfferDetailCard/styles'
import { capitalize, formatPrice, genderize } from 'src/utils'
import { MecButton } from 'src/markup/Components'
import {
  ASSET_QUALITYMAIN_URL,
  ASSET_QUALITY2_URL,
  ASSET_QUALITY3_URL,
} from 'src/constants'

import LogoDEFAULT from 'src/images/logo-mecanojob-2.png'
import { ReactComponent as IconNote } from 'src/images/icons/note.svg'
import { ReactComponent as IconCar } from 'src/images/icons/icon-car.svg'

const OfferDetailCard = ({
  setToggle,
  isOfferDetails = false,
  footer = false,
  offerDetail = null,
}) => {
  const [config] = useGlobal('config')
  const [user] = useGlobal('user')
  const gender = user?.gender?.toLowerCase() || null

  if (!offerDetail) {
    return null
  }

  const data = populateOffer(config, offerDetail)
  if (isEmpty(data)) return null

  return (
    <CardDetailsOfferWrapper>
      <DetailsOfferWrapper className="row justify-content-between">
        <DetailsOfferLeftWrapper className="" border>
          <LeftLogoWrapper>
            {isOfferDetails && (
              <div className="d-flex justify-content-start align-items-center w-100 title-offers mb-4">
                <IconCar className="mr-2 icon-reponsive" />
                <div className="d-flex flex-column">
                  <span className="pl-2">{data?.jobNames?.[0]}</span>
                  <span>{data?.name_company}</span>
                </div>
              </div>
            )}
            {!isOfferDetails && <img src={LogoDEFAULT} alt="logo-details" />}
          </LeftLogoWrapper>

          <LeftContentWrapper>
            <div className="ml-3">Résumé de l'offre</div>
            <div>
              {genderize({
                male: "Doté",
                female: "Dotée",
                both: "Doté(e)",
                gender,
              })}{" "}
              d'un {data?.diplomas?.[0]} ou niveau équivalent, vous disposez d'un profil{" "}
              <span className="text-first-lowercase">{data?.xp[0]?.name}</span> et vous êtes doté de{" "}
              <span className="text-first-lowercase">{data?.xp[0]?.status}</span> d'expérience en tant que :{" "}
              {data?.otherJobNames?.[0]}.
            </div>

            {isOfferDetails && <h3 className="text-orange mt-4">Offre détaillée</h3>}
          </LeftContentWrapper>
        </DetailsOfferLeftWrapper>

        <DetailsOfferRightWrapper className="d-flex flex-column flex-wrap">
          <div className="d-flex mb-4 list-skills justify-content-between">
            {get(data, "qualityMains", []).map((quality) => (
              <div className="d-flex flex-column align-items-center" key={quality.qualityMainId}>
                <img
                  className="icon-skills mb-2 icon-reponsive"
                  src={ASSET_QUALITYMAIN_URL + quality.filename}
                  alt={quality?.name}
                />
                <span className="card-text-home text-first-capitalize"> {quality?.name}</span>
              </div>
            ))}

            {get(data, "quality2", []).map((quality2) => (
              <div className="d-flex flex-column align-items-center" key={quality2.id}>
                <img
                  className="icon-skills mb-2 icon-reponsive"
                  src={ASSET_QUALITY2_URL + quality2.filename}
                  alt={quality2?.title}
                />
                <span className="card-text-home text-first-capitalize"> {quality2?.title}</span>
              </div>
            ))}

            {get(data, "quality3", []).map((quality3) => (
              <div className="d-flex flex-column align-items-center" key={quality3.quality3Id}>
                <img
                  className="icon-skills mb-2 icon-reponsive"
                  src={ASSET_QUALITY3_URL + quality3.filename}
                  alt={quality3?.title}
                />
                <span className="card-text-home text-first-capitalize"> {quality3?.title}</span>
              </div>
            ))}
          </div>

          <ul className="job-description infos text-blue ml-4">
            <li>
              <b>Localisation :</b> {[capitalize(offerDetail.pres_city), offerDetail.pres_zip].filter(Boolean).join(', ')}
            </li>
            <br></br>
            <li>
              <b>Contrat :</b> {data?.contractTypes[0]}
            </li>
            <br></br>
            <li>
              <b>Salaire :</b> {formatPrice(offerDetail.salary)} € Brut annuel
            </li>
          </ul>

          {!footer && (
            <div className="d-flex justify-content-end">
              <MecButton
                icon={IconNote}
                widthIcon={20}
                width={300}
                type="primary h-100"
                className="site-button site-button-gap radius-xl"
                onClick={() => setToggle(true)}
              >
                Vite, je postule !
              </MecButton>
            </div>
          )}
        </DetailsOfferRightWrapper>
        {footer && footer()}
      </DetailsOfferWrapper>
    </CardDetailsOfferWrapper>
  )
}

export default OfferDetailCard
