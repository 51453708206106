import React, { memo } from "reactn"
import { SEARCH } from "src/constants"
import { MainLayout } from "src/markup/Layout/MainLayout"
import { SavedOffer, SavedSearch, SearchJob } from "src/markup/Pages/SearchPage/Candidate"
import { SavedSearchCV, SearchCV } from "src/markup/Pages/SearchPage/Recruiter"

const childRecruiter = {
  [SEARCH.CV]: SearchCV,
  [SEARCH.SAVED_SEARCH]: SavedSearchCV,
}

const childCandidate = {
  [SEARCH.JOB]: SearchJob,
  [SEARCH.SAVED_SEARCH]: SavedSearch,
  [SEARCH.SAVED_OFFER]: SavedOffer,
}

const AuthPage = (props) => {
  const { recruiter, candidate, site } = props
  const Component = recruiter ? childRecruiter[site] : childCandidate[site]

  return (
    <MainLayout candidate={candidate} recruiter={recruiter}>
      <Component {...props} />
    </MainLayout>
  )
}

export default memo(AuthPage)
