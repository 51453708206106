import React, { useEffect, useState, useCallback, useMemo } from "reactn"
import { useLocation } from "react-router-dom"
import Header from "src/markup/Layout/Header"
import styled from "styled-components"
import { omit, keyBy } from "lodash"
import { toast } from "react-toastify"
import * as S from "./styles"
import { useTranslation } from "react-i18next"

import SidebarCandidate from "src/markup/Layout/SidebarCandidate"
import Footer from "src/markup/Layout/Footer"
import CardCVComponent from "src/markup/Pages/Candidate/CandidateMyCV/CardCV"
import { apiShareCV, deleteCv, getMyListCVCandidate } from "src/services/api"
import { CV_STATUS, SORT_BY } from "src/constants"
import { Loading } from "src/markup/Element/Loading"
import { ConfirmModal, ShareCVModal } from "src/markup/Components/Modals"

const Content = styled.div`
  margin-bottom: 50px;
  margin-top: 65px;
  padding: 0 20px;

  @media (min-width: 992px) {
    padding: 0 50px;
  }
`

const CandidateMyCV = () => {
  const [listCV, setListCV] = useState([])
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState({ showConfirmModal: false })
  const [shareCVModal, setShareCVModal] = useState({ showShareCVModal: false })
  const { t } = useTranslation()

  const [formData, setFormData] = useState({})

  const handleFormSubmit = (data) => {
    setFormData(data)
  }

  const getMyListCvs = useCallback(async () => {
    try {
      setLoading(true)
      const res = await getMyListCVCandidate({
        sort_by: SORT_BY.DESC,
      })

      if (res.length) setListCV(res)
    } catch (error) {
      return error
    } finally {
      setLoading(false)
    }
  }, [])

  const filteredOffers = useMemo(() => {
    return listCV.filter((of) =>
      location.pathname === "/candidate/mycv/draft" ? of.status === CV_STATUS.DRAFT : of.status === CV_STATUS.PUBLISHED
    )
  }, [location.pathname, listCV])

  useEffect(() => {
    getMyListCvs()
  }, [getMyListCvs])

  const handleDeleteCv = async (id) => {
    const shadowCvs = keyBy(listCV, "id")
    const res = await deleteCv(id)
    toast.success(res.message)
    setListCV(Object.values(omit(shadowCvs, id)))
    setModal({ showConfirmModal: false })
  }

  const onDelete = (id) => {
    setModal({
      showConfirmModal: true,
      onYes: () => handleDeleteCv(id),
      onNo: () => setModal({ showConfirmModal: false }),
      Description: "Êtes-vous sûr de vouloir supprimer le CV ?",
    })
  }

  const handleShareCV = async () => {
    try {
      await apiShareCV(formData)
      toast.success(t("the_cv_shared_successfully"))
      setShareCVModal({ showShareCVModal: false })
    } catch (error) {
      toast.error(t("error_occurred_while_sharing_cv"))
    }
  }

  const shareCV = () => {
    setShareCVModal({
      showShareCVModal: true,
      onCancel: () => setShareCVModal({ showShareCVModal: false }),
      onSend: handleShareCV,
    })
  }

  return (
    <div className="page-wraper">
      <Header candidate />

      <div className="container-content">
        <div className="d-flex" style={{ flexWrap: "nowrap" }}>
          <SidebarCandidate />
          {loading && <Loading />}
          <Content className="col-10 col-xl">
            <div className="d-flex justify-content-center">
              <S.Heading>{location.pathname === "/candidate/mycv/draft" ? "BROUILLONS" : "Mes CV"}</S.Heading>
            </div>
            <hr className="v3-separator pb-xl-3" />

            <div>
              <CardCVComponent listCV={filteredOffers} onDelete={onDelete} totalCV={listCV.length} shareCV={shareCV} />
            </div>
          </Content>
        </div>
      </div>

      <Footer />
      <ConfirmModal {...modal} />
      <ShareCVModal onSubmit={handleFormSubmit} {...shareCVModal} />
    </div>
  )
}
export default CandidateMyCV
