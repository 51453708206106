import styled from "styled-components";

export const Wrapper = styled.div`
  min-width: ${(props) => (props.minWidth || 120) + "px"};
  width: ${(props) => (props.width ? props.width + "px" : "100%")};
  height: ${(props) => (props.height ? props.height + "px" : "100%")};
  cursor: pointer;

  .flex-center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const WrapperImage = styled.div`
  margin-right: 5px;
  display: flex;

  svg {
    width: ${(props) => (props.widthIcon || 30) + "px"};
    height: ${(props) => (props.heightIcon || 30) + "px"};
    color: ${(props) => `var(--${props.bgIcon || "white"})`};
  }
`;

export const Button = styled.button`
  width: 100%;
  min-height: 35px;
  height: 100%;
  font-size: 1rem;
  line-height: 1.5rem;
  
  border-radius: ${(props) => `${props.radius || 30}px`};
  border: ${(props) =>
    props.borderColor ? `1px solid var(--${props.borderColor})` : "none"};

  opacity: ${(props) => props.isDisabled && 0.5};
  pointer-events: ${(props) => props.isDisabled && 'none'};

  &.primary {
    color: ${(props) => `var(--${props.textColor || "white"})`};
    background: var(--orange300);
    border: 2px solid var(--orange300);
  }

  &.secondary {
    background: transparent;
    border: 2px solid var(--orange300);
    color: ${(props) => `var(--${props.textColor || "white"})`};

    &:hover {
      box-shadow: 2px 4px 6px 1px rgba(0, 0, 0, 0.37);
      background: var(--orange300);
      color: var(--white);

      svg {
        color: var(--white);
      }
    }
  }

  &.submit {
    background: var(--blue600);
    color: var(--white);

    &:hover {
      box-shadow: 2px 4px 6px 1px rgba(0, 0, 0, 0.37);
      background: var(--blue600);
      color: var(--white);

      svg {
        color: var(--white);
      }
    }
  }

  &:hover {
    box-shadow: 2px 4px 6px 1px rgba(0, 0, 0, 0.37);
  }

  &.outline {
    background: ${(props) => (props.isBg ? "#fff" : "transparent")};
    border: 2px solid #fff;
    color: var(--orange300);

    svg {
      color: var(--orange300);
    }

    &:hover {
      box-shadow: 2px 4px 6px 1px rgba(0, 0, 0, 0.37);
      background: var(--orange300);
      color: #fff;

      svg {
        color: #fff;
      }
    }
  }

  &.outlineNotHover {
    background: ${(props) => (props.isBg ? "#fff" : "transparent")};
    border: 2px solid var(--orange300);
    color: ${(props) => `var(--${props.textColor || "orange300"})`};

    svg {
      color: var(--orange300);
      path {
        fill: var(--orange300);
      }
    }
  }

  padding: 3px 10px;

  outline: none;
  cursor: pointer;

  @media(max-width: 992px) {
    font-size: 14px;
  }
`;
