import { Link } from "react-router-dom"
import MecLogo from "../../../../images/logo-mecanojob.png"
import PcImg from "../../../../images/pc.png"
import { MecButton } from "src/markup/Components"
import "./index.scoped.scss"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

export default function Header() {
  const history = useHistory()

  return (
    <div className="header-wrapper">
      <div className="header-wrapper__top">
        <div className="logo-header-wrapper w-100">
          <Link to="/">
            <img src={MecLogo} alt="Mecanojob logo" className="logo" />
          </Link>
        </div>
        <MecButton
          widthIcon={24}
          type={"secondary"}
          className="w-auto ml-auto h-auto"
          onClick={(e) => {
            e.preventDefault()
            history.push("/recruiter/login")
          }}
        >
          CONNEXION
        </MecButton>
      </div>
      <div className="header-content">
        <div className="find-talent">
          <h1>
            Trouvez <span className="text-orange">vos talents</span> en quelques clics
          </h1>
          <p>Facilitez vos recrutements avec MécanoJob, le spécialiste des métiers techniques</p>
          <MecButton
            onClick={(e) => {
              e.preventDefault()
              history.push("/recruiter/login")
            }}
            className="find-button mt-5 px-4"
          >
            Publier une offre d’emploi
          </MecButton>
        </div>
        <div className="pc-image w-100">
          <img src={PcImg} alt="PC" />
        </div>
      </div>
    </div>
  )
}
