import React from "reactn"
import { FormGroup } from "reactstrap"

import { FindWrapper, FormWrapper, ImageWrapper } from "../styles"
import { MecButton } from "src/markup/Components"
import { ReactComponent as Search } from "src/images/icons/search.svg"
import { MecAutocompleteLocation, MecSelectorCustom } from "src/markup/Components/Forms"

export default function Banner({ config, formik }) {
  return (
    <div className="dez-bnr-inr dez-bnr-inr-md overlay-black-light img-fluid d-flex row m-0">
      <FindWrapper className="container d-flex col-10">
        <div className="dez-bnr-inr-entry align-m">
          <div className="job-search-form">
            <div className="custom-h3">
              <h3 className="text-white">Trouve ton nouveau job</h3>
            </div>
            <FormWrapper className="d-flex flex-column align-items-center" onSubmit={formik.handleSubmit}>
              <div className="input-group d-flex flex-column align-items-center">
                <FormGroup className="col-12">
                  <MecSelectorCustom
                    options={config?.jobs}
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ id }) => id}
                    className="text-align-last-left"
                    name="joblistId"
                    placeholder="Sélectionner un job"
                    colorTxtError="red"
                    onChange={(option) => {
                      formik.setFieldValue("joblistId", option)
                    }}
                    onBlur={() => formik.setFieldTouched("joblistId", true)}
                    value={formik.values["joblistId"]}
                    errors={formik.errors["joblistId"]}
                    touched={formik.touched["joblistId"]}
                  />
                </FormGroup>

                <FormGroup className="col-12">
                  <MecAutocompleteLocation formik={formik} name="pres_city" colorTxtError="red" />
                </FormGroup>

                <MecButton
                  icon={Search}
                  widthIcon={20}
                  width={200}
                  type="primary"
                  className="site-button site-button-gap radius-xl text-capitalize"
                >
                  Rechercher
                </MecButton>
              </div>
            </FormWrapper>
          </div>
        </div>
      </FindWrapper>
      <ImageWrapper className="image-scroll col-2" />
    </div>
  )
}
