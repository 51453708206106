import { ReactComponent as IconTick } from 'src/images/icons/icon-tick-blue.svg'
import { MecButton } from 'src/markup/Components'
import * as S from 'src/markup/Pages/OurPackage/Recruiter/components/styles'
import { formatPrice } from 'src/utils';

const benefitList = [
  {
    strong: 'Définition',
    span: 'exacte du profil recherché',
  },
  {
    strong: 'Publication',
    span: 'sur différents supports partenaires',
  },
  {
    strong: 'Recherche',
    span: 'de candidats',
  },
  {
    strong: 'Sélection',
    span: 'des profils',
  },
]

export const CardNormal = ({ onAddCart }) => {
  return (
    <S.Box style={{margin: 0}}>
      <div className="card-plan">
        <div>
          <div className="card-plan__title text-center">ACCOMPAGNÉE</div>
          <div className="card-plan__benefit active">
            {benefitList.map(({ strong, span }, idx) => (
              <div key={idx} className="d-flex py-2">
                <div className="col-1 pl-0">
                  <IconTick width={20} />
                </div>
                <div className="card-plan__text pl-2">
                  <div className="text-orange">
                    {strong}
                    <span className="text-blu pl-1">{span}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="card-plan__price text-center">{`${formatPrice(1500)} € HT`}</div>
      </div>
      <MecButton className="text-uppercase my-3" height={35} onClick={onAddCart}>sélectionner</MecButton>
    </S.Box>
  )
}
