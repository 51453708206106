import React, { memo, useState, useEffect, useCallback, useGlobal } from "reactn"
import { get, isEmpty } from "lodash"
import { ASSET_LANG_URL } from "src/constants"
import { DetailsOfferLeftWrapper as DetailsWrapper } from "src/markup/Components/Cards/OfferDetailCard/styles"
import {
  ContentLeftWrapper,
  ContentRightWrapper,
  ContentWrapper,
  LogoWrapper,
  MasterWrapper,
  TitleWrapper,
} from "src/markup/Element/OfferDetail/styles"
import { formatPrice } from "src/utils"
import { getOfferById } from "src/services/api"
import { Loading } from "src/markup/Element/Loading"
import { populateOffer } from "src/services/config"

// languages
const renderLanguages = (languages) =>
  languages.map((languagesJobs, index) => {
    return languagesJobs ? (
      <img className="icon-responsive" src={ASSET_LANG_URL + languagesJobs.filename} alt={index + 1} />
    ) : null
  })

export const DetailJob = memo(({ offerId }) => {
  const [config] = useGlobal("config")

  const [currentOffer, setCurrentOffer] = useState([])
  const [loading, setLoading] = useState(true)

  const handleGetOffer = useCallback(
    async (id) => {
      try {
        const offer = await getOfferById(id)
        setCurrentOffer(offer)
      } finally {
        setLoading(false)
      }
    },
    []
  )

  useEffect(() => {
    offerId && handleGetOffer(offerId)
  }, [handleGetOffer, offerId])

  const data = populateOffer(config, currentOffer)
  if (isEmpty(data)) return null

  return (
    <DetailsWrapper className="row flex-column justify-content-between px-4" border={false}>
      {loading && <Loading />}
      <LogoWrapper>{data.name_company}</LogoWrapper>
      <TitleWrapper>
        <div>RECRUTE</div>
        <div>
          {data.jobNames}, {get(data, "xp[0].name", "")}
        </div>
      </TitleWrapper>

      <div className="px-4">
        Vous êtes{" "}
        {data.qualityMains.map((quality, idx) => (
          <span key={idx}>{quality.name} </span>
        ))}
        ?
      </div>
      <div className="px-4">
        Vous avez {get(data, "quality2[0].name", "")} ? Vous êtes {get(data, "quality3[0].name", "")} ?
      </div>
      <div className="mt-2 px-4">
        Doté(e) d'un {get(data, "diplomas[0]", "")} ou niveau équivalent, vous avez {get(data, "xp[0].status", "")}{" "}
        d'experience en tant que {get(data, "jobNames[0]", "")}
      </div>
      <MasterWrapper className="d-flex flex-column mt-4">
        <div className="mb-2">Vous maîtrisez :</div>

        <div className="list-masters d-flex flex-wrap justify-content-between pl-4 pr-4">
          {get(data, "skills", []).map((skill, idx) => (
            <div key={idx} className="d-flex justify-content-between align-items-center">
              <img
                src="https://mecanoassets.s3.eu-west-3.amazonaws.com/qualityMain/creative.png"
                alt="icon-create"
                width={70}
                height={70}
                className="mr-2 icon-responsive"
              />
              <span>{skill}</span>
            </div>
          ))}
        </div>

        <ul className="pl-4 pr-4 mb-4 mt-4">
          {get(data, "skillsComp", [])
            .concat(get(data, "tools", []))
            .map((skill, idx) => (
              <li className="d-block" key={idx}>
                <span className="text-lowercase">{skill}</span>
              </li>
            ))}
        </ul>
        <div className="pl-4 pr-4 d-flex flex-wrap align-items-center justify-content-between">
          <div className="mr-4 d-flex">{renderLanguages(get(data, "languages", []))}</div>
          <div>Vous parlez couramment : {get(data, "languages", []).map(({ name })=> name).join(', ')}.</div>
        </div>
        <div className="mt-2 pl-4 pr-4">
          {data.name_company} recherche {get(data, "recruiterObjectives", "")} un profil comme le vôtre.
        </div>
        <div className="mt-4 pl-4 pr-4">
          Sous la responsabilité {get(data, "responsabilities[0]", "")} vous serez amené(e) à :
        </div>

        <ul className="pl-4 pr-4 mt-2 mb-0">
          {get(data, "missions", []).map((mission, idx) => (
            <li key={idx}>{mission}</li>
          ))}
        </ul>
        <div className="mt-2 pl-4 pr-4">Et plus largement à :</div>
        <ul className="pl-4 pr-4 mt-2 mb-0">
          {get(data, "missionComp", []).map((mission, idx) => (
            <li key={idx}>{mission}</li>
          ))}
        </ul>
      </MasterWrapper>
      <MasterWrapper className="d-flex flex-column mt-4">
        <div className="mb-2">Qui sommes-nous ?</div>

        <div className="mt-2 pl-4 pr-4">
          {data.name_company} recherche {get(data, "recruiterObjectives[0]", "")}, un profil comme le vôtre.
        </div>
      </MasterWrapper>

      <MasterWrapper className="d-flex flex-column mt-4">
        <div className="mb-2">Ce que l'on vous propose :</div>
        <ContentWrapper className="d-flex row">
          <ContentLeftWrapper className="col-6">
            <ul className="pl-4 pr-4 mt-2 mb-0">
              <li>Salaire : {formatPrice(data.salary)} € Brut annuel</li>
              <li>
                Avantages :
                <ul className="pl-4 pr-4 mt-2 mb-0 d-inline-grid sub-list">
                  {data?.advantages?.map((advantage, idx) => (
                    <li key={idx}>{advantage}</li>
                  ))}
                </ul>
              </li>
              <li>Autre: {get(data, "otherAdvantage", "")}</li>
            </ul>
          </ContentLeftWrapper>
          <ContentRightWrapper className="col-6">
            <div className="mb-2">Important :</div>
            <ul className="pl-4 pr-4 mt-2 mb-0">
              {data.driverLicenseOthers.map((license, idx) => (
                <li key={idx}>
                  <div className="d-inline-grid mr-5">
                    <span>{license}</span>
                  </div>
                </li>
              ))}
              {data.extra_criminalRecord && <li>Casier judiciaire vierge</li>}
            </ul>
          </ContentRightWrapper>
          <div className="text-center font-weight-bold p-4">
            Intéressé(e) ? N'hésitez plus, on vous attend ! Postulez pour répondre à notre offre d'emploi.
          </div>
        </ContentWrapper>
      </MasterWrapper>
    </DetailsWrapper>
  )
})
