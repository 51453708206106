import * as Yup from "yup"

import { OFFER_STATUS } from "src/constants"
import { YOUR_COMPANY, POSITION, PROFILE_DEFINITION, CONDITIONS } from "src/markup/Element/OfferForms/constant"

export const OfferFormInitialValues = {
  name_company: "",
  pres_city: "",
  pres_zip: "",
  recruit_recruitObj: null,
  recruit_responsability: null,
  joblistId: null,
  pres_jobOtherName: null,
  search_exp: null,
  search_minDiploma: null,
  mission: [
    {
      id: 1,
      search_mission: null,
      search_specMission: [],
    },
  ],
  search_qualityMain: [],
  search_quality2: null,
  search_quality3: null,

  skill: [
    {
      id: 1,

      search_skill: null,
      search_skillComp: [],
    },
  ],
  tools: [],

  languages: [{ filename: "french.png", id: 8, name: "Français" }],
  extra_driversLicenceOther: [],

  extra_criminalRecord: false,
  recruit_contractType: null,

  salary: null,

  adv_advantages: [],

  otherAdvantage: "",
  featured_offer: false,
  offer_logo: "",
  status: OFFER_STATUS.PUBLISHED,
}

const handleValidationWithType = ({ field, t }) => {
  switch (field.type) {
    case "object":
      return (
        field.isRequired &&
        Yup.object()
          .required(t("required_field"))
          .nullable(true)
      )

    case "autocomplete_location":
    case "string":
    case "input":
      return (
        field.isRequired &&
        Yup.string(t("invalid_field"))
          .trim()
          .required(t("required_field"))
          .nullable(true)
      )

    case "array_object":
      return field.isRequired && Yup.array().min(1, t("required_field"))

    case "array_mission":
      return (
        field.isRequired &&
        Yup.array().of(
          Yup.object().shape({
            [field.fieldFirstRequired]: Yup.object()
              .required(t("required_field"))
              .nullable(true),
            [field.fieldSecondRequired]: Yup.array().min(
              1,
              t("required_field")
            ),
          })
        )
      )

    default:
      break
  }
}

export const OfferFormvalidationSchema = ({ t, isDraft }) => {
  const validationObject = {}

  YOUR_COMPANY().forEach((field) => {
    validationObject[field.name] = handleValidationWithType({ field, t })
  })

  POSITION().forEach((field) => {
    validationObject[field.name] = handleValidationWithType({ field, t })
  })

  PROFILE_DEFINITION().forEach((field) => {
    validationObject[field.name] = handleValidationWithType({ field, t })
  })

  CONDITIONS.forEach((field) => {
    validationObject[field.name] = handleValidationWithType({ field, t })
  })
  return Yup.object(isDraft ? {} : validationObject)
}
