import React, { useState, useEffect, useGlobal} from "reactn"
import { useFormik } from "formik"
import { FormGroup } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { productionMode } from "src/utils"
import { includes, remove, isEmpty } from "lodash"

import PositionLooking from "src/markup/Element/CVForm/PositionLooking"
import Experience from "src/markup/Element/CVForm/Experience"
import Training from "src/markup/Element/CVForm/Training"
import { MecButton } from "src/markup/Components/MecButton"
import Profile from "src/markup/Element/CVForm/Profile"
import AdditionalInformation from "src/markup/Element/CVForm/AdditionalInformation"
import { StyledCVForm } from "src/markup/Element/CVForm/styles"
import { ReactComponent as IconRegister } from "src/images/icons/icon-register.svg"
import { ReactComponent as IconCancel } from "src/images/icons/icon-cancel.svg"
import { ReactComponent as IconChecked } from "src/images/icons/icon-checked.svg"
import { CVFormvalidationSchema, CVFormRandomizedValues } from "src/markup/Element/CVForm/FormValidation"
import { MecInput } from "src/markup/Components/Forms"
import { createCVCandidate, updateCv } from "src/services/api"
import { CV_STATUS } from "src/constants"
import RouteLeavingGuard from "src/markup/Components/RouteLeavingGuard"
import { ProfileModal } from "src/markup/Components/Modals"
import { useGetProfile } from "src/markup/Pages/Profile/Information/useGetProfile"

const CVForm = ({ initialFormValue, cvId, cv }) => {
  const [completed, setCompleted] = useState([])
  const [inexperienced, setInexperienced] = useState([])
  const [isDraft, setIsDraft] = useState(false)
  const [toggle, setToggle] = useState(false)
  const [isOpen, setIsOpen] = useState("positionLooking")
  const [isRouteGuard, setRouteGuard] = useState(true)
  const [stillWorking, setStillWorking] = useState([])

  const [config] = useGlobal('config')

  const { t } = useTranslation()

  const history = useHistory()
  const profile = useGetProfile()

  useEffect(() => {
    if (profile?.phonenumber === null || profile?.postalcode === null) {
      setToggle(true)
      setRouteGuard(false)
    }
  }, [profile])

  useEffect(
    () => {
      cv?.experiences.length === 0 && setInexperienced((prevState) => [...prevState, "experiences"])
      cv?.formations.length === 0 && setInexperienced((prevState) => [...prevState, "formations"])
      cv?.experiences.length > 0 &&
        cv?.experiences.map(
          (experience, idx) => experience.expExitDate === null && setStillWorking((prevState) => [...prevState, idx])
        )
    },
    [cv]
  )

  const formik = useFormik({
    initialValues: initialFormValue,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: () => CVFormvalidationSchema({ t, inexperienced, isDraft }),
    onSubmit: async (values) => {
      setRouteGuard(false)
      const valuesSubmit = { ...values }
      inexperienced.map((value) => (valuesSubmit[value] = []))

      valuesSubmit.experiences = valuesSubmit.experiences.map((experience) => ({
        ...experience,
        id: null,
      }))

      valuesSubmit.formations = valuesSubmit.formations.map((formation) => ({
        ...formation,
        id: null,
        domain_activity: formation.domain_activity?.id,
        formDiploma: { id: formation.formDiploma?.id, name: formation.formDiploma?.name },
        formDiplomaOption: { id: formation.formDiplomaOption?.id, name: formation.formDiplomaOption?.name },
      }))

      valuesSubmit.mission = valuesSubmit.mission.map((mission) => ({
        ...mission,
        id: null,
        search_mission: { id: mission.search_mission?.id, name: mission.search_mission?.name },
        search_specMission: mission.search_specMission?.map((specMission) => ({
          id: specMission.id,
          name: specMission.name,
        })),
      }))

      valuesSubmit.skill = valuesSubmit.skill.map((skill) => ({
        ...skill,
        id: null,
        search_skill: { id: skill.search_skill?.id, name: skill.search_skill?.name },
        search_skillComp: skill.search_skillComp?.map((skillComp) => ({
          id: skillComp.id,
          name: skillComp.name,
        })),
      }))

      valuesSubmit.tools = valuesSubmit.tools.map((tool) => ({
        ...tool,
        keyId: null,
      }))

      valuesSubmit.search_qualityMain = valuesSubmit.search_qualityMain.map((quality) => ({
        qualityMainId: quality.qualityMainId,
        name: quality.name,
      }))

      valuesSubmit.languages = valuesSubmit.languages.map((language) => ({
        id: language.id,
        name: language.name,
      }))
      valuesSubmit.search_quality2 = valuesSubmit.search_quality2?.id
      valuesSubmit.search_quality3 = valuesSubmit.search_quality3?.id
      valuesSubmit.joblistId = valuesSubmit.joblistId?.id
      valuesSubmit.search_exp = valuesSubmit.search_exp?.id
      valuesSubmit.pres_jobOtherName = valuesSubmit.pres_jobOtherName?.id

      try {
        if (cvId) {
          await updateCv({ ...valuesSubmit, id: cvId })
          toast.success("Le CV a été mis à jour avec succès")
        } else {
          await createCVCandidate(valuesSubmit)
          toast.success("Le CV a été enregistré avec succès")
        }
        history.push(valuesSubmit.status_cv === CV_STATUS.DRAFT ? "/candidate/mycv/draft" : "/candidate/mycv")
      } catch (error) {
        return error
      }
    },
  })

  const { setFieldValue, values, isSubmitting, errors, handleSubmit, setValues } = formik

  const handleCompleted = (e, value) => {
    e.preventDefault()
    setCompleted((prevState) => [...prevState, value])
  }

  const handleAddExperience = () => {
    const data = [
      ...values.experiences,
      {
        id: values.experiences[values.experiences.length - 1].id + 1,
        expJobName: "",
        expCompany: "",
        expEntryDate: "",
        expExitDate: "",
        expCity: "",
        expMissions: "",
      },
    ]
    if (data.length > 10) {
      toast.warn("Le nombre maximum d'expériences est atteint")
    } else {
      setFieldValue("experiences", data)
    }
  }

  const handleAddFormations = () => {
    const data = [
      ...values.formations,
      {
        id: values.formations[values.formations.length - 1].id + 1,
        domain_activity: "",
        formSchool: "",
        formCity: "",
        formDiploma: {},
        formDiplomaOption: {},
        formEntryDate: "",
        formExitDate: "",
        formMissions: "",
      },
    ]
    if (data.length > 6) {
      toast.warn("Le nombre maximum de formations est atteint")
    } else {
      setFieldValue("formations", data)
    }
  }

  const handleAddMission = () => {
    const data = [
      ...values.mission,
      {
        id: values.mission[values.mission.length - 1].id + 1,
        search_mission: null,
        search_specMission: [],
      },
    ]
    if (data.length > 6) {
      toast.warn("Le nombre maximum de savoirs-faire est atteint")
    } else {
      setFieldValue("mission", data)
    }
  }

  const handleAddSkill = () => {
    const data = [
      ...values.skill,
      {
        id: values.skill[values.skill.length - 1].id + 1,
        search_skill: null,
        search_skillComp: [],
      },
    ]
    if (data.length > 6) {
      toast.warn("Le nombre maximum de compétences est atteint")
    } else {
      setFieldValue("skill", data)
    }
  }

  const handleAddTools = () => {
    const data = [
      ...values.tools,
      {
        keyId: values.tools[values.tools.length - 1].keyId + 1,
        id: "",
        name: "",
        level: 1,
      },
    ]
    if (data.length > 5) {
      toast.warn("Le nombre maximum d'outils est atteint")
    } else {
      setFieldValue("tools", data)
    }
  }

  const handleInexperienced = (value) => {
    delete errors[value]
    setInexperienced((prevState) => {
      if (includes(prevState, value)) {
        remove(prevState, (state) => state === value)
        return [...prevState]
      } else {
        return [...prevState, value]
      }
    })
  }

  const handleStillWorking = (value) => {
    setStillWorking((prevState) => {
      if (includes(prevState, value)) {
        remove(prevState, (state) => state === value)
        formik.setFieldValue(`experiences.${value}.expExitDate`, "")

        return [...prevState]
      } else {
        formik.setFieldValue(`experiences.${value}.expExitDate`, null)

        return [...prevState, value]
      }
    })
    delete formik.errors.experiences?.[value]?.expExitDate
  }

  const handleOpenCollapse = (collapse) => {
    const positionLooking = ["city", "joblistId", "pres_jobOtherName", "search_exp"]
    // experiences, formations
    if (isOpen === "positionLooking") {
      const isHaveError = positionLooking.some((item) => formik.errors[item])
      if (!isHaveError) {
        setIsOpen((prev) => (prev === collapse ? "" : collapse))
        return
      }
    } else if (isEmpty(formik.errors[isOpen])) {
      setIsOpen((prev) => (prev === collapse ? "" : collapse))
      return
    }
  }

  const handleDeleteItem = (item, index) => {
    const shadow = [...values[item]]
    setValues({
      ...values,
      [item]: shadow.filter((ite) => (ite.keyId || ite.id) !== index),
    })
    toast.success(t(`Suppression confirmée`))
  }

  const handleRandomFill = () => {
    setValues(CVFormRandomizedValues(config))
  }

  return (
    <>
      {isRouteGuard && <RouteLeavingGuard shouldBlockNavigation={() => true} />}

      {!productionMode() && (
        <div style={{ textAlign: "-webkit-right" }}>
          <MecButton
            icon={IconChecked}
            type="primary"
            width={300}
            height={50}
            widthIcon={20}
            onClick={(e) => {
              e.preventDefault()
              handleRandomFill()
            }}
          >
            [DEV] Remplir aléatoirement
          </MecButton>
        </div>
      )}

      <StyledCVForm onSubmit={handleSubmit}>
        <div className="row">
          <FormGroup className="col-12 p-2 p-xl-5">
            <div className="py-2 label text-align-last-left label-required">Nommez votre CV</div>
            <MecInput formik={formik} className="secondary text-align-last-left" name="name_cv" />
          </FormGroup>
        </div>
        <PositionLooking
          formik={formik}
          handleCompleted={handleCompleted}
          completed={completed}
          isOpen={isOpen}
          handleOpenCollapse={handleOpenCollapse}
        />
        <Experience
          formik={formik}
          handleAddExperience={handleAddExperience}
          handleCompleted={handleCompleted}
          completed={completed}
          inexperienced={inexperienced}
          handleInexperienced={handleInexperienced}
          isOpen={isOpen}
          handleOpenCollapse={handleOpenCollapse}
          handleDeleteItem={handleDeleteItem}
          stillWorking={stillWorking}
          cvId={cvId}
          handleStillWorking={handleStillWorking}
        />
        <Training
          formik={formik}
          handleAddFormations={handleAddFormations}
          handleCompleted={handleCompleted}
          completed={completed}
          inexperienced={inexperienced}
          handleInexperienced={handleInexperienced}
          isOpen={isOpen}
          handleOpenCollapse={handleOpenCollapse}
          handleDeleteItem={handleDeleteItem}
        />
        <Profile
          formik={formik}
          handleAddMission={handleAddMission}
          handleAddSkill={handleAddSkill}
          handleAddTools={handleAddTools}
          handleCompleted={handleCompleted}
          completed={completed}
          isOpen={isOpen}
          handleOpenCollapse={handleOpenCollapse}
          handleDeleteItem={handleDeleteItem}
        />
        <AdditionalInformation
          formik={formik}
          handleCompleted={handleCompleted}
          completed={completed}
          isOpen={isOpen}
          handleOpenCollapse={handleOpenCollapse}
        />
        <div className="w-100 d-flex flex-wrap justify-content-center button-action pb-5">
          <MecButton
            icon={IconCancel}
            type="primary"
            width={300}
            height={50}
            widthIcon={20}
            className="text-capitalize outline"
            onClick={(e) => {
              e.preventDefault()
              history.push("/candidate/mycv")
            }}
          >
            Annuler
          </MecButton>

          <MecButton
            icon={IconRegister}
            type="primary"
            width={300}
            height={50}
            widthIcon={20}
            className="text-capitalize"
            onClick={(e) => {
              setIsDraft(true)
              setFieldValue("status_cv", CV_STATUS.DRAFT)
            }}
          >
            {isSubmitting ? "..." : "Enregistrer"}
          </MecButton>

          <MecButton
            icon={IconChecked}
            type="primary isSubmit"
            width={300}
            height={50}
            widthIcon={20}
            onClick={(e) => {
              setIsDraft(false)
              setFieldValue("status_cv", CV_STATUS.PUBLISHED)
            }}
            disabled={isSubmitting || !isEmpty(errors)}
          >
            {isSubmitting ? "..." : `${cvId ? "Mettre à jour et publier" : "Publier"}`}
          </MecButton>
        </div>

        <ProfileModal
          toggle={toggle}
          title="METTRE À JOUR LE PROFIL"
          content="Vous devez remplir toutes les informations avant de créer votre CV."
          to="/candidate/profile"
        />
      </StyledCVForm>
    </>
  )
}

export default CVForm
