import styled from "styled-components"

export const UploadImage = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 999;
    cursor: pointer;
  }
`

export const Image = styled.div`
  background: #ffffff;
  padding: 10px;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  position: relative;
  cursor: pointer;

  img {
    border-radius: ${(props) => props.avatar && "50%"};
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

export const TitleUpload = styled.div`
  margin-top: -33px;

  font-weight: 800;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: #023f73;
`

export const Minus = styled.div`
  display: flex;
  align-items: center;

  right: -100%;
  position: relative;
  transform: translate(-300px, 0px);

  font-weight: 800;
  font-size: 19px;
  line-height: 28px;
  color: #023f73;
`
