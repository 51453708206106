import React from "reactn"
import { ModalBody } from "reactstrap"
import { Wrapper,Cancel } from "./styles"
import { ReactComponent as IconCancel } from "src/images/icons/icon-cancel.svg"

const ConfirmModal = ({ toggle, onCancel, onConfirm, setToggle }) => {
  return (
    <Wrapper isOpen={toggle} toggle={() => setToggle((prevState) => !prevState)} centered>
      <Cancel onClick={() => setToggle((prevState) => !prevState)}>
        <IconCancel width={30} height={30} />
      </Cancel>
      <ModalBody>
        <div className="quote-bx text-center mb-5">
          <div className="quote-info">
            <h3 className="text-uppercase">Quitter la page ?</h3>
            <p>Les modifications que vous avez apportées ne seront pas enregistrées.</p>
            <div className="left-job-buttons">
              <div className="site-button site-button-gap radius-xl text-uppercase" onClick={onCancel}>
                Annuler
              </div>
              <div className="site-button site-button-gap radius-xl text-uppercase" onClick={onConfirm}>
                Confirmer
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Wrapper>
  )
}

export default ConfirmModal
