import React from "reactn";
import { NavLink, useLocation } from "react-router-dom";

import { isRecruiter, logout } from "src/services/auth";
import { UserAccountIcon } from "src/css/Sidebar";

export default function SidebarProfile({ baseRoute, setActiveSub, activeSub }) {
  const location = useLocation();
  const { pathname } = location;

  const RECRUITER = isRecruiter();

  return (
    <div
      className="menu-account mb-5 menu-sidebar"
      onClick={() =>
        setActiveSub((prevState) =>
          prevState === "Mon compte" ? "" : "Mon compte"
        )
      }
    >
      <div
        className={`sidebar-item ${
          (pathname === `/${baseRoute}/profile` ||
            pathname === `/${baseRoute}/password`) &&
          "is-active"
        }`}
      >
        <UserAccountIcon />

        <div className="menu-title">Mon compte</div>
      </div>
      <ul
        className={`sub-menu-wrapper ${
          activeSub === "Mon compte" && "active-sub"
        }`}
      >
        <NavLink
          to={`/${baseRoute}/profile`}
          className="sub-menu-item"
          activeClassName={"is-active"}
        >
          <li className="sub-item-title">Mes informations</li>
        </NavLink>
        <NavLink
          to={`/${baseRoute}/password`}
          className="sub-menu-item"
          activeClassName={"is-active"}
        >
          <li className="sub-item-title">Mot de passe</li>
        </NavLink>
        {RECRUITER && (
          <NavLink
            exact
            to={`/${baseRoute}/invoice`}
            className="sub-menu-item"
            activeClassName={"is-active"}
          >
            <li className="sub-item-title">Mes factures</li>
          </NavLink>
        )}
        {RECRUITER && (
          <NavLink
            exact
            to={`/${baseRoute}/cart`}
            className="sub-menu-item"
            activeClassName={"is-active"}
          >
            <li className="sub-item-title">Mon panier</li>
          </NavLink>
        )}
        <NavLink
          exact
          to="/"
          onClick={logout}
          className="sub-menu-item"
          activeClassName={"is-active"}
        >
          <li className="sub-item-title">Se déconnecter</li>
        </NavLink>
      </ul>
    </div>
  );
}
