import { useGlobal, useLayoutEffect, useState } from 'reactn';
import { apiGetMyAutoPackages } from 'src/services/api';

export const useCheckPackage = () => {
  const [user] = useGlobal("user");
  const [packages, setPackages] = useState([]);

  useLayoutEffect(() => {
    let subscribed = true;
    (async() => {
      const packages = await apiGetMyAutoPackages();
      if (subscribed) {
        setPackages(packages);
      }
    })();

    return () => { subscribed = false; }
  }, []);

  return {
    packages,
    hasPackage: (packages?.length || 0) > 0 || user?.bypass,
  }
}
