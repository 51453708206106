import styled from 'styled-components'

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Box = styled(FlexColumn)`
  min-width: 300px;
  max-width: 300px;
  padding: 40px 25px;
  border-radius: 15px;

  margin: 0 auto;

  ${({ isBg }) =>
    isBg &&
    `background: #e8eef2;
    margin-bottom: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  `}

  .card-plan {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    border-radius: 15px;
    padding: 20px;
    min-height: 500px;
    max-height: 500px;
    &__title {
      padding-bottom: 10px;
      color: var(--blue600);
      font-weight: 700;
      font-size: 22px;
    }

    &__benefit {
      border-top: 4px solid #bcced8;
      padding-top: 15px;
      &.active {
        svg {
          path {
            fill: var(--orange300);
          }
        }
      }
      &-package {
        color: #023f73;
        font-size: 18px;
        font-weight: 700;
      }
    }

    &__tag {
      border: 3px solid var(--orange300);
      border-radius: 15px;
      padding: 5px 7px;
    }

    &__text {
      color: #5e7e99;
      font-weight: 600;
      .text-orange {
        color: var(--orange300);
      }

      .text-blu {
        color: var(--blue600);
        font-weight: 300;
      }
    }

    &__price {
      padding-top: 20px;
      margin-top: 20px;
      border-top: 4px solid #bcced8;

      color: var(--orange300);
      font-weight: 600;
      font-size: 24px;
      font-family: 'Raleway';
    }
  }
`
