import React, { useEffect, useState } from "reactn"
import qs from "query-string"
import { useHistory, useLocation, Link } from "react-router-dom"
import { toast } from "react-toastify"
import { MecButton } from "src/markup/Components"
import { Loading } from "src/markup/Element/Loading"
import AuthLayout from "src/markup/Layout/AuthLayout"
import * as S from "src/markup/Pages/AuthPage/ForgotPassword/Recruiter/styles"
import { verifyCodeByEmail } from "src/services/api"

const VerifyEmailPage = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('Vérification en cours...')

  const { search } = useLocation()
  const params = qs.parse(search)

  useEffect(() => {
    setLoading(true)
    verifyCodeByEmail(params)
      .then(({ message }) => {
        toast.success(message)
        setMessage(message)
      })
      .catch(console.log)
      .finally(() => setLoading(false))
  }, [history, params, search])

  return (
    <AuthLayout>
      {loading && <Loading />}
      <S.Heading>
        Vérification
        <div className="py-3">de votre email</div>
      </S.Heading>
      <Link to="/" className="pr-2">
        <S.MecButton>
          <MecButton
            widthIcon={24}
            width={200}
          >
            Accueil
          </MecButton>
        </S.MecButton>
      </Link>
      <S.NormalText className="py-4 text-center">{message}</S.NormalText>
    </AuthLayout>
  )
}

export default VerifyEmailPage;
