import React from "reactn"
import { filter } from "lodash"
import { MecRange } from "src/markup/Components"
import { MecSelectorCustom } from "src/markup/Components/Forms"
import { genderize } from "src/utils"
import * as Yup from "yup"
import { InputLocation } from "src/markup/Pages/SearchPage/Recruiter/components/InputLocation"

export const LEVELS = [
  { id: true, name: "Oui" },
  { id: false, name: "Non" },
]

export const SELECT_COMPONENT = 1
export const INPUT_COMPONENT = 2
export const RANGE_COMPONENT = 3

export const INITIAL_VALUES = {
  joblistId: null,
  pres_jobOtherName: null,
  search_exp: null,
  search_qualityMain: null,
  search_quality2: null,
  search_quality3: null,
  search_formDiploma: null,
  search_formDiplomaOption: null,
  search_mission: null,
  search_skill: null,
  search_skillComp: null,
  search_tools: null,
  search_language: null,
  extra_driversLicenceB: null,
  city: "",
  maxRange: 100,
  extra_criminalRecord: false,
}

export const SEARCH_BASIC_KEY = ["joblistId", "pres_jobOtherName", "city", "maxRange"]

export const SEARCH_ADVANCED_KEY = ["search_exp", "search_formDiploma", "search_formDiplomaOption"]

export const SECTION_SEARCH_KEY = ["exp", "mission", "quality", "skill", "other"]

export const SEARCH_BASIC = {
  joblistId: {
    label: "Quel est le métier du candidat ?",
    type: SELECT_COMPONENT,
    name: "joblistId",
    keyOption: "jobs",
    dependencyWatch: ["pres_jobOtherName", "search_mission", "search_specMission", "search_skill", "search_skillComp", "search_tools", "search_formDiploma", "search_formDiplomaOption"],
    className: "col-lg-6 col-12",
    isRequired: true,
  },
  pres_jobOtherName: {
    label: "Précisez votre recherche",
    type: SELECT_COMPONENT,
    name: "pres_jobOtherName",
    keyOption: "otherJobs",
    dependency: "joblistId",
    isCheckCondition: true,
    keyCheck: "joblistId",
    className: "col-lg-6 col-12",
    noOptionsMessage: "Veuillez sélectionner un métier...",
  },
  city: {
    label: "Dans quelle ville ?",
    type: INPUT_COMPONENT,
    name: "city",
    placeholder: "",
    className: "col-lg-6 col-12",
    isRequired: true,
  },
  maxRange: {
    label: "Rayon autour de la ville",
    type: RANGE_COMPONENT,
    name: "maxRange",
    placeholder: "",
    className: "col-lg-6 col-12",
  },
}

export const SEARCH_ADVANCED = {
  exp: {
    title: "RECHERCHE PAR NIVEAU D'EXPÉRIENCE ET NIVEAU D'ÉTUDE",
    keyInSection: ["search_exp", "search_formDiploma", "search_formDiplomaOption"],
    inputs: {
      search_exp: {
        label: "Quel niveau d'expérience recherchez-vous ?",
        name: "search_exp",
        keyOption: "xp",
        isCheckGender: true,
        valueGenders: {
          male: "xp_m",
          female: "xp_f",
          both: "xp",
        },
        className: "col-12",
      },
      search_formDiploma: {
        label: "Quel diplôme recherchez-vous ?",
        name: "search_formDiploma",
        keyOption: "diplomas",
        className: "col-lg-6 col-12",
      },
      search_formDiplomaOption: {
        label: "Spécifiez une option",
        name: "search_formDiplomaOption",
        keyOption: "diplomaOptions",
        dependency: "joblistId",
        isCheckCondition: true,
        keyCheck: "joblistId",
        className: "col-lg-6 col-12",
        noOptionsMessage: "Veuillez sélectionner un diplôme...",
      },
    },
  },

  mission: {
    title: "RECHERCHE PAR MISSION",
    keyInSection: ["search_mission", "search_specMission"],
    inputs: {
      search_mission: {
        label: "Pour quelles missions recrutez-vous ?",
        name: "search_mission",
        keyOption: "missions",
        dependency: "joblistId",
        isMulti: true,
        maxLengthSelect: 5,
        isCheckCondition: true,
        keyCheck: "joblistId",
        className: "col-lg-6 col-12",
        noOptionsMessage: "Veuillez sélectionner un métier...",
      },
      search_specMission: {
        label: "Précisez votre recherche",
        name: "search_specMission",
        placeholder: "Sélectionnez une mission complémentaire",
        keyOption: "missionSpecs",
        dependency: "joblistId",
        isCheckCondition: true,
        keyCheck: "joblistId",
        className: "col-lg-6 col-12",
        noOptionsMessage: "Veuillez sélectionner une mission...",
      },
    },
  },

  quality: {
    title: "RECHERCHE PAR QUALITÉ",
    keyInSection: ["search_qualityMain", "search_quality2", "search_quality3"],
    inputs: {
      search_qualityMain: {
        label: "Quelles doivent être les principales qualités du candidat ?",
        name: "search_qualityMain",
        keyOption: "qualityMain",
        className: "col-12",
        isMulti: true,
        maxLengthSelect: 3,
      },
      search_quality2: {
        label: "Il devrait aussi avoir :",
        name: "search_quality2",
        keyOption: "quality2",
        className: "col-lg-6 col-12",
      },
      search_quality3: {
        label: "Il devrait également être :",
        name: "search_quality3",
        keyOption: "quality3",
        className: "col-lg-6 col-12",
      },
    },
  },

  skill: {
    title: "RECHERCHE PAR COMPÉTENCE",
    keyInSection: ["search_skill", "search_skillComp", "search_tools", "search_language"],
    inputs: {
      search_skill: {
        label: "Quelles compétences recherchez-vous ?",
        name: "search_skill",
        keyOption: "skills",
        dependency: "joblistId",
        isCheckCondition: true,
        keyCheck: "joblistId",
        className: "col-12",
        isMulti: true,
        maxLengthSelect: 3,
        noOptionsMessage: "Veuillez sélectionner un métier...",
      },
      search_skillComp: {
        label: "Plus spécifiquement ?",
        name: "search_skillComp",
        keyOption: "skillsComp",
        dependency: "joblistId",
        isCheckCondition: true,
        keyCheck: "joblistId",
        className: "col-12",
        isMulti: true,
        maxLengthSelect: 3,
        noOptionsMessage: "Veuillez sélectionner une compétence...",
      },
      search_tools: {
        label: "Quels outils le candidat doit-il maîtriser ?",
        name: "search_tools",
        keyOption: "tools",
        dependency: "joblistId",
        isCheckCondition: true,
        keyCheck: "joblistId",
        className: "col-12",
        isMulti: true,
        maxLengthSelect: 5,
        noOptionsMessage: "Veuillez sélectionner un métier...",
      },
      search_language: {
        label: "Quelles langues le candidat doit-il parler ?",
        name: "search_language",
        keyOption: "languages",
        className: "col-12",
        isMulti: true,
      },
    },
  },
  other: {
    title: "AUTRES INFORMATIONS",
    keyInSection: ["extra_driversLicenceB"],
    inputs: {
      extra_driversLicenceB: {
        label: "Doit-il avoir un permis de conduire ?",
        name: "extra_driversLicenceB",
        keyOption: "otherDriverLicenses",
        className: "col-12",
        isMulti: true,
      },
    },
  },
}

export const getSchemaValidation = ({ t }) => {
  let schema = {}

  const keyValid = ["joblistId"]
  for (let i = 0; i < keyValid.length; i++) {
    Object.assign(schema, {
      [keyValid[i]]: Yup.object().required(t("required_field")).nullable()
    })
  }
  return Yup.object({
    ...schema,
    city: Yup.string().required(t("required_field"))
  })
}

const compareData = (rootData, keyCheck, valueCompare) =>
  keyCheck ? filter(rootData, (data) => data[keyCheck] === valueCompare) : []

export const renderFields = (props) => {
  const {
    formik,
    config,
    name,
    isMulti,
    maxLengthSelect,
    placeholder,
    keyOption,
    type,
    isCheckGender,
    isCheckCondition,
    gender,
    dependency,
    dependencyWatch,
    noOptionsMessage,
  } = props

  let options = config[keyOption]

  if (isCheckGender) {
    const { valueGenders } = props
    options = genderize({
      male: config[valueGenders.male],
      female: config[valueGenders.female],
      both: config[valueGenders.both],
      gender,
    })
  }
  if (isCheckCondition) {
    const { keyCheck } = props
    options = compareData(config[keyOption], keyCheck, formik.values[dependency]?.id)
  }

  switch (type) {
    case INPUT_COMPONENT:
      return (
        <InputLocation
          placeholder={placeholder || "Entrez le nom d'une ville"}
          formik={formik}
          name={name}
          className="secondary w-100"
          style={{ height: 45, minWidth: "auto" }}
        />
      )
    case RANGE_COMPONENT:
      return (
        <MecRange
          onValueChanged={(value) => {
            formik.setFieldValue(name, value)
          }}
          name={name}
          value={formik.values[name]}
          formik={formik}
          min={0}
          max={100}
        />
      )
    case SELECT_COMPONENT:
    default:
      return (
        <MecSelectorCustom
          isMulti={isMulti}
          options={options}
          getOptionLabel={({ name }) => name}
          getOptionValue={({ id }) => id}
          className="text-align-last-left"
          name={name}
          placeholder={placeholder || "Sélectionnez..."}
          colorTxtError="red"
          onChange={(option) => {
            if (option.length > maxLengthSelect) {
              return
            }
            formik.setFieldValue(name, option)
            if (dependencyWatch) {
              dependencyWatch.forEach((dependency) => {
                formik.setFieldValue(dependency, null)
              })
            }
          }}
          onBlur={() => formik.setFieldTouched(name, true)}
          value={formik.values[name]}
          errors={formik.errors[name]}
          touched={formik.touched[name] || formik.touched[name]}
          customNoOptionsMessage={noOptionsMessage}
          dirty
        />
      )
  }
}
