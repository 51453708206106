import React from "reactn"
import { GOOGLE_API_KEY } from "src/constants"
import { extractFromAddress } from "src/utils"
import * as S from "./styles"

export const InputLocation = ({ formik, name, colorTxtError, ...rest }) => {
  const { handleBlur, setFieldValue, values, errors, touched } = formik
  return (
    <>
      <S.AutocompleteLocation
        defaultValue={values[name]}
        options={{
          componentRestrictions: { country: "fr" },
          type: ["(regions)"],
        }}
        id={name}
        name={name}
        apiKey={GOOGLE_API_KEY}
        onPlaceSelected={({ address_components }) => {
          setFieldValue(name, extractFromAddress(address_components, "locality"))
        }}
        onBlur={handleBlur}
        error={touched[name] && (!!errors[name] ? 1 : 0)}
        invalid={errors[name] && (touched[name] ? 1 : 0)}
        {...rest}
      />
      <S.Feedback colorTxtError={colorTxtError}>{errors[name] && touched[name] && errors[name]}</S.Feedback>
    </>
  )
}
