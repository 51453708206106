import React, { useGlobal } from "reactn"
import moment from "moment"
import { find } from "lodash"

import { FORMAT_DATE } from "src/constants"
import * as S from "./styles"

const Lists = ({ handleViewMessage, messageView, messages }) => {
  const [user] = useGlobal("user")

  return (
    <S.MessageArchived>
      <S.Header>
        <div className="row">
          <div className="col d-flex pt-3">
            <div className="label text-align-last-left label-required mr-2">De :</div>
          </div>

          <div className="col d-flex pt-3">
            <div className="label text-align-last-left label-required mr-2">Objet :</div>
          </div>

          <div className="col d-flex pt-3">
            <div className="label text-align-last-left label-required mr-2">Date :</div>
          </div>
        </div>
        <hr style={{ position: "initial" }} />
      </S.Header>
      <S.ListWrapper>
        {messages?.map((message) => (
          <S.List
            className="m-0 p-0"
            key={message.id}
            onClick={() => handleViewMessage(message)}
            active={messageView?.id === message.id}
            style={find(message.user_read, (id) => id === user.id) && { fontWeight: "200" }}
          >
            <div className="row">
              <div className="col d-flex pt-3">
                <div className="label text-align-last-left label-required mr-2">{message.group_messages_users[0].email}</div>
              </div>

              <div className="col d-flex pt-3">
                <div className="label text-align-last-left label-required mr-2">{message.object_title}</div>
              </div>

              <div className="col d-flex pt-3">
                <div className="label text-align-last-left label-required mr-2">
                  {moment(message.createdAt).format(FORMAT_DATE)}
                </div>
              </div>
            </div>
          </S.List>
        ))}
      </S.ListWrapper>
    </S.MessageArchived>
  )
}

export default Lists
