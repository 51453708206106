import React from "reactn"
import { FormGroup } from "reactstrap"

import { StyledForm } from "src/markup/Pages/Agenda/Recruiter/components/FormScheduleMeeting/styles"
import { ReactComponent as IconPlush } from "src/images/icons/icon-plus.svg"
import { MecInput, MecInputMultiple, MecSelectorCustom, MecTabSwitch } from "src/markup/Components/Forms"

import { ReactComponent as IconMinus } from "src/images/icons/icon-minus.svg"
import { MecDatePicker } from "src/markup/Components/MecDatePicker"
import { DURATION_AGENDA } from "src/constants"

export const durationOptions = [
  { name: "30 minutes", id: DURATION_AGENDA["THIRTY_MIN"] },
  { name: "1 heure", id: DURATION_AGENDA["ONE_HOUR"] },
  { name: "1 heure 30 minutes", id: DURATION_AGENDA["ONE_HOUR_THIRTY_MIN"] },
  { name: "2 heures", id: DURATION_AGENDA["TWO_HOURS"] },
]

const isVideo = [
  { id: true, name: "Oui" },
  { id: false, name: "Non" },
]

export const FormScheduleMeeting = ({
  formik,
  offers,
  cvs,
  handleAddInviteColleagues,
  handleAddCvs,
  handleRemoveInviteColleagues,
  handleRemoveCvs,
  agenda
}) => {
  const {setFieldValue, setFieldTouched, values, errors, touched} = formik

  return (
    <StyledForm>
      <div className="row d-flex justify-content-between">
        <div className={`flex-column justify-content-end col-12 `}>
          <div className="sub-title text-align-last-left mb-4 " style={{ flex: 1 }}>
            Créez votre prochain entretien
          </div>
          <FormGroup className='w-100'>
            <div className='py-2 label text-align-last-left label-required'>
              Pour quelle offre d'emploi souhaitez-vous planifier un entretien ?
            </div>
            <MecSelectorCustom
              options={offers}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              className="text-align-last-left"
              name='offerId'
              placeholder="Sélectionnez une offre"
              colorTxtError="red"
              onChange={(option) => {
                setFieldValue('offerId', option)
              }}
              onBlur={() => setFieldTouched('offerId', true)}
              value={values.offerId}
              errors={errors.offerId}
              touched={touched.offerId}
              disabled={!!agenda}
            />
          </FormGroup>
        </div>

        <div className={`flex-column justify-content-end col-xl-6 `}>
          <FormGroup className='w-100'>
            <div className='py-2 label text-align-last-left label-required'>
              Sélectionnez le ou les candidat(s) que vous souhaitez rencontrer
            </div>
            <div className="py-2 sub-label text-align-last-left">
              Choisissez les candidats à partir des CV retenus et sélectionnés
            </div>
            {values.cvs.map((item, index) => (
              <div key={index} className="w-100 d-flex justify-content-between" style={{ gap: "15px" }}>
                <div style={{ flex: 1, marginBottom: "15px" }}>
                  <MecSelectorCustom
                    options={cvs[0] === null ? [] : cvs}
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ id }) => id}
                    formik={formik}
                    onChange={(option) => {
                      formik.setFieldValue(`cvs.${index}`, option)
                    }}
                    onBlur={() => setFieldTouched('cvs', true)}
                    value={formik.values.cvs?.[index]}
                    errors={formik.errors.cvs?.[index]}
                    touched={formik.touched.cvs?.[index] || formik.touched.cvs}
                    className="secondary text-align-last-left"
                    placeholder="Sélectionnez un candidat"
                    disabled={!!agenda}
                    name='cvs'
                  />
                </div>

                {index ? <IconMinus onClick={() => handleRemoveCvs(index)} style={{ cursor: "pointer" }} /> : null}
              </div>
            ))}
          </FormGroup>
          {!agenda && (
            <div className="row d-flex justify-content-end">
              <FormGroup className="d-flex justify-content-end add-experience" onClick={handleAddCvs}>
                <IconPlush />
                <div className="py-2 label">Ajouter un candidat</div>
              </FormGroup>
            </div>
          )}
        </div>

        <div className={`flex-column justify-content-end col-12`}>
          <FormGroup className='w-100'>
            <div className='py-2 label text-align-last-left label-required'>
              Choisissez la date et l'heure de l'entretien
            </div>
            <MecDatePicker
              onChange={(value) => {
                setFieldValue('datetime_interview', value)
              }}
              name='datetime_interview'
              onBlur={() => setFieldTouched('datetime_interview', true)}
              value={values.datetime_interview}
              errors={errors.datetime_interview}
              touched={touched.datetime_interview}
              selected={values.datetime_interview && new Date(values.datetime_interview)}
              placeholder='Séléctionnez une date horaire'
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="Horaire"
              dateFormat="dd/M/yyyy HH:mm"
              autoComplete="off"
              isTime
              minDate={new Date()}
            />
          </FormGroup>
        </div>

        <div className={`flex-column justify-content-end col-12`}>
          <FormGroup className='w-100'>
            <div className='py-2 label text-align-last-left label-required'>
              Quelle sera la durée de l'entretien ?
            </div>
            <MecSelectorCustom
              options={durationOptions}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              className="text-align-last-left"
              name='duration'
              placeholder="Sélectionnez une durée"
              colorTxtError="red"
              onChange={(option) => {
                setFieldValue('duration', option)
              }}
              onBlur={() => setFieldTouched('duration', true)}
              value={values.duration}
              errors={errors.duration}
              touched={touched.duration}
            />
          </FormGroup>
        </div>

        <div className={`flex-column justify-content-end col-12`}>
          <FormGroup className='w-100 d-flex'>
            <div className='py-2 label text-align-last-left label-required'>
              L'entretien sera-t-il en vidéoconférence ?
            </div>
            <MecTabSwitch
              tabs={isVideo}
              activeTab={formik.values.isVideo ? 0 : 1}
              className="w-250 ml-3"
              name='isVideo'
              onChange={(e) => {
                formik.setFieldValue('isVideo', !e)
              }}
            />
          </FormGroup>
        </div>

        <div className={`flex-column justify-content-end col-12 ${values.isVideo && 'd-none'}`}>
          <FormGroup className='w-100'>
            <div className='py-2 label text-align-last-left label-required'>
              Où souhaitez-vous faire passer l’entretien ?
            </div>
            <MecInput
              name='address'
              placeholder='Adresse'
              formik={formik}
              colorTxtError="red"
              className="secondary text-align-last-left"
              style={{ minWidth: "auto" }}
            />
          </FormGroup>
        </div>

        <div className={`flex-column justify-content-end col-12 ${values.isVideo && 'd-none'}`}>
          <FormGroup className='w-100'>
            <MecInput
              name='address1'
              placeholder='CP Ville'
              formik={formik}
              colorTxtError="red"
              className="secondary text-align-last-left"
              style={{ minWidth: "auto" }}
            />
          </FormGroup>
        </div>

        <div className={`flex-column justify-content-end col-12 ${values.isVideo && 'd-none'}`}>
          <FormGroup className='w-100'>
            <MecInput
              name='address2'
              placeholder="Complément d'adresse"
              formik={formik}
              colorTxtError="red"
              className="secondary text-align-last-left"
              style={{ minWidth: "auto" }}
            />
          </FormGroup>
        </div>

        <div className={`flex-column justify-content-end col-12`}>
          <FormGroup className='w-100'>
            <div className='py-2 label text-align-last-left'>
              Informations complémentaires
            </div>
            <MecInput
              formik={formik}
              name='information'
              placeholder="Ajoutez des informations concernant cet entretien"
              className="secondary text-align-last-left"
              style={{ minWidth: "auto" }}
              cols="50"
              rows="10"
              as='textarea'
            />
          </FormGroup>
        </div>

        <div className={`flex-column justify-content-end col-12`}>
          <FormGroup className='w-100'>
            <div className='py-2 label text-align-last-left label-required'>
              Organisateur
            </div>
            {
              values.organizer.map((item, index) => (
                <div key={index} className="w-100 row m-0 d-flex" style={{flexWrap: 'nowrap', gap: '30px'}}>
                  <div className="w-100">
                    <MecInputMultiple
                      formik={formik}
                      onChange={(e) => {
                        formik.setFieldValue(`organizer.${index}.name`, e.target.value)
                      }}
                      value={formik.values.organizer?.[index]?.name}
                      errors={formik.errors.organizer?.[index]?.name}
                      touched={formik.touched.organizer?.[index]?.name || formik.touched.organizer}
                      className="secondary text-align-last-left"
                      placeholder='Nom, prénom'
                      name='organizer'
                      style={{ minWidth: "auto" }}
                    />
                  </div>
                  <div className="w-100">
                    <MecInputMultiple
                      formik={formik}
                      onChange={(e) => {
                        formik.setFieldValue(`organizer.${index}.email`, e.target.value)
                      }}
                      value={formik.values.organizer?.[index]?.email}
                      errors={formik.errors.organizer?.[index]?.email}
                      touched={formik.touched.organizer?.[index]?.email || formik.touched.organizer}
                      className="secondary text-align-last-left"
                      placeholder='E-mail'
                      name='organizer'
                      style={{ minWidth: "auto" }}
                    />
                  </div>
                </div>
              ))
            }
          </FormGroup>
        </div>
        <div className={`flex-column justify-content-end col-xl-6`}>
          <FormGroup className='w-100'>
            <div className='py-2 label text-align-last-left'>
              Invitez des collègues
            </div>
            {
              values.invite_colleagues.map((item, index) => (
                <div key={index} className="d-flex justify-content-between" style={{ gap: "15px" }}>
                  <div style={{ flex: 1, marginBottom: "15px" }}>
                    <MecInputMultiple
                      formik={formik}
                      onChange={(e) => {
                        formik.setFieldValue(`invite_colleagues.${index}.email`, e.target.value)
                      }}
                      value={formik.values.invite_colleagues?.[index]?.email}
                      errors={formik.errors.invite_colleagues?.[index]?.email}
                      touched={formik.touched.invite_colleagues?.[index]?.email || formik.touched.invite_colleagues}
                      className="secondary text-align-last-left"
                      placeholder='E-mail'
                      style={{ minWidth: "auto" }}
                      name='invite_colleagues'
                    />
                  </div>
                  {index ? <IconMinus onClick={() => handleRemoveInviteColleagues(index)} style={{ cursor: "pointer" }} /> : null}
                </div>
              ))
            }
          </FormGroup>
          <div className="row d-flex justify-content-end">
            <FormGroup className="d-flex justify-content-end add-experience" onClick={handleAddInviteColleagues}>
              <IconPlush />
              <div className="py-2 label">Inviter un collègue</div>
            </FormGroup>
          </div>
        </div>
      </div>
    </StyledForm>
  )
}
