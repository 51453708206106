import React, { memo, useGlobal } from "reactn"
import { get, isEmpty } from "lodash"
import { ASSET_QUALITY2_URL, ASSET_QUALITY3_URL, ASSET_QUALITYMAIN_URL } from "src/constants"
import { ReactComponent as IconCar } from "src/images/icons/icon-car.svg"
import { DetailOffer, FooterOffer } from "src/markup/Pages/Offer/components"
import { populateOffer } from "src/services/config"
import { capitalize, formatPrice, genderize } from "src/utils"
import {
  CardDetailsOfferWrapper,
  CollapseDetailCV,
  DetailsOfferLeftWrapper,
  DetailsOfferRightWrapper,
  DetailsOfferWrapper,
  LeftContentWrapper,
  LeftLogoWrapper,
} from "./styles"

export const CardOffer = memo(({ offer }) => {
  const [config] = useGlobal("config")
  const [user] = useGlobal("user")
  const gender = user?.gender?.toLowerCase() || null

  if (isEmpty(offer)) return null

  const data = populateOffer(config, offer)
  if (isEmpty(data)) return null;

  return (
    <CardDetailsOfferWrapper isPrefix={get(offer, "featured_offer", false)}>
      <DetailsOfferWrapper className="row justify-content-between">
        <DetailsOfferLeftWrapper border>
          <LeftLogoWrapper>
            {get(offer, "offer_logo", false) ? (
              <img src={offer.offer_logo} alt="logo-details" />
            ) : (
              <div className="d-flex justify-content-start align-items-center w-100 title-offers mb-4">
                <IconCar className="mr-2 icon-reponsive" />
                <div className="d-flex flex-column">
                  <span>{get(data, "jobNames[0]", "")}</span>
                  <span>{data.name_company}</span>
                </div>
              </div>
            )}
          </LeftLogoWrapper>

          <LeftContentWrapper>
            <div className="ml-3">Résumé de l'offre</div>
            <div>
              {genderize({
                male: "Doté",
                female: "Dotée",
                both: "Doté(e)",
                gender,
              })}{" "}
              d'un {get(data, "diplomas[0]", "")} ou niveau équivalent, vous disposez d'un profil{" "}
              <span className="text-first-lowercase">{get(data, "xp[0].name", "")}</span> et vous êtes doté de{" "}
              <span className="text-first-lowercase">{get(data, "xp[0].status", "")}</span>  d'expérience en tant{" "}
              que :{" "}
              {get(data, "otherJobNames[0]", "")}.
            </div>
          </LeftContentWrapper>
        </DetailsOfferLeftWrapper>

        <DetailsOfferRightWrapper className="d-flex flex-column justify-content-between" border>
          <div className="d-flex mb-4 list-skills">
            {get(data, "qualityMains", []).map((quality) => (
              <div className="d-flex flex-column align-items-center" key={quality.qualityMainId}>
                <img
                  className="icon-skills mb-2 icon-reponsive"
                  src={ASSET_QUALITYMAIN_URL + quality.filename}
                  alt={quality?.name}
                />
                <span className="text-first-capitalize card-text-home">{quality?.name}</span>
              </div>
            ))}

            {get(data, "quality2", []).map((quality2) => (
              <div className="d-flex flex-column align-items-center" key={quality2.id}>
                <img
                  className="icon-skills mb-2 icon-reponsive"
                  src={ASSET_QUALITY2_URL + quality2.filename}
                  alt={quality2?.title}
                />
                <span className="text-first-capitalize card-text-home">{quality2?.title}</span>
              </div>
            ))}

            {get(data, "quality3", []).map((quality3) => (
              <div className="d-flex flex-column align-items-center" key={quality3.quality3Id}>
                <img
                  className="icon-skills mb-2 icon-reponsive"
                  src={ASSET_QUALITY3_URL + quality3.filename}
                  alt={quality3?.title}
                />
                <span className="text-first-capitalize card-text-home">{quality3?.title}</span>
              </div>
            ))}
          </div>

          <ul className="job-description infos text-blue ml-4">
            <li>
              <b>Localisation :</b> {[capitalize(offer.pres_city), offer.pres_zip].filter(Boolean).join(', ')}
            </li>
            <br></br>
            <li>
              <b>Contrat :</b> {data?.contractTypes[0]}
            </li>
            <br></br>
            <li>
              <b>Salaire :</b> {formatPrice(offer.salary)} € Brut annuel
            </li>
          </ul>
        </DetailsOfferRightWrapper>

        <CollapseDetailCV isOpen>
          <h3 className="text-orange mt-4">OFFRE DÉTAILLÉE</h3>
          <DetailOffer data={data} />
        </CollapseDetailCV>

        <FooterOffer offer={{ ...offer, date: data.sentDate }} />
      </DetailsOfferWrapper>
    </CardDetailsOfferWrapper>
  )
})
