import React, { useMemo, useCallback, useGlobal } from "reactn";
import { get } from "lodash";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import * as S from "./styles";
import { TextBlue } from "./styles";
import { MecButton } from "src/markup/Components";
import { ReactComponent as SearchIcon } from "src/images/icons/ic-search.svg";
import { ReactComponent as ScheduleIcon } from "src/images/icons/schedule-icon.svg";
import { ReactComponent as ClockIcon } from "src/images/icons/clock-icon.svg";
import { ReactComponent as LocationIcon } from "src/images/icons/location-icon.svg";
import { ReactComponent as CancelIcon } from "src/images/icons/cancel-icon-white.svg";
import { ReactComponent as TickIcon } from "src/images/icons/tick-icon.svg";

import { getJobById, parseDateTime } from "src/services/config";
import { apiCandidateEditStatusAgenda } from "src/services/api";
import { toast } from "react-toastify";
import { AGENDA_STATUS } from "src/constants";

export const Card = ({ isPrefix, agenda, config, updateAgendaSuccess }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [user] = useGlobal("user");

  const getJobInfo = useMemo(() => {
    if (config && agenda) {
      const options = {
        config,
        otherJobId: get(agenda, "offer.joblistJobs[0].otherJobId", null),
        jobId: get(agenda, "offer.joblistJobs[0].mainJobId", null),
      };
      const data = getJobById(options);

      return data;
    }

    return null;
  }, [agenda, config]);

  const handleChangeStatus = useCallback(
    async (queries) => {
      try {
        const updateData = {
          agendaId: get(agenda, "id", ""),
          offerId: get(agenda, "offerId"),
          status: queries.status,
        };
        await apiCandidateEditStatusAgenda(updateData);
        toast.success(
          t(
            `Agenda was ${
              queries && queries.accepted ? "accepted" : "rejected"
            } success`
          )
        );
        updateAgendaSuccess();
      } catch (error) {
        console.log(error);
      }
    },
    [agenda, t, updateAgendaSuccess]
  );

  const getJobLiasion = useMemo(() => {
    if (!agenda) {
      return null;
    }

    const { jobliaison } = agenda;
    const jobliaisonSelect = jobliaison.find(
      (lia) =>
        lia.agendaId === agenda.id &&
        lia.offerId === agenda.offerId &&
        lia.candidateId === user.id
    );

    return jobliaisonSelect;
  }, [agenda, user.id]);

  return (
    <S.Wrapper isPrefix={isPrefix}>
      <div className="d-flex flex-wrap justify-content-between border-bottom-5 pb-2">
        <div className="d-flex align-items-center px-0">
          <S.WrapperShipIcon />
          <S.TextOrange className="pl-2" bold>
            {`${getJobInfo?.jobName} - ${getJobInfo?.otherJobName}`}
          </S.TextOrange>
        </div>
        <div className="row justify-content-end align-items-center">
          <div style={{ height: 40 }} className="w-100">
            <MecButton icon={SearchIcon} widthIcon={18} width="100%" onClick={() => history.push(`/offer/${agenda.offerId}`)} >
              Voir l'annonce
            </MecButton>
          </div>
        </div>
      </div>

      <div className="row py-2 justify-content-between">
        <div className="col-8 row align-items-center">
          <ScheduleIcon width={20} />
          <S.TextOrange bold className="pl-2" size={30}>
            {parseDateTime(get(agenda, "time", new Date()))}
          </S.TextOrange>
        </div>
        <div className="col-4 d-flex align-items-center justify-content-end">
          <ClockIcon width={18} />
          <TextBlue className="pl-2" bold>
            Durée : {get(agenda, "duration", "-")}
          </TextBlue>
        </div>
      </div>

      <div className="row justify-content-between border-bottom-5 pb-2">
        <div className="col-8 row align-items-center">
          <LocationIcon width={18} />
          <S.TextBlue className="pl-2" bold>
            {`${get(agenda, "address", "")}-${get(
              agenda,
              "address1",
              ""
            )}-${get(agenda, "address2", "")}`}
          </S.TextBlue>
        </div>
      </div>

      <div className="py-4">
        <div className="d-flex pb-1">
          <S.TextOrange size={15} bold>
            Société:
          </S.TextOrange>
          <S.TextBlue className="pl-1" size={15} bold>
            {get(agenda, "offer.name_company", "")}
          </S.TextBlue>
        </div>
        <div className="d-flex pb-1">
          <S.TextOrange size={15} bold>
            Organisateur(s) :
          </S.TextOrange>
          <S.TextBlue className="pl-1" size={15} bold>
            {agenda?.organizer
              .map((or) => `${or.name} - ${or.email}`)
              .join(", ")}
          </S.TextBlue>
        </div>
        <div className="d-flex pb-1">
          <S.TextOrange size={15} bold>
            Informations complémentaires :
          </S.TextOrange>
          <S.TextBlue className="pl-1" size={15} bold>
            {agenda?.information}
          </S.TextBlue>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4 col-6 mb-3 pl-0">
          <MecButton
            type="outlineNotHover"
            widthIcon={18}
            onClick={() => history.push(`/candidate/mycv`)}
          >
            Voir le CV envoyé
          </MecButton>
        </div>
        <div className="row flex-wrap offset-lg-2 offset-md-0 col-lg-6 col-12 justify-content-end px-0 mb-3">
          {getJobLiasion &&
          getJobLiasion.status_agenda === AGENDA_STATUS.WAITING ? (
            <>
              <div className="col-6 pl-0">
                <MecButton
                  icon={CancelIcon}
                  widthIcon={18}
                  onClick={() =>
                    handleChangeStatus({
                      status: AGENDA_STATUS.ACCEPTED,
                    })
                  }
                >
                  Accepter
                </MecButton>
              </div>
              <div className="col-6 pr-0">
                <MecButton
                  icon={TickIcon}
                  widthIcon={18}
                  width="100%"
                  onClick={() =>
                    handleChangeStatus({
                      rejected: AGENDA_STATUS.REJECTED,
                    })
                  }
                >
                  Refuser
                </MecButton>
              </div>
            </>
          ) : (
            <div className="col-6 pr-0">
              <MecButton
                icon={TickIcon}
                widthIcon={18}
                width="100%"
              >
                {getJobLiasion && getJobLiasion.status_agenda === AGENDA_STATUS.ACCEPTED ? 'Accepté' : 'Refuser' }
              </MecButton>
            </div>
          )}
        </div>
      </div>
    </S.Wrapper>
  );
};
