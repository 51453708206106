import { ModalBody } from 'reactstrap'
import { useHistory, useLocation } from 'react-router-dom'

import { useGetDataModal } from 'src/services'
import { ROLE } from 'src/constants'
import * as S from './styles'

import { ReactComponent as IconCancel } from "src/images/icons/icon-cancel.svg"

const pathMatch = {
  [ROLE.CANDIDATE]: {
    register: '/candidate/register',
    login: '/candidate/login',
  },
  [ROLE.RECRUITER]: {
    register: '/recruiter/register',
    login: '/recruiter/login',
  },
}

export const ConfirmModalAuth = ({ onClose }) => {
  const { pathname } = useLocation()
  const history = useHistory()
  const {
    isOpen,
    confirmAuth: { role },
  } = useGetDataModal();

  const onRedirect = (path) => {
    Promise.resolve()
      .then(history.push({
        pathname: path,
        state: pathname,
      }))
      .then(() => {
        onClose()
      })
  }

  return (
    <S.Wrapper isOpen={isOpen} centered toggle={onClose}>
      <ModalBody>
        <S.Cancel onClick={onClose}>
          <IconCancel width={30} height={30} />
        </S.Cancel>
        <div className="quote-bx text-center mb-5">
          <div className="quote-info">
            <h3 className="text-uppercase">Mes recherches</h3>
            <p>Connectez-vous en tant que candidat pour enregistrer votre CV et vos recherches</p>
            <div className="left-job-buttons">
              <div
                onClick={() => onRedirect(pathMatch[role].register)}
                className="site-button site-button-gap radius-xl text-uppercase"
              >
                Inscription
              </div>
              <div
                onClick={() => onRedirect(pathMatch[role].login)}
                className="site-button site-button-gap radius-xl text-uppercase"
              >
                Connexion
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </S.Wrapper>
  )
}
