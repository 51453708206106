import styled from 'styled-components'

export const Heading = styled.h3`
  position: relative;
  color: var(--white);
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 96px;

  &:after {
    content: '';
    position: absolute;
    top: calc(100% + 18px);
    left: 50%;
    transform: translateX(-50%);
    height: 2px;
    width: 200px;
    background: var(--white);
  }

  @media(max-width: 992px) {
    padding-top: 80px;
  }
  
  @media(max-width: 768px) {
    padding-top: 50px;
  }
`

export const Button = styled.button`
  background: var(--blue600);
  border-radius: 20px;
  color: var(--white);
  font-family: Raleway;
  font-weight: 800;
  padding: 10px 15px;
  outline: none;
  border: none;

  min-width: 185px;
  cursor: pointer;

  &:hover {
    box-shadow: 2px 4px 6px 1px rgba(0, 0, 0, 0.37);
  }
`

export const NormalText = styled.div`
  font-size: 14px;
  color: var(--white);
  text-align: left;

  cursor: pointer;

  &.text-bottom {
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }
`
