import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationFR from "src/locales/fr";
import translationEN from "src/locales/en";

const DEFAULT_LANG = "fr";

// Translations files
const resources = {
  fr: {
    translation: translationFR,
  },
  en: {
    translation: translationEN,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,

    fallbackLng: DEFAULT_LANG,
    lng: localStorage.getItem('lang'),

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
