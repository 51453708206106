import React from "react"
import Skeleton from "react-loading-skeleton"

import * as S from "./styles"

const CardLoadingSkeleton = () => {
  return (
    <S.Box>
      <S.Infor>
        <S.TitleInfor>
          <Skeleton width={300} />
          <Skeleton width={150} />
          <Skeleton width={200} />
        </S.TitleInfor>
      </S.Infor>

      <S.Summary>
        <Skeleton count={4} />
      </S.Summary>

      <S.Infor>
        <Skeleton count={3} />
      </S.Infor>

      <S.Footer>
        <Skeleton count={2} />
      </S.Footer>
    </S.Box>
  )
}

export default CardLoadingSkeleton
