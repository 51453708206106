import styled from "styled-components";


export const Heading = styled.h3`
  position: relative;
  color: var(--orange300);
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 96px;

  &:after {
    content: '';
    position: absolute;
    top: calc(100% + 18px);
    left: 50%;
    transform: translateX(-50%);
    height: 2px;
    width: 200px;
    background: #E8EEF2;
  }

  @media(max-width: 992px) {
    font-size: 30px;
  }
`

export const OfferDetailsWrapper = styled.div`
  @media (min-width: 1440px) {
    .content-inner-2 {
      padding: 20px 0;
    }
  }
`;


export const ContentWrapper = styled.div`
  box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
  border-radius: 30px;

  & > div {
    border: none;
    box-shadow: none;
  }

  & > div {
    padding-bottom: 0;
  }

  .icon-skills {
    circle {
      fill: #002d56;
    }
  }
  .mec-button:first-child,
  .send-details {
  }
`;


export const StyledCard = styled.div`
  box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
  border-radius: 30px;

  & > div {
    border: none;
    box-shadow: none;
  }

  .icon-skills {
    circle {
      fill: #002d56;
    }
  }
`
