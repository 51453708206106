import React, { memo, useGlobal, useLayoutEffect, useRef, useState } from "reactn"
import { useHistory } from "react-router-dom"
import { keyBy, values } from "lodash"
import { toast } from "react-toastify"
import { useMainContext } from "src/markup/Layout/MainLayout/MainContext"
import { FormSearchJob, ListCardJob } from "src/markup/Pages/SearchPage/Candidate/components"
import { deleteSavedSearch, getListSavedSearch, updateSavedSearch } from "src/services/api"
import * as S from "./styles"

export const SavedSearch = memo(({ site }) => {
  const history = useHistory()
  const [config] = useGlobal("config")
  const { isShowResult } = useMainContext()

  const refResult = useRef(null)
  const [formList, setFormList] = useState({})

  const _getListSavedSearch = async () => {
    const { data } = await getListSavedSearch()
    setFormList(keyBy(data, (el) => el.id))
  }

  useLayoutEffect(() => {
    _getListSavedSearch()
    if (isShowResult && refResult.current) {
      refResult.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }
  }, [isShowResult])

  if (!config) return null

  const onDelete = async (id) => {
    const { message } = await deleteSavedSearch(id)
    toast.success(message, {
      autoClose: 2000,
      theme: "colored",
    })
    delete formList[id]
    setFormList({ ...formList })
  }

  const onUpdate = async (id, newData) => {
    const { message } = await updateSavedSearch(id, {
      data_save_search: newData,
      name_search: [
        newData?.joblistId?.name,
        newData?.city
      ].filter(Boolean).join(', '),
    })
    toast.success(message, {
      autoClose: 2000,
      theme: "colored",
    })
    setFormList({ ...formList })
    history.go(0);
  }

  return (
    <S.Wrapper>
      <div className="d-flex justify-content-center">
        <S.Heading>Recherches enregistrées ({values(formList).length})</S.Heading>
      </div>
      <hr className="v3-separator pb-xl-3" />
      {values(formList).map((formItem) => (
        <FormSearchJob
          key={formItem.id}
          heading="RECHERCHES SAUVEGARDÉES"
          onDelete={onDelete}
          onUpdate={onUpdate}
          formItem={formItem}
          site={site}
        />
      ))}

      {isShowResult && (
        <div ref={refResult} className="pt-5">
          <ListCardJob />
        </div>
      )}
    </S.Wrapper>
  )
})
