import TestimonialImg1 from "./../../../images/testimonial-1.png"
import TestimonialImg2 from "./../../../images/testimonial-2.png"
import TestimonialImg3 from "./../../../images/testimonial-3.png"

import ExpertIcon from "../../../images/icons/expert.svg";
import VisibilityIcon from "../../../images/icons/visibility.svg";
import PersonalMonitorIcon from "../../../images/icons/personal-monitor.svg";

export const PRICING_PLANS = [
  {
    title: "Annonce Standard",
    price: "GRATUIT",
    duration: "",
    features: [
      { text: "1 annonce", check: true },
      { text: "Diffusion pendant 15 jours", check: true },
      { text: "Multi-diffusion", check: false },
    ],
    packageNum: 0,
  },
  {
    title: "Annonce Multi-diffusion",
    price: "149€",
    duration: "pour 3 mois",
    features: [
      { text: "1 annonce", check: true },
      { text: "Diffusion pendant 3 mois", check: true },
      { text: "Multi-diffusion sur 35 sites", check: true },
    ],
    packageNum: 1,
  },
  {
    title: "Abonnement Premium",
    price: "250€",
    duration: "par mois / 12 mois",
    features: [
      { text: "10 annonces", check: true },
      { text: "Durée illimitée", check: true },
      { text: "Multi-diffusion sur 35 sites", check: true },
    ],
    packageNum: 2,
  },
]

export const OFFER_FEATURES = [
  { text: "Définition des profils adaptés à vos besoins", check: true },
  { text: "Diffusion des offres sur MécanoJob et nos 35 JobBoards", check: true },
  { text: "Sourcing ciblé dans notre base et nos CVthèques", check: true },
  { text: "Analyse des candidatures par nos experts", check: true },
  { text: "Entretiens structurés et suivi personnalisé pour une intégration", check: true },
]

export const TESTIMONIALS = [
  {
    name: "JULIEN",
    role: "Recruteur, Gérant d’un garage",
    text: "Nous avions du mal à trouver des profils qualifiés. Avec MécanoJob, nous avons enfin des candidatures pertinentes et des recrutements plus rapides",
    image: TestimonialImg1,
  },
  {
    name: "MAXIME",
    role: "Candidat, mécanicien automobile",
    text: "Grâce à MécanoJob, j’ai trouvé un emploi en moins d’une semaine ! Le site est clair, et le matching avec les offres m’a permis de postuler aux annonces les plus adaptées à mon profil.",
    image: TestimonialImg2,
  },
  {
    name: "Sophie",
    role: "Recruteur, Responsable RH en concession",
    text: "Recruter des profils techniques n’a jamais été aussi simple. Le matching nous fait gagner un temps précieux et les entretiens sont mieux ciblés. En quelques jours, nous avons trouvé le bon candidat !",
    image: TestimonialImg3,
  },
]

export const FEATURE = [
  {
    id: "expert",
    title: "Des experts du secteur",
    icon: ExpertIcon,
    description: "Des professionnels spécialisés qui comprennent vos besoins.",
  },
  {
    id: "visibility",
    title: "Une visibilité maximale",
    icon: VisibilityIcon,
    description: "Multi-diffusion de vos offres sur 35 JobBoards (Indeed, Monster, Figaro Emploi etc.)",
  },
  {
    id: "monitoring",
    title: "Un suivi personnalisé",
    icon: PersonalMonitorIcon,
    description: "Entretiens structurés et garantie de remplacement pendant la période d’essai.",
  },
];