import styled, { css } from "styled-components"

const CustomLabelCommon = css`
  .label {
    font-weight: 800;
    font-size: 19px;
    line-height: 28px;
    color: #023f73;
    display: flex;
  }

  .custom-radio {
    min-width: 50px;
    margin-left: 15px;
    height: 20px;
  }

  .label-disabled {
    color: #bec7cc;
    circle {
      fill: #bec7cc;
    }
  }

  .label-required:after {
    content: "*";
    font-weight: 500;
    font-size: 53px;
    color: #ff7200;
    padding-left: 15px;
  }
`

export const FormWrapper = styled.div`
  .button-action {
    gap: 20px;
  }

  ${CustomLabelCommon}
`
