import React, { useReducer, useContext, createContext } from "react"

const initialState = {
  isShowSearchAdv: false,
  isShowResult: false,
  listCV: [],
  listJob: [],
  profile: {
    cvList: [],
  },
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "UPDATE_RESULT_SEARCH_PAGE": {
      return {
        ...state,
        isShowSearchAdv: false,
        isShowResult: true,
        listCV: payload,
      }
    }
    case "UPDATE_RESULT_SEARCH_JOB_PAGE": {
      return {
        ...state,
        isShowResult: true,
        listJob: payload,
      }
    }
    case "SHOW_SEARCH_ADVANCED": {
      return {
        ...state,
        isShowSearchAdv: !state.isShowSearchAdv,
      }
    }
    case "GET_CV_LIST": {
      return {
        ...state,
        profile: {
          cvList: payload,
        },
      }
    }
    default:
      return state
  }
}

const MainContext = createContext(initialState)

const MainProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const _onUpdateResult = (payload) => {
    dispatch({
      type: "UPDATE_RESULT_SEARCH_PAGE",
      payload,
    })
  }

  const _onUpdateResultJob = (payload) => {
    dispatch({
      type: "UPDATE_RESULT_SEARCH_JOB_PAGE",
      payload,
    })
  }

  const _getCvList = (payload) => {
    dispatch({
      type: "GET_CV_LIST",
      payload,
    })
  }

  const _toggleSearchAdv = () => {
    dispatch({
      type: "SHOW_SEARCH_ADVANCED",
    })
  }

  return (
    <MainContext.Provider
      value={{
        ...state,
        _onUpdateResult,
        _onUpdateResultJob,
        _toggleSearchAdv,
        _getCvList,
      }}
    >
      {children}
    </MainContext.Provider>
  )
}

const useMainContext = () => useContext(MainContext)

export { MainProvider, useMainContext }
