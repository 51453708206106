import React from "reactn"
import { ModalBody } from "reactstrap"
import * as S from "./styles"

export const ShareSocialsModal = ({
  showModal,
  onNo,
  Title = "Partager sur",
  TitleBtn = "Annuler",
  onToggle = onNo,
  children,
}) => {
  return (
    <S.Wrapper isOpen={showModal} centered toggle={onToggle}>
      <ModalBody>
        <div className="quote-bx text-center mb-5">
          <div className="quote-info">
            <h3 className="text-uppercase">{Title}</h3>
            <S.ListSocial>{children}</S.ListSocial>
            <div className="left-job-buttons">
              <div onClick={onNo} className="site-button site-button-gap radius-xl text-uppercase">
                {TitleBtn}
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </S.Wrapper>
  )
}
