import styled from "styled-components"

export const Box = styled.div`
  border-radius: 30px;
  line-height: 2;
  padding: 1rem;
  margin-bottom: 0.5rem;
  box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
  margin-bottom: 30px;

  display: flex;
`

export const Left = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

export const Infor = styled.div`
  display: flex;
  width: 100%;
`

export const ImgInfor = styled.div`
  width: 50px;
  margin-right: 15px;

  & > span {
    & > span {
      width: 50px;
      height: 50px;

      border-radius: 50%;
    }
  }
`

export const TitleInfor = styled.div`
  width: 100%;
`

export const Summary = styled.div`
  width: 100%;
`

export const Right = styled.div``

export const Skills = styled.div`
  margin-bottom: 15px;

  & > span {
    display: flex;
    justify-content: space-between;

    width: 100%;

    & > span {
      width: 50px;
      height: 50px;

      border-radius: 50%;
    }
  }
`
