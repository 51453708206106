import styled from "styled-components";
import { Collapse } from "reactstrap";

export const StyledCollapseWrapper = styled.div``;

export const CollapseWrapper = styled.div`
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
  border-radius: 15px;
  padding: 20px;
  cursor: pointer;
`;

export const CollapseTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: margin 0.5s;

  margin-bottom: ${(props) => props.isOpen && "30px"};

  .title {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 50px;
    height: 64px;
    color: #023f73;

    svg {
      transform: scale(0.5);
    }
    .icon-checked {
      path {
        fill: #ff7200;
      }
      path:last-child {
        fill: #ffffff;
      }
    }
    span {
      margin-left: 15px;
    }

    @media (min-width: 1024px) {
      font-size: 30px;
    }
  }
  .title-icon {
    transition: transform 0.5s;
    transform: scale(1.5) ${(props) => props.isOpen && "rotate(180deg)"};
    margin-right: 15px;
  }
`;

export const StyledCollapse = styled(Collapse)`
  &[disabled] {
    opacity: 0.5;
    pointer-events: none
  }
`;
