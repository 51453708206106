import React, { useCallback, useEffect, useState, useGlobal } from "reactn"
import { useFormik } from "formik"
import { keyBy } from 'lodash'
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import * as S from "src/markup/Pages/Agenda/Recruiter/ScheduleMeeting/styles"
import { OfferFormInitialValues, OfferFormvalidationSchema } from "./FormValidation"
import { FormScheduleMeeting } from "src/markup/Pages/Agenda/Recruiter/components/index"
import { recruiterGetListOffers, recruiterCreateAgenda, recruiterDetailAgenda, recruiterEditAgenda } from "src/services/api"
import { populateOffer } from "src/services/config"
import { MecButton } from "src/markup/Components/MecButton"
import { ReactComponent as IconCancel } from "src/images/icons/icon-cancel.svg"
import { ReactComponent as IconChecked } from "src/images/icons/icon-checked.svg"
import { OFFER_STATUS, SORT_BY } from "src/constants"
import { durationOptions } from '../components/FormScheduleMeeting'
import qs from 'query-string';
import { Loading } from "src/markup/Element/Loading"
import moment from "moment"

export const ScheduleMeeting = () => {
  const location = useLocation();
  const [config] = useGlobal("config")
  const [initialFormValues, setInitialFormValues] = useState(OfferFormInitialValues);

  const [offers, setOffers] = useState([])
  const [isVideo, setIsVideo] = useState(false)
  const [cvs, setCvs] = useState([])

  const { t } = useTranslation()
  const history = useHistory()
  const [loading, setLoading] = useState(false);
  const { agenda } = qs.parse(location.search);

  const getListOffers = useCallback(async () => {
    try {
      setLoading(true);
      let offers = await recruiterGetListOffers({
        status: OFFER_STATUS.PUBLISHED,
        sort_by: SORT_BY.DESC,
      })

      const offerSelector = offers?.map((offerLiaison) => {
        const { jobNames } = populateOffer(config, offerLiaison)
        setCvs(
          offerLiaison.liaisons.map((liaison) => {
            if (!liaison.rejected && !liaison.hidden) {
              return {
                id: liaison.id,
                name: liaison.cv.name_cv,
              }
            }
            return null;
          })
        )
        return { id: offerLiaison.id, name: jobNames?.[0] };
      })

      setOffers(offerSelector)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false);
    }
  }, [config])

  useEffect(() => {
    getListOffers()
  }, [getListOffers])

  const handleAddInviteColleagues = () => {
    const data = [
      ...values.invite_colleagues,
      {
        email: "",
      },
    ]
    setFieldValue("invite_colleagues", data)
  }

  const handleRemoveInviteColleagues = (index) => {
    const data = [...values.invite_colleagues]
    data.splice(index, 1)
    setFieldValue("invite_colleagues", data)
  }

  const handleAddCvs = () => {
    const data = [
      ...values.cvs,
      null
    ]
    if (data.length <= cvs.length) {
      setFieldValue("cvs", data)
    }
  }

  const handleRemoveCvs = (index) => {
    const data = [...values.cvs]
    data.splice(index, 1)
    setFieldValue("cvs", data)
  }

  const formik = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema: () => OfferFormvalidationSchema({ t, isVideo, values }),
    onSubmit: async (values) => {
      const shadowValues = { ...values }
      shadowValues.liaisons = values.cvs.map(cv => cv.id)
      shadowValues.duration = values.duration.id
      shadowValues.offerId = values.offerId.id
      shadowValues.cvs = values.cvs.map(cv => {
        return {name_cv: cv.name, id: cv.id}
      })
      agenda && (shadowValues.id = agenda)

      try {
        if (agenda) {
          await recruiterEditAgenda(shadowValues)
        } else {
          await recruiterCreateAgenda(shadowValues)
        }
        toast.success("Entretien planifié avec succès");
        history.push('/recruiter/agenda/list')
      } catch (error) {
        return error
      }
    },
  })

  const { handleSubmit, isSubmitting, setFieldValue, values } = formik

  useEffect(() => {
    setIsVideo(values.isVideo)
  }, [values.isVideo])

  useEffect(() => {
    if (agenda) {
      setLoading(true);
      recruiterDetailAgenda(agenda).then(data => {
        const shadowOffers = keyBy(offers, 'id')
        const shadowDurationOptions = keyBy(durationOptions, 'id')

        const formValues = {
          offerId: shadowOffers[data?.offerId],
          cvs: data?.jobliaison?.map((liaison) => ({
            id: liaison?.cv?.id,
            name: liaison?.cv?.name_cv,
            status: liaison?.cv?.status,
          })),
          datetime_interview: moment(data?.time),
          duration: shadowDurationOptions[data?.duration],
          isVideo: data?.isVideo,
          address: data?.address,
          address1: data?.address1,
          address2: data?.address2,
          information: data?.information,
          organizer: data?.organizer,
          invite_colleagues: data?.invite_colleagues,
        }
        setInitialFormValues(formValues)
        setLoading(false)
      }).catch(error => console.log(error))
    }
  }, [location, offers, agenda])

  return (
    <S.Wrapper onSubmit={handleSubmit}>
      <div className="d-flex justify-content-center">
        <S.Heading>Planifier un entretien</S.Heading>
      </div>
      <hr className="v3-separator pb-xl-3" />

      <S.WrapperContent>
        {loading && <Loading />}
        <FormScheduleMeeting
          formik={formik}
          offers={offers}
          cvs={cvs}
          handleAddCvs={handleAddCvs}
          handleAddInviteColleagues={handleAddInviteColleagues}
          handleRemoveInviteColleagues={handleRemoveInviteColleagues}
          handleRemoveCvs={handleRemoveCvs}
          agenda={agenda}
        />
      </S.WrapperContent>
      <div className="w-100 d-flex flex-wrap justify-content-center button-action pb-5">
        <MecButton
          icon={IconCancel}
          type="primary"
          width={300}
          height={50}
          widthIcon={20}
          className="text-capitalize outline"
          onClick={() => history.push("/recruiter/agenda/list")}
        >
          Annuler
        </MecButton>

        <MecButton
          icon={IconChecked}
          type="primary isSubmit"
          width={300}
          height={50}
          widthIcon={20}
          className="text-capitalize ml-4"
          disabled={isSubmitting}
        >
          {isSubmitting ? "..." : "Publier"}
        </MecButton>
      </div>
    </S.Wrapper>
  )
}
