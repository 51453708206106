import React, { useRef } from "react"
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { useFormik } from "formik"
import { useHistory } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha"
import { MecButton } from "../../MecButton"
import { GetQuoteModalInitialValues, GetQuoteModalValidationSchema } from "./FormValidation"
import "./index.scoped.scss"
import { apiCreateQuote } from "src/services/api"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"

const GetQuoteModal = ({ isOpen, toggle }) => {
  const {t} = useTranslation()
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY
  const recaptchaRef = useRef(null)
  const history = useHistory()

  const handleReCAPTCHAChange = (value) => {
    formik.setFieldValue("capcha", value)
  }

  const formik = useFormik({
    initialValues: GetQuoteModalInitialValues,
    validateOnMount: true,
    validationSchema: GetQuoteModalValidationSchema(),
    onSubmit: async (values) => {
      try {
        await apiCreateQuote(values)
        toast.success("Créer un devis avec succès")
        history.push("/recruiter/login")
      } catch (error) {
        console.error("Erreur lors de l'envoi du formulaire :", error)
        recaptchaRef.current.reset()
        formik.setFieldValue("capcha", null)
      }
    },
  })

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="get-quote-modal">
      <form onSubmit={formik.handleSubmit}>
        <ModalHeader className="bg-white justify-content-center">
          <h2 className="text-orange">{t('get_a_quote').toUpperCase()}</h2>
        </ModalHeader>
        <ModalBody>
          <div>
            <input
              name="companyName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.companyName}
              placeholder={t('company_name')}
              onBlur={formik.handleBlur}
            />
            {formik.touched.companyName && formik.errors.companyName && (
              <div className="error-message">{t('required_field')}</div>
            )}
          </div>

          <Row>
            <Col>
              <input
                name="firstName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                placeholder={t('first_name')}
                onBlur={formik.handleBlur}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className="error-message">{t('required_field')}</div>
              )}
            </Col>
            <Col>
              <input
                name="lastName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                placeholder={t('last_name')}
                onBlur={formik.handleBlur}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <div className="error-message">{t('required_field')}</div>
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              <input
                name="phone"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.phone}
                placeholder={t('phone_number')}
                onBlur={formik.handleBlur}
              />
              {formik.touched.phone && formik.errors.phone && (
                <div className="error-message">{t('required_field')}</div>
              )}
            </Col>
            <Col>
              <input
                name="email"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                placeholder={t('email')}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="error-message">{t('required_field')}</div>
              )}
            </Col>
          </Row>

          <textarea
            name="comment"
            value={formik.values.comment}
            onChange={formik.handleChange}
            placeholder={t('comment')}
            onBlur={formik.handleBlur}
          />
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={recaptchaSiteKey}
            onChange={handleReCAPTCHAChange}
            className="d-flex justify-content-center"
          />
          {formik.touched.capcha && formik.errors.capcha && (
            <div className="error-message">{t('required_field')}</div>
          )}
        </ModalBody>
        <ModalFooter>
          <MecButton
            type="primary isSubmit"
            className="m-auto w-auto px-4"
            disabled={!formik.isValid || formik.isSubmitting}
          >
            {formik.isSubmitting ? t('sending') : t('send')}
          </MecButton>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default GetQuoteModal
