import React, { useEffect, useGlobal, useState, useCallback } from "reactn"
import { get, isEmpty, keys, values } from "lodash"
import { toast } from "react-toastify"
import { CV_STATUS, SORT_BY } from "src/constants"
import { Loading } from "src/markup/Element/Loading"
import { useMainContext } from "src/markup/Layout/MainLayout/MainContext"
import { CardJobSaved } from "src/markup/Pages/SearchPage/Candidate/components"
import { useGetFavoriteOffer } from "src/markup/Pages/SearchPage/Candidate/hooks/useGetFavoriteOffer"
import { getMyListCVCandidate, handleFavoriteOffer } from "src/services/api"
import { populateOffer } from "src/services/config"
import { isCandidate } from "src/services/auth"
import { useGetJobLiaisons } from "src/markup/Pages/SearchPage/Candidate/hooks/useGetJobLiaisons"

import * as S from "./styles"

export const SavedOffer = () => {
  const [config] = useGlobal("config")
  const {
    isGetList,
    favoriteList,
    setFavoriteList,
    offerFavoriteIdList,
    favoriteIdList,
  } = useGetFavoriteOffer()
  const { _getCvList } = useMainContext()
  const {
    loadingGetListOfferJoined,
    liaisonsOfferList,
    setAsyncLiaisonsOfferList,
  } = useGetJobLiaisons()

  const [loadingById, setLoadingById] = useState(null)

  const getMyCvs = useCallback(async () => {
    const CANDIDATE = isCandidate()

    if (CANDIDATE) {
      const res = await getMyListCVCandidate({
        status: CV_STATUS.PUBLISHED,
        sort_by: SORT_BY.DESC,
      })
      const optionsCvs = (res || []).map((cv) => ({
        value: cv.id,
        label: cv.name_cv,
      }))
      _getCvList(optionsCvs)
    }
  }, [_getCvList])

  useEffect(() => {
    getMyCvs()
  }, [getMyCvs])

  return (
    <S.Wrapper>
      {
        isGetList && <Loading />
      }
      <div className="d-flex justify-content-center">
        <S.Heading>Offres d'emploi enregistrées ({keys(favoriteList)?.length || 0})</S.Heading>
      </div>
      <hr className="v3-separator pb-xl-3" />
      {values(favoriteList).map((offer) => {
        if (isEmpty(offer)) return null
        const offerId = get(offer, "id")

        const data = populateOffer(config, offer)

        if (isEmpty(data)) return null

        const onRemoveFavorite = async () => {
          try {
            setLoadingById(offerId)
            const data = await handleFavoriteOffer(offerId)
            delete favoriteList[offerId]
            setFavoriteList({ ...favoriteList })
            toast.success(data.message, {
              autoClose: 2000,
              theme: "colored",
            })
          } catch (e) {
            toast.error(e.message, {
              autoClose: 2000,
              theme: "colored",
            })
          } finally {
            setLoadingById(null)
          }
        }

        const cartProps = { offer, data, offerId }

        const footerProps = {
          callback: setLoadingById,
          loadingGetListOfferJoined,
          offer,
          data,
          favoriteIdList,
          liaisonsOfferList,
          setAsyncLiaisonsOfferList,
          offerFavoriteIdList,
          onRemoveFavorite,
        }

        const loading = loadingById === offerId

        return (
          <S.StyledCard key={offerId} className="page-wraper mt-5">
            {loading && <Loading />}
            <S.CardDetailsOfferWrapper
              isPrefix={get(offer, "featured_offer", false)}
              disabled={loadingById && !loading}>
              <CardJobSaved {...cartProps} footerProps={footerProps} />
            </S.CardDetailsOfferWrapper>
          </S.StyledCard>
        )
      })}
    </S.Wrapper>
  )
}
