import { memo } from 'react'
import CheckIcon from "../../../../images/icons/check.svg"
import FailIcon from "../../../../images/icons/fail.svg"

const FeatureList = memo(({ items }) => (
  <ul className="feature-list">
    {items?.map((feature, index) => (
      <li key={`${feature.text}-${index}`}>
        <img src={feature.check ? CheckIcon : FailIcon} alt="" className="mr-3" />
        {feature.text}
      </li>
    ))}
  </ul>
))

export default FeatureList