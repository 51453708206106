import styled from "styled-components"

export const Heading = styled.h1`
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #ff7200;

  @media(max-width: 992px) {
    font-size: 30px;
  }
`

export const StyledCardCVComponent = styled.div`
  .styled-action {
    .note {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.75rem;
      color: #ff7200;
      margin-top: 15px;
    }
  }
`

export const StyledCardCV = styled.div`
  box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
  border-radius: 30px;

  & > div {
    border: none;
    box-shadow: none;
  }

  .icon-skills {
    circle {
      fill: #002d56;
    }
  }
`
