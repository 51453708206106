import * as Yup from "yup"
import { find, get } from 'lodash'

export const OfferFormInitialValues = {
  offerId: null,
  cvs: [null],
  datetime_interview: "",
  duration: null,
  isVideo: false,
  address: "",
  address1: "",
  address2: "",
  information: "",
  organizer: [
    {
      name: "",
      email: "",
    },
  ],
  invite_colleagues: [
    {
      email: "",
    },
  ],
}

const usedEmailCheck = (list, value) => {
  const data = find(list, (item => item.email === value))
  return !data?.email;
};

Yup.addMethod(Yup.array, 'unique', function (propertyName, message) {
  return this.test("unique", message, function(list) {
    const mapper = x => get(x, propertyName);
    const set = [...new Set(list.map(mapper))];
    const isUnique = list.length === set.length;
    if (isUnique) {
      return true;
    }

    const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
    return this.createError({ path: `[[invite_colleagues.${idx}].${propertyName}]`, message });
  });
});

export const OfferFormvalidationSchema = ({ t, isVideo, values }) => {
  return Yup.object().shape({
    offerId: Yup.object().required(t("required_field")).nullable(true),
    cvs: Yup.array().of(Yup.object().shape({id: Yup.number().required(t("required_field")),}).required(t("required_field")).nullable(true)),
    datetime_interview: Yup.string(t("invalid_field")).required(t("required_field")),
    duration: Yup.object().required(t("required_field")).nullable(true),
    address: !isVideo && Yup.string(t("invalid_field")).required(t("required_field")),
    address1: !isVideo && Yup.string(t("invalid_field")).required(t("required_field")),
    address2: !isVideo && Yup.string(t("invalid_field")).required(t("required_field")),
    organizer: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(t("required_field")),
        email: Yup.string().email(t("invalid_field")).required(t("required_field")).test("Unique", "Email déjà indiqué", (value) => {
          return usedEmailCheck(values.invite_colleagues, value);
        }),
      })
    ),
    invite_colleagues: Yup.array().of(
      Yup.object().shape({
        'email': Yup.string().email(t("invalid_field"))
      })
    ).unique('email', 'E-mail déjà utilisé'),
  })
}
