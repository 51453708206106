import React from 'reactn'
import { ModalBody } from 'reactstrap'
import { useGetDataModal } from 'src/services'
import * as S from './styles'
import { ReactComponent as IconCancel } from "src/images/icons/icon-cancel.svg"

export const ConfirmModal = (props) => {
  const {
    showConfirmModal, payload: { onNo, onYes, Title, Description }
  } = useGetDataModal();

  return (
    <S.Wrapper
      isOpen={showConfirmModal} centered
      toggle={props.onClose}
    >
      <ModalBody>
        <S.Cancel onClick={props.onClose}>
          <IconCancel width={30} height={30} />
        </S.Cancel>
        <div className="quote-bx text-center mb-5">
          <div className="quote-info">
            <h3 className="text-uppercase">{Title}</h3>
            <p>{Description}</p>
            <div className="left-job-buttons">
              <div
                onClick={onNo}
                className="site-button site-button-gap radius-xl text-uppercase"
              >
                No
              </div>
              <div
                onClick={onYes}
                className="site-button site-button-gap radius-xl text-uppercase"
              >
                Yes
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </S.Wrapper>
  )
}
