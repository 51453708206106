import React, {memo, useState, useLayoutEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import _find from "lodash/find";

import SidebarProfile from "src/markup/Layout/SidebarProfile";
import {
  SidebarWrapper,
  SlideMultipleSearchIcon,
  AppIcon,
  ProfileIcon,
} from "src/css/Sidebar";
import sidebar from "src/images/gallery/sidebar-candidate.jpg";

import { apiGetNotifications } from 'src/services/api'
import { get } from "lodash";

const renderSideBar = (document) => {
  return [
    {
      title: "Mes CV",
      icon: AppIcon,
      subs: [
        {
          title: "Nouveau CV",
          to: "/candidate/mycv/create",
        },
        {
          title: "Mes CV publiés",
          to: "/candidate/mycv",
        },
        {
          title: "Brouillons",
          to: "/candidate/mycv/draft",
        },
      ],
    },
    {
      title: "Trouver mon job",
      icon: SlideMultipleSearchIcon,
      subs: [
        {
          title: "Rechercher",
          to: "/candidate/searchjob",
        },
        {
          title: "Recherches enregistrées",
          to: "/candidate/saved-search",
        },
        {
          title: "Offres d'emploi enregistrées",
          to: "/candidate/offer/favorite",
        },
      ],
    },
    {
      title: "Candidatures",
      icon: ProfileIcon,
      subs: [
        {
          title: "Envoyées",
          to: "/candidate/application/sent",
          badgeNumber: get(document, "jobs.jobSent", 0),
        },
        {
          title: "Retenues",
          to: "/candidate/application/accepted",
          badgeNumber: get(document, "jobs.jobAccepted", 0)
        },
        {
          title: "Déclinées",
          to: "/candidate/application/declined",
          badgeNumber: get(document, "jobs.jobRejected", 0)
        },
      ],
    },
    {
      title: "Agenda",
      icon: SlideMultipleSearchIcon,
      subs: [
        {
          title: "Mes prochains entretiens",
          to: "/candidate/agenda/interviews",
          badgeNumber: get(document, 'agenda' , 0),
        },
        {
          title: "Entretiens passés",
          to: "/candidate/agenda/past",
        },
      ],
    },
    {
      title: "Messagerie",
      icon: AppIcon,
      subs: [
        {
          title: "Nouveau message",
          to: "/candidate/message/new",
        },
        {
          title: "Messages reçus",
          to: "/candidate/message/received",
          badgeNumber: get(document, 'messages.received', 0 ),
        },
        {
          title: "Messages envoyés",
          to: "/candidate/message/sent",
        },
        {
          title: "Messages archivés",
          to: "/candidate/message/archived",
        },
      ],
    },
  ];
}

const SidebarCandidate = () => {
  const [activeSub, setActiveSub] = useState("");
  const location = useLocation();
  const { pathname } = location;
  const [sidebarConfig, setSidebarConfig] = useState(() => renderSideBar());

  useLayoutEffect(() => {
    (async() => {
      const data = await apiGetNotifications();
      setSidebarConfig(renderSideBar(data))
    })()
  }, [])

  return (
    <SidebarWrapper className="div-sidebar" activeSub={activeSub}>
      <nav className="nav-sidebar-wrapper">
        {sidebarConfig.map((sidebar, idx) => (
          <div
            className="menu-myoffers menu-sidebar"
            onClick={() =>
              setActiveSub((prevState) =>
                prevState === sidebar.title ? "" : sidebar.title
              )
            }
            key={idx}
          >
            <div
              className={`sidebar-item ${
                _find(sidebar.subs, (sub) => sub.to === pathname) && "is-active"
              }`}
            >
              <sidebar.icon />
              <div className="menu-title">{sidebar.title}</div>
            </div>
            <ul
              className={`sub-menu-wrapper ${
                activeSub === sidebar.title && "active-sub"
              }`}
            >
              {sidebar.subs.map((sub, idx) => {
                return(
                  <NavLink
                    exact
                    to={sub.to}
                    className="sub-menu-item"
                    activeClassName={"is-active"}
                    key={idx}
                  >
                    {!!sub.badgeNumber && (
                      <div className="sub-menu-notification">{sub.badgeNumber > 10 ? '10+' : sub.badgeNumber}</div>
                    )}
                    <div className="sub-item-title">{sub.title}</div>
                  </NavLink>
              )})}
            </ul>
          </div>
        ))}
        <SidebarProfile
          baseRoute="candidate"
          activeSub={activeSub}
          setActiveSub={setActiveSub}
        />
      </nav>
      <div className="sidebar-pic">
        <img src={sidebar} alt="Sidebar" />
      </div>
    </SidebarWrapper>
  );
};

export default memo(SidebarCandidate);
