import React, { useCallback, useEffect, useState, memo } from "reactn"
import { useHistory } from "react-router-dom"
import { get } from "lodash"
import { ReactComponent as IconView } from "src/images/icons/eye.svg"
import { ReactComponent as TickIcon } from "src/images/icons/tick-icon.svg"
import { MecButton } from "src/markup/Components"
import { Loading } from "src/markup/Element/Loading"
import { ReactComponent as IconCreate } from "src/images/icons/note.svg"
import Header from "src/markup/Layout/Header"
import Footer from "src/markup/Layout/Footer"
import { formatPrice } from "src/utils"
import { getOrderList } from "src/services/api"
import Invoice from "./Invoice"
import * as S from "./styles"

const InvoicePage = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [orderList, setOrderList] = useState([])
  const [currentIdOrder, setCurrentIdOrder] = useState(null)
  const [isShow, setIsShow] = useState(false)

  const getInvoiceAsync = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await getOrderList()
      setOrderList(data)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getInvoiceAsync()
  }, [getInvoiceAsync])

  const onShowDetail = (id) => () => {
    Promise.resolve().then(setIsShow(true)).then(setCurrentIdOrder(id))
  }

  const onHide = useCallback(() => {
    setIsShow((prevIsShow) => !prevIsShow)
  }, [])

  return (
    <>
      <Header recruiter />

      <S.Wrapper>
      <div className="d-flex justify-content-center">
        <S.Heading>Mes factures</S.Heading>
      </div>
      <hr className="v3-separator pb-xl-3" />

        {loading ? (
          <Loading />
        ) : (
          <S.Box>
            {/* TODO: if list is empty, display feedback to user */}
            <S.STable responsive className="cart">
              <thead>
                <tr>
                  <th>N°</th>
                  <th>Date</th>
                  <th>Désignation</th>
                  <th>Montant</th>
                  <th>Mode de paiement</th>
                </tr>
              </thead>
              <tbody>
                {orderList.map((order) => {
                  return (
                    <tr key={order.id}>
                      <td>{order.code}</td>
                      <td style={{ minWidth: 200 }}>
                        {new Date(get(order, "createdAt", "")).toLocaleDateString("fr-FR")}
                      </td>
                      <td style={{ minWidth: 200 }}>
                        {get(order, "orderDetails", []).map((orderDetail) => (
                          <li key={orderDetail.id}>
                            {orderDetail.package_name}
                          </li>
                        ))}
                      </td>
                      <td style={{ minWidth: 200 }}>
                        <li>{formatPrice(get(order, "total", ""), 2)} € HT</li>
                        <li>{formatPrice(get(order, "total_ttc", ""), 2)} € TTC</li>
                      </td>
                      <td>
                        <div className="w-100 h-100 d-flex">
                          <MecButton icon={TickIcon} width={150} widthIcon={23}>
                            {get(order, "method_payment", "")?.toUpperCase()}
                          </MecButton>
                          <MecButton
                            className="mx-2"
                            icon={IconView}
                            widthIcon={23}
                            width={150}
                            onClick={onShowDetail(order.id)}
                          >
                            Voir
                          </MecButton>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </S.STable>
          </S.Box>
        )}
      </S.Wrapper>

      <Invoice isShow={isShow} id={currentIdOrder} onHide={onHide} />

      <div className="row pt-3 justify-content-center mb-5">
         <MecButton
           icon={IconCreate}
           width={350}
           height={44}
           type="primary"
           className="site-button site-button-gap radius-xl"
           onClick={() => history.push("/pricing")}
         >
           Souscrire à une nouvelle formule
         </MecButton>
       </div>

       <Footer />
    </>
  )
}

export default memo(InvoicePage)
