import styled from "styled-components"
import { Modal } from "react-bootstrap"

export const WrapperModal = styled(Modal)`
  overflow-x: scroll;

  .modal-content {
    min-width: 1000px;
    width: 100%;
    transform: translateX(-25%);
  }
`

export const Wrapper = styled.div`
  background: #f7f7f9;
  border-radius: 5px;
  padding: 20px 0;
  font-family: Rubik;

  @media (max-width: 768px) {
    min-width: 100%;
    transform: translateX(0);
  }
`

export const Header = styled.div`
  width: 100%;
  color: #3d3d3d;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
`

export const HeadingTable = styled.div`
  padding: 10px;
  background: #fdeee0;
  .label {
    color: black;
    font-size: 1rem;
  }

  .value {
    color: var(--orange300);
    font-size: 1.2rem;
    font-weight: 700;
    padding-left: 5px;
  }
`

export const TableInfo = styled.div`
  padding: 10px;
  .label {
    color: black;
    font-size: 1rem;
  }

  .value {
    color: #023F73;
    font-size: 0.875rem;
    padding-left: 5px;
    font-family: Rubik;
  }
`

export const TablePlan = styled.table`
  padding: 15px;

  tr:first-child {
    background: var(--orange300);
    color: #fff;
    font-weight: 700;
    text-align: center;
  }

  td {
    text-align: center;
  }
`

export const FooterInvoice = styled.div``

export const CopyRight = styled.div`
  padding: 20px;
  background: #fdeee0;
  color: var(--orange300);
  font-weight: 500;
  text-align: center;
  font-family: Rubik;
`
