import React, { useRef } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./index.scoped.scss"
import QuotationIcon from "./../../../../images/icons/quotation.svg"
import { TESTIMONIALS } from "../constants"

const TestimonialSlider = () => {
  const sliderRef = useRef(null)

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "185px",
    autoplay: true,
    autoplaySpeed: 3000,
    className: "slider-wrapper",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: false,
          centerPadding: "0px",
        },
      },
    ],
  }

  return (
    <div className="testimonial-slider">
      <Slider ref={sliderRef} {...settings}>
        {TESTIMONIALS.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <div className="testimonial-content">
              <div className="testimonial-text">
                <div>
                  <img src={QuotationIcon} className="mb-4" alt=""></img>
                </div>
                <p className="testimonial-quote">“{testimonial.text}”</p>
                <strong>{testimonial.name}</strong>
                <p className="testimonial-role">{testimonial.role}</p>
              </div>
              <img src={testimonial.image} alt={testimonial.name} className="testimonial-img" />
            </div>
          </div>
        ))}
      </Slider>

      <button className="prev-btn" onClick={() => sliderRef.current.slickPrev()}>
        ◀
      </button>
      <button className="next-btn" onClick={() => sliderRef.current.slickNext()}>
        ▶
      </button>
    </div>
  )
}

export default TestimonialSlider
