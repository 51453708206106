import React, { useDispatch } from "reactn"
import { MODAL } from "src/constants"
import { ConfirmModalAuth } from "./ConfirmModalAuth"
import { ConfirmApplyCv } from "./ConfirmApplyCv"
import { closeModal, useGetDataModal } from "src/services"
import { ConfirmModal } from "./ConfirmModal"

const modalComponentMatch = {
  [MODAL.CONFIRM_AUTH]: ConfirmModalAuth,
  [MODAL.CONFIRM_APPLY_CV]: ConfirmApplyCv,
  [MODAL.CONFIRM_MODAL]: ConfirmModal,
}

export const GlobalModal = (props) => {
  const { modalName } = useGetDataModal()
  const Component = modalComponentMatch[modalName]
  const onClose = useDispatch(closeModal)

  return <Component {...props} onClose={onClose} />
}
