import React, { useEffect, useCallback, useState } from "react"
import { useLocation, useHistory } from "react-router-dom"

import { loadStripe } from "@stripe/stripe-js/pure"; // https://stackoverflow.com/questions/45718026/stripe-js-making-duplicate-requests-new-requests-on-state-change
import { Elements } from "@stripe/react-stripe-js";

import Header from "src/markup/Layout/Header"
import Footer from "src/markup/Layout/Footer"
import PaiementCollapse from "./Components/PaiementCollapse"
import FacturationCollapse from "./Components/FacturationCollapse"
import * as S from "./styles"
import { getOrderById } from "src/services/api"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function Checkout() {
  const [order, setOrder] = useState()
  const [isOpen, setIsOpen] = useState("facturation")

  const { search } = useLocation()
  const history = useHistory()

  const handleGetOrderById = useCallback(async () => {
    const id = search?.split("=")[1]
    try {
      const res = await getOrderById(id)
      if (res.data.status !== 1) {
        history.push("/pricing")
      }
      setOrder(res)
    } catch (e) {
      history.push("/pricing")
      return e
    }
  }, [search, history])

  useEffect(() => {
    handleGetOrderById()
  }, [handleGetOrderById])

  const handleOpenCollapse = (collapse) => {
    setIsOpen((prev) => (prev === collapse ? "" : collapse))
  }

  return (
    <div className="page-wraper">
      <Header recruiter showTitle={false} />

      <div className="container-content">
        <div className="d-flex">
          <div className="col p-0" style={{ textAlignLast: "center", marginBottom: "50px" }}>
            <div className="d-flex justify-content-center">
              <h1 className="m-b5 text-uppercase text-orange pt-5">PAIEMENT</h1>
            </div>
            <hr className="v3-separator pb-xl-3"></hr>
            <div className="container">
              <S.FormWrapper>
                <Elements stripe={stripePromise}>
                  <FacturationCollapse order={order} handleOpenCollapse={handleOpenCollapse} isOpen={isOpen} />
                  <PaiementCollapse order={order} handleOpenCollapse={handleOpenCollapse} isOpen={isOpen} />
                </Elements>
              </S.FormWrapper>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}
