import styled from "styled-components";

export const PartnerComponentWrapper = styled.div``;

export const ListPartnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  padding: 55px;

  overflow: hidden;

  @media (min-width: 1440px) {
    max-height: 230px;
  }
`;

export const PartnerWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 1260px;

  div {
    width: 1260px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    animation: animate 40s linear infinite;
    animation-delay: -40s;

    img {
      transform: scale(0.7);
    }
  }

  div:nth-child(2) {
    animation: animate2 40s linear infinite;
    animation-delay: -20s;
  }

  @keyframes animate {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(-100%);
    }
  }

  @keyframes animate2 {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-200%);
    }
  }
`;
