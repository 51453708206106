import styled from "styled-components"

export const Wrapper = styled.div`
  padding-bottom: 50px;
  position: relative;
`

export const Heading = styled.h1`
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #ff7200;

  @media(max-width: 992px) {
    font-size: 30px;
  }
`


export const StyledCard = styled.div`
  position: relative;
  box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
  border-radius: 30px;

  & > div {
    border: none;
    box-shadow: none;
  }

  .icon-skills {
    circle {
      fill: #002d56;
    }
  }
`

export const CardDetailsOfferWrapper = styled.div`
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  background-color: #fff;
  padding: 30px;
  position: relative;
  margin-top: ${(props) => (props.isPrefix ? "180px" : "0px")} !important;
  pointer-events: ${(props) => (props.disabled ? "none": 'auto')} !important;
  opacity: ${(props) => (props.disabled ? "0.5": '1')} !important;

  &::before {
    opacity: ${(props) => (props.disabled ? "0.5": '1')} !important;
    content: "Urgent";
    position: absolute;
    width: 300px;
    height: 163px;
    left: -5px;
    top: -80px;
    background: #ffffff;
    box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
    border-radius: 40px;
    text-align: center;
    font-weight: 800;
    font-size: 26px;
    line-height: 38px;
    color: #023f73;
    z-index: -1;
    padding: 20px;
    display: ${(props) => (props.isPrefix ? "block" : "none")};
  }

  border: ${(props) => (props.isPrefix ? "none" : "5px solid #ff7200")};

  .d-inline-grid {
    display: inline-grid;
  }

  .icon-responsive {
    width: 40px;
    height: 40px;
  }

  @media (min-width: 1024px) {
    padding: 60px;
    &::before {
      width: 380px;
      top: -95px;
      font-size: 35px;
      line-height: 41px;
      padding: 30px;
    }
  }

  @media (min-width: 1440px) {
    .icon-responsive {
      width: 70px;
      height: 70px;
    }
  }
`
