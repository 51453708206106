import React from "reactn"
import { ReactComponent as Save } from "src/images/icons/save.svg"
import { ReactComponent as Search } from "src/images/icons/search.svg"
import { ReactComponent as Trash } from "src/images/icons/trash.svg"
import { ReactComponent as Wrench } from "src/images/icons/wrench.svg"
import { MecButton } from "src/markup/Components"

export const FooterSaveSearch = ({ onSearch, onUpdate, onDelete, disabled }) => {
  return (
    <div className="d-flex offset-md-2 offset-0 align-items-center justify-content-between mb-5">
      <div className="col-4">
        <MecButton
          icon={Search}
          type="primary"
          widthIcon={25}
          heightIcon={25}
          onClick={onSearch}
          disabled={disabled}
        >
          Rechercher
        </MecButton>
      </div>
      <div className="col-4">
        <MecButton
          disabled={disabled}
          icon={Wrench}
          widthIcon={25}
          heightIcon={25}
          type="outline"
          style={{ border: "1px solid var(--orange300)", borderRadius: 30 }}
          onClick={onUpdate}
        >
          Modifier ma recherche
        </MecButton>
      </div>

      <div className="col-4">
        <MecButton disabled={disabled} icon={Trash} widthIcon={20} type="primary" onClick={onDelete}>
          Supprimer la recherche
        </MecButton>
      </div>
    </div>
  )
}

export const FooterSearch = ({ onSearch, onSave, disabled }) => {
  return (
    <div className="d-flex flex-wrap offset-lg-4 offset-0 align-items-center justify-content-between">
      <div className="col">
        <MecButton
          icon={Search}
          type="primary"
          widthIcon={25}
          heightIcon={25}
          onClick={onSearch}
          disabled={disabled}
        >
          Rechercher
        </MecButton>
      </div>
      <div className="col">
        <MecButton
          disabled={disabled}
          icon={Save}
          widthIcon={25}
          heightIcon={25}
          type="outline"
          style={{ border: "1px solid var(--orange300)", borderRadius: 30 }}
          onClick={onSave}
        >
          Enregistrer ma recherche
        </MecButton>
      </div>
    </div>
  )
}
