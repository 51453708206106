import React from "react"
import Skeleton from "react-loading-skeleton"

import * as S from "./styles"

const CardLoadingSkeleton = () => {
  return (
    <S.Box className="col-12">
      <S.Left className="col-5">
        <S.Infor>
          <S.ImgInfor>
            <Skeleton />
          </S.ImgInfor>

          <S.TitleInfor>
            <Skeleton count={2} />
          </S.TitleInfor>
        </S.Infor>

        <S.Summary>
          <Skeleton count={2} />
        </S.Summary>
      </S.Left>

      <S.Right className="col-7">
        <S.Skills>
          <Skeleton count={5} />
        </S.Skills>

        <Skeleton count={3} />
      </S.Right>
    </S.Box>
  )
}

export default CardLoadingSkeleton 