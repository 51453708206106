import styled from 'styled-components'
import { ReactComponent as ShipIcon } from 'src/images/icons/ship-icon.svg'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border: 5px solid var(--orange300);
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  padding: 30px;
  background: var(--white);
  margin-top: ${(props) => (props.isPrefix ? "180px" : "0px")} !important;

  .border-bottom-5 {
    border-bottom: 5px solid #E8EEF2;
  }

  &::before {
    content: "Nouveau";
    position: absolute;
    width: auto;
    height: 163px;
    left: -5px;
    top: -80px;
    background: #FFFFFF;
    box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
    border-radius: 40px;
    text-align: center;
    font-weight: 800;
    font-size: 26px;
    line-height: 38px;
    color: #023F73;
    z-index: -1;
    padding: 20px;
    display: ${(props) => (props.isPrefix ? "block" : "none")};
  }
  @media (min-width: 1024px) {
    padding: 60px;
    &::before {
      top: -95px;
      font-size: 35px;
      line-height: 41px;
      padding: 30px;
    }
  }
`

export const WrapperShipIcon = styled(ShipIcon)`
  width: 60px;
  @media(max-width: 1280px) {
    width: 40px;
  }
  
  @media(max-width: 768px) {
    width: 30px;
  }
`

export const TextOrange = styled.div`
  font-family: Raleway;
  font-weight: ${({ bold }) => bold ? 'bold' : 600};
  color: var(--orange300);
  font-size: ${({ size }) => (size || 25) + 'px'};
`

export const TextBlue = styled.div`
  color: var(--blue600);
  font-weight: ${({ bold }) => bold ? 'bold' : 300};
  font-size: ${({ size }) => (size || 16) + 'px'};
`

// export const Tag = styled.div`
//   background: var(--white);
//   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
//   border-radius: 5px;
//   padding: 5px 10px;
//
//   position: absolute;
//   top: 0;
//   left: 0;
// `
