import React from 'react'
import { MainLayout } from 'src/markup/Layout/MainLayout'

import { ListAgendaInterview, AgendaPastList } from 'src/markup/Pages/Agenda/Candidate'
import { ScheduleInterview, ScheduleMeeting } from 'src/markup/Pages/Agenda/Recruiter'

import { AGENDA } from 'src/constants'

const childRecruiter = {
  [AGENDA.INTERVIEW]: ScheduleInterview,
  [AGENDA.SCHEDULE]: ScheduleMeeting,
}

const childCandidate = {
  [AGENDA.INTERVIEW]: ListAgendaInterview,
  [AGENDA.PAST]: AgendaPastList,
}

const Agenda = (props) => {
  const { recruiter, site } = props
  const Component = recruiter ? childRecruiter[site] : childCandidate[site]

  return (
    <MainLayout {...props}>
      <Component />
    </MainLayout>
  )
}

export default Agenda
