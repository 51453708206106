import {
  CardCvcElement,
  CardExpiryElement, CardNumberElement, useElements, useStripe
} from "@stripe/react-stripe-js";
import React, { useMemo, useState, useCallback } from "reactn";
import { addCard } from "src/services/api";
import { Wrapper } from "./cardStyles";
import useResponsiveFontSize from "./useResponsiveFontSize";
import { toast } from "react-toastify"
import { Loading } from "src/markup/Element/Loading";
import { MecButton } from "src/markup/Components";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const SplitForm = ({ onSuccess, onCancel }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    try {
      setLoading(true);
      const payload = await stripe.createToken(
        elements.getElement(CardNumberElement)
      );
      const { token } = payload;
      await addCard({ card_token: token.id });
      toast.success('Le moyen de paiement a été ajouté avec succès');
      onSuccess();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  }, [elements, stripe, onSuccess]);

  return (
    <Wrapper onSubmit={handleSubmit}>
      {loading && <Loading />}
      <label>
        Numéro de carte
        <CardNumberElement options={options} />
      </label>
      <label>
        Date d'expiration
        <CardExpiryElement options={options} />
      </label>
      <label>
        CVC / CVV
        <CardCvcElement options={options} />
      </label>
      <div className="d-flex justify-content-center" style={{ gap: "20px" }}>
        <MecButton
          type="primary"
          className="outlineNotHover"
          width={150}
          height={45}
          onClick={(e) => onCancel(e)}
        >
          Annuler
        </MecButton>
        <MecButton
          type="primary"
          width={200}
          height={45}
          style={{ fill: "none" }}
          disabled={!stripe}
          isSubmit>
          Enregistrer
        </MecButton>
      </div>
    </Wrapper>
  );
};

export default SplitForm;
