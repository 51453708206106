import React, { useGlobal, useState } from "reactn";
import { useFormik } from "formik"
import * as Yup from "yup"

import Jobsection from "./Jobsection";
import Header from "src/markup/Layout/Header";
import Footer from "src/markup/Layout/Footer";
import MecUrgentComponent from "./OffersUrgent";
import { DISTANCE_RANGES } from "src/constants";
import { isRecruiter } from 'src/services/auth'
import { PageWrapper } from "./styles";
import { AuthModal } from "src/markup/Components/Modals";
import MecPartnerComponent from "./Partners";
import Banner from "./Banner";

export default function Homepage() {
  const [toggle, setToggle] = useState(false);

  const [config] = useGlobal("config");

  const [update, setUpdate] = useState([]);

  const RECRUITER = isRecruiter();

  const request = { type: "recruiter" };

  const formik = useFormik({
    initialValues: {
      joblistId: null,
      pres_city: "",
    },
    enableReinitialize: true,
    validationSchema: () =>
      Yup.object().shape({
        joblistId: Yup.object()
          .required("Veuillez sélectionner un job")
          .nullable(true),
        pres_city: Yup.string()
          .trim()
          .required('Veuillez renseigner une ville')
          .nullable(true)
      }),
    onSubmit: async (values) => {
      try {
        request.joblistId = values.joblistId.id;
        request.city = `${values.pres_city}, France`;
        request.maxRange = DISTANCE_RANGES[DISTANCE_RANGES.length - 1].value; // 50 km as default maxRange?

        setUpdate(request);
      } catch (error) {
        return error
      }
    },
  })

  return (
    <PageWrapper className="page-wraper">
      <Header showTitle={false} />

      <div className="page-content" style={{ backgroundColor: "#fff" }}>
        <Banner config={config} formik={formik} />
        <Jobsection search={update} setToggle={setToggle} />
        <MecUrgentComponent setToggle={setToggle} />
        <MecPartnerComponent />
        {!RECRUITER && <AuthModal setToggle={setToggle} toggle={toggle} />}
      </div>
      <Footer />
    </PageWrapper>
  );
}
