import React from 'reactn'
import { Link, useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { FormGroup, Spinner } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { loginSchema } from 'src/markup/Pages/AuthPage/validationSchema'
import { MecInput } from 'src/markup/Components/Forms'

import { cacheUser, loginRecruiter } from 'src/services/auth'
import * as S from 'src/markup/Pages/AuthPage/Login/Recruiter/styles'

import tickIcon from 'src/images/gallery/tick-icon.png'

export const RecruiterLogin = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: () => loginSchema({ t }),
    onSubmit: async (values) => {
      const { email, password } = values
      const user = await loginRecruiter(email, password)
      if (user) {
        cacheUser(user, () => history.push('/recruiter/myoffer'))
      }
    },
  })

  const { isSubmitting, handleSubmit } = formik

  return (
    <form onSubmit={handleSubmit}>
      <S.Heading>
        <span>Connexion</span>
      </S.Heading>
      <FormGroup>
        <MecInput
          name="email"
          placeholder="E-mail"
          formik={formik}
          colorTxtError="var(--blue600)"
        />
      </FormGroup>
      <FormGroup>
        <MecInput
          type="password"
          name="password"
          placeholder="Mot de passe"
          formik={formik}
          colorTxtError="var(--blue600)"
        />
      </FormGroup>

      <S.NormalText className="pb-5">
        <div>
          <span onClick={() => history.push('/recruiter/forgot-password')} className="underline mr-3">Mot de passe oublié ?</span>
        </div>
      </S.NormalText>
      <Spinner color="secondary" type="grow">
        Chargement...
      </Spinner>
      <div className="mx-auto">
        <S.Button disabled={isSubmitting}>
          {isSubmitting ? (
            '...'
          ) : (
            <div className="d-flex justify-content-center">
              <div className="pr-2">
                <img src={tickIcon} alt="tick-icon" />
              </div>
              <div>Valider</div>
            </div>
          )}
        </S.Button>
      </div>

      <Link to="/recruiter/register">
        <S.NormalText className="text-bottom">
          Pas encore inscrit ?{' '}
          <span className="underline">Créez votre compte</span>
        </S.NormalText>
      </Link>
    </form>
  )
}
