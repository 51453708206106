import styled from "styled-components"

export const Content = styled.div`
  margin-bottom: 50px;
  margin-top: 65px;
  padding: 0 20px;

  @media (min-width: 992px) {
    padding: 0 50px;
  }
`

export const Heading = styled.h1`
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #ff7200;

  @media(max-width: 992px) {
    font-size: 30px;
  }
`
