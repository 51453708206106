import React from "reactn";
import MecUrgentCard from "./MecUrgentCard";
import { UrgentComponentWrapper } from "./styles";

const MecUrgentComponent = ({ setToggle }) => {
  return (
    <UrgentComponentWrapper className="container">
      <div className="section-head text-center text-uppercase">
        <h3 className="text-orange">À pourvoir en urgence</h3>
        <hr className="v3-separator pb-xl-3" />
      </div>
      <MecUrgentCard setToggle={setToggle} />
    </UrgentComponentWrapper>
  );
};

export default MecUrgentComponent;
