import React, { memo, useGlobal, useMemo } from "reactn"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { FormGroup } from "reactstrap"
import { SEARCH } from "src/constants"
import { ReactComponent as ShapeDown } from "src/images/icons/shape-down.svg"
import { Loading } from "src/markup/Element/Loading"
import { MecTabSwitch } from "src/markup/Components/Forms"
import { useMainContext } from "src/markup/Layout/MainLayout/MainContext"
import {
  getSchemaValidation,
  INITIAL_VALUES,
  LEVELS,
  renderFields,
  SEARCH_ADVANCED,
  SEARCH_BASIC,
  SEARCH_BASIC_KEY,
  SECTION_SEARCH_KEY,
} from "src/markup/Pages/SearchPage/Recruiter/components/FormSearchCV/constant"
import {
  FooterSaveSearch,
  FooterSearch,
} from "src/markup/Pages/SearchPage/Recruiter/components/FormSearchCV/FooterSearch"
import { requestSearchCv, saveSearchCv } from "src/services/api"
import { checkFormikHasValue } from "src/utils"
import _, { get } from "lodash"
import * as S from "./styles"

export const FormSearchCV = memo(({ site, onDelete, onUpdate, formItem = {} }) => {
  const { t } = useTranslation();
  const [config] = useGlobal("config")
  const [user] = useGlobal("user")
  const history = useHistory()
  const { isShowSearchAdv, _onUpdateResult, _toggleSearchAdv } = useMainContext()

  const gender = user?.gender?.toLowerCase()

  const onHandleCollapse = () => {
    _toggleSearchAdv()
  }

  const initialValues = useMemo(() => (site === SEARCH.SAVED_SEARCH ? formItem.data_save_search : INITIAL_VALUES), [formItem.data_save_search, site])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: () => getSchemaValidation({ t }),
    onSubmit: async (values, { setSubmitting }) => {
      const {
        joblistId,
        pres_jobOtherName,
        search_exp,
        search_qualityMain,
        search_quality2,
        search_quality3,
        search_formDiploma,
        search_formDiplomaOption,
        search_mission,
        search_skill,
        search_skillComp,
        search_tools,
        search_language,
        extra_driversLicenceB,
        city,
        maxRange,
        extra_criminalRecord,
      } = values

      const payloadFormat = {
        joblistId: joblistId.id,
        pres_jobOtherName: pres_jobOtherName && "" + pres_jobOtherName.id,
        search_exp: search_exp && search_exp.id,
        search_qualityMain: search_qualityMain && [
          {
            qualityMainId: search_qualityMain.id,
            name: search_qualityMain.name,
          },
        ],
        search_quality2: search_quality2 && {
          id: search_quality2.id,
        },
        search_quality3: search_quality3 && {
          id: search_quality3.id,
        },
        search_formDiploma: search_formDiploma && {
          id: search_formDiploma.id,
          name: search_formDiploma.name,
        },
        search_formDiplomaOption: search_formDiplomaOption && {
          id: search_formDiplomaOption.id,
          name: search_formDiplomaOption.name,
        },
        search_mission: search_mission && [
          {
            id: search_mission.id,
            name: search_mission.name,
          },
        ],
        search_skill: search_skill && [
          {
            joblistId: search_skill.joblistId,
            id: search_skill.id,
            name: search_skill.name,
          },
        ],
        search_skillComp: search_skillComp && [
          {
            id: search_skillComp.id,
            name: search_skillComp.name,
          },
        ],
        search_tools: search_tools && [
          {
            id: search_tools.id,
            name: search_tools.name,
            joblistId: search_tools.joblistId,
          },
        ],
        search_language: search_language && [
          {
            id: search_language.id,
            name: search_language.name,
          },
        ],
        extra_driversLicenceB: extra_driversLicenceB && extra_driversLicenceB.id,
        city,
        maxRange,
        extra_criminalRecord,
      }

      const payload = _(payloadFormat).omitBy(_.isUndefined).omitBy(_.isNull).value()
      try {
        const data = await requestSearchCv(payload)
        isShowSearchAdv && _toggleSearchAdv()
        _onUpdateResult(data)
      } catch (e) {
      } finally {
        setSubmitting(false)
      }
    },
  })

  const hasValueForm = useMemo(() => checkFormikHasValue(formik.values), [formik.values])

  const onSaveForm = async () => {
    try {
      formik.setSubmitting(true)
      const data = await saveSearchCv({
        data_save_search: formik.values,
        name_search: [
          formik.values?.joblistId?.name,
          formik.values?.city
        ].filter(Boolean).join(', '),
      })

      if (data) {
        toast.success(data.message, {
          autoClose: 2000,
          theme: "colored",
        })
      }

      history.push('/recruiter/saved-search')
    } catch (e) {
      console.log(e);
    } finally {
      formik.setSubmitting(false)
    }
  }

  const onDeleteForm = async () => {
    onDelete(formItem.id)
  }

  const onUpdateForm = async () => {
    onUpdate(formItem.id, formik.values)
  }

  if (!config) return null

  const FooterAction = {
    [SEARCH.CV]: FooterSearch,
    [SEARCH.SAVED_SEARCH]: FooterSaveSearch,
  }[site]

  const propsDataForm = { config, formik, gender }

  return (
    <S.FormWrapper>
      <S.BoxContent>
        {site === SEARCH.SAVED_SEARCH && (
          <S.HeadingSearch>{get(formItem, "name_search", "")}</S.HeadingSearch>
        )}
        <S.Title>Quel candidat recherchez-vous ?</S.Title>
        {formik.isSubmitting && <Loading />}

        <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
          {SEARCH_BASIC_KEY.map((key, idx) => {
            const { className, label, isRequired, isRange, ...rest } = SEARCH_BASIC[key]
            const restProps = { ...rest, ...propsDataForm }
            return (
              <div key={idx} className={className}>
                <div className="label py-3">
                  {label} {isRequired && <span className="text-red">*</span>}
                </div>
                {renderFields(restProps)}
              </div>
            )
          })}
        </div>
      </S.BoxContent>

      <div className="py-4">
        <S.BoxContent>
          <div className="d-flex align-items-center justify-content-between" onClick={onHandleCollapse}>
            <S.Title>Recherche avancée</S.Title>
            <S.Icon isOpen={isShowSearchAdv}>
              <ShapeDown />
            </S.Icon>
          </div>
          <S.MyCollapse isOpen={isShowSearchAdv}>
            <S.HeadingOrange className="py-3">
              Tous les champs ci-dessous sont facultatifs, spécifiez vos recherches en fonction de vos critères.
            </S.HeadingOrange>

            {SECTION_SEARCH_KEY.map((key, idx) => {
              const { title, keyInSection, inputs } = SEARCH_ADVANCED[key]
              return (
                <div key={idx}>
                  <S.HeadingOrange bold isBefore className="pt-5">
                    {title}
                  </S.HeadingOrange>
                  <div className="row align-items-center justify-content-between">
                    {keyInSection.map((keySection, ids) => {
                      const { className, label, isRequired, isRange, ...rest } = inputs[keySection]
                      const restProps = { ...rest, ...propsDataForm }
                      return (
                        <div key={ids} className={className}>
                          <div className="label py-3">{label}</div>
                          {renderFields(restProps)}
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}

            <FormGroup>
              <div className="pt-4 d-flex align-items-center justify-content-between">
                <div className="label">Le candidat doit-il obligatoirement avoir un casier judiciaire vierge ?</div>
                <MecTabSwitch
                  style={{ width: 250 }}
                  tabs={LEVELS}
                  activeTab={formik.values["extra_criminalRecord"] ? 0 : 1}
                  onChange={(value) => {
                    formik.setFieldValue("extra_criminalRecord", !value)
                  }}
                />
              </div>
            </FormGroup>
          </S.MyCollapse>
        </S.BoxContent>
      </div>

      <FooterAction
        onSearch={formik.handleSubmit}
        onSave={onSaveForm}
        onUpdate={onUpdateForm}
        onDelete={onDeleteForm}
        disabled={!hasValueForm || formik.isSubmitting}
      />
    </S.FormWrapper>
  )
})
